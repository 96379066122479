@charset "UTF-8";
/*
* Trunk version 2.0.0
*/
/** @group Base */
a:focus,
button:focus {
	outline: none !important;
}

button::-moz-focus-inner {
	border: 0;
}

*:focus {
	outline: none;
}

blockquote {
	padding: 0;
	margin: 0;
}

input,
button,
select,
textarea {
	outline: none;
}

label {
	margin-bottom: 0;
}

p {
	margin: 0;
}

ul,
ol {
	list-style: none;
	padding: 0;
	margin: 0;
}

ul li,
ol li {
	display: block;
}

dl {
	margin: 0;
}

dt,
dd {
	line-height: inherit;
}

dt {
	font-weight: inherit;
}

dd {
	margin-bottom: 0;
}

cite {
	font-style: normal;
}

form {
	margin-bottom: 0;
}

blockquote {
	padding-left: 0;
	border-left: 0;
}

address {
	margin-top: 0;
	margin-bottom: 0;
}

figure {
	margin-bottom: 0;
}

html p a:hover {
	text-decoration: none;
}

/*
*
* Contexts
*/
/*
*
* Typography
*/
body {
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	font-size: 14px;
	line-height: 1.71429;
	font-weight: 400;
	letter-spacing: normal;
	color: #151515;
	background-color: #ffffff;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: subpixel-antialiased;
}

h1, h2, h3, h4, h5, h6, [class*='heading-'] {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: 600;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	letter-spacing: normal;
	color: #151515;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, [class*='heading-'] a {
	color: inherit;
}

h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, [class*='heading-'] a:hover {
	color: #01b3a7;
}

h1,
.heading-1 {
	font-size: 40px;
	line-height: 0.88;
	font-weight: 400;
	letter-spacing: .075em;
}

@media (min-width: 768px) {
	h1,
	.heading-1 {
		font-size: 46px;
	}
}

@media (min-width: 992px) {
	h1,
	.heading-1 {
		font-size: 54px;
	}
}

@media (min-width: 1200px) {
	h1,
	.heading-1 {
		font-size: 64px;
		line-height: 1.2;
	}
}

h2,
.heading-2 {
	font-size: 32px;
	line-height: 1.2;
}

@media (min-width: 768px) {
	h2,
	.heading-2 {
		font-size: 36px;
	}
}

@media (min-width: 992px) {
	h2,
	.heading-2 {
		font-size: 40px;
	}
}

@media (min-width: 1200px) {
	h2,
	.heading-2 {
		font-size: 48px;
		line-height: 1.2;
	}
}

h3,
.heading-3 {
	font-size: 28px;
	line-height: 1.2;
}

@media (min-width: 768px) {
	h3,
	.heading-3 {
		font-size: 30px;
	}
}

@media (min-width: 992px) {
	h3,
	.heading-3 {
		font-size: 36px;
		line-height: 1.2;
	}
}

h4,
.heading-4 {
	font-size: 20px;
	line-height: 1.2;
}

@media (min-width: 768px) {
	h4,
	.heading-4 {
		font-size: 24px;
		line-height: 1.2;
	}
}

h5,
.heading-5 {
	font-size: 21px;
	line-height: 1.2;
	font-weight: 500;
}

h6,
.heading-6 {
	font-size: 18px;
	line-height: 1.2;
	font-weight: 500;
}

small,
.small {
	display: block;
	font-size: 12px;
	line-height: 1.5;
}

mark,
.mark {
	padding: 2px 4px;
	color: #ffffff;
	background: #151515;
}

.big {
	font-size: 18px;
	line-height: 1.55556;
}

.lead {
	font-size: 24px;
	line-height: 34px;
	font-weight: 300;
}

code {
	padding: 10px 5px;
	border-radius: 0.2rem;
	font-size: 90%;
	color: #111111;
	background: #f2f2f2;
}

p [data-toggle='tooltip'] {
	color: #b7b7b7;
}

p [style*='max-width'] {
	display: inline-block;
}

/*
*
* Brand
*/
.brand {
	display: inline-block;
}

.brand .brand-logo-light {
	display: none;
}

.brand .brand-logo-dark {
	display: block;
}

/*
*
* Links
*/
a {
	transition: all 0.3s ease-in-out;
}

a, a:focus, a:active, a:hover {
	text-decoration: none;
}

a, a:focus, a:active {
	color: #01b3a7;
}

a:hover {
	color: #016760;
}

a[href*='tel'], a[href*='mailto'] {
	white-space: nowrap;
}

.link-hover {
	color: #016760;
}

.link-press {
	color: #016760;
}

.privacy-link {
	display: inline-block;
}

* + .privacy-link {
	margin-top: 40px;
}

.link-classic {
	display: inline-block;
	font-size: 18px;
	line-height: 1.334;
	letter-spacing: normal;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.link-classic, .link-classic:focus, .link-classic:active {
	color: rgba(21, 21, 21, 0.4);
}

.link-classic:hover {
	color: #151515;
}

.link-classic span {
	display: inline-flex;
	vertical-align: middle;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 3px 10px;
	line-height: 24px;
	width: 25px;
	opacity: .13;
	transition: all .3s ease;
}

.link-classic span::before, .link-classic span::after {
	display: inline-block;
	vertical-align: middle;
	content: '';
	transition: inherit;
}

.link-classic span::before {
	height: 2px;
	flex-grow: 1;
	background-color: #151515;
}

.link-classic span::after {
	width: 0;
	height: 0;
	margin-left: 3px;
	border-style: solid;
	border-width: 3px 0 3px 6px;
	border-color: transparent transparent transparent #151515;
}

.link-classic:hover span {
	width: 40px;
	opacity: 1;
}

* + .link-classic {
	margin-top: 30px;
}

@media (min-width: 768px) {
	* + .link-classic {
		margin-top: 40px;
	}
}

@media (min-width: 992px) {
	.container + .link-classic {
		margin-top: 56px;
	}
}

.gallery-title,
.link-instafeed-3 {
	display: inline-block;
	min-width: 210px;
	padding: 8px 40px 10px;
	font-weight: 400;
	letter-spacing: .05em;
	background: #ffffff;
}

.gallery-title a, .gallery-title a:focus, .gallery-title a:active,
.link-instafeed-3 a,
.link-instafeed-3 a:focus,
.link-instafeed-3 a:active {
	color: #50ba87;
}

.gallery-title a:hover,
.link-instafeed-3 a:hover {
	color: #01b3a7;
}

.gallery-title {
	font-weight: 500;
	font-size: 14px;
}

.gallery-title a {
	color: #01b3a7;
	text-transform: uppercase;
	display: inline-block;
	margin-left: 6px;
	font-weight: 500;
}

.gallery-title a:hover {
	color: #50ba87;
}

/*
*
* Blocks
*/
.block-center, .block-sm, .block-lg {
	margin-left: auto;
	margin-right: auto;
}

.block-sm {
	max-width: 560px;
}

.block-lg {
	max-width: 768px;
}

.block-center {
	padding: 10px;
}

.block-center:hover .block-center-header {
	background-color: #01b3a7;
}

.block-center-title {
	background-color: #ffffff;
}

@media (max-width: 1599.98px) {
	.block-center {
		padding: 20px;
	}
	.block-center:hover .block-center-header {
		background-color: #ffffff;
	}
	.block-center-header {
		background-color: #01b3a7;
	}
}

/*
*
* Boxes
*/
.box-icon-creative {
	text-align: left;
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
}

.box-icon-creative-2 .box-icon-creative-icon {
	color: #50ba87;
}

.box-icon-creative-2 .box-icon-creative-title a:hover {
	color: #50ba87;
}

.box-icon-creative-count {
	position: relative;
	left: 0;
	font-size: 80px;
	line-height: 1;
	font-weight: 300;
	letter-spacing: .025em;
	color: #f2f2f2;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	transition: all .2s ease;
}

@media (min-width: 1200px) {
	.box-icon-creative-count {
		line-height: 0.675;
	}
}

.box-icon-creative-header > * {
	display: inline-block;
	vertical-align: bottom;
}

@media (min-width: 576px) {
	.box-icon-creative-header {
		margin: 0 5px;
	}
}

@media (min-width: 768px) {
	.box-icon-creative-header {
		margin: 0;
	}
}

.box-icon-creative-icon {
	position: relative;
	text-align: center;
	width: 64px;
	height: 64px;
	font-size: 36px;
	line-height: 64px;
	color: #01b3a7;
}

.box-icon-creative-icon::after {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 2px solid #151515;
	transition: all .3s ease;
}

.box-icon-creative-decor {
	position: relative;
	height: 43px;
	width: 54px;
	margin: 0 12px;
	border-top: 2px solid #151515;
	transition: all .2s ease;
}

.box-icon-creative-decor::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	height: 50%;
	width: 2px;
	background: #151515;
	transition: all .2s ease;
}

.box-icon-creative-title {
	font-weight: 400;
}

.box-icon-creative-title a, .box-icon-creative-title a:focus, .box-icon-creative-title a:active {
	color: inherit;
}

.box-icon-creative-title a:hover {
	color: #01b3a7;
}

.box-icon-creative-text {
	letter-spacing: .025em;
	color: #9b9b9b;
}

@media (min-width: 992px) {
	.desktop .box-icon-creative:hover .box-icon-creative-icon::after {
		border-color: #01b3a7;
		transform: rotate(32deg);
	}
	.desktop .box-icon-creative:hover .box-icon-creative-decor {
		border-color: #01b3a7;
	}
	.desktop .box-icon-creative:hover .box-icon-creative-decor:before {
		height: 100%;
		background: #01b3a7;
	}
	.desktop .box-icon-creative:hover .box-icon-creative-count {
		left: 4px;
		color: #01b3a7;
	}
	.desktop .box-icon-creative-2:hover .box-icon-creative-icon::after {
		border-color: #50ba87;
	}
	.desktop .box-icon-creative-2:hover .box-icon-creative-decor {
		border-color: #50ba87;
	}
	.desktop .box-icon-creative-2:hover .box-icon-creative-decor:before {
		background: #50ba87;
	}
	.desktop .box-icon-creative-2:hover .box-icon-creative-count {
		color: #50ba87;
	}
}

* + .box-icon-creative-title {
	margin-top: 16px;
}

* + .box-icon-creative-text {
	margin-top: 10px;
}

@media (min-width: 1200px) {
	.box-icon-creative-text {
		max-width: 94%;
	}
}

.box-icon-modern {
	text-align: center;
	max-width: 370px;
	min-height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 66px 15px 50px;
	background: #ffffff;
	box-shadow: 0 0 13px -4px rgba(0, 0, 0, 0.17);
	transition: all .3s ease;
}

.box-icon-modern-2 {
	padding-top: 42px;
	padding-bottom: 35px;
}

.box-icon-modern-2 .box-icon-modern-decor {
	margin-top: 18px;
	margin-bottom: 15px;
}

.box-icon-modern-2 .box-icon-modern-text {
	letter-spacing: .025em;
}

.box-icon-modern-2 * + .box-icon-modern-title {
	margin-top: 18px;
}

.box-icon-modern-3 {
	padding-top: 46px;
	padding-bottom: 30px;
}

.box-icon-modern-3 .box-icon-modern-decor {
	margin-top: 18px;
	margin-bottom: 15px;
}

.box-icon-modern-3 .box-icon-modern-text {
	letter-spacing: .025em;
}

.box-icon-modern-3 * + .box-icon-modern-title {
	margin-top: 28px;
}

.box-icon-modern-custom {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	padding-top: 36px;
	padding-bottom: 38px;
}

.box-icon-modern-icon {
	position: relative;
	font-size: 47px;
	line-height: 1;
	color: inherit;
	z-index: 1;
}

.box-icon-modern-icon::after {
	position: absolute;
	content: '';
	left: 50%;
	bottom: 9px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 34.5px 60px 34.5px;
	border-color: transparent transparent #01b3a7 transparent;
	transform: translate3d(-50%, 0, 0) rotate(15deg);
	transform-origin: 50% 80%;
	transition: all .3s ease;
	z-index: -1;
}

.box-icon-modern-title a, .box-icon-modern-title a:focus, .box-icon-modern-title a:active {
	color: inherit;
}

.box-icon-modern-title a:hover {
	color: #01b3a7;
}

.box-icon-modern-big-title {
	line-height: 1.04;
}

.box-icon-modern-decor {
	position: relative;
	max-width: 126px;
	width: 100px;
	height: 2px;
	margin: 20px auto 22px;
}

.box-icon-modern-decor::before {
	position: absolute;
	content: '';
	height: 100%;
	left: 15px;
	right: 15px;
	background: #d7d7d7;
	transition: all .3s ease;
}

.box-icon-modern-decor::after {
	position: absolute;
	content: '';
	height: 100%;
	left: 50%;
	right: 50%;
	background: #01b3a7;
	transition: all .3s ease;
}

.box-icon-modern-text {
	max-width: 320px;
	margin-left: auto;
	margin-right: auto;
}

* + .box-icon-modern-title {
	margin-top: 16px;
}

.box-icon-modern-decor + .button {
	margin-top: 8px;
}

.box-icon-modern-decor + .box-icon-modern-text {
	margin-top: 0;
}

@media (min-width: 992px) {
	.desktop .box-icon-modern:hover {
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
	}
	.desktop .box-icon-modern:hover .box-icon-modern-icon:after {
		transform: translate3d(-50%, 0, 0) rotate(0deg);
	}
	.desktop .box-icon-modern:hover .box-icon-modern-title {
		letter-spacing: .1em;
	}
	.desktop .box-icon-modern:hover .box-icon-modern-decor::before {
		left: 50%;
		right: 50%;
		transition-duration: .2s;
	}
	.desktop .box-icon-modern:hover .box-icon-modern-decor::after {
		left: 0;
		right: 0;
	}
}

@media (min-width: 1200px) {
	.box-icon-modern-3 {
		padding: 66px 30px 50px;
	}
}

.ie-edge .box-icon-modern-custom,
.ie-10 .box-icon-modern-custom,
.ie-11 .box-icon-modern-custom {
	height: 100%;
}

.box-icon-classic {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 2px;
	min-height: 100%;
	background: #ffffff;
	transition: all .2s ease;
	z-index: 1;
}

.box-icon-classic::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 8px solid #f4f4f4;
	transition: all .2s ease;
	z-index: -1;
}

.box-icon-classic-2 .box-icon-classic-icon {
	color: #50ba87;
}

.box-icon-classic-3::before {
	top: 8px;
	right: 8px;
	bottom: 8px;
	left: 8px;
	border: 1px solid #e1e1e1;
}

.box-icon-classic-3 .box-icon-classic-icon {
	color: #151515;
}

.box-icon-classic-icon {
	font-size: 36px;
	line-height: 1;
	color: #01b3a7;
	transition: all .2s ease-in-out;
}

.box-icon-classic-title {
	font-weight: 500;
	
}


.box-icon-classic-title a, .box-icon-classic-title a:focus, .box-icon-classic-title a:active {
	color: inherit;
}

.box-icon-classic-title a:hover {
	color: #01b3a7;
}

.box-icon-classic-title:hover {
	color: #01b3a7;
	cursor: pointer;
}

.box-icon-classic-text {
	color: #9b9b9b;
	text-align: left;
}

@media (min-width: 992px) {
	.desktop .box-icon-classic:hover {
		transition-delay: .1s;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
	}
	.desktop .box-icon-classic:hover::before {
		border-width: 0;
	}
	.desktop .box-icon-classic-3:hover {
		transition-delay: 0s;
	}
	.desktop .box-icon-classic-3:hover::before {
		border-width: 1px;
		top: 15px;
		right: 15px;
		bottom: 15px;
		left: 15px;
	}
	.desktop .box-icon-classic-3:hover .box-icon-classic-icon {
		color: #50ba87;
	}
}

* + .box-icon-classic-text {
	margin-top: 10px;
}

@media (min-width: 768px) {
	.box-icon-classic {
		padding-top: 10px;
		padding-bottom: 20px;
	}
}

.ie-edge .box-icon-classic,
.ie-10 .box-icon-classic,
.ie-11 .box-icon-classic {
	height: 100%;
}

.box-icon-leah {
	position: relative;
	text-align: left;
	padding: 20px 20px 30px;
	min-height: 100%;
	background: #f7f7f7;
	transition: all .3s ease-in-out;
}

.box-icon-leah-2 {
	background: #ffffff;
}

.box-icon-leah-2 .box-icon-leah-count {
	color: #9b9b9b;
}

.box-icon-leah-2 .box-icon-leah-count::after {
	background: #f7f7f7;
}

.box-icon-leah-icon {
	font-size: 42px;
	line-height: 1;
	color: #01b3a7;
}

.box-icon-leah-title {
	font-weight: 400;
}

.box-icon-leah-count {
	position: absolute;
	text-align: center;
	top: 0;
	right: 0;
	padding: 6px 3px 0 7px;
	min-width: 45px;
	font-size: 36px;
	line-height: 1;
	font-weight: 400;
	letter-spacing: .05em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	text-transform: uppercase;
	color: #50ba87;
	transition: inherit;
	z-index: 1;
}

.box-icon-leah-count::before {
	display: inline-block;
	transition: transform .3s ease;
}

.box-icon-leah-count::after {
	position: absolute;
	content: '';
	top: -10px;
	right: -10px;
	bottom: 0;
	left: 0;
	background: #ffffff;
	pointer-events: none;
	z-index: -1;
}

.box-icon-leah-text {
	color: #9b9b9b;
}

* + .box-icon-leah-title {
	margin-top: 16px;
}

* + .box-icon-leah-text {
	margin-top: 10px;
}

@media (min-width: 992px) {
	.desktop .box-icon-leah:hover {
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
	}
	.desktop .box-icon-leah:hover .box-icon-leah-count::before {
		transform: translate3d(0, -10px, 0);
	}
	.desktop .box-icon-leah-2:hover .box-icon-leah-count {
		color: #50ba87;
	}
}

@media (min-width: 1200px) {
	.box-icon-leah {
		padding-left: 30px;
		padding-right: 30px;
	}
}

.box-comment {
	text-align: left;
	padding-bottom: 45px;
	border-bottom: 1px solid #dddddd;
}

.box-comment .box-comment-time,
.box-comment .box-rating {
	margin-top: 4px;
}

.box-comment > .box-comment {
	margin-top: 30px;
	padding-top: 45px;
	padding-bottom: 0;
	border-bottom: none;
	border-top: 1px solid #dddddd;
}

.box-comment + .box-comment {
	margin-top: 45px;
}

.box-comment-figure {
	display: inline-block;
}

.box-comment-figure img {
	max-width: 119px;
}

.box-comment-author {
	font-size: 18px;
	font-weight: 500;
}

.box-comment-author a, .box-comment-author a:focus, .box-comment-author a:active {
	color: inherit;
}

.box-comment-author a:hover {
	color: #01b3a7;
}

.box-comment-reply {
	position: relative;
	top: 2px;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: .1em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.box-comment-reply::before {
	content: '|';
	color: #dddddd;
	padding-right: 13px;
}

.box-comment-reply a, .box-comment-reply a:focus, .box-comment-reply a:active {
	color: #01b3a7;
}

.box-comment-reply a:hover {
	color: #50ba87;
}

.box-comment-time {
	font-size: 16px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: .1em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #9b9b9b;
}

@media (min-width: 768px) {
	.box-comment > .box-comment {
		margin-left: 145px;
	}
}

* + .box-comment {
	margin-top: 30px;
}

.box-comment + h5 {
	margin-top: 46px;
}

.box-contacts {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 310px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
	transition: all .3s ease;
}

.box-contacts-body {
	max-width: 250px;
}

.box-contacts-icon {
	font-size: 60px;
	line-height: 1;
	color: #01b3a7;
}

.box-contacts-decor {
	margin: 30px auto;
	height: 2px;
	max-width: 100px;
	background: #01b3a7;
	transition: all .3s ease-in-out;
}

.box-contacts-link {
	font-size: 18px;
	line-height: 1.67;
	letter-spacing: .025em;
}

.box-contacts-link a, .box-contacts-link a:focus, .box-contacts-link a:active {
	color: inherit;
}

.box-contacts-link a:hover {
	color: #50ba87;
}

@media (min-width: 992px) {
	.desktop .box-contacts {
		box-shadow: none;
		border: 1px solid #e1e1e1;
	}
	.desktop .box-contacts-decor {
		margin-top: 17px;
		margin-bottom: 17px;
		width: 0;
	}
	.desktop .box-contacts:hover {
		border-color: transparent;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
	}
	.desktop .box-contacts:hover .box-contacts-decor {
		margin-top: 30px;
		margin-bottom: 30px;
		width: 100%;
		transition: margin .3s ease, width .4s ease-in-out;
	}
}

.box-contacts-icon + .box-contacts-link {
	margin-top: 40px;
}

* + .box-contacts-link {
	margin-top: 0;
}

.ie-edge .box-contacts,
.ie-10 .box-contacts,
.ie-11 .box-contacts {
	height: 310px;
}

.box-pricing {
	position: relative;
	overflow: hidden;
	max-width: 370px;
	margin-left: auto;
	margin-right: auto;
	padding: 70px 15px 0;
	border-radius: 8px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
}

.box-pricing .button {
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
}

.box-pricing .button:hover, .box-pricing .button:active {
	background-color: #50ba87;
	border-color: #50ba87;
}

.box-pricing-black {
	color: #ffffff;
	background: #2c2e31;
}

.box-pricing-black .box-pricing-time,
.box-pricing-black .box-pricing-price,
.box-pricing-black .box-pricing-title {
	color: inherit;
}

.box-pricing-black .divider {
	background: rgba(225, 225, 225, 0.2);
}

.box-pricing-black .box-pricing-list > li {
	color: #5d6264;
}

.box-pricing-black .box-pricing-list > li::before {
	color: #5d6264;
}

.box-pricing-black .box-pricing-list > li.active {
	color: inherit;
}

.box-pricing-black .box-pricing-list > li.active::before {
	color: #01b3a7;
}

.box-pricing-black .button-gray-4, .box-pricing-black .button-gray-4:focus {
	color: #ffffff;
	background-color: #494c51;
	border-color: #494c51;
}

.box-pricing-divider {
	position: relative;
	padding: 35px 0;
}

.box-pricing-divider > span {
	display: inline-block;
	position: absolute;
	top: 60%;
	left: 50%;
	font-size: 40px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: normal;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: rgba(255, 255, 255, 0.05);
	transform: translate3d(-50%, -50%, 0);
	will-change: transform;
}

.box-pricing-popular .box-pricing-badge {
	display: inline-block;
}

.box-pricing-body {
	max-width: 260px;
	margin-left: auto;
	margin-right: auto;
}

.box-pricing-title {
	letter-spacing: .1em;
}

.box-pricing-time {
	font-size: 14px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: .2em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.box-pricing-button {
	margin-top: 40px;
	margin-left: -15px;
	margin-right: -15px;
}

.box-pricing-list {
	text-align: left;
}

.box-pricing-list > li {
	text-indent: -25px;
	padding-left: 25px;
	color: #9b9b9b;
}

.box-pricing-list > li::before {
	position: relative;
	display: inline-block;
	content: '\f00c';
	left: 0;
	top: 0;
	width: 25px;
	font-size: 14px;
	line-height: 1;
	text-indent: 0;
	font-family: "FontAwesome";
	color: #9b9b9b;
}

.box-pricing-list > li.active {
	color: #151515;
}

.box-pricing-list > li.active::before {
	color: #01b3a7;
}

.box-pricing-list > li + li {
	margin-top: 14px;
}

.box-pricing-badge {
	position: absolute;
	top: 15px;
	right: 15px;
	display: none;
	padding: 10px 13px 6px;
	font-size: 14px;
	line-height: 1;
	font-weight: 600;
	letter-spacing: .075em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #ffffff;
	background: #50ba87;
	border-radius: 4px;
}

* + .box-pricing-price {
	margin-top: 4px;
}

* + .box-pricing-time {
	margin-top: 6px;
}

* + .box-pricing-list {
	margin-top: 30px;
}

.divider + .box-pricing-list {
	margin-top: 0;
}

.box-pricing-divider + .box-pricing-list {
	margin-top: 0;
}

@media (min-width: 576px) {
	.box-pricing-divider > span {
		color: rgba(255, 255, 255, 0.03);
	}
}

@media (min-width: 768px) {
	.box-pricing-divider > span {
		font-size: 50px;
	}
}

@media (min-width: 992px) {
	.box-pricing-divider > span {
		font-size: 55px;
	}
}

.box-sportlight {
	position: relative;
	text-align: left;
}

.box-sportlight-sm .box-sportlight-arrow {
	width: 50px;
	margin-left: 15px;
}

.box-sportlight-figure {
	display: block;
	overflow: hidden;
}

.box-sportlight-figure img {
	width: 100%;
	transition: all .3s ease;
}

.box-sportlight-caption {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 15px;
	border-width: 0 1px 1px 1px;
	border-style: solid;
	border-color: #e1e1e1;
	background: #ffffff;
	transition: all .3s ease-in-out;
}

.box-sportlight-title {
	margin-top: 4px;
	font-weight: 400;
	letter-spacing: .025em;
}

.box-sportlight-arrow {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-shrink: 0;
	width: 88px;
	margin-left: 20px;
	font-size: 0;
	line-height: 0;
	transition: all .25s ease;
}

.box-sportlight-arrow::before, .box-sportlight-arrow::after {
	display: inline-block;
	vertical-align: middle;
	content: '';
	transition: all .3s ease-in-out;
}

.box-sportlight-arrow::before {
	height: 2px;
	width: 21px;
	margin-right: 4px;
	background-color: rgba(21, 21, 21, 0.21);
}

.box-sportlight-arrow::after {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 0 5px 8px;
	border-color: transparent transparent transparent rgba(21, 21, 21, 0.21);
}

.box-sportlight-badge {
	position: absolute;
	overflow: hidden;
	padding: 6px 10px 3px 18px;
	top: 30px;
	right: 0;
	font-size: 27px;
	line-height: 1;
	font-weight: 400;
	letter-spacing: .025em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	text-transform: uppercase;
	color: #ffffff;
	z-index: 1;
}

.box-sportlight-badge::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transform: skew(-10deg);
	transform-origin: 50% 100%;
	z-index: -1;
}

.box-sportlight-sale::before {
	background: #ff5e5e;
}

.box-sportlight-new::before {
	background: #01b3a7;
}

@media (min-width: 992px) {
	.desktop .box-sportlight-figure img {
		transform: scale(1.1);
		will-change: transform;
	}
	.desktop .box-sportlight:hover .box-sportlight-figure img {
		transform: none;
	}
	.desktop .box-sportlight:hover .box-sportlight-caption {
		background: #50ba87;
		border-color: #50ba87;
	}
	.desktop .box-sportlight:hover .box-sportlight-title {
		color: #ffffff;
		letter-spacing: .1em;
	}
	.desktop .box-sportlight:hover .box-sportlight-title a, .desktop .box-sportlight:hover .box-sportlight-title a:focus, .desktop .box-sportlight:hover .box-sportlight-title a:active {
		color: inherit;
	}
	.desktop .box-sportlight:hover .box-sportlight-title a:hover {
		color: rgba(255, 255, 255, 0.8);
	}
	.desktop .box-sportlight:hover .box-sportlight-arrow::before {
		width: 100%;
		background-color: #ffffff;
	}
	.desktop .box-sportlight:hover .box-sportlight-arrow::after {
		border-left-color: #ffffff;
	}
}

@media (min-width: 768px) {
	.box-sportlight-caption {
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media (min-width: 992px) {
	.box-sportlight-caption {
		padding: 15px 20px 15px 30px;
	}
}

@media (min-width: 1200px) {
	.box-sportlight-caption {
		padding: 25px 22px 25px 38px;
	}
	.box-sportlight-title {
		margin-top: 5px;
		font-size: 36px;
		line-height: 1;
		letter-spacing: .025em;
	}
}

.box-icon-ruby {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 30px 16px;
	min-height: 100%;
	border: 1px solid #d7d7d7;
	transition: all .2s ease;
	z-index: 1;
}

.box-icon-ruby::before, .box-icon-ruby::after {
	position: absolute;
	content: '';
}

.box-icon-ruby::before {
	top: 6px;
	right: 6px;
	bottom: 6px;
	left: 6px;
	border: 1px solid #d7d7d7;
	transition: all .3s ease-in-out;
	z-index: -1;
}

.box-icon-ruby::after {
	top: 14px;
	right: 14px;
	bottom: 14px;
	left: 14px;
	background: #50ba87;
	visibility: hidden;
	opacity: 0;
	transition: all .3s ease;
	z-index: -2;
}

.box-icon-ruby-icon {
	font-size: 42px;
	line-height: 1;
	color: #50ba87;
}

.box-icon-ruby-title {
	font-weight: 400;
}

.box-icon-ruby-title a {
	transition-duration: .2s;
}

.box-icon-ruby-title a, .box-icon-ruby-title a:focus, .box-icon-ruby-title a:active {
	color: inherit;
}

.box-icon-ruby-title a:hover {
	color: rgba(255, 255, 255, 0.8);
}

.box-icon-ruby-text {
	color: #9b9b9b;
	letter-spacing: .075em;
}

.box-icon-ruby-text,
.box-icon-ruby-icon {
	transition: all .2s ease-in-out;
}

@media (min-width: 992px) {
	.desktop .box-icon-ruby:hover {
		border-color: #50ba87;
	}
	.desktop .box-icon-ruby:hover::before {
		border-color: rgba(255, 255, 255, 0.4);
	}
	.desktop .box-icon-ruby:hover::after {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 1;
		visibility: visible;
	}
	.desktop .box-icon-ruby:hover .box-icon-ruby-text,
	.desktop .box-icon-ruby:hover .box-icon-ruby-icon,
	.desktop .box-icon-ruby:hover .box-icon-ruby-title {
		color: #ffffff;
	}
}

* + .box-icon-ruby-text {
	margin-top: 2px;
}

@media (min-width: 768px) {
	.box-icon-ruby {
		padding-top: 46px;
		padding-bottom: 46px;
	}
}

.ie-edge .box-icon-ruby,
.ie-10 .box-icon-ruby,
.ie-11 .box-icon-ruby {
	height: 100%;
}

.box-info {
	text-align: left;
	min-height: 100%;
	padding: 20px;
	border: 2px solid #111111;
}

.box-info-text {
	letter-spacing: .025em;
}

* + .box-info-text {
	margin-top: 12px;
}

* + .box-info-list {
	margin-top: 32px;
}

.box-info-list + .button {
	margin-top: 35px;
}

@media (min-width: 768px) {
	.box-info {
		padding: 42px 24px 32px 20px;
	}
}

.box-project {
	display: flex;
	align-items: center;
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
	min-height: 100%;
	text-align: center;
	padding: 30px 15px;
	border: 2px solid #252525;
}

.box-project-title {
	line-height: 1.042;
}

.box-project-title > span {
	color: #01b3a7;
}

.box-project-text {
	letter-spacing: .025em;
}

* + .box-project-text {
	margin-top: 10px;
}

.box-project-text + .button {
	margin-top: 24px;
}

@media (min-width: 768px) {
	.box-project {
		text-align: left;
	}
}

@media (min-width: 992px) {
	.box-project-title {
		font-size: 40px;
		line-height: 0.88;
	}
}

@media (min-width: 1200px) {
	.box-project {
		padding-left: 40px;
	}
	.box-project-title {
		font-size: 48px;
		line-height: 1.20833;
	}
}

.blurb {
	width: 100%;
}

.blurb-title {
	font-size: 18px;
	font-weight: 600;
	color: #151515;
	line-height: 1.3em;
}

.blurb-inner {
	font-size: 14px;
	line-height: 24px;
	color: #01b3a7;
	font-weight: 500;
	margin: 4px 0;
}

.blurb-link a {
	font-size: 16px;
	font-weight: 500;
	color: #151515;
	letter-spacing: 0.050em;
}

.blurb-link a:hover {
	color: #01b3a7;
}

.blurb-path {
	position: relative;
	padding-top: 78px;
}

.blurb-path .blurb-icon {
	padding: 0;
}

.blurb-path .blurb-icon img {
	border-radius: 50%;
}

.blurb-path:before, .blurb-path:after {
	content: '';
	position: absolute;
	width: calc(50% - 67px);
	height: 52px;
	top: 78px;
	background: #f7f7f7;
	border: solid #f7f7f7;
}

.blurb-path:before {
	left: 0;
	border-width: 1px 0 0 1px;
}

.blurb-path:after {
	right: 0;
	border-width: 1px 1px 0 0;
}

.blurb-path svg {
	display: block;
	width: 100%;
	max-width: 134px;
	margin: 0 auto;
}

.blurb-path svg html:not(.ie-11) {
	height: auto;
}

.blurb-path path.fill {
	fill: #f7f7f7;
}

.blurb-path path.stroke {
	stroke: #f7f7f7;
	fill: transparent;
}

.blurb-icon {
	position: absolute;
	height: 118px;
	width: 118px;
	font-size: 60px;
	top: 0px;
	left: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	border-radius: 50%;
	transform: translate(-50%);
	background: white;
	border: 1px solid #f7f7f7;
}

.blurb-content {
	background: #f7f7f7;
	padding: 25px 20px;
	border: solid #f7f7f7;
	border-width: 0 1px 1px 1px;
}

/*
* Element groups
*/
html .group {
	margin-bottom: -20px;
	margin-left: -15px;
}

html .group:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 20px;
	margin-left: 15px;
}

html .group-xs {
	margin-bottom: -11px;
	margin-left: -9px;
}

html .group-xs:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group-xs > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 11px;
	margin-left: 9px;
}

html .group-sm {
	margin-bottom: -12px;
	margin-left: -12px;
}

html .group-sm:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group-sm > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 12px;
	margin-left: 12px;
}

html .group-md {
	margin-bottom: -17px;
	margin-left: -17px;
}

html .group-md:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group-md > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 17px;
	margin-left: 17px;
}

html .group-xmd {
	margin-bottom: -22px;
	margin-left: -22px;
}

html .group-xmd:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group-xmd > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 22px;
	margin-left: 22px;
}

html .group-lg {
	margin-bottom: -20px;
	margin-left: -30px;
}

html .group-lg:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group-lg > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 20px;
	margin-left: 30px;
}

html .group-xl {
	margin-bottom: -30px;
	margin-left: -30px;
}

html .group-xl:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .group-xl > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 30px;
	margin-left: 30px;
}

html .group-justify {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

html .group-sm-justify {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

@media (min-width: 576px) {
	html .group-sm-justify {
		justify-content: space-between;
	}
}

html .group-lg-justify {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

@media (min-width: 992px) {
	html .group-lg-justify {
		justify-content: space-between;
	}
}

html .group-middle {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

@media (min-width: 768px) {
	html .group-middle {
		justify-content: flex-start;
	}
}

* + .group-sm {
	margin-top: 40px;
}

* + .group-md {
	margin-top: 40px;
}

* + .group-xl {
	margin-top: 40px;
}

.row + .group-md {
	margin-top: 35px;
}

/*
*
* Responsive units
*/
.unit {
	display: flex;
	flex: 0 1 100%;
	margin-bottom: -30px;
	margin-left: -20px;
}

.unit > * {
	margin-bottom: 30px;
	margin-left: 20px;
}

.unit:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.unit-body {
	flex: 0 1 auto;
	margin: 10px;
	justify-content: center;
}

.unit-left,
.unit-right {
	flex: 0 0 auto;
	max-width: 100%;
}

.unit-spacing-xxs {
	margin-bottom: -8px;
	margin-left: -8px;
}

.unit-spacing-xxs > * {
	margin-bottom: 8px;
	margin-left: 8px;
}

.unit-spacing-xs {
	display: flex;
	align-items: center;
}

.unit-spacing-sm {
	margin-bottom: -10px;
	margin-left: -16px;
}

.unit-spacing-sm > * {
	margin-bottom: 10px;
	margin-left: 16px;
}

.unit-spacing-md {
	margin-bottom: -20px;
	margin-left: -24px;
}

.unit-spacing-md > * {
	margin-bottom: 20px;
	margin-left: 24px;
}

.unit-spacing-lg {
	margin-bottom: -20px;
	margin-left: -30px;
}

.unit-spacing-lg > * {
	margin-bottom: 20px;
	margin-left: 30px;
}

/*
*
* Lists
*/
.list-inline > li {
	display: inline-block;
}

html .list-inline-sm {
	transform: translate3d(0, -8px, 0);
	margin-bottom: -8px;
	margin-left: -8px;
	margin-right: -8px;
}

html .list-inline-sm > * {
	margin-top: 8px;
	padding-left: 8px;
	padding-right: 8px;
}

html .list-inline-md {
	transform: translate3d(0, -8px, 0);
	margin-bottom: -8px;
	margin-left: -10px;
	margin-right: -10px;
}

html .list-inline-md > * {
	margin-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
}

.list-terms dt + dd {
	margin-top: 10px;
}

.list-terms dd + dt {
	margin-top: 40px;
}

* + .list-terms {
	margin-top: 40px;
}

.index-list {
	counter-reset: li;
}

.index-list > li .list-index-counter:before {
	content: counter(li, decimal-leading-zero);
	counter-increment: li;
}

.list-marked {
	text-align: left;
}

.list-marked > li {
	text-indent: -25px;
	padding-left: 25px;
}

.list-marked > li::before {
	position: relative;
	display: inline-block;
	left: 0;
	top: 0;
	content: '';
	height: 9px;
	width: 9px;
	margin-right: 16px;
	border-radius: 50%;
	background: #d7d7d7;
}

.list-marked > li + li {
	margin-top: 10px;
}

.list-marked-2 {
	text-align: left;
}

.list-marked-2 > li {
	text-indent: -15px;
	padding-left: 15px;
}

.list-marked-2 > li::before {
	position: relative;
	display: inline-block;
	content: '';
	width: 0;
	height: 0;
	margin-right: 9px;
	border-style: solid;
	border-width: 4px 0 4px 6px;
	border-color: transparent transparent transparent rgba(21, 21, 21, 0.5);
}

.list-marked-2 > li + li {
	margin-top: 12px;
}

.list-marked-sm > li {
	text-indent: -15px;
	padding-left: 15px;
}

.list-marked-sm > li::before {
	height: 7px;
	width: 7px;
	margin-right: 8px;
}

.list-marked-md > li {
	text-indent: -18px;
	padding-left: 18px;
}

.list-marked-md > li::before {
	height: 8px;
	width: 8px;
	margin-right: 10px;
}

.list-marked-3 {
	text-align: left;
	font-size: 18px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: .05em;
	text-transform: uppercase;
	color: #9fa3a7;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.list-marked-3 > li {
	text-indent: -40px;
	padding-left: 40px;
}

.list-marked-3 > li::before {
	position: relative;
	display: inline-block;
	left: 0;
	top: -4px;
	content: '';
	height: 2px;
	width: 26px;
	margin-right: 14px;
	background: #151515;
}

.list-marked-3 > li + li {
	margin-top: 18px;
}

.list-marked-secondary > li::before {
	background: #50ba87;
}

.list-marked-primary > li::before {
	background: #01b3a7;
}

* + .list-marked {
	margin-top: 15px;
}

p + .list-marked {
	margin-top: 10px;
}

.list-ordered {
	counter-reset: li;
	text-align: left;
}

.list-ordered > li {
	position: relative;
	text-indent: -18px;
	padding-left: 18px;
}

.list-ordered > li:before {
	content: counter(li, decimal) ".";
	counter-increment: li;
	display: inline-block;
	top: 0;
	left: 0;
	text-indent: 0;
	min-width: 18px;
	color: inherit;
}

.list-ordered > li + li {
	margin-top: 10px;
}

* + .list-ordered {
	margin-top: 15px;
}

.list-contacts {
	text-align: left;
	font-size: 0;
	line-height: 0;
}

.list-contacts .icon {
	text-align: center;
	min-width: 21px;
	font-size: 24px;
	line-height: 24px;
	color: #01b3a7;
}

.list-contacts .fa-envelope {
	font-size: 20px;
}

.list-contacts a {
	display: inline-block;
	vertical-align: middle;
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: .025em;
}

.list-contacts a, .list-contacts a:focus, .list-contacts a:active {
	color: #151515;
}

.list-contacts a:hover {
	color: #01b3a7;
}

.list-contacts li + li {
	margin-top: 30px;
}

.list > li + li {
	margin-top: 10px;
}

.list-0 > li + li {
	margin-top: 0;
}

.list-xs > li + li {
	margin-top: 5px;
}

.list-sm > li + li {
	margin-top: 14px;
}

.list-md > li + li {
	margin-top: 18px;
}

.list-lg > li + li {
	margin-top: 25px;
}

.list-xl > li + li {
	margin-top: 40px;
}

.list-custom {
	display: inline-block;
}

@media (min-width: 576px) {
	.list-custom {
		columns: 2;
		break-inside: avoid;
		-moz-column-gap: 28px;
		-webkit-column-gap: 28px;
	}
	.list-custom li {
		display: inline-block;
		width: 100%;
	}
}

@media (min-width: 768px) {
	.list-custom {
		columns: 3;
	}
}

@media (min-width: 992px) {
	.list-custom {
		columns: 2;
	}
}

@media (min-width: 1200px) {
	.list-custom {
		columns: 3;
	}
}

p + .list-custom {
	margin-top: 30px;
}

@media (max-width: 991.98px) {
	.list-custom-2 {
		margin-left: -20px;
	}
	.list-custom-2 li {
		display: inline-block;
		margin-left: 20px;
	}
}

@media (min-width: 992px) {
	.list-custom-2 {
		columns: 2;
		break-inside: avoid;
		-moz-column-gap: 30px;
		-webkit-column-gap: 30px;
	}
}

/*
*
* Images
*/
img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}

.img-responsive {
	width: 100%;
}

.img-circles {
	border-radius: 50%;
}

.figure-classic {
	position: relative;
	display: inline-block;
	margin-top: 16px;
}

.figure-classic::before {
	display: inline-block;
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 2px solid #363636;
	transition: all .3s ease;
	z-index: 0;
}

.figure-classic img {
	position: relative;
	transition: all .3s ease;
}

.figure-classic-left {
	margin-left: 16px;
}

.figure-classic-left::before {
	transform: translate3d(-16px, -16px, 0);
}

.figure-classic-right {
	margin-right: 16px;
}

.figure-classic-right::before {
	transform: translate3d(16px, -16px, 0);
}

@media (min-width: 992px) {
	.desktop .figure-classic:hover::before {
		transform: none;
	}
	.desktop .figure-classic-left:hover img {
		transform: translate3d(-16px, -16px, 0);
	}
	.desktop .figure-classic-right:hover img {
		transform: translate3d(16px, -16px, 0);
	}
}

@media (min-width: 768px) {
	.figure-classic {
		margin-top: 22px;
	}
	.figure-classic-left {
		margin-left: 22px;
	}
	.figure-classic-left::before {
		transform: translate3d(-22px, -22px, 0);
	}
	.figure-classic-right {
		margin-right: 22px;
	}
	.figure-classic-right::before {
		transform: translate3d(22px, -22px, 0);
	}
}

@media (min-width: 768px) and (min-width: 992px) {
	.desktop .figure-classic-left:hover img {
		transform: translate3d(-22px, -22px, 0);
	}
	.desktop .figure-classic-right:hover img {
		transform: translate3d(22px, -22px, 0);
	}
}

/*
*
* Icons
*/
.icon {
	display: inline-block;
	font-size: 16px;
	line-height: 1;
}

.icon::before {
	position: relative;
	display: inline-block;
	font-weight: 400;
	font-style: normal;
	speak: none;
	text-transform: none;
}

.icon-circle {
	border-radius: 50%;
}

/*
*
* Tables custom
*/
.table-custom {
	width: 100%;
	text-align: left;
	max-width: 100%;
	font-size: 18px;
	line-height: 1;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	background: #ffffff;
	border-collapse: collapse;
	border: 1px solid #e1e1e1;
}

.table-custom th,
.table-custom td {
	color: #2c343b;
	background: #ffffff;
}

.table-custom th {
	padding: 29px 15px 27px;
	font-weight: 500;
	letter-spacing: .1em;
	text-transform: uppercase;
	color: #9b9b9b;
	border-bottom: 1px solid #e1e1e1;
}

@media (max-width: 991.98px) {
	.table-custom th {
		padding-top: 18px;
		padding-bottom: 14px;
	}
}

.table-custom td {
	padding: 12px 15px;
	line-height: 1;
	font-weight: 400;
	letter-spacing: .1em;
	color: #151515;
}

.table-custom tfoot td {
	font-weight: 700;
}

* + .table-custom-responsive {
	margin-top: 25px;
}

.table-custom.table-custom-primary thead th {
	color: #ffffff;
	background: #01b3a7;
	border: 0;
}

.table-custom.table-custom-bordered tr td:first-child {
	border-left: 0;
}

.table-custom.table-custom-bordered tr td:last-child {
	border-right: 0;
}

.table-custom.table-custom-bordered td {
	border: 1px solid #e1e1e1;
}

.table-custom.table-custom-bordered tbody > tr:first-of-type > td {
	border-top: 0;
}

.table-custom.table-custom-striped {
	border-bottom: 1px solid #e1e1e1;
}

.table-custom.table-custom-striped tbody tr:nth-of-type(odd) td {
	background: transparent;
}

.table-custom.table-custom-striped tbody tr:nth-of-type(even) td {
	background: #d7d7d7;
}

.table-custom.table-custom-striped tbody td {
	border: 0;
}

.table-custom.table-custom-striped tfoot td:not(:first-child) {
	border-left: 0;
}

@media (max-width: 991.98px) {
	.table-custom-responsive {
		display: block;
		width: 100%;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	.table-custom-responsive.table-bordered {
		border: 0;
	}
}

.table-cart {
	text-align: center;
}

.table-cart th:first-child,
.table-cart td:first-child {
	text-align: left;
}

@media (min-width: 992px) {
	.table-cart th:first-child,
	.table-cart td:first-child {
		padding-left: 32px;
	}
}

.table-cart tbody tr:first-child td {
	padding-top: 24px;
}

.table-cart tbody tr:last-child td {
	padding-bottom: 24px;
}

.table-cart td {
	width: auto;
	min-width: auto;
	font-size: 18px;
	letter-spacing: .075em;
}

.table-cart td:first-child {
	width: 61%;
	min-width: 350px;
}

.table-cart td:first-child > * {
	display: inline-block;
	vertical-align: middle;
}

@media (min-width: 992px) {
	.table-cart td:first-child {
		min-width: 500px;
	}
}

.table-cart td:not(:first-child) {
	width: 13%;
}

.table-cart td:nth-child(2), .table-cart td:nth-child(4) {
	min-width: 140px;
}

.table-cart td:nth-child(3) {
	min-width: 150px;
}

@media (min-width: 992px) {
	.table-cart td {
		font-size: 24px;
	}
}

.table-cart-link {
	letter-spacing: .05em;
}

.table-cart-link, .table-cart-link:focus, .table-cart-link:active {
	color: #151515;
}

.table-cart-link:hover {
	color: #01b3a7;
}

.table-cart-figure {
	display: inline-block;
	text-align: center;
	width: 100%;
	max-width: 90px;
	background: #f4f4f4;
}

@media (min-width: 992px) {
	.table-cart-figure {
		max-width: 146px;
	}
}

.table-cart-figure + a {
	margin-left: 15px;
}

@media (min-width: 992px) {
	.table-cart-figure + a {
		margin-left: 30px;
	}
}

.table-checkout tr + tr td {
	border-top: 1px solid #e1e1e1;
}

.table-checkout td {
	padding: 20px 20px 16px;
	min-width: 150px;
}

.table-checkout td:last-child {
	text-align: right;
}

@media (min-width: 576px) {
	.table-checkout td {
		padding: 29px 64px 25px 25px;
	}
}

/*
*
* Dividers
*/
hr {
	margin-top: 0;
	margin-bottom: 0;
	border-top: 1px solid #d7d7d7;
}

.divider {
	font-size: 0;
	line-height: 0;
	height: 1px;
	width: 100%;
	background: #e1e1e1;
}

.divider-30 {
	margin: 30px 0;
}

.divider-35 {
	margin: 35px 0;
}

.divider-40 {
	margin: 40px 0;
}

.divider + * {
	margin-top: 0;
}

.title-decoration-lines-left {
	position: relative;
	padding-left: 30px;
	line-height: 1.2;
}

.title-decoration-lines-left::before {
	position: absolute;
	content: '';
	top: 8%;
	left: 0;
	height: 78%;
	width: 4px;
	background: #d7d7d7;
}

.title-decoration-lines-bottom {
	position: relative;
	line-height: 1.2;
}

.title-decoration-lines-bottom span {
	display: block;
	font-size: 0;
	line-height: 0;
	margin-top: 20px;
}

.title-decoration-lines-bottom span::after {
	display: inline-block;
	content: '';
	bottom: 0;
	height: 4px;
	width: 70px;
	background: #01b3a7;
}

@media (min-width: 576px) {
	.title-decoration-lines-bottom span {
		margin-top: 30px;
	}
}

.title-decoration-lines-left + p {
	margin-top: 8px;
}

.title-decoration-lines-bottom + p {
	margin-top: 30px;
}

.text-decoration-lines {
	position: relative;
	overflow: hidden;
	width: 100%;
}

.text-decoration-lines-content {
	position: relative;
	display: inline-block;
	padding: 0 16px;
	font-size: 18px;
	letter-spacing: .1em;
	color: #9b9b9b;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	text-transform: uppercase;
}

.text-decoration-lines-content::before, .text-decoration-lines-content::after {
	position: absolute;
	content: '';
	top: calc( 50% - 2px);
	height: 1px;
	width: 100vw;
	background: #e1e1e1;
}

.text-decoration-lines-content::before {
	left: 0;
	transform: translate3d(-100%, 0, 0);
}

.text-decoration-lines-content::after {
	right: 0;
	transform: translate3d(100%, 0, 0);
}

* + .text-decoration-lines {
	margin-top: 26px;
}

.text-decoration-lines + .rd-form {
	margin-top: 22px;
}

.title-decoration-lines {
	position: relative;
	overflow: hidden;
	width: 100%;
}

.title-decoration-lines-content {
	position: relative;
	display: inline-block;
	padding: 0 16px;
	letter-spacing: .075em;
}

.title-decoration-lines-content::before, .title-decoration-lines-content::after {
	position: absolute;
	content: '';
	top: calc( 50% - 2px);
	height: 1px;
	width: 100vw;
	background: #d7d7d7;
}

.title-decoration-lines-content::before {
	left: 0;
	transform: translate3d(-100%, 0, 0);
}

.title-decoration-lines-content::after {
	right: 0;
	transform: translate3d(100%, 0, 0);
}

/*
*
* Buttons
*/
.button {
	position: relative;
	overflow: hidden;
	display: inline-block;
	min-width: 200px;
	padding: 17px 33px 15px;
	font-size: 16px;
	line-height: 1.34;
	border: 2px solid;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	font-weight: 500;
	letter-spacing: normal;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
	user-select: none;
	transition: 250ms all ease-in-out;
}

.button-block {
	display: block;
	width: 100%;
}

.button-default, .button-default:focus {
	color: #cccccc;
	background-color: #2c343b;
	border-color: #2c343b;
}

.button-default:hover, .button-default:active {
	color: #ffffff;
	background-color: #01b3a7;
	border-color: #01b3a7;
}

.button-default.button-ujarak::before {
	background: #01b3a7;
}

.button-default.button-pipaluk, .button-default.button-pipaluk:focus {
	background: transparent;
}

.button-default.button-pipaluk::before, .button-default.button-pipaluk:focus::before {
	background: #2c343b;
	border-color: #01b3a7;
}

.button-default.button-pipaluk::after, .button-default.button-pipaluk:focus::after {
	border-color: #01b3a7;
}

.button-default.button-pipaluk:hover, .button-default.button-pipaluk:active {
	color: #01b3a7;
	background: transparent;
}

.button-default.button-wapasha, .button-default.button-wapasha:focus {
	color: #2c343b;
	background: transparent;
}

.button-default.button-wapasha::before, .button-default.button-wapasha:focus::before {
	border-color: #2c343b;
}

.button-default.button-wapasha::after, .button-default.button-wapasha:focus::after {
	border-color: #01b3a7;
}

.button-default.button-wapasha:hover, .button-default.button-wapasha:active {
	color: #01b3a7;
	background: transparent;
}

.button-primary, .button-primary:focus {
	color: #ffffff;
	background-color: #01b3a7;
	border-color: #01b3a7;
}

.button-primary:hover, .button-primary:active {
	color: #ffffff;
	background-color: #151515;
	border-color: #151515;
}

.button-primary.button-ujarak::before {
	background: #151515;
}

.button-primary.button-pipaluk, .button-primary.button-pipaluk:focus {
	background: transparent;
}

.button-primary.button-pipaluk::before, .button-primary.button-pipaluk:focus::before {
	background: #01b3a7;
	border-color: #151515;
}

.button-primary.button-pipaluk::after, .button-primary.button-pipaluk:focus::after {
	border-color: #151515;
}

.button-primary.button-pipaluk:hover, .button-primary.button-pipaluk:active {
	color: #151515;
	background: transparent;
}

.button-primary.button-wapasha, .button-primary.button-wapasha:focus {
	color: #01b3a7;
	background: transparent;
}

.button-primary.button-wapasha::before, .button-primary.button-wapasha:focus::before {
	border-color: #01b3a7;
}

.button-primary.button-wapasha::after, .button-primary.button-wapasha:focus::after {
	border-color: #151515;
}

.button-primary.button-wapasha:hover, .button-primary.button-wapasha:active {
	color: #151515;
	background: transparent;
}

.button-secondary, .button-secondary:focus {
	color: #ffffff;
	background-color: #01b3a7;
	border-color: #01b3a7;
}

.button-secondary:hover, .button-secondary:active {
	color: #ffffff;
	background-color: #151515;
	border-color: #151515;
}

.button-secondary.button-ujarak::before {
	background: #151515;
}

.button-secondary.button-pipaluk, .button-secondary.button-pipaluk:focus {
	background: transparent;
}

.button-secondary.button-pipaluk::before, .button-secondary.button-pipaluk:focus::before {
	background: #01b3a7;
	border-color: #151515;
}

.button-secondary.button-pipaluk::after, .button-secondary.button-pipaluk:focus::after {
	border-color: #151515;
}

.button-secondary.button-pipaluk:hover, .button-secondary.button-pipaluk:active {
	color: #151515;
	background: transparent;
}

.button-secondary.button-wapasha, .button-secondary.button-wapasha:focus {
	color: #01b3a7;
	background: transparent;
}

.button-secondary.button-wapasha::before, .button-secondary.button-wapasha:focus::before {
	border-color: #01b3a7;
}

.button-secondary.button-wapasha::after, .button-secondary.button-wapasha:focus::after {
	border-color: #151515;
}

.button-secondary.button-wapasha:hover, .button-secondary.button-wapasha:active {
	color: #151515;
	background: transparent;
}

.button-secondary-4, .button-secondary-4:focus {
	color: #ffffff;
	background-color: #50ba87;
	border-color: #50ba87;
}

.button-secondary-4:hover, .button-secondary-4:active {
	color: #ffffff;
	background-color: #151515;
	border-color: #151515;
}

.button-secondary-4.button-ujarak::before {
	background: #151515;
}

.button-secondary-4.button-pipaluk, .button-secondary-4.button-pipaluk:focus {
	background: transparent;
}

.button-secondary-4.button-pipaluk::before, .button-secondary-4.button-pipaluk:focus::before {
	background: #50ba87;
	border-color: #151515;
}

.button-secondary-4.button-pipaluk::after, .button-secondary-4.button-pipaluk:focus::after {
	border-color: #151515;
}

.button-secondary-4.button-pipaluk:hover, .button-secondary-4.button-pipaluk:active {
	color: #151515;
	background: transparent;
}

.button-secondary-4.button-wapasha, .button-secondary-4.button-wapasha:focus {
	color: #50ba87;
	background: transparent;
}

.button-secondary-4.button-wapasha::before, .button-secondary-4.button-wapasha:focus::before {
	border-color: #50ba87;
}

.button-secondary-4.button-wapasha::after, .button-secondary-4.button-wapasha:focus::after {
	border-color: #151515;
}

.button-secondary-4.button-wapasha:hover, .button-secondary-4.button-wapasha:active {
	color: #151515;
	background: transparent;
}

.button-white, .button-white:focus {
	color: #151515;
	background-color: #ffffff;
	border-color: #ffffff;
}

.button-white:hover, .button-white:active {
	color: #ffffff;
	background-color: #50ba87;
	border-color: #50ba87;
}

.button-white.button-ujarak::before {
	background: #50ba87;
}

.button-white.button-pipaluk, .button-white.button-pipaluk:focus {
	background: transparent;
}

.button-white.button-pipaluk::before, .button-white.button-pipaluk:focus::before {
	background: #ffffff;
	border-color: #50ba87;
}

.button-white.button-pipaluk::after, .button-white.button-pipaluk:focus::after {
	border-color: #50ba87;
}

.button-white.button-pipaluk:hover, .button-white.button-pipaluk:active {
	color: #50ba87;
	background: transparent;
}

.button-white.button-wapasha, .button-white.button-wapasha:focus {
	color: #ffffff;
	background: transparent;
}

.button-white.button-wapasha::before, .button-white.button-wapasha:focus::before {
	border-color: #ffffff;
}

.button-white.button-wapasha::after, .button-white.button-wapasha:focus::after {
	border-color: #50ba87;
}

.button-white.button-wapasha:hover, .button-white.button-wapasha:active {
	color: #50ba87;
	background: transparent;
}

.button-gray-100, .button-gray-100:focus {
	color: #151515;
	background-color: #f2f2f2;
	border-color: #f2f2f2;
}

.button-gray-100:hover, .button-gray-100:active {
	color: #151515;
	background-color: #e5e5e5;
	border-color: #e5e5e5;
}

.button-gray-100.button-ujarak::before {
	background: #e5e5e5;
}

.button-gray-100.button-pipaluk, .button-gray-100.button-pipaluk:focus {
	background: transparent;
}

.button-gray-100.button-pipaluk::before, .button-gray-100.button-pipaluk:focus::before {
	background: #f2f2f2;
	border-color: #e5e5e5;
}

.button-gray-100.button-pipaluk::after, .button-gray-100.button-pipaluk:focus::after {
	border-color: #e5e5e5;
}

.button-gray-100.button-pipaluk:hover, .button-gray-100.button-pipaluk:active {
	color: #e5e5e5;
	background: transparent;
}

.button-gray-100.button-wapasha, .button-gray-100.button-wapasha:focus {
	color: #f2f2f2;
	background: transparent;
}

.button-gray-100.button-wapasha::before, .button-gray-100.button-wapasha:focus::before {
	border-color: #f2f2f2;
}

.button-gray-100.button-wapasha::after, .button-gray-100.button-wapasha:focus::after {
	border-color: #e5e5e5;
}

.button-gray-100.button-wapasha:hover, .button-gray-100.button-wapasha:active {
	color: #e5e5e5;
	background: transparent;
}

.button-gray-4, .button-gray-4:focus {
	color: #151515;
	background-color: #f7f7f7;
	border-color: #f7f7f7;
}

.button-gray-4:hover, .button-gray-4:active {
	color: #ffffff;
	background-color: #151515;
	border-color: #151515;
}

.button-gray-4.button-ujarak::before {
	background: #151515;
}

.button-gray-4.button-pipaluk, .button-gray-4.button-pipaluk:focus {
	background: transparent;
}

.button-gray-4.button-pipaluk::before, .button-gray-4.button-pipaluk:focus::before {
	background: #f7f7f7;
	border-color: #151515;
}

.button-gray-4.button-pipaluk::after, .button-gray-4.button-pipaluk:focus::after {
	border-color: #151515;
}

.button-gray-4.button-pipaluk:hover, .button-gray-4.button-pipaluk:active {
	color: #151515;
	background: transparent;
}

.button-gray-4.button-wapasha, .button-gray-4.button-wapasha:focus {
	color: #f7f7f7;
	background: transparent;
}

.button-gray-4.button-wapasha::before, .button-gray-4.button-wapasha:focus::before {
	border-color: #f7f7f7;
}

.button-gray-4.button-wapasha::after, .button-gray-4.button-wapasha:focus::after {
	border-color: #151515;
}

.button-gray-4.button-wapasha:hover, .button-gray-4.button-wapasha:active {
	color: #151515;
	background: transparent;
}

.button-gray-7, .button-gray-7:focus {
	color: #ffffff;
	background-color: #414141;
	border-color: #414141;
}

.button-gray-7:hover, .button-gray-7:active {
	color: #151515;
	background-color: #ffffff;
	border-color: #ffffff;
}

.button-gray-7.button-ujarak::before {
	background: #ffffff;
}

.button-gray-7.button-pipaluk, .button-gray-7.button-pipaluk:focus {
	background: transparent;
}

.button-gray-7.button-pipaluk::before, .button-gray-7.button-pipaluk:focus::before {
	background: #414141;
	border-color: #ffffff;
}

.button-gray-7.button-pipaluk::after, .button-gray-7.button-pipaluk:focus::after {
	border-color: #ffffff;
}

.button-gray-7.button-pipaluk:hover, .button-gray-7.button-pipaluk:active {
	color: #ffffff;
	background: transparent;
}

.button-gray-7.button-wapasha, .button-gray-7.button-wapasha:focus {
	color: #414141;
	background: transparent;
}

.button-gray-7.button-wapasha::before, .button-gray-7.button-wapasha:focus::before {
	border-color: #414141;
}

.button-gray-7.button-wapasha::after, .button-gray-7.button-wapasha:focus::after {
	border-color: #ffffff;
}

.button-gray-7.button-wapasha:hover, .button-gray-7.button-wapasha:active {
	color: #ffffff;
	background: transparent;
}

.button-gray-8, .button-gray-8:focus {
	color: #ffffff;
	background-color: #353535;
	border-color: #353535;
}

.button-gray-8:hover, .button-gray-8:active {
	color: #ffffff;
	background-color: #50ba87;
	border-color: #50ba87;
}

.button-gray-8.button-ujarak::before {
	background: #50ba87;
}

.button-gray-8.button-pipaluk, .button-gray-8.button-pipaluk:focus {
	background: transparent;
}

.button-gray-8.button-pipaluk::before, .button-gray-8.button-pipaluk:focus::before {
	background: #353535;
	border-color: #50ba87;
}

.button-gray-8.button-pipaluk::after, .button-gray-8.button-pipaluk:focus::after {
	border-color: #50ba87;
}

.button-gray-8.button-pipaluk:hover, .button-gray-8.button-pipaluk:active {
	color: #50ba87;
	background: transparent;
}

.button-gray-8.button-wapasha, .button-gray-8.button-wapasha:focus {
	color: #353535;
	background: transparent;
}

.button-gray-8.button-wapasha::before, .button-gray-8.button-wapasha:focus::before {
	border-color: #353535;
}

.button-gray-8.button-wapasha::after, .button-gray-8.button-wapasha:focus::after {
	border-color: #50ba87;
}

.button-gray-8.button-wapasha:hover, .button-gray-8.button-wapasha:active {
	color: #50ba87;
	background: transparent;
}

.button-gray-11, .button-gray-11:focus {
	color: #ffffff;
	background-color: #303233;
	border-color: #303233;
}

.button-gray-11:hover, .button-gray-11:active {
	color: #151515;
	background-color: #ffffff;
	border-color: #ffffff;
}

.button-gray-11.button-ujarak::before {
	background: #ffffff;
}

.button-gray-11.button-pipaluk, .button-gray-11.button-pipaluk:focus {
	background: transparent;
}

.button-gray-11.button-pipaluk::before, .button-gray-11.button-pipaluk:focus::before {
	background: #303233;
	border-color: #ffffff;
}

.button-gray-11.button-pipaluk::after, .button-gray-11.button-pipaluk:focus::after {
	border-color: #ffffff;
}

.button-gray-11.button-pipaluk:hover, .button-gray-11.button-pipaluk:active {
	color: #ffffff;
	background: transparent;
}

.button-gray-11.button-wapasha, .button-gray-11.button-wapasha:focus {
	color: #303233;
	background: transparent;
}

.button-gray-11.button-wapasha::before, .button-gray-11.button-wapasha:focus::before {
	border-color: #303233;
}

.button-gray-11.button-wapasha::after, .button-gray-11.button-wapasha:focus::after {
	border-color: #ffffff;
}

.button-gray-11.button-wapasha:hover, .button-gray-11.button-wapasha:active {
	color: #ffffff;
	background: transparent;
}

.button-gray-14, .button-gray-14:focus {
	color: #ffffff;
	background-color: #2f2f2f;
	border-color: #2f2f2f;
}

.button-gray-14:hover, .button-gray-14:active {
	color: #151515;
	background-color: #ffffff;
	border-color: #ffffff;
}

.button-gray-14.button-ujarak::before {
	background: #ffffff;
}

.button-gray-14.button-pipaluk, .button-gray-14.button-pipaluk:focus {
	background: transparent;
}

.button-gray-14.button-pipaluk::before, .button-gray-14.button-pipaluk:focus::before {
	background: #2f2f2f;
	border-color: #ffffff;
}

.button-gray-14.button-pipaluk::after, .button-gray-14.button-pipaluk:focus::after {
	border-color: #ffffff;
}

.button-gray-14.button-pipaluk:hover, .button-gray-14.button-pipaluk:active {
	color: #ffffff;
	background: transparent;
}

.button-gray-14.button-wapasha, .button-gray-14.button-wapasha:focus {
	color: #2f2f2f;
	background: transparent;
}

.button-gray-14.button-wapasha::before, .button-gray-14.button-wapasha:focus::before {
	border-color: #2f2f2f;
}

.button-gray-14.button-wapasha::after, .button-gray-14.button-wapasha:focus::after {
	border-color: #ffffff;
}

.button-gray-14.button-wapasha:hover, .button-gray-14.button-wapasha:active {
	color: #ffffff;
	background: transparent;
}

.button-gray-31, .button-gray-31:focus {
	color: #ffffff;
	background-color: #53575b;
	border-color: #53575b;
}

.button-gray-31:hover, .button-gray-31:active {
	color: #ffffff;
	background-color: #151515;
	border-color: #151515;
}

.button-gray-31.button-ujarak::before {
	background: #151515;
}

.button-gray-31.button-pipaluk, .button-gray-31.button-pipaluk:focus {
	background: transparent;
}

.button-gray-31.button-pipaluk::before, .button-gray-31.button-pipaluk:focus::before {
	background: #53575b;
	border-color: #151515;
}

.button-gray-31.button-pipaluk::after, .button-gray-31.button-pipaluk:focus::after {
	border-color: #151515;
}

.button-gray-31.button-pipaluk:hover, .button-gray-31.button-pipaluk:active {
	color: #151515;
	background: transparent;
}

.button-gray-31.button-wapasha, .button-gray-31.button-wapasha:focus {
	color: #53575b;
	background: transparent;
}

.button-gray-31.button-wapasha::before, .button-gray-31.button-wapasha:focus::before {
	border-color: #53575b;
}

.button-gray-31.button-wapasha::after, .button-gray-31.button-wapasha:focus::after {
	border-color: #151515;
}

.button-gray-31.button-wapasha:hover, .button-gray-31.button-wapasha:active {
	color: #151515;
	background: transparent;
}

.button-gray-32, .button-gray-32:focus {
	color: #ffffff;
	background-color: #292929;
	border-color: #292929;
}

.button-gray-32:hover, .button-gray-32:active {
	color: #ffffff;
	background-color: #151515;
	border-color: #151515;
}

.button-gray-32.button-ujarak::before {
	background: #151515;
}

.button-gray-32.button-pipaluk, .button-gray-32.button-pipaluk:focus {
	background: transparent;
}

.button-gray-32.button-pipaluk::before, .button-gray-32.button-pipaluk:focus::before {
	background: #292929;
	border-color: #151515;
}

.button-gray-32.button-pipaluk::after, .button-gray-32.button-pipaluk:focus::after {
	border-color: #151515;
}

.button-gray-32.button-pipaluk:hover, .button-gray-32.button-pipaluk:active {
	color: #151515;
	background: transparent;
}

.button-gray-32.button-wapasha, .button-gray-32.button-wapasha:focus {
	color: #292929;
	background: transparent;
}

.button-gray-32.button-wapasha::before, .button-gray-32.button-wapasha:focus::before {
	border-color: #292929;
}

.button-gray-32.button-wapasha::after, .button-gray-32.button-wapasha:focus::after {
	border-color: #151515;
}

.button-gray-32.button-wapasha:hover, .button-gray-32.button-wapasha:active {
	color: #151515;
	background: transparent;
}

.button-default-outline, .button-default-outline:focus {
	color: #ffffff;
	background-color: transparent;
	border-color: #ffffff;
}

.button-default-outline:hover, .button-default-outline:active {
	color: #ffffff;
	background-color: #50ba87;
	border-color: #50ba87;
}

.button-default-outline.button-ujarak::before {
	background: #50ba87;
}

.button-default-outline.button-pipaluk, .button-default-outline.button-pipaluk:focus {
	background: transparent;
}

.button-default-outline.button-pipaluk::before, .button-default-outline.button-pipaluk:focus::before {
	background: transparent;
	border-color: #50ba87;
}

.button-default-outline.button-pipaluk::after, .button-default-outline.button-pipaluk:focus::after {
	border-color: #50ba87;
}

.button-default-outline.button-pipaluk:hover, .button-default-outline.button-pipaluk:active {
	color: #50ba87;
	background: transparent;
}

.button-default-outline.button-wapasha, .button-default-outline.button-wapasha:focus {
	color: #ffffff;
	background: transparent;
}

.button-default-outline.button-wapasha::before, .button-default-outline.button-wapasha:focus::before {
	border-color: #ffffff;
}

.button-default-outline.button-wapasha::after, .button-default-outline.button-wapasha:focus::after {
	border-color: #50ba87;
}

.button-default-outline.button-wapasha:hover, .button-default-outline.button-wapasha:active {
	color: #50ba87;
	background: transparent;
}

.button-default-outline-2, .button-default-outline-2:focus {
	color: #151515;
	background-color: transparent;
	border-color: #01b3a7;
}

.button-default-outline-2:hover, .button-default-outline-2:active {
	color: #ffffff;
	background-color: #50ba87;
	border-color: #50ba87;
}

.button-default-outline-2.button-ujarak::before {
	background: #50ba87;
}

.button-default-outline-2.button-pipaluk, .button-default-outline-2.button-pipaluk:focus {
	background: transparent;
}

.button-default-outline-2.button-pipaluk::before, .button-default-outline-2.button-pipaluk:focus::before {
	background: transparent;
	border-color: #50ba87;
}

.button-default-outline-2.button-pipaluk::after, .button-default-outline-2.button-pipaluk:focus::after {
	border-color: #50ba87;
}

.button-default-outline-2.button-pipaluk:hover, .button-default-outline-2.button-pipaluk:active {
	color: #50ba87;
	background: transparent;
}

.button-default-outline-2.button-wapasha, .button-default-outline-2.button-wapasha:focus {
	color: #01b3a7;
	background: transparent;
}

.button-default-outline-2.button-wapasha::before, .button-default-outline-2.button-wapasha:focus::before {
	border-color: #01b3a7;
}

.button-default-outline-2.button-wapasha::after, .button-default-outline-2.button-wapasha:focus::after {
	border-color: #50ba87;
}

.button-default-outline-2.button-wapasha:hover, .button-default-outline-2.button-wapasha:active {
	color: #50ba87;
	background: transparent;
}

.button-white-outline, .button-white-outline:focus {
	color: #ffffff;
	background-color: transparent;
	border-color: #ffffff;
}

.button-white-outline:hover, .button-white-outline:active {
	color: #ffffff;
	background-color: #01b3a7;
	border-color: #01b3a7;
}

.button-white-outline.button-ujarak::before {
	background: #01b3a7;
}

.button-white-outline.button-pipaluk, .button-white-outline.button-pipaluk:focus {
	background: transparent;
}

.button-white-outline.button-pipaluk::before, .button-white-outline.button-pipaluk:focus::before {
	background: transparent;
	border-color: #01b3a7;
}

.button-white-outline.button-pipaluk::after, .button-white-outline.button-pipaluk:focus::after {
	border-color: #01b3a7;
}

.button-white-outline.button-pipaluk:hover, .button-white-outline.button-pipaluk:active {
	color: #01b3a7;
	background: transparent;
}

.button-white-outline.button-wapasha, .button-white-outline.button-wapasha:focus {
	color: #ffffff;
	background: transparent;
}

.button-white-outline.button-wapasha::before, .button-white-outline.button-wapasha:focus::before {
	border-color: #ffffff;
}

.button-white-outline.button-wapasha::after, .button-white-outline.button-wapasha:focus::after {
	border-color: #01b3a7;
}

.button-white-outline.button-wapasha:hover, .button-white-outline.button-wapasha:active {
	color: #01b3a7;
	background: transparent;
}

.button-black-outline, .button-black-outline:focus {
	color: #151515;
	background-color: transparent;
	border-color: #151515;
}

.button-black-outline:hover, .button-black-outline:active {
	color: #151515;
	background-color: #01b3a7;
	border-color: #01b3a7;
}

.button-black-outline.button-ujarak::before {
	background: #01b3a7;
}

.button-black-outline.button-pipaluk, .button-black-outline.button-pipaluk:focus {
	background: transparent;
}

.button-black-outline.button-pipaluk::before, .button-black-outline.button-pipaluk:focus::before {
	background: transparent;
	border-color: #01b3a7;
}

.button-black-outline.button-pipaluk::after, .button-black-outline.button-pipaluk:focus::after {
	border-color: #01b3a7;
}

.button-black-outline.button-pipaluk:hover, .button-black-outline.button-pipaluk:active {
	color: #01b3a7;
	background: transparent;
}

.button-black-outline.button-wapasha, .button-black-outline.button-wapasha:focus {
	color: #151515;
	background: transparent;
}

.button-black-outline.button-wapasha::before, .button-black-outline.button-wapasha:focus::before {
	border-color: #151515;
}

.button-black-outline.button-wapasha::after, .button-black-outline.button-wapasha:focus::after {
	border-color: #01b3a7;
}

.button-black-outline.button-wapasha:hover, .button-black-outline.button-wapasha:active {
	color: #01b3a7;
	background: transparent;
}

.button-ghost {
	border: 0;
	background-color: transparent;
}

.button-ghost:hover {
	color: #ffffff;
	background: #01b3a7;
}

.button-facebook, .button-facebook:focus {
	color: #ffffff;
	background-color: #6576ad;
	border-color: #6576ad;
}

.button-facebook:hover, .button-facebook:active {
	color: #ffffff;
	background-color: #5668a2;
	border-color: #5668a2;
}

.button-facebook.button-ujarak::before {
	background: #5668a2;
}

.button-facebook.button-pipaluk, .button-facebook.button-pipaluk:focus {
	background: transparent;
}

.button-facebook.button-pipaluk::before, .button-facebook.button-pipaluk:focus::before {
	background: #6576ad;
	border-color: #5668a2;
}

.button-facebook.button-pipaluk::after, .button-facebook.button-pipaluk:focus::after {
	border-color: #5668a2;
}

.button-facebook.button-pipaluk:hover, .button-facebook.button-pipaluk:active {
	color: #5668a2;
	background: transparent;
}

.button-facebook.button-wapasha, .button-facebook.button-wapasha:focus {
	color: #6576ad;
	background: transparent;
}

.button-facebook.button-wapasha::before, .button-facebook.button-wapasha:focus::before {
	border-color: #6576ad;
}

.button-facebook.button-wapasha::after, .button-facebook.button-wapasha:focus::after {
	border-color: #5668a2;
}

.button-facebook.button-wapasha:hover, .button-facebook.button-wapasha:active {
	color: #5668a2;
	background: transparent;
}

.button-twitter, .button-twitter:focus {
	color: #ffffff;
	background-color: #63bbe8;
	border-color: #63bbe8;
}

.button-twitter:hover, .button-twitter:active {
	color: #ffffff;
	background-color: #4db1e5;
	border-color: #4db1e5;
}

.button-twitter.button-ujarak::before {
	background: #4db1e5;
}

.button-twitter.button-pipaluk, .button-twitter.button-pipaluk:focus {
	background: transparent;
}

.button-twitter.button-pipaluk::before, .button-twitter.button-pipaluk:focus::before {
	background: #63bbe8;
	border-color: #4db1e5;
}

.button-twitter.button-pipaluk::after, .button-twitter.button-pipaluk:focus::after {
	border-color: #4db1e5;
}

.button-twitter.button-pipaluk:hover, .button-twitter.button-pipaluk:active {
	color: #4db1e5;
	background: transparent;
}

.button-twitter.button-wapasha, .button-twitter.button-wapasha:focus {
	color: #63bbe8;
	background: transparent;
}

.button-twitter.button-wapasha::before, .button-twitter.button-wapasha:focus::before {
	border-color: #63bbe8;
}

.button-twitter.button-wapasha::after, .button-twitter.button-wapasha:focus::after {
	border-color: #4db1e5;
}

.button-twitter.button-wapasha:hover, .button-twitter.button-wapasha:active {
	color: #4db1e5;
	background: transparent;
}

.button-google, .button-google:focus {
	color: #ffffff;
	background-color: #eb6e6e;
	border-color: #eb6e6e;
}

.button-google:hover, .button-google:active {
	color: #ffffff;
	background-color: #e85858;
	border-color: #e85858;
}

.button-google.button-ujarak::before {
	background: #e85858;
}

.button-google.button-pipaluk, .button-google.button-pipaluk:focus {
	background: transparent;
}

.button-google.button-pipaluk::before, .button-google.button-pipaluk:focus::before {
	background: #eb6e6e;
	border-color: #e85858;
}

.button-google.button-pipaluk::after, .button-google.button-pipaluk:focus::after {
	border-color: #e85858;
}

.button-google.button-pipaluk:hover, .button-google.button-pipaluk:active {
	color: #e85858;
	background: transparent;
}

.button-google.button-wapasha, .button-google.button-wapasha:focus {
	color: #eb6e6e;
	background: transparent;
}

.button-google.button-wapasha::before, .button-google.button-wapasha:focus::before {
	border-color: #eb6e6e;
}

.button-google.button-wapasha::after, .button-google.button-wapasha:focus::after {
	border-color: #e85858;
}

.button-google.button-wapasha:hover, .button-google.button-wapasha:active {
	color: #e85858;
	background: transparent;
}

.button-shadow {
	box-shadow: 0 9px 21px 0 rgba(204, 204, 204, 0.15);
}

.button-shadow:hover {
	box-shadow: 0 9px 10px 0 rgba(204, 204, 204, 0.15);
}

.button-shadow:focus, .button-shadow:active {
	box-shadow: none;
}

.button-ujarak {
	position: relative;
	z-index: 0;
	transition: background .4s, border-color .4s, color .4s;
}

.button-ujarak::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 101%;
	height: 101%;
	background: #50ba87;
	z-index: -1;
	opacity: 0;
	transform: scale3d(0.7, 1, 1);
	transition: transform 0.42s, opacity 0.42s;
	border-radius: inherit;
}

.button-ujarak, .button-ujarak::before {
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button-ujarak:hover {
	transition: background .4s .4s, border-color .4s 0s, color .2s 0s;
}

.button-ujarak:hover::before {
	opacity: 1;
	transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

.button-xs {
	padding: 8px 17px;
}

.button-sm {
	padding: 5px 25px 3px;
	font-size: 12px;
	line-height: 1.5;
}

.button-md {
	min-width: 140px;
	padding: 12px 23px 10px;
	font-size: 16px;
	line-height: 1.5;
}

.button-md.button-gradient, .button-md.button-wapasha, .button-md.button-pipaluk {
	padding: 14px 25px 12px;
}

.button-lg {
	padding: 20px 33px 18px;
	line-height: 1.33;
}

.button-lg.button-gradient, .button-lg.button-wapasha, .button-lg.button-pipaluk {
	padding: 22px 35px 20px;
}

.button-xl {
	padding: 16px 50px 14px;
	font-size: 16px;
	line-height: 28px;
}

@media (min-width: 992px) {
	.button-xl {
		padding: 20px 80px;
	}
}

.button-circle {
	border-radius: 30px;
}

.button-round-1 {
	border-radius: 5px;
}

.button-round-2 {
	border-radius: 10px;
}

.button.button-icon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
}

.button.button-icon .icon {
	position: relative;
	display: inline-block;
	top: -1px;
	vertical-align: middle;
	color: inherit;
	font-size: 14px;
	line-height: 1;
}

.button.button-icon-left .icon {
	padding-right: 8px;
}

.button.button-icon-right {
	flex-direction: row-reverse;
}

.button.button-icon-right .icon {
	padding-left: 8px;
}

.button.button-icon.button-link .icon {
	top: 5px;
	font-size: 1em;
}

.button.button-icon.button-xs .icon {
	top: .05em;
	font-size: 1.2em;
	padding-right: 8px;
}

.button.button-icon.button-xs .button-icon-right {
	padding-left: 8px;
}

.button.button-icon.button-facebook .icon, .button.button-icon.button-twitter .icon, .button.button-icon.button-google .icon {
	font-size: 18px;
}

.btn-primary {
	border-radius: 3px;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	font-weight: 500;
	letter-spacing: .05em;
	text-transform: uppercase;
	transition: .33s;
}

.btn-primary, .btn-primary:active, .btn-primary:focus {
	color: #ffffff;
	background: #01b3a7;
	border-color: #01b3a7;
}

.btn-primary:hover {
	color: #ffffff;
	background: #000000;
	border-color: #000000;
}

.button-pipaluk {
	position: relative;
	overflow: visible;
	padding: 14px 35px 15px;
	border: none;
	z-index: 1;
}

.button-pipaluk::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	transition: all .3s ease-in-out;
}

.button-pipaluk::after {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-width: 2px;
	border-style: solid;
	opacity: 0;
	visibility: hidden;
	transition: all .2s ease 0s;
	transform: scale(1.05);
	will-change: transform;
	z-index: -1;
}

.button-pipaluk.button-circle::before, .button-pipaluk.button-circle::after {
	border-radius: inherit;
}

.button-pipaluk:hover::before, .button-pipaluk:active::before {
	opacity: 0;
	visibility: hidden;
	transform: scale(0.9);
	transition-duration: .2s;
}

.button-pipaluk:hover::after, .button-pipaluk:active::after {
	opacity: 1;
	visibility: visible;
	transform: none;
	transition-duration: .3s;
	transition-delay: .1s;
}

.button-wapasha {
	position: relative;
	overflow: visible;
	padding: 19px 35px 17px;
	border: none;
	z-index: 1;
}

.button-wapasha::before, .button-wapasha::after {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-width: 2px;
	border-style: solid;
}

.button-wapasha::before {
	opacity: 1;
	visibility: visible;
	transition: all .3s ease;
	z-index: -1;
}

.button-wapasha::after {
	opacity: 0;
	visibility: hidden;
	transition: all .2s ease;
	transform: translate3d(8px, -8px, 0);
	will-change: transform;
	z-index: -1;
}

.button-wapasha:hover::before, .button-wapasha:active::before {
	opacity: 0;
	visibility: hidden;
	transform: translate3d(-8px, 8px, 0);
	transition-duration: .2s;
}

.button-wapasha:hover::after, .button-wapasha:active::after {
	opacity: 1;
	visibility: visible;
	transform: none;
	transition-duration: .3s;
}

.button-social {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: -14px;
	margin-left: -14px;
}

.button-social:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.button-social > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 14px;
	margin-left: 14px;
}

.button-social > * {
	min-width: 175px;
	flex-grow: 1;
}

.button-social * + .button {
	margin-top: 0;
}

.button-width-170 {
	min-width: 170px;
}

@media (min-width: 1200px) {
	.button-width-xl-230 {
		min-width: 230px;
	}
	.button-width-xl-310 {
		min-width: 310px;
	}
}

.button-gradient {
	border: none;
	padding: 19px 35px 17px;
	background-size: auto 200%;
}

.button-primary-gradient {
	background-image: linear-gradient(to bottom, #01b3a7 0%, #01b3a7 50%, #50ba87 100%);
	background-repeat: no-repeat;
}

.button-primary-gradient, .button-primary-gradient:focus {
	color: #ffffff;
	background-color: #01b3a7;
	border-color: transparent;
}

.button-primary-gradient:hover, .button-primary-gradient:active {
	color: #ffffff;
	background-color: #50ba87;
	border-color: transparent;
}

.button-primary-gradient.button-ujarak::before {
	background: #50ba87;
}

.button-primary-gradient.button-pipaluk, .button-primary-gradient.button-pipaluk:focus {
	background: transparent;
}

.button-primary-gradient.button-pipaluk::before, .button-primary-gradient.button-pipaluk:focus::before {
	background: #01b3a7;
	border-color: transparent;
}

.button-primary-gradient.button-pipaluk::after, .button-primary-gradient.button-pipaluk:focus::after {
	border-color: transparent;
}

.button-primary-gradient.button-pipaluk:hover, .button-primary-gradient.button-pipaluk:active {
	color: transparent;
	background: transparent;
}

.button-primary-gradient.button-wapasha, .button-primary-gradient.button-wapasha:focus {
	color: transparent;
	background: transparent;
}

.button-primary-gradient.button-wapasha::before, .button-primary-gradient.button-wapasha:focus::before {
	border-color: transparent;
}

.button-primary-gradient.button-wapasha::after, .button-primary-gradient.button-wapasha:focus::after {
	border-color: transparent;
}

.button-primary-gradient.button-wapasha:hover, .button-primary-gradient.button-wapasha:active {
	color: transparent;
	background: transparent;
}

.button-primary-gradient:hover, .button-primary-gradient:active {
	background-position: 50% 100%;
}

/*
*
* Form styles
*/
.rd-form {
	position: relative;
	text-align: left;
}

.rd-form .button {
	min-width: 222px;
}

@media (max-width: 575.98px) {
	.rd-form .button {
		display: block;
		width: 100%;
	}
}

.rd-form * + .button {
	margin-top: 33px;
}

.rd-form-variant-2 .button {
	min-width: 256px;
}

* + .rd-form {
	margin-top: 26px;
}

p + .rd-form {
	margin-top: 30px;
}

h5 + .rd-form {
	margin-top: 40px;
}

h4 + .rd-form-variant-2 {
	margin-top: 34px;
}

.row + .checkbox-inline {
	margin-top: 20px;
}

.form-wrap + .checkbox-inline {
	margin-top: 15px;
}

input:-webkit-autofill ~ label,
input:-webkit-autofill ~ .form-validation {
	color: #000000 !important;
}

.form-wrap {
	position: relative;
}

.form-wrap.has-error .form-input {
	border-color: #f5543f;
}

.form-wrap.has-focus .form-input {
	border-color: #01b3a7;
}

.form-wrap + * {
	margin-top: 20px;
}

.form-input {
	display: block;
	width: 100%;
	min-height: 60px;
	padding: 17px 22px;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: .025em;
	color: #151515;
	background-color: #f7f7f7;
	background-image: none;
	border-radius: 0;
	-webkit-appearance: none;
	transition: .3s ease-in-out;
	border: 1px solid #f7f7f7;
}

.form-input:focus {
	outline: 0;
}

.form-input-inverse {
	background-color: #ffffff;
	border-color: #e1e1e1;
}

.form-input-price {
	border-bottom: 2px solid #01b3a7;
}

.form-input-disabled {
	cursor: default;
}

textarea.form-input {
	height: 156px;
	min-height: 60px;
	max-height: 265px;
	resize: vertical;
}

textarea.textarea-lg {
	height: 206px;
	max-height: 350px;
	resize: vertical;
}

.form-label,
.form-label-outside {
	margin-bottom: 0;
	color: #151515;
	font-weight: 400;
}

.form-label {
	position: absolute;
	top: 30px;
	left: 0;
	right: 0;
	padding-left: 22px;
	padding-right: 22px;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: .025em;
	pointer-events: none;
	text-align: left;
	z-index: 9;
	transition: .25s;
	transform: translate3d(0, -50%, 0);
	will-change: transform;
}

.form-label.focus {
	opacity: 0;
}

.form-label.auto-fill {
	color: #151515;
}

.form-label-outside {
	width: 100%;
	margin-bottom: 4px;
}

@media (min-width: 768px) {
	.form-label-outside {
		position: static;
	}
	.form-label-outside, .form-label-outside.focus, .form-label-outside.auto-fill {
		transform: none;
	}
}

.form-label-disabled {
	color: #9b9b9b;
}

[data-x-mode='true'] .form-label {
	pointer-events: auto;
}

.form-validation {
	position: absolute;
	right: 8px;
	top: 0;
	z-index: 11;
	margin-top: 2px;
	font-size: 9px;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0;
	color: #f5543f;
	transition: .3s;
}

.form-validation-left .form-validation {
	top: 100%;
	right: auto;
	left: 0;
}

#form-output-global {
	position: fixed;
	bottom: 30px;
	left: 15px;
	z-index: 2000;
	visibility: hidden;
	transform: translate3d(-500px, 0, 0);
	transition: .3s all ease;
}

#form-output-global.active {
	visibility: visible;
	transform: translate3d(0, 0, 0);
}

@media (min-width: 576px) {
	#form-output-global {
		left: 30px;
	}
}

.form-output {
	position: absolute;
	top: 100%;
	left: 0;
	font-size: 10px;
	font-weight: 400;
	line-height: 1.2;
	margin-top: 2px;
	transition: .3s;
	opacity: 0;
	visibility: hidden;
}

.form-output.active {
	opacity: 1;
	visibility: visible;
}

.form-output.error {
	color: #f5543f;
}

.form-output.success {
	color: #98bf44;
}

.radio .radio-custom,
.radio-inline .radio-custom,
.checkbox .checkbox-custom,
.checkbox-inline .checkbox-custom {
	opacity: 0;
}

.radio .radio-custom, .radio .radio-custom-dummy,
.radio-inline .radio-custom,
.radio-inline .radio-custom-dummy,
.checkbox .checkbox-custom,
.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom,
.checkbox-inline .checkbox-custom-dummy {
	position: absolute;
	left: 0;
	width: 14px;
	height: 14px;
	outline: none;
	cursor: pointer;
}

.radio .radio-custom-dummy,
.radio-inline .radio-custom-dummy,
.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom-dummy {
	pointer-events: none;
	background: #ffffff;
}

.radio .radio-custom-dummy::after,
.radio-inline .radio-custom-dummy::after,
.checkbox .checkbox-custom-dummy::after,
.checkbox-inline .checkbox-custom-dummy::after {
	position: absolute;
	opacity: 0;
	transition: .22s;
}

.radio .radio-custom:focus,
.radio-inline .radio-custom:focus,
.checkbox .checkbox-custom:focus,
.checkbox-inline .checkbox-custom:focus {
	outline: none;
}

.radio input,
.radio-inline input,
.checkbox input,
.checkbox-inline input {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.radio-custom:checked + .radio-custom-dummy:after,
.checkbox-custom:checked + .checkbox-custom-dummy:after {
	opacity: 1;
}

.radio,
.radio-inline {
	position: relative;
	font-size: 18px;
	line-height: 1;
	padding-left: 32px;
	letter-spacing: .1em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	cursor: pointer;
}

.radio .radio-custom-dummy,
.radio-inline .radio-custom-dummy {
	top: -4px;
	left: 0;
	width: 21px;
	height: 21px;
	margin: 0;
	color: #01b3a7;
	border: 1px solid #e1e1e1;
	border-radius: 3px;
	background: #fefefe;
}

.radio .radio-custom-dummy::after,
.radio-inline .radio-custom-dummy::after {
	content: '\f222';
	font-family: "Material Design Icons";
	position: absolute;
	top: -3px;
	left: -2px;
	font-size: 24px;
	line-height: 1;
}

.checkbox,
.checkbox-inline {
	position: relative;
	padding-left: 32px;
	letter-spacing: .025em;
	color: #9b9b9b;
	cursor: pointer;
}

.checkbox .checkbox-custom-dummy,
.checkbox-inline .checkbox-custom-dummy {
	top: 2px;
	left: 0;
	width: 21px;
	height: 21px;
	margin: 0;
	color: #01b3a7;
	border: 1px solid #e1e1e1;
	border-radius: 3px;
	background: #fefefe;
}

.checkbox .checkbox-custom-dummy::after,
.checkbox-inline .checkbox-custom-dummy::after {
	content: '\f222';
	font-family: "Material Design Icons";
	position: absolute;
	top: -3px;
	left: -2px;
	font-size: 24px;
	line-height: 1;
}

.toggle-custom {
	padding-left: 60px;
	-webkit-appearance: none;
}

.toggle-custom:checked ~ .checkbox-custom-dummy::after {
	background: #01b3a7;
	transform: translate(20px, -50%);
}

.toggle-custom ~ .checkbox-custom-dummy {
	position: relative;
	display: inline-block;
	margin-top: -1px;
	width: 44px;
	height: 20px;
	background: #f7f7f7;
	cursor: pointer;
}

.toggle-custom ~ .checkbox-custom-dummy::after {
	content: '';
	position: absolute;
	display: inline-block;
	width: 16px;
	height: 16px;
	left: 0;
	top: 50%;
	background: #b7b7b7;
	transform: translate(4px, -50%);
	opacity: 1;
	transition: .22s;
}

.rd-form-inline {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: space-between;
	text-align: center;
	max-width: 480px;
	margin-left: auto;
	margin-right: auto;
}

.rd-form-inline > * {
	margin-top: 0;
}

.rd-form-inline .form-wrap {
	width: 100%;
}

.rd-form-inline .form-wrap-select {
	text-align: left;
}

.rd-form-inline .form-button {
	flex-grow: 1;
	flex-shrink: 0;
	min-height: 60px;
	margin-top: 10px;
}

.rd-form-inline .form-button .button {
	display: block;
	min-height: inherit;
	min-width: auto;
	width: 100%;
}

.rd-form-inline.rd-search {
	max-width: 100%;
}

@media (min-width: 576px) {
	.rd-form-inline .form-wrap {
		flex: 1;
		min-width: 185px;
	}
	.rd-form-inline .form-button {
		flex-grow: 0;
		margin-top: 0;
		margin-left: 7px;
	}
	.rd-form-inline .form-button .button {
		display: inline-block;
	}
	.rd-form-inline.rd-form-coupon .form-button {
		margin-left: 18px;
	}
}

.form-sm .form-input,
.form-sm .button {
	padding-top: 9px;
	padding-bottom: 9px;
	min-height: 40px;
}

.form-sm .form-validation {
	top: -16px;
}

.form-sm .form-label {
	top: 20px;
}

.form-sm * + .button {
	margin-top: 10px;
}

.form-lg .form-input,
.form-lg .form-label,
.form-lg .select2-container .select2-choice {
	font-size: 16px;
}

.form-lg .form-input,
.form-lg .select2-container .select2-choice {
	font-size: 16px;
	padding-top: 17px;
	padding-bottom: 17px;
}

.form-lg .form-input,
.form-lg .select2-container .select2-choice {
	min-height: 58px;
}

.form-lg .form-button {
	min-height: 60px;
}

.form-lg .form-label {
	top: 30px;
}

.form-wrap-recaptcha {
	display: inline-block;
}

@media (max-width: 575.98px) {
	.recaptcha iframe {
		transform: scale(0.95);
		transform-origin: 0 50%;
		will-change: transform;
	}
	.form-wrap-recaptcha .form-validation {
		right: 22px;
	}
}

.rd-form-coupon .form-wrap {
	flex-shrink: 0;
}

.rd-form-coupon .form-button {
	min-width: 212px;
}

.rd-form-coupon .form-label {
	padding-left: 32px;
}

.rd-form-coupon .form-input {
	min-width: 266px;
	padding-left: 32px;
}

@media (min-width: 576px) {
	.rd-form-coupon {
		max-width: none;
		min-width: 496px;
	}
}

.box-form {
	text-align: center;
	padding: 30px 20px;
	background: #f7f7f7;
}

.box-form .form-input:not(.select-filter) {
	min-height: 50px;
	padding-top: 12px;
	padding-bottom: 12px;
	background: #ffffff;
	border-color: #e1e1e1;
}

.box-form .form-input.form-input-price {
	border-bottom-color: #01b3a7;
	padding-bottom: 11px;
}

.box-form .form-label {
	top: 25px;
}

.box-form .form-label span {
	font-size: 12px;
	color: #9b9b9b;
}

.box-form textarea.form-input {
	height: 116px;
	max-height: 197px;
}

.box-form .button.button-ujarak:not([class*='button-secondary']) {
	color: #ffffff;
}

.box-form .button.button-ujarak:not([class*='button-secondary'])::before {
	background: #50ba87;
}

.box-form .button.button-ujarak:not([class*='button-secondary']):hover, .box-form .button.button-ujarak:not([class*='button-secondary']):active {
	border-color: #50ba87;
}

.box-form .form-wrap + * {
	margin-top: 10px;
}

.box-form * + .rd-form {
	margin-top: 16px;
}

.box-form .row + .button {
	margin-top: 16px;
}

.box-form-title {
	letter-spacing: .05em;
}

.box-form-text {
	color: #9b9b9b;
}

@media (min-width: 576px) {
	.box-form {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (min-width: 992px) {
	.box-form * + .rd-form {
		margin-top: 16px;
	}
}

@media (min-width: 1200px) {
	.box-form {
		padding: 50px 60px;
	}
	.box-form-text {
		max-width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
}

/*
*
* Posts
*/
.post {
	text-align: left;
}

.post-classic-title {
	letter-spacing: .1em;
}

.post-classic-panel {
	margin-bottom: -20px;
	margin-left: -36px;
	font-size: 18px;
	line-height: 1;
	letter-spacing: .1em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.post-classic-panel:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.post-classic-panel > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 20px;
	margin-left: 36px;
}

.post-classic-panel > *:not(.badge) {
	position: relative;
	top: 2px;
}

.post-classic-comments .icon,
.post-classic-time .icon {
	color: #9b9b9b;
}

.post-classic-comments > *,
.post-classic-time > * {
	display: inline-block;
	vertical-align: middle;
}

.post-classic-comments .icon {
	font-size: 24px;
	position: relative;
	top: -4px;
}

.post-classic-comments a, .post-classic-comments a:focus, .post-classic-comments a:active {
	color: inherit;
}

.post-classic-comments a:hover {
	color: #01b3a7;
}

.post-classic-comments span + * {
	margin-left: 6px;
}

.post-classic-time .icon {
	position: relative;
	top: -2px;
	font-size: 20px;
}

.post-classic-time span + * {
	margin-left: 3px;
}

.post-classic-author {
	color: #9b9b9b;
}

.post-classic-author a {
	display: inline-block;
	margin-left: 5px;
}

.post-classic-author a, .post-classic-author a:focus, .post-classic-author a:active {
	color: #01b3a7;
}

.post-classic-author a:hover {
	color: #50ba87;
}

.post-classic-figure {
	display: block;
	overflow: hidden;
}

.post-classic-figure img {
	width: 100%;
	transition: all .3s ease;
}

.post-classic-gallery {
	display: block;
	overflow: hidden;
}

.post-classic-figure-small {
	overflow: hidden;
}

.post-classic-figure-small img {
	width: 100%;
	transition: all .3s ease;
}

@media (min-width: 992px) {
	.desktop .post-classic-figure img,
	.desktop .post-classic-figure-small img {
		transform: scale(1.1);
		will-change: transform;
	}
	.desktop .post-classic:hover .post-classic-figure img,
	.desktop .post-classic:hover .post-classic-figure-small img {
		transform: none;
	}
}

* + .post-classic-panel {
	margin-top: 20px;
}

.post-classic-panel + * {
	margin-top: 30px;
}

.post-classic-panel + .owl-carousel {
	margin-top: 30px;
}

* + .post-classic-text {
	margin-top: 26px;
}

* + .post-classic-figure {
	margin-top: 30px;
}

.post-classic + .post-classic {
	margin-top: 60px;
}

@media (max-width: 575.98px) {
	.col-custom-right {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.col-custom-right > div {
		max-width: calc(50% - 5px);
	}
}

@media (min-width: 576px) {
	.post-classic-figure-small + .post-classic-figure-small {
		margin-top: 8%;
	}
}

@media (min-width: 576px) {
	.col-custom-left {
		max-width: 62.5%;
		flex: 0 0 62.5%;
	}
	.col-custom-right {
		max-width: 37.5%;
		flex: 0 0 37.5%;
	}
}

.post-modern-figure {
	position: relative;
	overflow: hidden;
	display: block;
}

.post-modern-figure img {
	width: 100%;
	transition: all .3s ease;
}

.post-modern-time {
	display: inline-block;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 10px 10px 0 1px;
	color: #01b3a7;
	font-size: 16px;
	line-height: .5;
	letter-spacing: .025em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	background: #ffffff;
}

@media (min-width: 768px) {
	.post-modern-time {
		font-size: 202px;
	}
}

.post-modern-time::before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 15px;
	background: inherit;
	transform: translate3d(0, -100%, 0) skewX(30deg);
	transform-origin: 50% 100%;
	will-change: transform;
}

.post-modern-time .post-modern-time-number {
	font-size: 30px;
	line-height: 1.1;
}

@media (min-width: 768px) {
	.post-modern-time .post-modern-time-number {
		font-size: 40px;
	}
}

.post-modern-time * + .post-modern-time-month {
	margin-left: 8px;
}

.post-modern-text {
	letter-spacing: .025em;
	color: #9b9b9b;
}

@media (min-width: 992px) {
	.desktop .post-modern-figure img {
		transform: scale(1.1);
		will-change: transform;
	}
	.desktop .post-modern:hover .post-modern-figure img {
		transform: none;
	}
}

@media (min-width: 768px) {
	.post-modern-time {
		padding-top: 17px;
		font-size: 24px;
	}
}

* + .post-modern-title {
	margin-top: 26px;
}

* + .post-modern-text {
	margin-top: 12px;
}

.post-creative {
	position: relative;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
	transition: all .3s ease-in-out;
	z-index: 1;
}

.post-creative-2 .post-creative-time,
.post-creative-2 .post-creative-author a:hover,
.post-creative-2 .post-creative-title a:hover {
	color: #50ba87;
}

.post-creative-header {
	padding: 19px 15px 13px;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: normal;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.post-creative-header > div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media (min-width: 576px) {
	.post-creative-header > div {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: center;
		flex-direction: row;
	}
}

@media (min-width: 576px) {
	.post-creative-header > div {
		align-items: center;
		justify-content: space-between;
	}
}

.post-creative-author {
	color: #9b9b9b;
}

.post-creative-author a, .post-creative-author a:focus, .post-creative-author a:active {
	color: inherit;
}

.post-creative-author a:hover {
	color: #50ba87;
}

.post-creative-time {
	color: #01b3a7;
}

.post-creative-figure {
	position: relative;
	overflow: hidden;
	display: block;
}

.post-creative-figure img {
	width: 100%;
	transition: all .3s ease;
}

.post-creative-footer {
	padding: 24px 15px 27px;
}

.post-creative-title {
	line-height: 1.18;
	font-weight: 400;
}

@media (min-width: 992px) {
	.desktop .post-creative {
		box-shadow: none;
		border: 1px solid #d7d7d7;
	}
	.desktop .post-creative-figure {
		margin-right: -1px;
		margin-left: -1px;
	}
	.desktop .post-creative-figure img {
		transform: scale(1.1);
		will-change: transform;
	}
	.desktop .post-creative:hover {
		border-color: transparent;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
	}
	.desktop .post-creative:hover .post-creative-figure img {
		transform: none;
	}
}

@media (min-width: 576px) {
	.post-creative-header {
		padding-left: 20px;
		padding-right: 20px;
	}
	.post-creative-footer {
		padding-left: 20px;
	}
}

.post-aria {
	position: relative;
}

.post-aria-2 .post-aria-time {
	color: #01b3a7;
}

.post-aria-2 .post-aria-list-social .icon::after {
	background: #01b3a7;
}

.post-aria-figure {
	display: block;
	overflow: hidden;
}

.post-aria-figure img {
	width: 100%;
	transition: all .3s ease;
}

.post-aria-footer {
	position: relative;
	text-align: center;
	max-width: 90%;
	margin-left: auto;
	margin-right: auto;
	margin-top: -80px;
	padding: 20px 15px;
	background: #ffffff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.27);
}

.post-aria-title {
	font-size: 20px;
	letter-spacing: normal;
	line-height: 1.2;
	margin-bottom: 20px;
	font-weight: 600;
}

.post-aria-time {
	font-size: 20px;
	font-weight: 500;
	letter-spacing: normal;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #01b3a7;
}

.post-aria-list-social {
	font-size: 0;
	line-height: 0;
	transition: all .3s ease;
}

.post-aria-list-social .icon {
	position: relative;
	width: 32px;
	height: 32px;
	font-size: 14px;
	line-height: 32px;
	border-radius: 50%;
	background: #151515;
	z-index: 1;
}

.post-aria-list-social .icon, .post-aria-list-social .icon:focus, .post-aria-list-social .icon:active {
	color: #ffffff;
}

.post-aria-list-social .icon:hover {
	color: #ffffff;
}

.post-aria-list-social .icon::after {
	display: inline-block;
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: inherit;
	background: #50ba87;
	transform: scale(0);
	transform-origin: 50% 0;
	transition: all .3s ease;
	z-index: -1;
}

.post-aria-list-social .icon:hover {
	background: transparent;
	transition-duration: 0s;
	transition-delay: .3s;
}

.post-aria-list-social .icon:hover::after {
	transform: none;
}

@media (min-width: 992px) {
	.desktop .post-aria {
		padding-bottom: 33px;
	}
	.desktop .post-aria-figure img {
		transform: scale(1.1);
		will-change: transform;
	}
	.desktop .post-aria-footer {
		position: absolute;
		overflow: hidden;
		left: 50%;
		bottom: 0;
		width: 100%;
		transform: translate3d(-50%, 0, 0);
		padding: 24px 20px 20px;
		transition: all .3s ease;
	}
	.desktop .post-aria-list-social {
		overflow: hidden;
		height: 0;
	}
	.desktop .post-aria-list-social li {
		transform: translate3d(0, -100%, 0);
		opacity: 0;
		visibility: hidden;
		transition: inherit;
	}
	.desktop .post-aria:hover .post-aria-figure img {
		transform: none;
	}
	.desktop .post-aria:hover .post-aria-footer {
		padding-top: 24px;
	}
	.desktop .post-aria:hover .post-aria-list-social {
		height: 44px;
	}
	.desktop .post-aria:hover .post-aria-list-social li {
		transform: none;
		opacity: 1;
		visibility: visible;
	}
	.desktop .post-aria:hover .post-aria-list-social li:nth-child(1) {
		transition-delay: 50ms;
	}
	.desktop .post-aria:hover .post-aria-list-social li:nth-child(2) {
		transition-delay: 100ms;
	}
	.desktop .post-aria:hover .post-aria-list-social li:nth-child(3) {
		transition-delay: 150ms;
	}
	.desktop .post-aria:hover .post-aria-list-social li:nth-child(4) {
		transition-delay: 200ms;
	}
	.desktop .post-aria:hover .post-aria-list-social li:nth-child(5) {
		transition-delay: 250ms;
	}
	.desktop .post-aria:hover .post-aria-list-social li:nth-child(6) {
		transition-delay: 300ms;
	}
	.desktop .post-aria:hover .post-aria-list-social li:nth-child(7) {
		transition-delay: 350ms;
	}
	.desktop .post-aria:hover .post-aria-list-social li:nth-child(8) {
		transition-delay: 400ms;
	}
	.desktop .post-aria:hover .post-aria-list-social li:nth-child(9) {
		transition-delay: 450ms;
	}
	.desktop .post-aria:hover .post-aria-list-social li:nth-child(10) {
		transition-delay: 500ms;
	}
}

* + .post-aria-list-social {
	margin-top: 3px;
}

@media (min-width: 992px) {
	.post-aria-title {
		font-size: 20px;
	}
	.post-aria-time {
		font-size: 16px;
	}
}

@media (min-width: 1200px) {
	.post-aria-title {
		font-size: 24px;
	}
	.desktop .post-aria-footer {
		padding: 40px;
	}
}

.post-sidebar {
	text-align: left;
}

.post-sidebar h5 {
	display: inline-block;
	padding: 8px 10px 15px 2px;
	min-width: 150px;
	font-weight: 400;
	letter-spacing: .1em;
	background: #ffffff;
}

.post-sidebar .quote-minimal {
	padding-bottom: 20px;
	border-bottom: 1px solid #e1e1e1;
}

.post-sidebar .quote-minimal:last-child {
	padding-bottom: 0;
	border: none;
}

.post-sidebar .rd-form .form-input {
	border-color: #ffffff;
	background: #ffffff;
}

.post-sidebar .form-wrap + .form-button {
	margin-top: 14px;
}

.post-sidebar-item {
	background: #f7f7f7;
}

.post-sidebar-item-inset {
	padding: 20px 15px;
}

.list-categories {
	font-size: 16px;
	text-align: left;
}

.list-categories li {
	position: relative;
	padding-left: 50px;
}

.list-categories a, .list-categories a:focus, .list-categories a:active {
	color: inherit;
}

.list-categories a:hover {
	color: #01b3a7;
}

.list-categories a::before {
	position: absolute;
	content: '';
	top: 12px;
	left: 0;
	width: 0;
	height: 3px;
	background: #01b3a7;
	transition: all .2s ease;
}

.list-categories a.active {
	color: #01b3a7;
}

.list-categories a.active::before {
	width: 32px;
}

.list-categories > li + li {
	margin-top: 11px;
}

.post-sidebar-item + .post-sidebar-item {
	margin-top: 55px;
}

@media (min-width: 768px) {
	.post-sidebar-item-inset {
		padding: 36px 30px;
	}
	.post-sidebar-item-inset.inset-right-20 {
		padding-right: 20px;
	}
	.list-categories li {
		padding-left: 70px;
	}
	.list-categories a.active::before {
		width: 52px;
	}
}

@media (min-width: 1200px) {
	.post-sidebar-inset {
		padding-left: 70px;
	}
	.post-sidebar-item-inset {
		padding: 36px 34px;
	}
}

.badge {
	position: relative;
	overflow: hidden;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	padding: 0 0 0 15px;
	color: #ffffff;
	font-size: 16px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: .1em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	background: transparent;
	border-radius: 0;
	z-index: 1;
}

.badge > div {
	padding: 4px 9px 4px 0;
	background: #151515;
	transition: background .3s ease-in-out;
}

.badge svg {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 16px;
	transition: inherit;
	z-index: -1;
}

.badge:focus, .badge:hover {
	color: #ffffff;
}

.badge:focus > div, .badge:hover > div {
	background: #151515;
}

.badge:focus > svg, .badge:hover > svg {
	fill: #151515;
}

.badge-primary > div {
	background: #01b3a7;
}

.badge-primary > svg {
	fill: #01b3a7;
}

.badge-secondary > div {
	background: #50ba87;
}

.badge-secondary > svg {
	fill: #50ba87;
}

.badge-secondary-3 > div {
	background: #50ba87;
}

.badge-secondary-3 > svg {
	fill: #50ba87;
}

.badge-white {
	color: #9b9b9b;
}

.badge-white > div {
	background: #ffffff;
}

.badge-white > svg {
	fill: #ffffff;
}

.badge-white:focus, .badge-white:hover {
	color: #ffffff;
}

.badge-white:focus > div, .badge-white:hover > div {
	background: #01b3a7;
}

.badge-white:focus > svg, .badge-white:hover > svg {
	fill: #01b3a7;
}

.badge-pink > div {
	background: #f27474;
}

.badge-pink > svg {
	fill: #f27474;
}

.badge-yellow-3 {
	color: #151515;
}

.badge-yellow-3 > div {
	background: #fbe073;
}

.badge-yellow-3 > svg {
	fill: #fbe073;
}

.post-minimal-figure {
	display: block;
	overflow: hidden;
}

.post-minimal-figure img {
	width: 100%;
	transition: all .3s ease;
}

.post-minimal-title {
	font-size: 16px;
	line-height: 1.5;
}

.post-minimal-title a, .post-minimal-title a:focus, .post-minimal-title a:active {
	color: inherit;
}

.post-minimal-title a:hover {
	color: #50ba87;
}

@media (min-width: 992px) {
	.desktop .post-minimal-figure img {
		transform: scale(1.1);
		will-change: transform;
	}
	.desktop .post-minimal:hover .post-minimal-figure img {
		transform: none;
	}
}

* + .post-minimal-title {
	margin-top: 17px;
}

.post-minimal + .post-minimal {
	margin-top: 30px;
}

.post-minimal-2 {
	max-width: 220px;
}

.post-minimal-2-title a, .post-minimal-2-title a:focus, .post-minimal-2-title a:active {
	color: #50ba87;
}

.post-minimal-2-title a:hover {
	color: #01b3a7;
}

.post-minimal-2-time {
	font-size: 12px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: .075em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #151515;
}

* + .post-minimal-2 {
	margin-top: 24px;
}

* + .post-minimal-2-time {
	margin-top: 10px;
}

.post-minimal-2 + .post-minimal-2 {
	margin-top: 26px;
}

.blog-post {
	text-align: left;
	padding-bottom: 40px;
	border-bottom: 1px solid #dddddd;
}

.blog-post-tags {
	font-size: 16px;
	font-weight: 500;
	letter-spacing: .1em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.blog-post-tags a, .blog-post-tags a:focus, .blog-post-tags a:active {
	color: #9b9b9b;
}

.blog-post-tags a:hover {
	color: #01b3a7;
}

.blog-post-tags a::after {
	content: ', ';
	pointer-events: none;
}

.blog-post-tags a:last-child::after {
	display: none;
}

* + .blog-post-bottom-panel {
	margin-top: 30px;
}

* + .blog-post-comments {
	margin-top: 60px;
}

@media (min-width: 768px) {
	* + .blog-post-comments {
		margin-top: 100px;
	}
}

.post-ruth {
	padding: 15px;
	border: 1px solid #e1e1e1;
}

.post-ruth .unit-left {
	flex-basis: 40%;
}

.post-ruth-figure {
	display: block;
	overflow: hidden;
}

.post-ruth-figure img {
	width: 100%;
	transition: all .3s ease-in-out;
}

.post-ruth-title {
	font-weight: 400;
	line-height: 1.2;
}

.post-ruth-time {
	font-size: 16px;
	font-weight: 500;
	letter-spacing: .075em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #9b9b9b;
}

* + .post-ruth-title {
	margin-top: 10px;
}

* + .post-ruth-badge {
	margin-top: 12px;
}

@media (min-width: 768px) {
	.post-ruth {
		padding: 20px;
	}
}

@media (min-width: 992px) {
	.post-ruth {
		padding: 18px 22px;
	}
	.desktop .post-ruth-figure img {
		transform: scale(1.1);
		will-change: transform;
	}
	.desktop .post-ruth:hover .post-ruth-figure img {
		transform: none;
	}
}

@media (min-width: 1200px) {
	.post-ruth-title {
		max-width: 94%;
	}
	.post-ruth-body {
		padding-bottom: 10px;
	}
}

/*
*
* Quotes
*/
.quote-classic {
	position: relative;
	text-align: left;
	padding-top: 35px;
}

.quote-classic-text {
	font-size: 20px;
	line-height: 1.625;
	letter-spacing: .025em;
}

.quote-classic-text::before {
	position: absolute;
	left: 0;
	top: 0;
	content: '\201C';
	font-size: 70px;
	line-height: 1;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #01b3a7;
}

.quote-classic-cite {
	color: #50ba87;
}

.quote-classic-big {
	padding-top: 40px;
}

.quote-classic-big .quote-classic-text {
	line-height: 1.5;
}

.quote-classic-big .quote-classic-text::before {
	font-size: 90px;
}

* + .quote-classic {
	margin-top: 22px;
}

* + .quote-classic-cite {
	margin-top: 16px;
}

.quote-classic + p {
	margin-top: 30px;
}

.post-classic + .quote-classic {
	margin-top: 20px;
}

.quote-classic + .quote-classic {
	margin-top: 30px;
}

@media (min-width: 768px) {
	.quote-classic {
		padding-top: 0;
		padding-left: 40px;
	}
	.quote-classic-text {
		font-size: 24px;
	}
	.quote-classic-text::before {
		top: 2px;
		left: -4px;
		font-size: 91px;
	}
	.quote-classic-big {
		padding-left: 70px;
	}
	.quote-classic-big .quote-classic-text::before {
		top: -8px;
		left: 1px;
		font-size: 129px;
	}
	.post-classic + .quote-classic {
		margin-top: 40px;
	}
}

.quote-modern {
	position: relative;
	text-align: left;
	padding: 30px 15px;
	letter-spacing: .025em;
	background: #ffffff;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.15));
}

.quote-modern::before {
	position: absolute;
	content: '';
	top: calc(100% - 10px);
	left: 60px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 34px 34px 0 0;
	border-color: #ffffff transparent transparent transparent;
}

.quote-modern-2 .quote-modern-status {
	color: #01b3a7;
}

.quote-modern-figure {
	display: inline-block;
}

.quote-modern-cite {
	font-weight: 400;
	letter-spacing: .075em;
}

.quote-modern-status {
	color: #50ba87;
}

* + .quote-modern-status {
	margin-top: 0;
}

* + .quote-modern-text {
	margin-top: 12px;
}

@media (min-width: 576px) {
	.quote-modern {
		padding: 40px 20px;
	}
}

@media (min-width: 992px) {
	.quote-modern {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (min-width: 1200px) {
	.quote-modern {
		padding: 52px 45px 44px;
	}
	.quote-modern-custom {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (min-width: 1600px) {
	.quote-modern-custom {
		padding-left: 45px;
		padding-right: 45px;
	}
}

.ie-10 .quote-modern,
.ie-11 .quote-modern,
.ie-edge .quote-modern {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
}

.ie-10 .quote-modern::before,
.ie-11 .quote-modern::before,
.ie-edge .quote-modern::before {
	top: 100%;
	border-width: 24px 24px 0 0;
	z-index: 1;
}

.ie-10 .quote-modern::after,
.ie-11 .quote-modern::after,
.ie-edge .quote-modern::after {
	position: absolute;
	content: '';
	top: calc(100% + 2px);
	left: 58px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 28px 28px 0 0;
	border-color: rgba(0, 0, 0, 0.04) transparent transparent transparent;
	z-index: 0;
}

.quote-creative {
	text-align: center;
	padding: 0 15px 30px;
	letter-spacing: .025em;
	background: #ffffff;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.15));
}

.quote-creative-figure {
	display: inline-block;
	margin-top: -42px;
	border: 15px solid #ffffff;
	border-radius: 50%;
}

.quote-creative-cite {
	color: #01b3a7;
	font-weight: 400;
}

.quote-creative-cite a, .quote-creative-cite a:focus, .quote-creative-cite a:active {
	color: inherit;
}

.quote-creative-cite a:hover {
	color: #50ba87;
}

* + .quote-creative-cite {
	margin-top: 18px;
}

@media (min-width: 768px) {
	.quote-creative {
		padding: 0 20px 40px;
	}
}

.ie-10 .quote-creative,
.ie-11 .quote-creative,
.ie-edge .quote-creative {
	position: relative;
	background: transparent;
	z-index: 1;
}

.ie-10 .quote-creative::before,
.ie-11 .quote-creative::before,
.ie-edge .quote-creative::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #ffffff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
	z-index: -1;
}

.ie-10 .quote-creative-figure,
.ie-11 .quote-creative-figure,
.ie-edge .quote-creative-figure {
	position: relative;
}

.ie-10 .quote-creative-figure::before,
.ie-11 .quote-creative-figure::before,
.ie-edge .quote-creative-figure::before {
	position: absolute;
	content: '';
	top: -16px;
	right: -16px;
	bottom: -16px;
	left: -16px;
	border-radius: 50%;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
	z-index: -2;
}

.quote-nancy {
	position: relative;
	text-align: left;
	letter-spacing: .025em;
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

.quote-nancy-content {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	transition: all .5s ease;
}

.quote-nancy-body {
	position: relative;
	padding: 40px 20px 30px;
	background: #f7f7f7;
	will-change: transform;
	-webkit-will-change: transform;
	overflow: hidden;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 0);
	-webkit-clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 0);
	z-index: 1;
}

.quote-nancy-figure {
	position: absolute;
	content: '';
	top: 1px;
	right: 1px;
	bottom: 1px;
	left: 1px;
	overflow: hidden;
	-webkit-clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 0);
	clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 0);
}

.quote-nancy-figure img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	min-height: 100%;
	min-width: 100%;
	width: auto;
	height: auto;
	max-width: none;
}

@supports (object-fit: cover) {
	.quote-nancy-figure img {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		-webkit-transform: none;
		transform: none;
		object-fit: cover;
		-webkit-object-fit: cover;
		object-position: center center;
		-webkit-object-position: center center;
	}
}

.quote-nancy-quote {
	position: relative;
	padding-top: 35px;
}

.quote-nancy-quote h4 {
	line-height: 1.2;
	font-weight: 300;
	letter-spacing: .05em;
}

.quote-nancy-quote::before {
	position: absolute;
	left: 0;
	top: 0;
	content: '\201C';
	font-size: 70px;
	line-height: 1;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #01b3a7;
}

.quote-nancy-cite,
.quote-nancy-author {
	font-weight: 400;
}

.quote-nancy-status {
	color: #9b9b9b;
}

@media (min-width: 992px) {
	.desktop .quote-nancy:hover .quote-nancy-content {
		transform: rotateY(180deg);
		-webkit-transform: rotateY(180deg);
	}
}

@media (min-width: 768px) {
	.quote-nancy-quote {
		padding-top: 0;
		padding-left: 40px;
	}
	.quote-nancy-quote::before {
		top: -3px;
		left: 0;
		font-size: 91px;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.owl-quote .quote-nancy-quote h4 {
		font-size: 34px;
	}
}

@media (min-width: 992px) {
	.quote-nancy-body {
		padding: 60px 30px 40px;
	}
}

* + .quote-nancy-status {
	margin-top: 0;
}

* + .unit {
	margin-top: 20px;
}

.ie-10 .quote-nancy-body::before,
.ie-10 .quote-nancy-figure::before,
.ie-11 .quote-nancy-body::before,
.ie-11 .quote-nancy-figure::before,
.ie-edge .quote-nancy-body::before,
.ie-edge .quote-nancy-figure::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	width: 44px;
	background: #ffffff;
	-webkit-transform: skew(-45deg);
	transform-origin: 100% 100%;
	-webkit-transform-origin: 100% 100%;
	z-index: 1;
}

.ie-10 .quote-nancy-figure::before,
.ie-11 .quote-nancy-figure::before,
.ie-edge .quote-nancy-figure::before {
	z-index: 1;
}

.ie-10 .quote-nancy-body,
.ie-11 .quote-nancy-body,
.ie-edge .quote-nancy-body {
	transition: opacity .05s ease .15s;
}

@media (min-width: 992px) {
	.ie-10.desktop .quote-nancy:hover .quote-nancy-content,
	.ie-11.desktop .quote-nancy:hover .quote-nancy-content,
	.ie-edge.desktop .quote-nancy:hover .quote-nancy-content {
		transform: rotateY(180deg);
		-webkit-transform: rotateY(180deg);
	}
	.ie-10.desktop .quote-nancy:hover .quote-nancy-body,
	.ie-11.desktop .quote-nancy:hover .quote-nancy-body,
	.ie-edge.desktop .quote-nancy:hover .quote-nancy-body {
		opacity: 0;
	}
}

.quote-lisa {
	text-align: center;
	padding: 30px 10px;
	letter-spacing: .025em;
	background: #ffffff;
	box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.17);
}

.quote-lisa-2 .quote-lisa-text::before, .quote-lisa-2 .quote-lisa-text::after {
	color: #01b3a7;
}

.quote-lisa-2 .quote-lisa-cite a:hover {
	color: #01b3a7;
}

.quote-lisa-2 .quote-lisa-status {
	color: #50ba87;
}

.quote-lisa-body {
	max-width: 880px;
	margin-left: auto;
	margin-right: auto;
}

.quote-lisa-text {
	position: relative;
	padding-left: 20px;
	padding-right: 20px;
}

.quote-lisa-text::before, .quote-lisa-text::after {
	position: absolute;
	font-size: 48px;
	line-height: 1;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #50ba87;
}

.quote-lisa-text::before {
	left: 0;
	top: 4px;
	content: '\201C';
}

.quote-lisa-text::after {
	right: 0;
	bottom: -24px;
	content: '\201D';
}

.quote-lisa-cite {
	font-weight: 400;
	letter-spacing: .075em;
}

.quote-lisa-cite a:hover {
	color: #50ba87;
}

.quote-lisa-status {
	color: #01b3a7;
}

* + .quote-lisa-status {
	margin-top: 0;
}

* + .quote-lisa-text {
	margin-top: 18px;
}

* + .quote-lisa-cite {
	margin-top: 26px;
}

@media (min-width: 576px) {
	.quote-lisa {
		padding-left: 15px;
		padding-right: 15px;
	}
	.quote-lisa-text {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (min-width: 992px) {
	.quote-lisa {
		padding-top: 60px;
		padding-bottom: 60px;
	}
}

.quote-minimal {
	position: relative;
	text-align: left;
}

.quote-minimal-text {
	padding-left: 30px;
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: .025em;
}

.quote-minimal-text::before {
	position: absolute;
	left: 0;
	top: 4px;
	content: '\201C';
	font-size: 48px;
	line-height: 1;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #01b3a7;
}

.quote-minimal-cite {
	line-height: 1.33;
	font-weight: 400;
	color: #9b9b9b;
}

.quote-minimal-source {
	display: block;
}

.quote-minimal-source a, .quote-minimal-source a:focus, .quote-minimal-source a:active {
	color: #01b3a7;
}

.quote-minimal-source a:hover {
	color: #50ba87;
}

* + .quote-minimal {
	margin-top: 22px;
}

* + .quote-minimal-cite {
	margin-top: 20px;
}

.quote-minimal + .quote-minimal {
	margin-top: 30px;
}

.quote-jean {
	position: relative;
	max-width: 520px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 10px;
	
	background-repeat: no-repeat;
	background-position: 50% 0;
}

* + .quote-jean {
	margin-top: 16px;
}

.quote-jean + .isotope-filters {
	margin-top: 35px;
}

/*
*
* Thumbnails
*/
.thumbnail {
	position: relative;
}

.thumbnail-xxs {
	max-width: 220px;
	margin-left: auto;
	margin-right: auto;
}

.thumbnail-xs {
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
}

.thumbnail-sm {
	max-width: 370px;
	margin-left: auto;
	margin-right: auto;
}

.thumbnail-md {
	max-width: 420px;
	margin-left: auto;
	margin-right: auto;
}

.thumbnail-lg {
	max-width: 570px;
	margin-left: auto;
	margin-right: auto;
}

.thumbnail-xl {
	max-width: 870px;
	margin-left: auto;
	margin-right: auto;
}

.thumbnail-classic {
	text-align: left;
	overflow: hidden;
}

.thumbnail-classic-figure {
	display: block;
}

.thumbnail-classic-figure img {
	width: 100%;
	transition: all .3s ease;
}

.thumbnail-classic-caption {
	text-align: center;
	position: absolute;
	content: '';
	left: 50%;
	bottom: 0;
	padding: 10px 15px;
	min-width: 230px;
	transform: translate3d(-50%, 0, 0);
	background: #ffffff;
	pointer-events: none;
	transition: all .3s ease;
}

.thumbnail-classic-caption > [class*='thumbnail-classic-'] {
	pointer-events: auto;
	width: 100%;
}

.thumbnail-classic-title-wrap > * {
	display: inline-block;
	vertical-align: middle;
}

.thumbnail-classic-title-wrap .icon {
	margin-top: 3px;
	margin-right: 16px;
	font-size: 26px;
}

.thumbnail-classic-title-wrap .icon img {
	display: none;
}

.thumbnail-classic-title-wrap .icon, .thumbnail-classic-title-wrap .icon:focus, .thumbnail-classic-title-wrap .icon:active {
	color: #151515;
}

.thumbnail-classic-title-wrap .icon:hover {
	color: #50ba87;
}

.thumbnail-classic-title {
	color: #01b3a7;
}

.thumbnail-classic-title a, .thumbnail-classic-title a:focus, .thumbnail-classic-title a:active {
	color: inherit;
}

.thumbnail-classic-title a:hover {
	color: #50ba87;
}

.thumbnail-classic-text {
	display: none;
}

@media (max-width: 575.98px) {
	.thumbnail-custom-mobile {
		min-height: 300px;
	}
	.thumbnail-custom-mobile a {
		min-height: inherit;
	}
	.thumbnail-custom-mobile img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-height: 100%;
		min-width: 100%;
		width: auto;
		height: auto;
		max-width: none;
	}
	@supports (object-fit: cover) {
		.thumbnail-custom-mobile img {
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			transform: none;
			object-fit: cover;
			object-position: center center;
		}
	}
}

@media (min-width: 992px) {
	.desktop .thumbnail-classic-figure img {
		transform: scale(1.1);
		will-change: transform;
	}
	.desktop .thumbnail-classic-caption {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		text-align: inherit;
		padding: 20px;
		top: 20px;
		right: 20px;
		bottom: 20px;
		left: 20px;
		opacity: 0;
		visibility: hidden;
		transform: translate3d(-20px, -20px, 0);
		will-change: transform;
	}
	.desktop .thumbnail-classic-title-wrap .icon {
		margin-right: 24px;
		font-size: 36px;
	}
	.desktop .thumbnail-classic-text {
		display: block;
		font-size: 13px;
		width: 100%;
	}
	.desktop .thumbnail-classic:hover .thumbnail-classic-figure img {
		transform: none;
	}
	.desktop .thumbnail-classic:hover .thumbnail-classic-caption {
		opacity: 1;
		visibility: visible;
		transform: none;
	}
}

@media (min-width: 1200px) {
	.desktop .thumbnail-classic-caption {
		padding: 30px;
	}
	.desktop .thumbnail-classic-text {
		font-size: inherit;
	}
}

@media (min-width: 1800px) {
	.thumbnail-md .thumbnail-classic-caption {
		padding-left: 40px;
		padding-right: 40px;
	}
}

* + .thumbnail-classic-text {
	margin-top: 14px;
}

.thumbnail-modern {
	text-align: left;
}

.thumbnail-modern-figure {
	position: relative;
	overflow: hidden;
	display: block;
}

.thumbnail-modern-figure img {
	width: 100%;
	transition: inherit;
}

.thumbnail-modern-title {
	font-weight: 400;
	transition: all .3s ease;
}

.thumbnail-modern-title a {
	transition-property: color;
}

.thumbnail-modern-subtitle {
	color: #9b9b9b;
}

@media (min-width: 992px) {
	.desktop .thumbnail-modern-figure::before {
		position: absolute;
		content: '';
		top: 0;
		width: 0;
		bottom: 0;
		left: 0;
		background: rgba(48, 46, 46, 0.18);
		transition: inherit;
		visibility: hidden;
		opacity: 0;
		z-index: 1;
	}
	.desktop .thumbnail-modern-figure img {
		transform: scale(1.1);
		transform-origin: 0 50%;
		will-change: transform;
	}
	.desktop .thumbnail-modern:hover .thumbnail-modern-figure img {
		transform-origin: 100% 50%;
	}
	.desktop .thumbnail-modern:hover .thumbnail-modern-title {
		color: #01b3a7;
	}
	.desktop .thumbnail-modern:hover .thumbnail-modern-figure:before {
		width: 100%;
		visibility: visible;
		opacity: 1;
	}
}

* + .thumbnail-modern-caption {
	margin-top: 18px;
}

* + .thumbnail-modern-subtitle {
	margin-top: 2px;
}

.thumbnail-creative {
	overflow: hidden;
	text-align: left;
}

.thumbnail-creative-figure {
	display: block;
}

.thumbnail-creative-figure img {
	width: 100%;
	transition: all .3s ease;
}

.thumbnail-creative-caption {
	text-align: center;
	padding: 15px;
	background: #303233;
}

.thumbnail-creative-caption > * {
	position: relative;
	z-index: 1;
}

.thumbnail-creative-title {
	color: #ffffff;
	transition-delay: 0s;
}

.thumbnail-creative-time {
	font-size: 16px;
	font-weight: 500;
	letter-spacing: .075em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	text-transform: uppercase;
	color: #01b3a7;
	transition-delay: .5s;
}

.thumbnail-creative-button {
	transition-delay: .1s;
}

.thumbnail-creative-button .button {
	padding-left: 20px;
	padding-right: 20px;
	font-weight: 600;
	min-width: 125px;
}

.thumbnail-creative-button .button::after {
	border-color: #ffffff;
}

.thumbnail-creative-button .button:hover, .thumbnail-creative-button .button:active {
	color: #ffffff;
	background: transparent;
	border-color: #ffffff;
}

@media (min-width: 992px) {
	.desktop .thumbnail-creative-figure img {
		transform: scale(1.1);
		transform-origin: 100% 50%;
		will-change: transform;
	}
	.desktop .thumbnail-creative-caption {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		text-align: inherit;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 50%;
		min-width: 180px;
		padding: 30px 15px 30px 30px;
		background: transparent;
	}
	.desktop .thumbnail-creative-caption::before {
		position: absolute;
		content: '';
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #303233;
		transform: translate3d(200%, 0, 0);
		will-change: transform;
		visibility: hidden;
		opacity: 0;
		transition: all .3s ease;
	}
	.desktop .thumbnail-creative-title,
	.desktop .thumbnail-creative-time,
	.desktop .thumbnail-creative-button {
		transform: translate3d(-40px, 0, 0);
		will-change: transform;
		opacity: 0;
		visibility: hidden;
		transition: all .3s ease;
	}
	.desktop .thumbnail-creative:hover .thumbnail-creative-figure img {
		transform-origin: 0 50%;
	}
	.desktop .thumbnail-creative:hover .thumbnail-creative-caption::before {
		transform: none;
		visibility: visible;
		opacity: 1;
	}
	.desktop .thumbnail-creative:hover .thumbnail-creative-title,
	.desktop .thumbnail-creative:hover .thumbnail-creative-time,
	.desktop .thumbnail-creative:hover .thumbnail-creative-button {
		transform: none;
		opacity: 1;
		visibility: visible;
	}
	.desktop .thumbnail-creative:hover .thumbnail-creative-title {
		transition-delay: .25s;
	}
	.desktop .thumbnail-creative:hover .thumbnail-creative-time {
		transition-delay: .32s;
	}
	.desktop .thumbnail-creative:hover .thumbnail-creative-button {
		transition-delay: .39s;
	}
}

* + .thumbnail-creative-time {
	margin-top: 5px;
}

* + .thumbnail-creative-button {
	margin-top: 12px;
}

.thumbnail-mary {
	text-align: center;
	overflow: hidden;
	transition: all .3s ease;
}

.thumbnail-mary::before, .thumbnail-mary::after {
	position: absolute;
	content: '';
	transition: inherit;
	pointer-events: none;
}

.thumbnail-mary::before {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(25, 24, 24, 0.45);
	z-index: 1;
}

.thumbnail-mary::after {
	display: inline-block;
	top: 10px;
	right: 10px;
	bottom: 10px;
	left: 10px;
	border: 1px solid rgba(255, 255, 255, 0.5);
	z-index: 1;
}

.thumbnail-mary-2::after {
	display: none;
}

.thumbnail-mary-2 .thumbnail-mary-caption .icon:hover {
	color: #01b3a7;
}

.thumbnail-mary-3::before {
	background: rgba(22, 46, 68, 0.37);
}

.thumbnail-xxs .thumbnail-mary-caption .icon {
	font-size: 30px;
}

.thumbnail-mary-figure {
	display: block;
}

.thumbnail-mary-figure img {
	width: 100%;
	transition: all .3s ease;
}

.thumbnail-mary-caption {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	z-index: 1;
}

.thumbnail-mary-caption > * {
	pointer-events: auto;
}

.thumbnail-mary-caption .icon {
	font-size: 36px;
}

.thumbnail-mary-caption .icon img {
	display: none;
}

.thumbnail-mary-caption .icon, .thumbnail-mary-caption .icon:focus, .thumbnail-mary-caption .icon:active {
	color: #ffffff;
}

.thumbnail-mary-caption .icon:hover {
	color: rgba(255, 255, 255, 0.5);
}

.thumbnail-mary-title {
	color: #ffffff;
}

.thumbnail-mary-title a, .thumbnail-mary-title a:focus, .thumbnail-mary-title a:active {
	color: inherit;
}

.thumbnail-mary-title a:hover {
	color: #01b3a7;
}

.thumbnail-mary-project {
	font-weight: 400;
}

.thumbnail-mary-time {
	font-weight: 400;
	color: #01b3a7;
}

.owl-timeline .thumbnail-mary-decor {
	display: inline-block;
	position: relative;
	margin: 15px 0 14px;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: #01b3a7;
}

.owl-timeline .thumbnail-mary-decor::before, .owl-timeline .thumbnail-mary-decor::after {
	display: inline-block;
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: inherit;
	height: inherit;
	border-radius: inherit;
	opacity: 1;
	transform: none;
	visibility: visible;
}

.owl-timeline .thumbnail-mary-decor::before {
	background: rgba(1, 179, 167, 0.5);
	animation: item1B 1s ease infinite;
}

.owl-timeline .thumbnail-mary-decor::after {
	background: rgba(1, 179, 167, 0.3);
	animation: item1A 1s ease infinite;
}

.thumbnail-mary-description .thumbnail-mary-decor {
	display: none;
}

@media (min-width: 992px) {
	.desktop .thumbnail-mary::before {
		opacity: 0;
		visibility: hidden;
	}
	.desktop .thumbnail-mary::after {
		transform: scale(0.85);
		opacity: 0;
		visibility: hidden;
	}
	.desktop .thumbnail-mary-figure img {
		transform: scale(1.2);
		will-change: transform;
	}
	.desktop .thumbnail-mary-caption .icon,
	.desktop .thumbnail-mary-title {
		opacity: 0;
		visibility: hidden;
		transition: all .3s ease;
	}
	.desktop .thumbnail-mary-caption .icon {
		transform: translate3d(0, -10px, 0);
	}
	.desktop .thumbnail-mary-title {
		transform: translate3d(0, 10px, 0);
	}
	.desktop .thumbnail-mary:hover::before {
		opacity: 1;
		visibility: visible;
	}
	.desktop .thumbnail-mary:hover::after {
		opacity: 1;
		visibility: visible;
		transform: none;
	}
	.desktop .thumbnail-mary:hover .thumbnail-mary-figure img {
		transform: none;
	}
	.desktop .thumbnail-mary:hover .icon,
	.desktop .thumbnail-mary:hover .thumbnail-mary-title {
		opacity: 1;
		visibility: visible;
		transform: none;
	}
	.desktop .owl-timeline:hover .thumbnail-mary-decor::before, .desktop .owl-timeline:hover .thumbnail-mary-decor::after {
		animation: none;
	}
	.desktop .owl-timeline:hover .owl-stage > .owl-item:hover .thumbnail-mary-decor::before {
		animation: item1B 1s ease infinite;
	}
	.desktop .owl-timeline:hover .owl-stage > .owl-item:hover .thumbnail-mary-decor::after {
		animation: item1A 1s ease infinite;
	}
}

@keyframes item1B {
	25% {
		opacity: 1;
		transform: none;
		visibility: visible;
	}
	100% {
		opacity: 0;
		transform: scale(3);
		visibility: hidden;
	}
}

@keyframes item1A {
	100% {
		opacity: 0;
		transform: scale(4);
		visibility: hidden;
	}
}

* + .thumbnail-mary-title {
	margin-top: 16px;
}

* + .thumbnail-mary-description {
	margin-top: 30px;
}

.thumbnail-joan {
	text-align: center;
	overflow: hidden;
	transition: all .3s ease;
}

.thumbnail-joan::before {
	position: absolute;
	content: '';
	transition: inherit;
	pointer-events: none;
}

.thumbnail-joan::before {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(22, 46, 68, 0.37);
	z-index: 1;
}

.thumbnail-joan-figure {
	display: block;
}

.thumbnail-joan-figure img {
	width: 100%;
	transition: all .3s ease;
}

.thumbnail-joan-caption {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	z-index: 1;
}

.thumbnail-joan-caption > * {
	pointer-events: auto;
}

.thumbnail-joan-title {
	color: #ffffff;
}

.thumbnail-joan-title a, .thumbnail-joan-title a:focus, .thumbnail-joan-title a:active {
	color: inherit;
}

.thumbnail-joan-title a:hover {
	color: #01b3a7;
}

@media (min-width: 992px) {
	.desktop .thumbnail-joan::before {
		opacity: 0;
		visibility: hidden;
	}
	.desktop .thumbnail-joan-figure img {
		transform: scale(1.2);
		will-change: transform;
	}
	.desktop .thumbnail-joan-title {
		opacity: 0;
		visibility: hidden;
		transition: all .3s ease;
		transform: translate3d(0, 10px, 0);
	}
	.desktop .thumbnail-joan:hover::before {
		opacity: 1;
		visibility: visible;
	}
	.desktop .thumbnail-joan:hover .thumbnail-joan-figure img {
		transform: none;
	}
	.desktop .thumbnail-joan:hover .thumbnail-joan-title {
		opacity: 1;
		visibility: visible;
		transform: none;
	}
}

.thumbnail-instafeed {
	padding-bottom: 72%;
}

.thumbnail-instafeed .thumbnail-mary-figure img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-height: 100%;
	min-width: 100%;
	width: auto;
	height: auto;
	max-width: none;
}

@media (min-width: 992px) {
	.desktop .thumbnail-instafeed .thumbnail-mary-figure img {
		transform: translate(-50%, -50%) scale(1.1);
	}
	.desktop .thumbnail-instafeed:hover .thumbnail-mary-figure img {
		transform: translate(-50%, -50%);
	}
}

.single-project .button {
	min-width: 230px;
}

.single-project p + p {
	margin-top: 24px;
}

@media (min-width: 576px) {
	.single-project {
		text-align: left;
	}
	.single-project .title-decoration-lines-bottom + p {
		margin-top: 38px;
	}
}

@media (min-width: 1200px) {
	.single-project h4 + p {
		margin-top: 24px;
	}
}

@media (min-width: 1200px) {
	.single-project-3 {
		margin-right: 70px;
	}
}

.single-project-4 h4 {
	line-height: 1.2;
}

@media (min-width: 1200px) {
	.single-project-4 {
		margin-left: 40px;
	}
}

.single-tour-price .product-big-price-wrap {
	right: 16px;
}

.single-tour-title {
	padding-top: 40px;
}

@media (min-width: 576px) {
	.single-tour-title {
		padding-top: 0;
		text-align: left;
	}
}

@media (min-width: 768px) {
	.single-tour-title {
		padding-right: 80px;
		text-align: left;
	}
}

@media (min-width: 992px) {
	.single-tour-title {
		padding-right: 100px;
	}
}

.single-project-aside {
	padding-top: 30px;
	border-top: 1px solid #e1e1e1;
}

@media (min-width: 576px) {
	.single-project-aside {
		text-align: left;
	}
}

@media (min-width: 992px) {
	.single-project-aside {
		padding-left: 40px;
		padding-top: 0;
		border-left: 1px solid #e1e1e1;
		border-top: none;
	}
}

@media (min-width: 1200px) {
	.single-project-aside {
		padding-left: 70px;
	}
	.single-project-aside .divider {
		width: 65%;
	}
	.single-project-aside * + .button {
		margin-top: 50px;
	}
}

.single-project-aside-title {
	font-weight: 400;
	letter-spacing: .075em;
}

.single-project-aside-title + .list-description {
	margin-top: 28px;
}

.project-navigation-arrow-prev,
.project-navigation-arrow-next {
	position: absolute;
	top: 50%;
	display: none;
	align-items: center;
	justify-content: space-between;
	height: 58px;
	width: 52px;
	font-size: 0;
	line-height: 58px;
	background: #ffffff;
	transform: translate3d(0, -50%, 0);
	transition: all .25s ease;
}

.project-navigation-arrow-prev::before, .project-navigation-arrow-prev::after,
.project-navigation-arrow-next::before,
.project-navigation-arrow-next::after {
	display: inline-block;
	vertical-align: middle;
	content: '';
	transition: all .3s ease-in-out;
}

.project-navigation-arrow-prev::before,
.project-navigation-arrow-next::before {
	height: 2px;
	width: 25px;
	background-color: #9b9b9b;
}

.project-navigation-arrow-prev::after,
.project-navigation-arrow-next::after {
	width: 0;
	height: 0;
	border-style: solid;
}

.project-navigation-arrow-prev:hover::before,
.project-navigation-arrow-next:hover::before {
	background-color: #151515;
}

.project-navigation-arrow-prev:hover::after,
.project-navigation-arrow-next:hover::after {
	border-left-color: #151515;
	border-right-color: #151515;
}

.project-navigation-arrow-prev {
	flex-direction: row-reverse;
	left: 0;
	padding-left: 5px;
	padding-right: 13px;
}

.project-navigation-arrow-prev::after {
	border-width: 4px 6px 4px 0;
	border-color: transparent #9b9b9b transparent transparent;
}

.project-navigation-arrow-prev:hover {
	padding-left: 0;
	padding-right: 8px;
}

.project-navigation-arrow-next {
	right: 0;
	padding-left: 13px;
	padding-right: 5px;
}

.project-navigation-arrow-next::after {
	border-width: 4px 0 4px 6px;
	border-color: transparent transparent transparent #9b9b9b;
}

.project-navigation-arrow-next:hover {
	padding-left: 8px;
	padding-right: 0;
}

@media (min-width: 768px) {
	.project-navigation {
		position: relative;
		padding: 20px 70px;
		background: #f7f7f7;
	}
	.project-navigation-arrow-prev,
	.project-navigation-arrow-next {
		display: flex;
	}
}

.project-minimal {
	text-align: center;
}

.project-minimal-figure {
	display: block;
	overflow: hidden;
}

.project-minimal-figure img {
	width: 100%;
	transition: all .3s ease;
}

.project-minimal-title {
	font-size: 18px;
	line-height: 1.25;
	font-weight: 500;
	letter-spacing: .025em;
}

.project-minimal-title a, .project-minimal-title a:focus, .project-minimal-title a:active {
	color: inherit;
}

.project-minimal-title a:hover {
	color: #01b3a7;
}

.project-minimal-text {
	color: #9b9b9b;
}

* + .project-minimal-title {
	margin-top: 10px;
}

.project-minimal-title a {
	display: block;
}

.project-minimal-title a span {
	display: block;
	font-size: 20px;
	font-weight: 500;
}

@media (min-width: 992px) {
	.desktop .project-minimal-figure img {
		transform: scale(1.1);
		will-change: transform;
	}
	.desktop .project-minimal:hover .project-minimal-figure img {
		transform: none;
	}
}

@media (min-width: 1200px) {
	.project-minimal-title {
		font-size: 24px;
	}
}

.thumbnail-info {
	width: 100%;
	position: relative;
}

.thumbnail-info .counter-classic-number {
	font-size: 36px;
}

@media (min-width: 576px) {
	.thumbnail-info .counter-classic-number {
		font-size: 80px;
	}
}

.thumbnail-info .thumbnail-info-figure {
	position: absolute;
	left: 50%;
	top: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	width: 91%;
	height: 91%;
	transform: translate(-50%, -50%);
	padding: 10px 10px;
	background-color: #ffffff;
	align-items: center;
	text-align: center;
}

@media (min-width: 576px) {
	.thumbnail-info .thumbnail-info-figure {
		padding: 30px 20px;
	}
}

.thumbnail-info .thumbnail-info-figure .counter, .thumbnail-info .thumbnail-info-figure .symbol {
	font-size: 36px;
	line-height: 1.1;
	font-weight: 700;
	margin-bottom: 5px;
}

@media (min-width: 576px) {
	.thumbnail-info .thumbnail-info-figure .counter, .thumbnail-info .thumbnail-info-figure .symbol {
		font-size: 80px;
	}
}

.thumbnail-info .thumbnail-info-figure .info-description {
	margin: 0 auto;
	font-size: 18px;
	color: #151515;
	line-height: 1.1;
	font-weight: 600;
}

@media (min-width: 576px) {
	.thumbnail-info .thumbnail-info-figure .info-description {
		max-width: 60%;
		font-size: 24px;
	}
}

/*
*
* Breadcrumbs
*/
.breadcrumbs-custom {
	position: relative;
	overflow: hidden;
	padding: 40px 0;
	text-align: center;
	background-color: #cccccc;
	z-index: 1;
}

.breadcrumbs-custom-path {
	margin-left: -15px;
	margin-right: -15px;
	font-size: 14px;
	line-height: 1.33;
	font-weight: 500;
	letter-spacing: .1em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.breadcrumbs-custom-path > * {
	padding-left: 15px;
	padding-right: 15px;
}

.breadcrumbs-custom-path a {
	display: inline;
	vertical-align: middle;
	color: #ffffff;
}

.breadcrumbs-custom-path a, .breadcrumbs-custom-path a:active, .breadcrumbs-custom-path a:focus {
	color: #ffffff;
}

.breadcrumbs-custom-path a:hover {
	text-decoration: underline;
}

.breadcrumbs-custom-path li {
	position: relative;
	display: inline-block;
	vertical-align: middle;
}

.breadcrumbs-custom-path li::after {
	content: "/";
	display: inline-block;
	position: absolute;
	top: 50%;
	right: -8px;
	color: white;
	transform: translate3d(0, -50%, 0);
}

.breadcrumbs-custom-path li:last-child:after {
	display: none;
}

.breadcrumbs-custom-path a:hover {
	color: #ffffff;
}

.breadcrumbs-custom-path li.active {
	color: rgba(255, 255, 255, 0.53);
}

* + .breadcrumbs-custom-path {
	margin-top: 14px;
}

@media (min-width: 576px) {
	* + .breadcrumbs-custom-path {
		margin-top: 18px;
	}
}

@media (min-width: 1200px) {
	.breadcrumbs-custom {
		padding: 150px 0;
	}
}

@media (min-width: 1600px) {
	.breadcrumbs-custom-inset {
		max-width: 1920px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 75px;
		padding-right: 75px;
	}
}

/*
*
* Pagination custom
*/
.pagination {
	align-items: center;
	justify-content: center;
	font-size: 0;
	line-height: 0;
	margin-bottom: -14px;
	margin-left: -14px;
}

.pagination:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.pagination > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 14px;
	margin-left: 14px;
}

@media (min-width: 576px) {
	.pagination {
		margin-bottom: -18px;
		margin-left: -18px;
	}
	.pagination:empty {
		margin-bottom: 0;
		margin-left: 0;
	}
	.pagination > * {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 18px;
		margin-left: 18px;
	}
}

.page-item {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
}

.page-item:first-child .page-link, .page-item:last-child .page-link {
	color: #151515;
	border-radius: 50%;
}

.page-item:first-child .page-link:hover, .page-item:first-child .page-link:focus, .page-item:first-child .page-link:active, .page-item:last-child .page-link:hover, .page-item:last-child .page-link:focus, .page-item:last-child .page-link:active {
	color: #01b3a7;
}

.page-item:last-child .page-link {
	padding: 4px 4px 2px 6px;
}

.page-item:first-child .page-link {
	padding: 4px 6px 2px 4px;
}

@media (min-width: 576px) {
	.page-item:last-child .page-link {
		padding: 8px 8px 6px 10px;
	}
	.page-item:first-child .page-link {
		padding: 8px 10px 6px 8px;
	}
}

.page-link {
	display: inline-block;
	min-width: 38px;
	padding: 6px 5px 4px;
	font-size: 20px;
	line-height: 1.2;
	font-weight: 400;
	letter-spacing: .075em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #151515;
	background-color: #ffffff;
	border-radius: 50%;
	border: 2px solid transparent;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.17);
	transition: all 0.3s ease-in-out;
}

.page-link .icon {
	font-size: 22px;
}

@media (min-width: 576px) {
	.page-link {
		min-width: 45px;
		padding: 9px 9px 7px;
		font-size: 24px;
		line-height: 1.04167;
	}
}

.page-link:hover, .page-link:focus, .page-link:active {
	color: #50ba87;
	background-color: transparent;
	border-color: transparent;
}

.page-item.active > .page-link, .page-item.active > .page-link:hover, .page-item.active > .page-link:focus, .page-item.active > .page-link:active {
	color: #50ba87;
	background-color: transparent;
	border-color: transparent;
}

.page-item.disabled > .page-link, .page-item.disabled > .page-link:hover, .page-item.disabled > .page-link:focus, .page-item.disabled > .page-link:active {
	color: #151515;
	background-color: transparent;
	border-color: transparent;
}

.page-item-control .icon::before {
	font-family: 'FontAwesome';
	line-height: 25px;
}

.page-item-control:first-child .icon::before {
	content: '\f0d9';
	margin-left: -1px;
}

.page-item-control:last-child .icon::before {
	content: '\f0da';
	margin-right: -1px;
}

* + .pagination-wrap {
	margin-top: 50px;
}

/*
*
* Snackbars
*/
.snackbars {
	padding: 9px 16px;
	margin-left: auto;
	margin-right: auto;
	color: #ffffff;
	text-align: left;
	background-color: #151515;
	border-radius: 0;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
	font-size: 14px;
}

.snackbars .icon-xxs {
	font-size: 18px;
}

.snackbars p span:last-child {
	padding-left: 14px;
}

.snackbars-left {
	display: inline-block;
	margin-bottom: 0;
}

.snackbars-right {
	display: inline-block;
	float: right;
	text-transform: uppercase;
}

.snackbars-right:hover {
	text-decoration: underline;
}

@media (min-width: 576px) {
	.snackbars {
		max-width: 540px;
		padding: 12px 15px;
		font-size: 15px;
	}
}

/*
*
* Footers
*/
.footer-classic {
	color: rgba(255, 255, 255, 0.3);
	background: #ffffff;
}

.footer-classic h1, .footer-classic h2, .footer-classic h3, .footer-classic h4, .footer-classic h5, .footer-classic h6, .footer-classic [class*='heading-'] {
	color: rgba(255, 255, 255, 0.15);
}

.footer-classic .contact-classic-title {
	color: #01b3a7;
}

.footer-classic .contact-classic-link {
	color: #ffffff;
}

.footer-bottom-panel {
	padding-top: 30px;
	padding-bottom: 30px;
}

.footer-classic-2 .box-footer {
	background: #11263a;
}

.footer-classic-2 .footer-list-category a:hover {
	color: #01b3a7;
}

.footer-classic-2 .footer-list-category a:hover span::before {
	border-right-color: #01b3a7;
}

.footer-classic-2 .footer-list-category a:hover span::after {
	background-color: #01b3a7;
}

.box-footer {
	text-align: left;
	min-height: 100%;
	padding: 40px 15px;
	letter-spacing: .025em;
	background: #2c2e31;
}

.box-footer a, .box-footer a:focus, .box-footer a:active {
	color: #ffffff;
}

.box-footer a:hover {
	color: #50ba87;
}

.box-footer .form-wrap + * {
	margin-top: 12px;
}

.box-footer * + .rd-form {
	margin-top: 20px;
}

.box-footer .form-validation {
	color: #ffffff;
}

.box-footer .form-label {
	top: 32px;
	font-size: 16px;
	color: #ffffff;
}

.box-footer .form-input {
	font-size: 16px;
	padding-top: 18px;
	padding-bottom: 18px;
	border: none;
	color: #ffffff;
	background: rgba(255, 255, 255, 0.06);
}

.box-footer textarea.form-input {
	height: 160px;
}

.box-footer .button.button-ujarak::before {
	background: #ffffff;
}

.box-footer .button.button-ujarak:hover, .box-footer .button.button-ujarak:active {
	color: #151515;
	border-color: #ffffff;
}

.box-footer-small {
	padding-top: 45px;
}

.contact-classic .unit-left {
	min-width: 45px;
}

.contact-classic a {
	display: inline-block;
}

.contact-classic-item {
	padding: 20px 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.contact-classic-title {
	margin-top: 2px;
	letter-spacing: .025em;
	color: #01b3a7;
}

.contact-classic-link {
	color: #ffffff;
}

* + .contact-classic {
	margin-top: 3px;
}

.footer-brand + p {
	margin-top: 22px;
}

@media (min-width: 480px) {
	.box-footer {
		padding: 40px 30px;
	}
}

@media (min-width: 992px) {
	.box-footer * + .rd-form {
		margin-top: 8px;
	}
}

@media (min-width: 1200px) and (max-width: 1399px) {
	.box-footer {
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media (min-width: 1800px) {
	.box-footer {
		padding: 60px 60px 50px;
	}
}

.box-footer-2 {
	text-align: left;
	padding: 30px 20px;
	min-height: 100%;
	background: #ffffff;
	border: 1px solid #e1e1e1;
}

.box-footer-2 .footer-contacts .icon {
	color: #01b3a7;
}

.box-footer-form {
	padding: 30px 20px;
	background: #01b3a7;
}

.box-footer-form .form-input {
	min-height: 50px;
	padding-top: 12px;
	padding-bottom: 12px;
}

.box-footer-form .form-label {
	top: 25px;
}

.box-footer-form textarea.form-input {
	height: 116px;
	max-height: 197px;
}

.box-footer-form .button {
	min-width: auto;
	padding-left: 20px;
	padding-right: 20px;
}

.box-footer-form .form-wrap + * {
	margin-top: 10px;
}

.ios .box-footer-form .form-label,
.iphone .box-footer-form .form-label,
.ipad .box-footer-form .form-label,
.mac-os .box-footer-form .form-label {
	top: 13px;
	transform: none;
}

.box-footer-2-title {
	letter-spacing: .025em;
}

.box-footer-2-text {
	letter-spacing: .025em;
	color: #9b9b9b;
}

* + .box-footer-2-text {
	margin-top: 8px;
}

@media (min-width: 768px) {
	.box-footer-2 {
		border: none;
	}
}

@media (min-width: 992px) {
	.box-footer-2 {
		padding: 35px 30px 25px;
	}
	.box-footer-2 .footer-contacts li + li {
		margin-top: 28px;
	}
	.box-footer-form {
		padding: 35px 25px;
	}
	.box-footer-2-text + .footer-contacts {
		margin-top: 40px;
	}
}

@media (min-width: 1200px) {
	.box-footer-2-text + .footer-contacts {
		margin-top: 50px;
	}
}

.footer-social-list .icon {
	position: relative;
	text-align: center;
	width: 46px;
	height: 46px;
	font-size: 20px;
	line-height: 46px;
	border-radius: 50%;
	box-shadow: inset 0 0 0 4px #3a3e43;
	transition: color .3s;
	z-index: 1;
}

.footer-social-list .icon, .footer-social-list .icon:focus, .footer-social-list .icon:active {
	color: #ffffff;
}

.footer-social-list .icon:hover {
	color: #01b3a7;
}

.footer-social-list .icon::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 2px;
	border-radius: 50%;
	background: #3a3e43;
	pointer-events: none;
	transition: transform 0.2s, opacity 0.3s;
	z-index: -1;
}

.footer-social-list .icon:hover:after {
	opacity: 0;
	transform: scale(1.3);
}

* + .footer-social-list {
	margin-top: 26px;
}

@media (min-width: 480px) {
	.footer-social-list .icon {
		width: 56px;
		height: 56px;
		font-size: 25px;
		line-height: 56px;
	}
}

.footer-list-category li {
	font-weight: 400;
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.footer-list-category li:first-child {
	border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.footer-list-category a {
	position: relative;
	display: block;
	padding: 22px 40px 20px 2px;
	transition: color .3s ease-in-out, all .3s ease;
}

.footer-list-category a, .footer-list-category a:focus, .footer-list-category a:active {
	color: #ffffff;
}

.footer-list-category a:hover {
	color: #01b3a7;
}

.footer-list-category a span {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 38%;
	right: 0;
	font-size: 0;
	line-height: 0;
	padding-left: 6px;
	padding-right: 6px;
}

.footer-list-category a span::before, .footer-list-category a span::after {
	display: inline-block;
	vertical-align: middle;
	content: '';
	transition: all .2s ease-in-out;
}

.footer-list-category a span::before {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 10px 6px 0;
	border-color: transparent rgba(255, 255, 255, 0.1) transparent transparent;
}

.footer-list-category a span::after {
	width: 0;
	height: 2px;
	background-color: rgba(255, 255, 255, 0.1);
}

.footer-list-category a:hover {
	letter-spacing: .1em;
}

.footer-list-category a:hover span::before {
	border-right-color: #01b3a7;
}

.footer-list-category a:hover span::after {
	width: 30px;
	margin-left: 7px;
	background-color: #01b3a7;
}

* + .footer-list-category {
	margin-top: 20px;
}

@media (min-width: 480px) {
	.footer-list-category a {
		padding-right: 160px;
	}
	.footer-list-category a:hover {
		letter-spacing: .2em;
	}
	.footer-list-category a:hover span::after {
		width: 123px;
	}
}

@media (min-width: 992px) {
	* + .footer-list-category {
		margin-top: 8px;
	}
}

@media (min-width: 1200px) and (max-width: 1399px) {
	.footer-list-category a {
		padding-right: 130px;
	}
	.footer-list-category a:hover {
		letter-spacing: .15em;
	}
	.footer-list-category a:hover span::after {
		width: 90px;
	}
	.footer-list-category .heading-5 {
		font-size: 20px;
	}
}

.footer-variant-2 {
	text-align: center;
	letter-spacing: .025em;
}

.footer-variant-2 .form-label,
.footer-variant-2 .form-input {
	text-align: center;
}

.footer-variant-2 .rights {
	font-size: 12px;
}

.footer-variant-2 .rd-form * + .button {
	margin-top: 10px;
}

.footer-variant-2 * + .rd-form {
	margin-top: 25px;
}

.footer-variant-2 * + .group-lg {
	margin-top: 30px;
}

.footer-variant-2 * + .row {
	margin-top: 24px;
}

.footer-variant-2 * + .footer-contacts {
	margin-top: 22px;
	padding-top: 32px;
	border-top: 1px solid #d7d7d7;
}

@media (min-width: 768px) {
	.footer-variant-2 {
		text-align: left;
	}
}

.footer-variant-2-content {
	padding: 50px 0;
}

@media (min-width: 768px) {
	.footer-variant-2-content {
		padding-top: 65px;
		padding-bottom: 65px;
	}
}

.footer-variant-2-bottom-panel {
	padding: 30px 0;
}

.footer-contacts {
	text-align: left;
	font-size: 0;
	line-height: 0;
}

.footer-contacts .icon {
	vertical-align: middle;
	text-align: left;
	min-width: 21px;
	font-size: 24px;
	line-height: 24px;
	color: #01b3a7;
}

.footer-contacts .fa-envelope {
	font-size: 20px;
}

.footer-contacts .fa-clock-o {
	font-size: 22px;
}

.footer-contacts .link-phone {
	font-size: 18px;
	line-height: 1.34;
	letter-spacing: .025em;
}

.footer-contacts .link-location {
	max-width: 192px;
}

.footer-contacts p {
	font-size: 14px;
	line-height: 1.72;
	letter-spacing: .025em;
}

.footer-contacts a {
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
	line-height: 1.72;
	letter-spacing: .025em;
}

.footer-contacts a, .footer-contacts a:focus, .footer-contacts a:active {
	color: #151515;
}

.footer-contacts a:hover {
	color: #01b3a7;
}

.footer-contacts li + li {
	margin-top: 18px;
}

* + .footer-contacts {
	margin-top: 28px;
}

.footer-social-list-2 .icon {
	font-size: 16px;
}

.footer-social-list-2 a, .footer-social-list-2 a:focus, .footer-social-list-2 a:active {
	color: #151515;
}

.footer-social-list-2 a:hover {
	color: #50ba87;
}

.footer-modern {
	background: #404040;
}

.footer-modern .footer-variant-2-bottom-panel {
	background: #363636;
}

.footer-creative {
	background: #383b40;
}

.footer-creative .footer-variant-2-bottom-panel {
	background: #2f3439;
}

.footer-link-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-size: 12px;
	line-height: 2;
	font-weight: 500;
	margin-bottom: -5px;
	margin-left: -20px;
}

.footer-link-list:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.footer-link-list > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 5px;
	margin-left: 20px;
}

.footer-link-list a, .footer-link-list a:focus, .footer-link-list a:active {
	color: #151515;
}

.footer-link-list a:hover {
	color: #50ba87;
}

@media (min-width: 768px) {
	.footer-link-list {
		justify-content: flex-start;
	}
}

@media (min-width: 992px) {
	.footer-link-list {
		justify-content: space-between;
	}
}

.footer-corporate {
	text-align: center;
	background: #162e44;
}

.footer-corporate h6 {
	font-size: 16px;
}

.footer-corporate .footer-contacts .link-phone {
	font-size: 14px;
	line-height: 1.72;
}

.footer-corporate .footer-contacts a {
	letter-spacing: .05em;
}

.footer-corporate .footer-contacts .link-location {
	letter-spacing: .025em;
}

.footer-corporate .footer-contacts .unit {
	margin-bottom: -10px;
	margin-left: -22px;
}

.footer-corporate .footer-contacts .unit > * {
	margin-bottom: 10px;
	margin-left: 22px;
}

.footer-corporate .rights {
	font-size: 12px;
}

.footer-corporate .list-marked a, .footer-corporate .list-marked a:focus, .footer-corporate .list-marked a:active {
	color: inherit;
}

.footer-corporate .list-marked a:hover {
	color: #01b3a7;
}

.footer-corporate .button {
	min-width: auto;
	padding-left: 30px;
	padding-right: 30px;
}

.footer-corporate * + .list-marked {
	margin-top: 22px;
}

.footer-corporate * + .group-md {
	margin-top: 30px;
}

@media (max-width: 575.98px) {
	.footer-corporate .post-minimal-2 {
		margin-left: auto;
		margin-right: auto;
	}
	.footer-corporate .list-marked {
		text-align: center;
	}
}

@media (min-width: 576px) {
	.footer-corporate {
		text-align: left;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.footer-corporate .footer-contacts {
		margin-bottom: -10px;
		margin-left: -40px;
	}
	.footer-corporate .footer-contacts:empty {
		margin-bottom: 0;
		margin-left: 0;
	}
	.footer-corporate .footer-contacts > * {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 10px;
		margin-left: 40px;
	}
	.footer-corporate .footer-contacts .unit {
		align-items: center;
	}
}

@media (min-width: 992px) {
	.footer-corporate .list-marked {
		max-width: 90%;
	}
}

.footer-corporate-2 {
	background: #474b4e;
}

.footer-corporate-2 .footer-corporate-bottom-panel {
	background: #3c4043;
}

.footer-corporate-2 .post-minimal-2-title a, .footer-corporate-2 .post-minimal-2-title a:focus, .footer-corporate-2 .post-minimal-2-title a:active {
	color: #01b3a7;
}

.footer-corporate-2 .post-minimal-2-title a:hover {
	color: #ffffff;
}

.footer-corporate-2 .list-custom-2 {
	-moz-column-gap: 20px;
	-webkit-column-gap: 20px;
}

@media (min-width: 992px) {
	.footer-corporate-2 .list-marked {
		max-width: none;
	}
}

.footer-corporate-inset {
	padding: 50px 0;
}

@media (min-width: 992px) {
	.footer-corporate-inset {
		padding: 65px 0;
	}
}

@media (min-width: 1200px) {
	.footer-corporate-inset {
		padding-top: 75px;
	}
}

.footer-corporate-bottom-panel {
	padding: 30px 0;
	background: #11263a;
	color: rgba(255, 255, 255, 0.3);
}

.footer-minimal {
	background: #303233;
}

.footer-minimal .rights {
	font-size: 12px;
}

.footer-minimal-inset {
	padding: 40px 0;
}

.footer-minimal-bottom-panel {
	padding: 35px 0;
	border-top: 1px solid rgba(255, 255, 255, 0.13);
}

@media (min-width: 768px) {
	.footer-minimal-inset {
		padding: 60px 0;
	}
}

.footer-list-category-2 {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	text-align: left;
	font-size: 18px;
	line-height: 1.34;
	font-weight: 500;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.footer-list-category-2 a, .footer-list-category-2 a:focus, .footer-list-category-2 a:active {
	color: #ffffff;
}

.footer-list-category-2 a:hover {
	color: #01b3a7;
}

@media (max-width: 767.98px) {
	.footer-list-category-2 {
		margin-bottom: -10px;
		margin-left: -30px;
	}
	.footer-list-category-2:empty {
		margin-bottom: 0;
		margin-left: 0;
	}
	.footer-list-category-2 > * {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 10px;
		margin-left: 30px;
	}
}

@media (min-width: 768px) {
	.footer-list-category-2 {
		justify-content: flex-start;
		transform: translate3d(0, -10px, 0);
		margin-bottom: -10px;
		margin-left: -26px;
		margin-right: -26px;
	}
	.footer-list-category-2 > * {
		margin-top: 10px;
		padding-left: 26px;
		padding-right: 26px;
	}
	.footer-list-category-2 > li:not(:first-child) {
		border-left: 1px solid rgba(255, 255, 255, 0.2);
	}
}

@media (min-width: 1200px) {
	.footer-list-category-2 {
		transform: translate3d(0, -10px, 0);
		margin-bottom: -10px;
		margin-left: -48px;
		margin-right: -48px;
	}
	.footer-list-category-2 > * {
		margin-top: 10px;
		padding-left: 48px;
		padding-right: 48px;
	}
}

.section-prefooter {
	position: relative;
}

.section-prefooter .rd-google-map {
	height: 250px;
}

@media (min-width: 768px) {
	.section-prefooter .rd-google-map-wrap {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.section-prefooter .rd-google-map {
		width: inherit;
		height: inherit;
	}
}

.footer-corporate-inset .post-minimal-2 a {
	color: #01b3a7;
}

.footer-corporate-inset .post-minimal-2 a:hover {
	color: #50ba87;
}

.footer-corporate-inset .list-marked li:before {
	background: #01b3a7;
}

/**
*
* Card
*/
.card-form {
	background: #ffffff;
	transition: all 200ms ease;
}

.card-form * + .form-wrap {
	margin-top: 14px;
}

* + .card-form {
	margin-top: 26px;
}

.card-login-register {
	margin-left: auto;
	margin-right: auto;
	max-width: 400px;
}

.card-top-panel {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.card-form-login {
	position: relative;
	top: 0;
	width: 100%;
	z-index: 1;
}

.card-form-register {
	position: relative;
	display: none;
	z-index: 1;
}

.card-toggle {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 43px;
	height: 17px;
	background: #f7f7f7;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.card-toggle-circle {
	position: absolute;
	top: 50%;
	left: -4px;
	width: 26px;
	height: 26px;
	background: #ffffff;
	border-radius: 50%;
	border: 1px solid #e6e6e6;
	transform: translateY(-50%);
	will-change: transform;
	transition: all .1s linear;
}

.card-toggle-circle::before {
	position: absolute;
	content: '';
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #01b3a7;
	transform: translate(-50%, -50%);
	transition: all .2s ease-in-out;
}

.card-subtitle,
.card-top-panel-left {
	position: relative;
}

.card-title {
	margin-bottom: 0;
	letter-spacing: .05em;
	transition: all 300ms ease;
}

.card-subtitle {
	top: 2px;
	text-align: right;
	margin-right: 8px;
	font-size: 18px;
	letter-spacing: .1em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #9b9b9b;
	text-transform: uppercase;
	transition: all 300ms ease;
}

.card-subtitle > * {
	display: inline-block;
}

.card-subtitle-login,
.card-title-login {
	opacity: 1;
	visibility: visible;
}

.card-subtitle-register,
.card-title-register {
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: 0;
	visibility: hidden;
	transform: translate(-50%, -50%);
	will-change: transform;
	margin-bottom: 5px;
}

@media (min-width: 576px) {
	.card-subtitle-register,
	.card-title-register {
		margin-bottom: 0;
	}
}

.card-title-register {
	left: 0;
}

.card-subtitle-register {
	right: 0;
}

.card-toggle-circle:hover::before {
	width: 16px;
	height: 16px;
}

.card-toggle.active .card-toggle-circle {
	transform: translate(112%, -50%);
}

.card-login-register.active .card-form-login {
	display: none;
}

.card-login-register.active .card-form-register {
	display: block;
}

.card-login-register.active .card-subtitle-login,
.card-login-register.active .card-title-login {
	opacity: 0;
	visibility: hidden;
}

.card-login-register.active .card-subtitle-register,
.card-login-register.active .card-title-register {
	opacity: 1;
	visibility: visible;
}

@media (max-width: 575.98px) {
	.card-login-register .button-lg {
		padding-left: 20px;
		padding-right: 20px;
	}
	.card-top-panel-right {
		width: 200px;
		margin: 4px auto 0;
	}
	.card-title-register {
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@media (min-width: 576px) {
	.card-top-panel {
		flex-direction: row;
	}
	.card-login-register {
		max-width: 100%;
	}
}

/*
*
* Product
*/
.product {
	position: relative;
	text-align: center;
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
}

.product.box-ordered-item::before {
	position: absolute;
	display: inline-block;
	text-align: center;
	top: 0;
	left: 0;
	width: 30px;
	height: 30px;
	padding-top: 2px;
	font-size: 18px;
	line-height: 28px;
	font-weight: 500;
	letter-spacing: 0;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	border-radius: 50%;
	color: #ffffff;
	background: #01b3a7;
	transform: translate3d(-50%, -50%, 0);
	z-index: 1;
}

.product-2 {
	text-align: left;
	max-width: 260px;
}

.product-2 .unit {
	align-items: center;
	flex-direction: column;
	margin-bottom: -14px;
	margin-left: -20px;
}

.product-2 .unit > * {
	margin-bottom: 14px;
	margin-left: 20px;
}

.product-2 .button.button-sm {
	min-width: auto;
}

.product-2 .product-figure {
	max-width: 100px;
}

.product-2 .product-button {
	display: none;
}

.product-2 * + .button {
	margin-top: 6px;
}

@media (min-width: 576px) {
	.product-2 {
		max-width: none;
	}
}

@media (max-width: 991.98px) {
	.product-title {
		font-size: 20px;
	}
}

@media (min-width: 992px) {
	.product-2 {
		text-align: center;
	}
	.product-2 .product-figure {
		max-width: none;
	}
	.product-2 .product-button {
		display: block;
	}
	.product-2 .button.button-sm {
		display: none;
	}
}

.product-figure {
	position: relative;
	overflow: hidden;
	background: #f4f4f4;
	transition: all .3s ease;
}

.product-figure img {
	width: 100%;
	transform: none;
	transition: inherit;
}

.product-button {
	margin: 0 10px 10px;
}

.product-button .button {
	width: 100%;
}

.product-title {
	font-weight: 400;
}

.product-title a, .product-title a:focus, .product-title a:active {
	color: inherit;
}

.product-title a:hover {
	color: #50ba87;
}

.product-price {
	display: inline-block;
	vertical-align: middle;
	font-size: 18px;
	line-height: 1;
	letter-spacing: .075em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #01b3a7;
}

.product-price-old {
	color: #9b9b9b;
	text-decoration: line-through;
}

.product-price-old + .product-price {
	margin-left: 4px;
}

@media (min-width: 992px) {
	.desktop .product.box-ordered-item::before {
		display: none;
	}
	.desktop .product-figure::before {
		position: absolute;
		text-align: center;
		content: '';
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		background: rgba(17, 17, 17, 0.39);
		transition: all .3s ease;
		pointer-events: none;
		z-index: 1;
	}
	.desktop .product-button {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		margin: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translate3d(0, 15px, 0);
		opacity: 0;
		visibility: hidden;
		transition: all .3s ease;
		pointer-events: none;
		z-index: 1;
	}
	.desktop .product-button .button {
		width: auto;
		pointer-events: auto;
	}
	.desktop .product.active .product-figure::before,
	.desktop .product:hover .product-figure::before {
		transform: scale(0.93);
		opacity: 1;
		visibility: visible;
	}
	.desktop .product.active .product-figure img,
	.desktop .product:hover .product-figure img {
		transform: scale(1.04);
	}
	.desktop .product.active .product-button,
	.desktop .product:hover .product-button {
		transform: none;
		opacity: 1;
		visibility: visible;
		transition-delay: .18s;
	}
}

* + .product-title {
	margin-top: 14px;
}

* + .product-price-wrap {
	margin-top: 2px;
}

.product-minimal {
	align-items: center;
}

.product-minimal-figure {
	position: relative;
	display: block;
	overflow: hidden;
	background: #f4f4f4;
}

.product-minimal-figure::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(17, 17, 17, 0.39);
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease;
	z-index: 1;
}

.product-minimal-figure img {
	width: 100%;
	transform: none;
	transition: all .3s ease;
}

.product-minimal-title {
	font-weight: 400;
}

.product-minimal-title a, .product-minimal-title a:focus, .product-minimal-title a:active {
	color: inherit;
}

.product-minimal-title a:hover {
	color: #50ba87;
}

.product-minimal-price {
	display: inline-block;
	vertical-align: middle;
	font-size: 18px;
	line-height: 1;
	letter-spacing: .075em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #01b3a7;
}

.product-minimal:hover .product-minimal-figure::before {
	transform: scale(0.9);
	opacity: 1;
	visibility: visible;
}

.product-minimal:hover .product-minimal-figure img {
	transform: scale(1.1);
}

* + .product-minimal-price {
	margin-top: 6px;
}

.product-top-panel {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.product-top-panel-title {
	font-size: 16px;
	letter-spacing: .075em;
}

@media (min-width: 768px) {
	.product-top-panel {
		justify-content: space-between;
	}
}

.product-sidebar {
	text-align: left;
}

.product-sidebar .button {
	min-width: 115px;
}

.product-sidebar h5 {
	font-weight: 400;
	letter-spacing: .1em;
}

.product-sidebar h5 + * {
	margin-top: 26px;
}

.list-shop-filter .checkbox, .list-shop-filter .checkbox-inline {
	color: #151515;
}

.list-shop-filter li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 18px 0;
}

.list-shop-filter li > span {
	color: #01b3a7;
	letter-spacing: .075em;
}

.list-shop-filter li:first-child {
	padding-top: 0;
}

.list-shop-filter li:last-child {
	padding-bottom: 0;
}

.list-shop-filter li + li {
	margin: 0;
	border-top: 1px solid #e1e1e1;
}

.list-popular-product-item {
	padding: 26px 0;
}

.list-popular-product-item:first-child {
	padding-top: 0;
}

.list-popular-product-item:last-child {
	padding-bottom: 0;
}

.list-popular-product-item + .list-popular-product-item {
	margin: 0;
	border-top: 1px solid #e1e1e1;
}

h5 + .list-popular-product {
	margin-top: 18px;
}

.single-product {
	text-align: center;
	letter-spacing: .025em;
}

.single-product .button {
	min-width: 176px;
}

.single-product h3 + .group-md {
	margin-top: 10px;
}

.single-product .group-md + p {
	margin-top: 20px;
}

@media (min-width: 768px) {
	.single-product {
		text-align: left;
	}
}

.single-product-price {
	display: inline-block;
	vertical-align: middle;
	font-size: 24px;
	line-height: 1;
	letter-spacing: .05em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #01b3a7;
}

.list-description {
	text-align: left;
}

.list-description li > * {
	display: inline-block;
	vertical-align: middle;
}

.list-description li span:first-child {
	margin-right: 6px;
}

.list-description li span:last-child {
	color: #9b9b9b;
}

.list-description li + li {
	margin-top: 7px;
}

.social-title {
	font-size: 16px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: .1em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.social-list .icon {
	font-size: 20px;
}

.social-list .icon, .social-list .icon:focus, .social-list .icon:active {
	color: inherit;
}

.social-list .icon:hover {
	color: #01b3a7;
}

.box-rating .icon {
	font-size: 19px;
	color: #ffcc00;
}

.box-rating .icon + .icon {
	margin-left: 4px;
}

.cart-product-price {
	letter-spacing: .025em;
}

.cart-product-price sup {
	display: inline-block;
	margin-right: 5px;
	font-size: 50%;
}

.box-radio {
	text-align: left;
	padding: 28px 15px 18px;
	border: 1px solid #e1e1e1;
}

.radio-panel-content {
	height: 0;
	max-height: 0;
	opacity: 0;
	letter-spacing: .025em;
	color: #9b9b9b;
}

.radio-panel .radio-inline.active + .radio-panel-content {
	opacity: 1;
	height: 100%;
	max-height: 100%;
	transition: opacity .3s ease-out;
}

* + .radio-panel-content {
	margin-top: 8px;
}

* + .box-radio {
	margin-top: 25px;
}

.radio-panel + .radio-panel {
	margin-top: 15px;
}

@media (min-width: 576px) {
	.box-radio {
		padding-left: 24px;
		padding-right: 24px;
	}
}

.product-image-map {
	position: relative;
}

.product-image-map img {
	display: block;
	width: 100%;
}

[class*='product-image-marker'] {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 43px;
	height: 43px;
	padding: 0 4px;
	background: rgba(1, 179, 167, 0.79);
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
}

[class*='product-image-marker'] span {
	width: 25px;
	height: 25px;
	padding-top: 2px;
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;
	letter-spacing: 0;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	border-radius: inherit;
	background: #ffffff;
}

[class*='product-image-marker']::before, [class*='product-image-marker']::after {
	display: inline-block;
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: inherit;
	height: inherit;
	border-radius: inherit;
	opacity: 0;
	visibility: visible;
	will-change: transform;
	pointer-events: none;
	transform: none;
	z-index: -1;
}

[class*='product-image-marker']::before {
	opacity: 1;
	transform: scale(1.3);
	background: rgba(1, 179, 167, 0.46);
}

[class*='product-image-marker']::after {
	background: rgba(1, 179, 167, 0.3);
}

.product-image-marker-1-1 {
	bottom: 39%;
	left: 43%;
}

.product-image-marker-1-2 {
	bottom: 43%;
	left: 25%;
}

.product-image-marker-1-3 {
	bottom: 23%;
	left: 26%;
}

.product-image-marker-1-4 {
	top: 17%;
	right: 32%;
}

.product-image-marker-2-1 {
	top: 30%;
	left: 45%;
}

.product-image-marker-2-2 {
	bottom: 39%;
	right: 32%;
}

.product-image-marker-2-3 {
	bottom: 18%;
	left: 15%;
}

.product-image-marker-2-4 {
	bottom: 46%;
	left: 5%;
}

@media (min-width: 992px) {
	.desktop [class*='product-image-marker']:hover::before, .desktop [class*='product-image-marker'].active::before {
		animation: item1B 1s ease infinite;
	}
	.desktop [class*='product-image-marker']:hover::after, .desktop [class*='product-image-marker'].active::after {
		animation: item1A 1s ease infinite;
	}
}

@media (max-width: 1199.98px) {
	.tablet [class*='product-image-marker']::before,
	.mobile [class*='product-image-marker']::before {
		animation: item1B 1s ease infinite;
	}
	.tablet [class*='product-image-marker']::after,
	.mobile [class*='product-image-marker']::after {
		animation: item1A 1s ease infinite;
	}
}

@keyframes item1B {
	0% {
		opacity: 0;
		transform: none;
	}
	25% {
		opacity: 1;
		transform: none;
		visibility: visible;
	}
	100% {
		opacity: 0;
		transform: scale(1.8);
		visibility: hidden;
	}
}

@keyframes item1A {
	5% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: scale(2.5);
		visibility: hidden;
	}
}

.product-categories {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 120px;
	padding: 10px 15px 20px;
}

.product-categories-body {
	transition: all .3s ease;
}

* + .product-categories-title {
	margin-top: 10px;
}

@media (min-width: 768px) {
	.product-categories {
		min-height: 150px;
		padding: 20px 20px 30px;
	}
}

@media (min-width: 992px) {
	.desktop .product-categories:hover {
		background: #f7f7f7;
	}
	.desktop .product-categories:hover .product-categories-body {
		transform: translate3d(0, -6px, 0);
	}
}

@media (min-width: 1200px) {
	.product-categories {
		min-height: 183px;
	}
}

.box-categories {
	position: relative;
	text-align: left;
	max-width: 370px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	background: #ffffff;
	transition: all .3s ease;
	z-index: 1;
}

.box-categories::before {
	position: absolute;
	content: '';
	top: 12px;
	right: 12px;
	bottom: 12px;
	left: 12px;
	border: 1px solid #e1e1e1;
	background: transparent;
	will-change: transform;
	pointer-events: none;
	transition: inherit;
	z-index: -1;
}

.box-categories-content {
	margin: 0 auto;
}

.box-categories-title {
	font-weight: 400;
	letter-spacing: .1em;
}

.box-categories-list {
	letter-spacing: .025em;
}

.box-categories-list li {
	transition: all .2s ease;
}

.box-categories-list li::before {
	transition: all .3s ease-in-out;
}

.box-categories-list a, .box-categories-list a:focus, .box-categories-list a:active {
	color: inherit;
}

.box-categories-list a:hover {
	color: #01b3a7;
}

* + .box-categories-list {
	margin-top: 20px;
}

.product-big {
	position: relative;
	text-align: left;
}

.product-big .unit {
	margin-bottom: -30px;
	margin-left: 0;
}

.product-big .unit > * {
	margin-bottom: 30px;
	margin-left: 0;
}

.product-big .unit-body {
	display: flex;
	align-items: center;
}

.product-big * + .group-sm {
	margin-top: 12px;
}

.product-big * + .button {
	margin-top: 25px;
	min-width: 170px;
	padding: 17px 28px 15px;
}

.product-big-figure {
	position: relative;
	overflow: hidden;
	display: block;
}

.product-big-title {
	font-weight: 500;
	letter-spacing: normal;
	font-size: 21px;
}

.product-big-rating .icon {
	font-size: 19px;
	letter-spacing: 0;
	line-height: 24px;
	color: #ffcc00;
}

.product-big-rating .icon + .icon {
	margin-left: 0;
}

.product-big-reviews {
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: .1em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.product-big-reviews, .product-big-reviews:focus, .product-big-reviews:active {
	color: #9b9b9b;
}

.product-big-reviews:hover {
	color: #50ba87;
}

.product-big-text {
	letter-spacing: .025em;
	color: #9b9b9b;
}

.product-big-price-wrap {
	position: absolute;
	overflow: hidden;
	text-align: center;
	padding: 8px 10px 6px;
	top: 0;
	right: 0;
	font-size: 18px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: .025em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: rgba(255, 255, 255, 0.4);
	background: transparent;
	z-index: 1;
}

.product-big-price-wrap > * {
	display: block;
}

.product-big-price-wrap::before {
	position: absolute;
	content: '';
	bottom: 0;
	left: 14px;
	width: 400%;
	height: 100%;
	background: #01b3a7;
	transform: skew(45deg);
	transform-origin: 50% 100%;
	will-change: transform;
	pointer-events: none;
	z-index: -1;
}

.product-big-price {
	font-size: 21px;
	letter-spacing: .025em;
	color: #ffffff;
}

* + .product-big-text {
	margin-top: 6px;
}

@media (min-width: 768px) {
	.product-big {
		border: 1px solid #e1e1e1;
	}
	.product-big .unit {
		min-height: 366px;
	}
	.product-big .unit-left {
		flex-basis: 45%;
	}
	.product-big-body {
		padding: 30px 70px 30px 10%;
	}
	.product-big-figure {
		min-height: 100%;
	}
	.product-big-figure img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-height: 100%;
		min-width: 100%;
		width: auto;
		height: auto;
		max-width: none;
	}
	@supports (object-fit: cover) {
		.product-big-figure img {
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			transform: none;
			object-fit: cover;
			object-position: center center;
		}
	}
	.product-big-price-wrap {
		top: -1px;
		right: -1px;
		padding: 20px 10px 16px;
		font-size: 20px;
	}
	.product-big-price-wrap::before {
		left: 24px;
	}
}

@media (min-width: 992px) {
	.product-big .unit-left {
		flex-basis: 50%;
	}
	.product-big-body {
		padding-left: 12%;
		padding-right: 85px;
	}
	.product-big-price-wrap {
		font-size: 24px;
		padding: 30px 17px 26px;
	}
	.desktop .product-big-figure img {
		transform: translate(-50%, -50%) scale(1.2);
		transition: all .3s ease;
	}
	@supports (object-fit: cover) {
		.desktop .product-big-figure img {
			transform: scale(1.1);
		}
	}
	.desktop .product-big:hover .product-big-figure img {
		transform: translate(-50%, -50%) scale(1);
	}
	@supports (object-fit: cover) {
		.desktop .product-big:hover .product-big-figure img {
			transform: none;
		}
	}
}

.ie-edge .product-big .unit,
.ie-10 .product-big .unit,
.ie-11 .product-big .unit {
	height: 366px;
}

.cta-box-wrap {
	max-width: 100%;
	background: none;
}

.cta-box-wrap:before {
	display: none;
}

.cta-box-wrap .box-categories-content {
	width: 100%;
}

.cta-box-wrap .list-marked-2 {
	overflow: hidden;
	margin-bottom: 30px;
	margin-top: 0;
}

.cta-box-wrap .list-marked-2:hover img {
	transform: scale(1.1);
	transition: all ease .35s;
}

.cta-box-wrap .list-marked-2 img {
	transition: all ease .35s;
}

@media (min-width: 768px) {
	.cta-box-wrap .list-marked-2 {
		margin-bottom: 0;
	}
}

.cta-box-wrap .list-marked-2 li {
	position: relative;
	text-align: center;
	text-indent: 0;
	padding: 0;
}

.cta-box-wrap .list-marked-2 li .box-categories-title {
	position: absolute;
	font-weight: 700;
	max-width: 94%;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	bottom: 8%;
	color: #ffffff;
	letter-spacing: normal;
}

@media (min-width: 1200px) {
	.cta-box-wrap .list-marked-2 li .box-categories-title {
		font-size: 24px;
	}
}

.cta-box-wrap .list-marked-2 li a {
	display: block;
}

.cta-box-wrap .list-marked-2 li:before {
	display: none;
}

.cta-box-wrap + .link-classic {
	font-weight: 500;
	font-size: 16px;
	text-transform: none;
	color: #151515;
}

.cta-box-wrap + .link-classic:focus, .cta-box-wrap + .link-classic:active {
	color: #151515;
}

.cta-box-wrap + .link-classic span {
	opacity: 1;
}

.cta-box-wrap + .link-classic span:before {
	background-color: #151515;
}

.cta-box-wrap + .link-classic span:after {
	margin-left: 0;
}

/*
*
* Headings
*/
.title-classic {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-bottom: -15px;
	margin-left: -32px;
}

.title-classic:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.title-classic > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 15px;
	margin-left: 32px;
}

.title-classic-title {
	flex-shrink: 0;
}

.title-classic-title > h3 {
	margin-top: 6px;
}

.title-classic-text {
	font-size: 16px;
	padding-top: 20px;
	border-top: 1px solid #e1e1e1;
}

* + .title-classic-text {
	margin-top: 0;
}

@media (min-width: 576px) {
	.title-classic {
		text-align: left;
		flex-direction: row;
	}
	.title-classic-text {
		padding-top: 0;
		padding-left: 28px;
		border-top: none;
		border-left: 1px solid #e1e1e1;
	}
}

@media (min-width: 768px) {
	.title-classic-text {
		font-size: 18px;
	}
}

.title-modern {
	user-select: none;
	letter-spacing: .05em;
}

.title-modern > span {
	position: absolute;
	left: 50%;
	font-size: 7.8em;
	letter-spacing: 0.01em;
	line-height: .92;
	color: rgba(255, 255, 255, 0.07);
	transform: translate3d(-50%, 0, 0);
	will-change: transform;
	animation: titleColor 3s linear infinite;
	font-size: 6.8em;
}

@media (min-width: 576px) {
	.title-modern > span {
		font-size: 7.8em;
	}
}

.subtitle-404 {
	line-height: .90;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	font-size: 2.5em;
	display: block;
}

@media (min-width: 576px) {
	.subtitle-404 {
		font-size: 3.2em;
	}
}

@media (min-width: 768px) {
	.subtitle-404 {
		font-size: 4em;
	}
}

@media (min-width: 992px) {
	.subtitle-404 {
		font-size: 7.8em;
	}
}

.subtitle-503 {
	line-height: 1.2;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	font-size: 2.5em;
	display: block;
}

@media (min-width: 576px) {
	.subtitle-503 {
		font-size: 3.2em;
	}
}

@media (min-width: 768px) {
	.subtitle-503 {
		font-size: 4em;
	}
}

@media (min-width: 992px) {
	.subtitle-503 {
		font-size: 5em;
	}
}

@media (min-width: 1200px) {
	.section-single .section-503 .button {
		margin-top: 120px;
	}
}

h1.title-modern > span {
	top: -95px;
}

@media (min-width: 768px) {
	h1.title-modern > span {
		top: -130px;
	}
}

@media (min-width: 992px) {
	h1.title-modern > span {
		top: -123px;
	}
}

h2.title-modern {
	font-weight: 300;
}

h2.title-modern > span {
	top: -70px;
	font-size: 5.86em;
}

@media (min-width: 768px) {
	h2.title-modern > span {
		top: -90px;
	}
}

@media (min-width: 992px) {
	h2.title-modern > span {
		top: -120px;
	}
}

@media (min-width: 1200px) {
	h2.title-modern > span {
		top: -135px;
	}
}

@keyframes titleColor {
	50% {
		color: rgba(255, 255, 255, 0.3);
	}
	100% {
		color: rgba(255, 255, 255, 0.07);
	}
}

.title-opacity-9 {
	color: rgba(21, 21, 21, 0.09);
}

* + .heading-panel-subtitle {
	margin-top: 10px;
}

.heading-panel + .owl-services-2 {
	margin-top: 50px;
}

.heading-panel + .owl-quote-2 {
	margin-top: 35px;
}

@media (max-width: 575.98px) {
	.heading-panel * + .owl-custom-nav {
		margin-top: 20px;
	}
}

@media (min-width: 576px) {
	.heading-panel {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}
	.heading-panel-left {
		position: relative;
		text-align: left;
		margin-top: 4px;
		padding-left: 110px;
	}
	.heading-panel-decor {
		font-size: 0;
		line-height: 0;
		flex-grow: 1;
		height: 0;
		margin: 0 10px;
		border-top: 1px solid #dfdfdf;
	}
	.heading-panel-title {
		position: absolute;
		top: 50%;
		left: 0;
		color: rgba(21, 21, 21, 0.09);
		transform: translate3d(0, -50%, 0);
	}
	* + .heading-panel-subtitle {
		margin-top: 0;
	}
}

@media (min-width: 768px) {
	.heading-panel-left {
		padding-left: 160px;
		margin-top: 8px;
	}
}

@media (min-width: 992px) {
	.heading-panel {
		padding: 10px 0;
	}
	.heading-panel-left {
		padding-left: 200px;
	}
}

@media (min-width: 1200px) {
	.heading-panel {
		padding-bottom: 30px;
	}
	.heading-panel-left {
		padding-left: 240px;
	}
	.heading-panel-title {
		top: 60%;
	}
}

/*
*
* Services
*/
.services-classic {
	text-align: left;
	max-width: 370px;
	margin-left: auto;
	margin-right: auto;
}

.services-classic-figure {
	position: relative;
	overflow: hidden;
	display: block;
}

.services-classic-figure img {
	width: 100%;
	transition: all .3s ease;
}

.services-classic-caption {
	display: flex;
	align-items: center;
	position: relative;
	padding: 25px 0 14px 20px;
	border-bottom: 1px solid #e1e1e1;
	transition: all .3s ease;
}

.services-classic-caption::before {
	position: absolute;
	content: '';
	top: 54%;
	left: 1px;
	height: 34px;
	width: 1px;
	background: #e1e1e1;
	transform: translate3d(0, -50%, 0);
	transition: height .3s ease .3s, width .3s ease 0s;
}

.services-classic-counter {
	letter-spacing: .05em;
	color: rgba(21, 21, 21, 0.2);
	transition: inherit;
}

.services-classic-title {
	margin-left: 16px;
	max-width: 262px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.services-classic-title a {
	transition: all .3s ease-in-out, color .3s ease-in-out;
}

* + .services-classic-title {
	margin-top: 0;
}

@media (min-width: 992px) {
	.desktop .services-classic-figure img {
		transform: scale(1.1);
		will-change: transform;
	}
	.desktop .services-classic:hover .services-classic-figure img {
		transform: none;
	}
	.desktop .services-classic:hover .services-classic-caption {
		padding-left: 58px;
		transition-delay: .3s;
	}
	.desktop .services-classic:hover .services-classic-caption::before {
		height: 1px;
		width: 40px;
		transition-delay: 0s, .3s;
	}
	.desktop .services-classic:hover .services-classic-counter {
		color: #151515;
	}
	.desktop .services-classic:hover .services-classic-title {
		letter-spacing: .2em;
	}
	.desktop .services-classic:hover .services-classic-title a {
		transition-delay: .3s, 0s;
	}
}

@media (min-width: 768px) {
	.services-classic-title {
		margin-bottom: 4px;
	}
	.services-classic-caption::before {
		height: 40px;
	}
}

.services-modern {
	position: relative;
}

.services-modern-content-wrap {
	overflow: hidden;
	clip-path: polygon(calc(50% - 25px) 25px, calc(50% + 25px) 25px, calc(50% + 50px) 0, 100% 0, 100% 100%, 0 100%, 0 0, calc(50% - 50px) 0);
}

.services-modern-content {
	transition: all .3s ease;
}

.services-modern-figure {
	display: block;
	background: #363b3e;
}

.services-modern-figure img {
	width: 100%;
	transition: inherit;
}

.services-modern-title {
	font-weight: 400;
}

.services-modern-title a, .services-modern-title a:focus, .services-modern-title a:active {
	color: inherit;
}

.services-modern-title a:hover {
	color: #50ba87;
}

.services-modern-counter {
	position: absolute;
	top: -20px;
	left: 50%;
	font-size: 48px;
	line-height: 1;
	font-weight: 400;
	letter-spacing: .05em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: rgba(21, 21, 21, 0.3);
	transform: translate3d(-50%, 0, 0);
	transition: all .3s ease-in-out;
}

* + .services-modern-title {
	margin-top: 18px;
}

@media (min-width: 992px) {
	.desktop .services-modern:hover .services-modern-content {
		transform: translate3d(0, -30px, 0);
	}
	.desktop .services-modern:hover .services-modern-figure img {
		opacity: .5;
	}
	.desktop .services-modern:hover .services-modern-counter {
		transform: translate3d(-50%, -10px, 0);
		color: #50ba87;
	}
}

.single-service {
	text-align: left;
}

.single-service h3 + p {
	margin-top: 14px;
}

.single-service * + .quote-classic {
	margin-top: 34px;
}

.single-service * + .list-marked {
	margin-top: 24px;
}

.single-service .quote-classic + p {
	margin-top: 35px;
}

/*
*
* Team
*/
.team-classic {
	position: relative;
	overflow: hidden;
	text-align: left;
	max-width: 370px;
	margin-left: auto;
	margin-right: auto;
}

.team-classic-2 .team-classic-status {
	color: #50ba87;
}

.team-classic-figure {
	display: block;
}

.team-classic-figure img {
	width: 100%;
	filter: grayscale(100%);
	transition: all .3s ease;
}

.team-classic-caption {
	position: absolute;
	overflow: hidden;
	left: 0;
	bottom: 27px;
	width: 80%;
	min-width: 300px;
	margin-left: -25px;
	padding: 15px 20px 15px 50px;
	transition: all .3s ease;
	z-index: 1;
}

.team-classic-caption::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(17, 17, 17, 0.5);
	transform: skew(10deg);
	transform-origin: 50% 100%;
	z-index: -1;
}

.team-classic-name {
	color: #ffffff;
}

.team-classic-name a, .team-classic-name a:focus, .team-classic-name a:active {
	color: inherit;
}

.team-classic-name a:hover {
	color: #01b3a7;
}

.team-classic-status {
	line-height: 1.5;
	color: #01b3a7;
	letter-spacing: .025em;
}

* + .team-classic-status {
	margin-top: 0;
}

@media (min-width: 576px) {
	.team-classic-lg {
		max-width: 420px;
	}
	.team-classic-lg .team-classic-caption {
		bottom: 44px;
		margin-left: -30px;
		padding: 15px 20px 15px 85px;
	}
}

@media (min-width: 992px) {
	.desktop .team-classic:hover .team-classic-figure img {
		filter: grayscale(0);
	}
	.desktop .team-classic:hover .team-classic-caption {
		margin-left: 0;
	}
}

@media (min-width: 1200px) {
	.team-classic-lg .team-classic-caption {
		bottom: 10%;
		padding: 15px 6% 15px 18%;
	}
}

@media (min-width: 1200px) and (max-width: 1399px) {
	.team-classic-lg .team-classic-caption {
		min-width: 260px;
	}
}

.team-modern {
	position: relative;
	text-align: center;
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
	line-height: 0;
}

.team-modern svg path {
	fill: #f7f7f7;
}

.team-modern-caption {
	padding: 7px 14px 48px;
	background: #f7f7f7;
}

.team-modern-header {
	position: relative;
	overflow: hidden;
	padding-top: 12px;
	padding-bottom: 32px;
}

.team-modern-header svg {
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translate3d(-50%, 0, 0);
}

.team-modern-figure {
	position: relative;
	display: block;
	top: 0;
	width: 100%;
	max-width: 118px;
	max-height: 118px;
	margin: 0 auto;
	transition: all .3s ease;
	z-index: 1;
}

.team-modern-figure::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	border: 4px solid transparent;
	transition: all .3s ease-in-out;
}

.team-modern-status {
	color: #01b3a7;
	letter-spacing: .025em;
	line-height: 1.71429;
	font-weight: 500;
}

.team-modern-phone {
	font-weight: 500;
}

.team-modern-name {
	font-weight: 600;
}

@media (min-width: 992px) {
	.desktop .team-modern:hover .team-modern-figure {
		top: -10px;
	}
	.desktop .team-modern:hover .team-modern-figure::before {
		border-color: #01b3a7;
	}
	.desktop .team-modern-2:hover .team-modern-figure::before {
		border-color: #50ba87;
	}
}

* + .team-modern-status {
	margin-top: 4px;
}

* + .team-modern-phone {
	margin-top: 10px;
}

.team-creative {
	max-width: 370px;
	margin-left: auto;
	margin-right: auto;
}

.team-creative-figure {
	display: block;
}

.team-creative-figure img {
	width: 100%;
}

.team-creative-caption {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 33px 0 13px;
	transition: all .3s ease;
}

.team-creative-name {
	font-weight: 400;
}

.team-creative-status {
	font-size: 16px;
	line-height: 1.2;
	font-weight: 500;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: rgba(21, 21, 21, 0.45);
	transition: all .2s ease-in-out;
}

.team-creative-decor-bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 2px;
	width: 100%;
	background: #151515;
}

.team-creative-decor-bottom::before {
	position: absolute;
	content: '';
	right: 0;
	bottom: 0;
	width: 0;
	height: inherit;
	background: #01b3a7;
	transition: all .2s ease .2s;
}

.team-creative-decor-left {
	position: absolute;
	bottom: 2px;
	left: 0;
	width: 0;
	height: 0;
	transition: height .15s ease .15s, width .15s ease 0s;
}

.team-creative-decor-left::before, .team-creative-decor-left::after {
	position: absolute;
	content: '';
	background: #01b3a7;
}

.team-creative-decor-left::before {
	bottom: 0;
	left: 0;
	width: 2px;
	height: 100%;
}

.team-creative-decor-left::after {
	top: 0;
	left: 2px;
	height: 2px;
	width: 100%;
}

@media (min-width: 1200px) {
	.desktop .team-creative:hover .team-creative-caption {
		padding-left: 44px;
		transition-delay: .2s;
	}
	.desktop .team-creative:hover .team-creative-decor-bottom::before {
		width: 100%;
		transition-delay: 0s;
	}
	.desktop .team-creative:hover .team-creative-decor-left {
		width: 32px;
		height: 32px;
		transition-delay: .2s, .35s;
	}
	.desktop .team-creative:hover .team-creative-status {
		color: #01b3a7;
	}
}

* + .team-creative-status {
	margin-top: 0;
}

.team-nikki {
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
}

.team-nikki-figure {
	display: block;
	overflow: hidden;
}

.team-nikki-figure img {
	width: 100%;
	transition: all .3s ease;
}

.team-nikki-caption {
	position: relative;
	padding: 22px 15px 24px;
	background: #ffffff;
	z-index: 1;
	transition: all .3s ease-in-out;
}

.team-nikki-name {
	position: relative;
	font-weight: 400;
}

.team-nikki-status {
	font-size: 16px;
	line-height: 1.2;
	font-weight: 500;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #01b3a7;
	transition: all .3s ease-in-out;
}

.team-nikki-arrow {
	position: absolute;
	text-align: center;
	top: -10px;
	left: 50%;
	width: 43px;
	height: 43px;
	border-radius: 50%;
	color: #50ba87;
	background: #ffffff;
	transform: translate3d(-50%, 0, 0);
	transition: all .3s ease-in-out;
	z-index: -1;
}

.team-nikki-arrow::before {
	display: inline-block;
	content: '\f106';
	font-size: 18px;
	line-height: 27px;
	font-family: "FontAwesome";
	transform: none;
	transition: transform .3s ease;
}

@media (min-width: 992px) {
	.desktop .team-nikki-figure img {
		transform: scale(1.1);
		will-change: transform;
	}
	.desktop .team-nikki:hover .team-nikki-figure img {
		transform: none;
	}
	.desktop .team-nikki:hover .team-nikki-caption {
		background: #01b3a7;
	}
	.desktop .team-nikki:hover .team-nikki-name,
	.desktop .team-nikki:hover .team-nikki-status {
		color: #ffffff;
	}
	.desktop .team-nikki:hover .team-nikki-arrow {
		background: #01b3a7;
		color: #ffffff;
	}
	.desktop .team-nikki:hover .team-nikki-arrow::before {
		transform: rotate(180deg);
	}
	.desktop .team-nikki:hover .team-nikki-name a:focus, .desktop .team-nikki:hover .team-nikki-name a:hover {
		color: rgba(255, 255, 255, 0.7);
	}
}

* + .team-nikki-status {
	margin-top: 2px;
}

.clients-classic {
	position: relative;
	display: block;
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
	background: #ffffff;
	border: 1px solid #e1e1e1;
	transition: all .3s ease-in-out;
}

.clients-classic::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 1px solid transparent;
	transition: all .3s ease;
	will-change: transform;
}

.clients-classic img {
	width: 100%;
	opacity: .5;
}

@media (min-width: 992px) {
	.desktop .clients-classic img {
		will-change: transform;
		transition: all .3s ease;
	}
	.desktop .clients-classic:hover {
		border-color: #151515;
	}
	.desktop .clients-classic:hover::before {
		transform: scale(0.94, 0.9);
		border-color: #151515;
	}
	.desktop .clients-classic:hover img {
		opacity: 1;
		transform: scale(0.9);
	}
	.desktop .context-dark .clients-classic:hover, .desktop .bg-gray-dark .clients-classic:hover, .desktop .bg-primary .clients-classic:hover {
		border-color: rgba(255, 255, 255, 0.6);
	}
	.desktop .context-dark .clients-classic:hover::before, .desktop .bg-gray-dark .clients-classic:hover::before, .desktop .bg-primary .clients-classic:hover::before {
		border-color: rgba(255, 255, 255, 0.6);
	}
}

.context-dark .clients-classic, .bg-gray-dark .clients-classic, .bg-primary .clients-classic {
	background: transparent;
	border-color: rgba(255, 255, 255, 0.3);
}

.clients-modern {
	position: relative;
	display: block;
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
	background: #ffffff;
	border: 1px solid #e1e1e1;
	transition: all .3s ease-in-out;
}

.clients-modern::before, .clients-modern::after {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
	transition: color .1s ease, transform .3s ease;
	will-change: transform;
}

.clients-modern::before {
	transform: scaleX(0);
}

.clients-modern::after {
	transform: scaleY(0);
}

.clients-modern img {
	width: 100%;
	opacity: .5;
}

@media (min-width: 992px) {
	.desktop .clients-modern img {
		will-change: transform;
		transition: all .3s ease;
	}
	.desktop .clients-modern:hover {
		border-color: transparent;
	}
	.desktop .clients-modern:hover::before, .desktop .clients-modern:hover::after {
		transform: none;
	}
	.desktop .clients-modern:hover::before {
		border-color: #50ba87 transparent #50ba87 transparent;
	}
	.desktop .clients-modern:hover::after {
		border-color: transparent #50ba87 transparent #50ba87;
	}
	.desktop .clients-modern:hover img {
		opacity: 1;
		transform: scale(0.9);
	}
}

.clients-creative {
	position: relative;
	display: block;
	max-width: 270px;
	margin-left: auto;
	margin-right: auto;
	transition: all .3s ease-in-out;
}

.clients-creative img {
	width: 100%;
	opacity: .3;
}

.clients-creative-2 img,
.clients-creative-3 img {
	opacity: .5;
}

.clients-creative-3 {
	margin-top: 15px;
	margin-bottom: 15px;
}

@media (min-width: 992px) {
	.desktop .clients-creative img {
		will-change: transform;
		transition: all .3s ease;
	}
	.desktop .clients-creative:hover {
		border-color: #151515;
	}
	.desktop .clients-creative:hover img {
		opacity: .5;
		transform: translate3d(0, -10px, 0);
	}
	.desktop .clients-creative-2:hover img {
		opacity: .7;
	}
	.desktop .clients-creative-3:hover img {
		opacity: 1;
	}
}

@media (min-width: 768px) {
	.clients-creative-3 {
		margin-top: 30px;
		margin-bottom: 30px;
	}
}

/*
*
* Video
*/
.video-classic {
	position: relative;
	max-width: 570px;
	margin-left: auto;
	margin-right: auto;
}

.video-classic .video-classic-play {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
}

.video-classic-play {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	padding: 0;
	border: none;
	background: transparent;
	border-radius: 50%;
	transition: all .3s ease;
	cursor: pointer;
	z-index: 1;
}

.video-classic-play > span {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	border-style: solid;
	border-width: 6.5px 0 6.5px 12px;
	border-color: transparent transparent transparent #151515;
	transition: all .3s ease;
	z-index: 0;
}

.video-classic-play::before, .video-classic-play::after {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	will-change: transform;
	transition: inherit;
	z-index: -1;
}

.video-classic-play::before {
	background: rgba(255, 255, 255, 0.61);
}

.video-classic-play::after {
	background: #01b3a7;
	transform: scale(0.7);
	opacity: 0;
}

.video-classic-play:hover > span {
	border-left-color: #ffffff;
}

.video-classic-play:hover::before {
	transform: scale(1.2);
}

.video-classic-play:hover::after {
	opacity: 1;
	transform: none;
}

.video-classic-play-2 {
	width: 100px;
	height: 100px;
}

.video-classic-play-2::before {
	background: rgba(21, 21, 21, 0.61);
}

.video-classic-play-2 > span {
	border-left-color: #ffffff;
	border-width: 11.5px 0 11.5px 20px;
}

.video-modern * + .video-classic-play {
	margin-top: 24px;
}

.video-modern-subtitle {
	font-weight: 300;
	color: #01b3a7;
}

.video-modern-text {
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: 0;
	font-weight: 500;
}

* + .video-modern-title {
	margin-top: 4px;
}

* + .video-modern-text {
	margin-top: 10px;
}

.modal-video {
	align-items: center;
}

.modal-video.show {
	display: flex !important;
}

.modal-video .modal-dialog {
	width: 100%;
}

.modal-video .modal-content {
	display: block;
	font-size: 0;
	line-height: 0;
	border: none;
	border-radius: 0;
	background: transparent;
}

.modal-video .modal-header {
	display: block;
	padding: 0;
	border: none;
}

.modal-video .modal-body {
	padding: 0;
}

.modal-video video {
	width: 100%;
}

.modal-video .close {
	position: relative;
	color: #ffffff;
	font-size: 30px;
	font-weight: 400;
	text-shadow: none;
	cursor: pointer;
	z-index: 1;
}

@media (min-width: 576px) {
	.modal-video .modal-dialog {
		max-width: 80%;
	}
}

@media (min-width: 1200px) {
	.modal-video .modal-dialog {
		max-width: 60%;
	}
}

/*
*
* Page layout
*/
.page {
	position: relative;
	overflow: hidden;
	min-height: 100vh;
	text-align: center;
}

[data-x-mode] .page {
	opacity: 1;
}

html.boxed body {
	background: #f2f2f2 url(./images/bg-pattern-boxed.png) repeat fixed;
}

@media (min-width: 1630px) {
	html.boxed .page {
		max-width: 1600px;
		margin-left: auto;
		margin-right: auto;
		box-shadow: 0 0 23px 0 rgba(1, 1, 1, 0.1);
	}
	html.boxed .rd-navbar-static {
		max-width: 1600px;
		margin-left: auto;
		margin-right: auto;
	}
	html.boxed .rd-navbar-static.rd-navbar--is-stuck {
		max-width: 1600px;
		width: 100%;
		left: calc(50% - 1600px);
		right: calc(50% - 1600px);
	}
}

/*
*
* Text styling
*/
.text-italic {
	font-style: italic;
}

.text-normal {
	font-style: normal;
}

.text-underline {
	text-decoration: underline;
}

.text-strike {
	text-decoration: line-through;
}

.font-weight-thin {
	font-weight: 100;
}

.font-weight-light {
	font-weight: 300;
}

.font-weight-regular {
	font-weight: 400;
}

.font-weight-medium {
	font-weight: 500;
}

.font-weight-sbold {
	font-weight: 600;
}

.font-weight-ubold {
	font-weight: 900;
}

.text-spacing-0 {
	letter-spacing: 0;
}

.text-spacing-25 {
	letter-spacing: .025em;
}

.text-spacing-50 {
	letter-spacing: .05em;
}

.text-spacing-75 {
	letter-spacing: .075em;
}

.text-spacing-100 {
	letter-spacing: .025em;
}

.text-primary {
	color: #01b3a7;
}

.text-secondary {
	color: #50ba87;
}

.text-gray-500 {
	color: #9b9b9b;
}

.text-img {
	display: inline-block;
	overflow: hidden;
	font-size: 250px;
	line-height: 85%;
	font-weight: 700;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #151515;
}

.text-img:hover {
	color: #9b9b9b;
}

@media (min-width: 576px) {
	.text-img {
		font-size: 330px;
	}
}

@media (min-width: 992px) {
	.text-img {
		letter-spacing: .05em;
	}
}

@supports (-webkit-background-clip: text) {
	.text-img {
		-webkit-text-fill-color: transparent;
		background: -webkit-linear-gradient(transparent, transparent);
		background: -o-linear-gradient(transparent, transparent);
		
		-webkit-background-clip: text;
		background-position-y: 40%;
		animation: imgPos 5s linear normal infinite paused;
	}
	.text-img > span {
		pointer-events: none;
	}
	.text-img:hover {
		animation-play-state: running;
		color: red;
	}
	@media (min-width: 576px) {
		.text-img {
			background-position-y: 22%;
		}
	}
}

@keyframes imgPos {
	0% {
		background-position-x: 0;
	}
	50% {
		background-position-x: 100%;
	}
	100% {
		background-position-x: 0;
	}
}

.text-style-1 {
	font-size: 16px;
	line-height: 1.5;
	font-weight: 500;
	letter-spacing: .025em;
	text-indent: -28px;
	padding-left: 28px;
}

.text-style-1 .icon {
	vertical-align: middle;
	font-size: 20px;
	line-height: 24px;
	min-width: 18px;
	margin-right: 10px;
	text-indent: 0;
}

* + .text-style-1 {
	margin-top: 20px;
}

.text-strike-1 {
	position: relative;
	display: inline-block;
	color: rgba(21, 21, 21, 0.52);
}

.text-strike-1::before {
	position: absolute;
	content: '';
	bottom: 36%;
	left: -8px;
	height: 2px;
	width: 107%;
	transform: rotate(-3.8deg);
	transform-origin: 0 50%;
	will-change: transform;
	background: #fcef57;
	transition: all .3s ease 1.2s;
	z-index: 1;
}

.swiper-container .text-strike-1::before {
	transform: rotate(-3.8deg) scale(0);
}

.swiper-container .swiper-slide-active .text-strike-1::before {
	transform: rotate(-3.8deg) scale(1);
}

/*
*
* Offsets
*/
* + p {
	margin-top: 15px;
}

h1 + h2 {
	margin-top: 4px;
}

h2 + h3 {
	margin-top: 9px;
}

h3 + h4 {
	margin-top: 13px;
}

h4 + h5 {
	margin-top: 19px;
}

h5 + h6 {
	margin-top: 27px;
}

h1 + p {
	margin-top: 4px;
}

h2 + p {
	margin-top: 15px;
}

h3 + p {
	margin-top: 20px;
}

h4 + p {
	margin-top: 16px;
}

h5 + p {
	margin-top: 17px;
}

h6 + p {
	margin-top: 17px;
}

h3.font-weight-normal + p {
	margin-top: 16px;
}

p + h2,
p + .heading-2 {
	margin-top: 15px;
}

p + p {
	margin-top: 12px;
}

img + p {
	margin-top: 15px;
}

h3 + img {
	margin-top: 40px;
}

* + .row {
	margin-top: 40px;
}

* + .row-xxs {
	margin-top: 10px;
}

* + .row-xs {
	margin-top: 20px;
}

* + .row-sm {
	margin-top: 30px;
}

* + .row-lg {
	margin-top: 50px;
}

* + .row-xl {
	margin-top: 60px;
}

* + .row-xxl {
	margin-top: 70px;
}

* + .owl-carousel {
	margin-top: 30px;
}

* + .offset-top-30 {
	margin-top: 30px;
}

* + .offset-top-50 {
	margin-top: 50px;
}

* + .big {
	margin-top: 20px;
}

* + .button {
	margin-top: 30px;
}

* + .button-wrap {
	margin-top: 35px;
}

h3 + .section-relative {
	margin-top: 35px;
}

h4 + .instafeed {
	margin-top: 2px;
}

.row + h3,
.row + .heading-3 {
	margin-top: 80px;
}

.container + .container {
	margin-top: 35px;
}

.row + .row {
	margin-top: 35px;
}

.row + .button-wrap {
	margin-top: 45px;
}

@media (min-width: 1200px) {
	.container + .container {
		margin-top: 60px;
	}
	.row + .row {
		margin-top: 60px;
	}
}

.row-0 {
	margin-bottom: 0px;
}

.row-0:empty {
	margin-bottom: 0;
}

.row-0 > * {
	margin-bottom: 0px;
}

.row-4 {
	margin-bottom: -4px;
}

.row-4:empty {
	margin-bottom: 0;
}

.row-4 > * {
	margin-bottom: 4px;
}

.row-6 {
	margin-bottom: -6px;
}

.row-6:empty {
	margin-bottom: 0;
}

.row-6 > * {
	margin-bottom: 6px;
}

.row-10 {
	margin-bottom: -10px;
}

.row-10:empty {
	margin-bottom: 0;
}

.row-10 > * {
	margin-bottom: 10px;
}

.row-14 {
	margin-bottom: -14px;
}

.row-14:empty {
	margin-bottom: 0;
}

.row-14 > * {
	margin-bottom: 14px;
}

.row-15 {
	margin-bottom: -15px;
}

.row-15:empty {
	margin-bottom: 0;
}

.row-15 > * {
	margin-bottom: 15px;
}

.row-16 {
	margin-bottom: -16px;
}

.row-16:empty {
	margin-bottom: 0;
}

.row-16 > * {
	margin-bottom: 16px;
}

.row-20 {
	margin-bottom: -20px;
}

.row-20:empty {
	margin-bottom: 0;
}

.row-20 > * {
	margin-bottom: 20px;
}

.row-30 {
	margin-bottom: -30px;
}

.row-30:empty {
	margin-bottom: 0;
}

.row-30 > * {
	margin-bottom: 30px;
}

.row-40 {
	margin-bottom: -40px;
}

.row-40:empty {
	margin-bottom: 0;
}

.row-40 > * {
	margin-bottom: 40px;
}

.row-50 {
	margin-bottom: -50px;
}

.row-50:empty {
	margin-bottom: 0;
}

.row-50 > * {
	margin-bottom: 50px;
}

.row-55 {
	margin-bottom: -55px;
}

.row-55:empty {
	margin-bottom: 0;
}

.row-55 > * {
	margin-bottom: 55px;
}

.row-60 {
	margin-bottom: -60px;
}

.row-60:empty {
	margin-bottom: 0;
}

.row-60 > * {
	margin-bottom: 60px;
}

.row-70 {
	margin-bottom: -70px;
}

.row-70:empty {
	margin-bottom: 0;
}

.row-70 > * {
	margin-bottom: 70px;
}

.row-90 {
	margin-bottom: -90px;
}

.row-90:empty {
	margin-bottom: 0;
}

.row-90 > * {
	margin-bottom: 90px;
}

@media (min-width: 768px) {
	.row-md-50 {
		margin-bottom: -50px;
	}
	.row-md-50:empty {
		margin-bottom: 0;
	}
	.row-md-50 > * {
		margin-bottom: 50px;
	}
	.row-md-80 {
		margin-bottom: -80px;
	}
	.row-md-80:empty {
		margin-bottom: 0;
	}
	.row-md-80 > * {
		margin-bottom: 80px;
	}
}

@media (min-width: 992px) {
	.row-lg-40 {
		margin-bottom: -40px;
	}
	.row-lg-40:empty {
		margin-bottom: 0;
	}
	.row-lg-40 > * {
		margin-bottom: 40px;
	}
	.row-lg-80 {
		margin-bottom: -80px;
	}
	.row-lg-80:empty {
		margin-bottom: 0;
	}
	.row-lg-80 > * {
		margin-bottom: 80px;
	}
	.row-lg-90 {
		margin-bottom: -90px;
	}
	.row-lg-90:empty {
		margin-bottom: 0;
	}
	.row-lg-90 > * {
		margin-bottom: 90px;
	}
}

@media (min-width: 1200px) {
	.row-xl-24 {
		margin-bottom: -24px;
	}
	.row-xl-24:empty {
		margin-bottom: 0;
	}
	.row-xl-24 > * {
		margin-bottom: 24px;
	}
}

.box-typography h1 + p {
	margin-top: 16px;
}

.box-typography h2 + p {
	margin-top: 16px;
}

.box-typography h3 + p {
	margin-top: 16px;
}

.box-typography h4 + p {
	margin-top: 16px;
}

.box-typography h5 + p {
	margin-top: 16px;
}

.box-typography h6 + p {
	margin-top: 16px;
}

@media (min-width: 992px) {
	.box-typography h1 + p {
		margin-top: 4px;
	}
	.box-typography h2 + p {
		margin-top: 8px;
	}
	.box-typography h3 + p {
		margin-top: 8px;
	}
	.box-typography h5 + p {
		margin-top: 17px;
	}
	.box-typography h6 + p {
		margin-top: 17px;
	}
}

/*
*
* Sections
*/
.section-xs {
	padding-top: 40px;
	padding-bottom: 40px;
}

.section-sm {
	padding: 30px 0;
	margin-top: 0%;
	position: relative;
}

.section-sm.section-first {
	padding-top: 60px;
	margin: 0%;
}

.section-sm.section-last {
	padding-bottom: 60px;
}

.section-60,
.section-md,
.section-lg,
.section-xl {
	padding: 60px 0;
}

html [class*='section-'].section-bottom-0 {
	padding-bottom: 0;
}

html [class*='section-'].section-top-0 {
	padding-top: 0;
}

@media (min-width: 992px) {
	html [class*='section-'].section-lg-0 {
		padding: 0;
	}
}

@media (min-width: 768px) {
	.section-sm {
		padding: 40px 0;
	}
	.section-sm.section-first {
		padding-top: 80px;
	}
	.section-sm.section-last {
		padding-bottom: 80px;
	}
	.section-md {
		padding: 70px 0;
	}
	.section-lg {
		padding: 80px 0;
	}
	.section-xl {
		padding: 90px 0;
	}
}

@media (min-width: 992px) {
	.section-sm {
		padding: 50px 0;
	}
	.section-sm.section-first {
		padding-top: 100px;
	}
	.section-sm.section-last {
		padding-bottom: 100px;
	}
	.section-md {
		padding: 80px 0;
	}
	.section-lg {
		padding: 90px 0;
	}
	.section-inset-custom-6,
	.section-xl {
		padding: 100px 0;
	}
	.desktop .section-top-1 {
		padding-top: 0;
	}
}

@media (min-width: 1600px) {
	.section-inset-custom-1 {
		padding-top: 120px;
		padding-bottom: 160px;
	}
	.section-inset-custom-2 {
		padding-top: 90px;
		padding-bottom: 130px;
	}
	.section-inset-custom-3 {
		padding-top: 100px;
		padding-bottom: 130px;
	}
	.section-inset-custom-4 {
		padding-top: 125px;
		padding-bottom: 140px;
	}
	.section-inset-custom-5 {
		padding-top: 135px;
		padding-bottom: 135px;
	}
}

@media (min-width: 1600px) {
	.section-fluid {
		padding-left: 75px;
		padding-right: 75px;
	}
	.section-fluid .container-fluid {
		padding-left: 0;
		padding-right: 0;
		max-width: 1770px;
		margin-left: auto;
		margin-right: auto;
	}
}

.section-single {
	position: relative;
	overflow: hidden;
	display: flex;
	text-align: center;
}

.section-single p {
	margin-left: auto;
	margin-right: auto;
}

.section-single .rights {
	letter-spacing: 0;
	font-weight: 400;
}

.section-single .rights a {
	position: relative;
	margin-left: 10px;
}

.section-single .rights a:hover {
	text-decoration: underline;
	color: #ffffff !important;
}

.section-single .rd-mailform-wrap {
	max-width: 670px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.section-single * + p {
	margin-top: 6px;
}

.section-single p + p {
	margin-top: 0;
}

.section-single .DateCountdown + p {
	margin-top: 60px;
}

.section-single * + .button {
	margin-top: 40px;
}

.section-single * + .rights {
	margin-top: 35px;
}

.section-single * + .countdown-wrap {
	margin-top: 35px;
}

.section-single * + .rd-mailform-wrap {
	margin-top: 20px;
}

.section-single .countdown-wrap + * {
	margin-top: 35px;
}

@media (min-width: 992px) {
	.section-single * + .button {
		margin-top: 60px;
	}
	.section-single * + .rights {
		margin-top: 60px;
	}
	.section-single .countdown-wrap + * {
		margin-top: 35px;
	}
}

@media (min-width: 1200px) {
	.section-404 * + .button {
		margin-top: 120px;
	}
}

.section-single-header {
	padding: calc(1em + 4vh) 0 calc(1em + 2vh);
	z-index: 2;
}

.section-single-main {
	padding: 60px 0 calc(1em + 5vh);
}

.section-single-main * {
	font-family: "Teko", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.section-single-main .form-wrap *, .section-single-main .form-button * {
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.section-single-main p {
	font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.single-page h3 {
	font-size: 30px;
	font-weight: 200;
	letter-spacing: 0.050em;
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.single-page h3 {
		font-size: 48px;
	}
}

.section-single-footer {
	padding: calc(1em + 2vh) 0 calc(1em + 4vh);
}

.section-single-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 100vh;
}

.section-single-inner > * {
	width: 100%;
}

@supports (display: grid) {
	.section-single-inner {
		display: grid;
		justify-items: center;
		align-content: space-between;
		grid-template-columns: 1fr;
	}
}

@media (min-width: 768px) {
	.section-single-main {
		padding: 70px 0 30px;
	}
}

@media (min-width: 1200px) {
	.section-single-header {
		padding-top: calc(1em + 9vh);
	}
}

@media (min-width: 1600px) {
	.section-single-main {
		padding-top: 65px;
		padding-bottom: 100px;
	}
}

/*
*
* Grid modules
*/
.grid-demonstration {
	text-align: left;
}

@media (max-width: 1599.98px) {
	.grid-demonstration p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.grid-demonstration-fullwidth {
	line-height: 1.86;
}

.grid-demonstration-fullwidth * + p {
	margin-top: 12px;
}

.grid-demonstration-boxed {
	line-height: 1.8;
}

.grid-demonstration-boxed * + p {
	margin-top: 22px;
}

.grid-system-bordered [class*='col'] {
	padding-bottom: 15px;
	border-bottom: 1px solid #f5f5f5;
}

@media (min-width: 576px) {
	.grid-system-bordered [class*='col'] {
		padding-bottom: 30px;
	}
}

@media (min-width: 768px) {
	.grid-system-bordered [class*='col'] {
		padding-bottom: 45px;
	}
}

.grid-system-outline .row {
	border: 1px solid #f5f5f5;
}

.grid-system-outline .row:not(:last-child) {
	border-bottom-width: 0;
}

.grid-system-outline [class*='col']:not(:last-child) {
	border-right: 1px solid #d7d7d7;
	margin-right: -1px;
}

.grid-system-outline * + .row {
	margin-top: 45px;
}

.grid-system-outline .row + .row {
	margin-top: 0;
}

/*
*
* Backgrounds
*/
.context-dark, .bg-gray-dark, .bg-primary,
.context-dark h1,
.bg-gray-dark h1,
.bg-primary h1, .context-dark h2, .bg-gray-dark h2, .bg-primary h2, .context-dark h3, .bg-gray-dark h3, .bg-primary h3, .context-dark h4, .bg-gray-dark h4, .bg-primary h4, .context-dark h5, .bg-gray-dark h5, .bg-primary h5, .context-dark h6, .bg-gray-dark h6, .bg-primary h6, .context-dark [class*='heading-'], .bg-gray-dark [class*='heading-'], .bg-primary [class*='heading-'] {
	color: #ffffff;
}

.context-dark .form-wrap.has-error .form-input, .bg-gray-dark .form-wrap.has-error .form-input, .bg-primary .form-wrap.has-error .form-input {
	border-color: transparent;
}

.context-dark .form-input, .bg-gray-dark .form-input, .bg-primary .form-input,
.context-dark .form-label,
.bg-gray-dark .form-label,
.bg-primary .form-label,
.context-dark .form-validation,
.bg-gray-dark .form-validation,
.bg-primary .form-validation {
	color: #ffffff;
}

.context-dark .form-input, .bg-gray-dark .form-input, .bg-primary .form-input {
	background: rgba(255, 255, 255, 0.34);
	border-color: transparent;
}

.context-dark .rights, .bg-gray-dark .rights, .bg-primary .rights {
	color: #ffffff;
}

.context-dark .rights a, .bg-gray-dark .rights a, .bg-primary .rights a, .context-dark .rights a:focus, .bg-gray-dark .rights a:focus, .bg-primary .rights a:focus, .context-dark .rights a:active, .bg-gray-dark .rights a:active, .bg-primary .rights a:active {
	color: inherit;
}

.context-dark .rights a:hover, .bg-gray-dark .rights a:hover, .bg-primary .rights a:hover {
	color: #01b3a7;
}

.context-dark .button.button-pipaluk::after, .bg-gray-dark .button.button-pipaluk::after, .bg-primary .button.button-pipaluk::after {
	border-color: #ffffff;
}

.context-dark .button.button-pipaluk:hover, .bg-gray-dark .button.button-pipaluk:hover, .bg-primary .button.button-pipaluk:hover, .context-dark .button.button-pipaluk:active, .bg-gray-dark .button.button-pipaluk:active, .bg-primary .button.button-pipaluk:active {
	color: #ffffff;
}

.context-dark .button.button-ujarak:not([class*='button-secondary'])::before, .bg-gray-dark .button.button-ujarak:not([class*='button-secondary'])::before, .bg-primary .button.button-ujarak:not([class*='button-secondary'])::before {
	background: #50ba87;
}

.context-dark .button.button-ujarak:not([class*='button-secondary']):hover, .bg-gray-dark .button.button-ujarak:not([class*='button-secondary']):hover, .bg-primary .button.button-ujarak:not([class*='button-secondary']):hover, .context-dark .button.button-ujarak:not([class*='button-secondary']):active, .bg-gray-dark .button.button-ujarak:not([class*='button-secondary']):active, .bg-primary .button.button-ujarak:not([class*='button-secondary']):active {
	border-color: #50ba87;
}

.context-dark .button-secondary.button-ujarak::before, .bg-gray-dark .button-secondary.button-ujarak::before, .bg-primary .button-secondary.button-ujarak::before {
	background: #01b3a7;
}

.context-dark .button-secondary.button-ujarak:hover, .bg-gray-dark .button-secondary.button-ujarak:hover, .bg-primary .button-secondary.button-ujarak:hover, .context-dark .button-secondary.button-ujarak:active, .bg-gray-dark .button-secondary.button-ujarak:active, .bg-primary .button-secondary.button-ujarak:active {
	border-color: #01b3a7;
}

.footer-variant-2.context-dark, .footer-variant-2.bg-gray-dark, .footer-variant-2.bg-primary {
	color: rgba(255, 255, 255, 0.3);
}

.footer-variant-2.context-dark .rights, .footer-variant-2.bg-gray-dark .rights, .footer-variant-2.bg-primary .rights {
	color: rgba(255, 255, 255, 0.3);
}

.footer-variant-2.context-dark .button.button-ujarak::before, .footer-variant-2.bg-gray-dark .button.button-ujarak::before, .footer-variant-2.bg-primary .button.button-ujarak::before {
	background: #ffffff;
}

.footer-variant-2.context-dark .button.button-ujarak:hover, .footer-variant-2.bg-gray-dark .button.button-ujarak:hover, .footer-variant-2.bg-primary .button.button-ujarak:hover, .footer-variant-2.context-dark .button.button-ujarak:active, .footer-variant-2.bg-gray-dark .button.button-ujarak:active, .footer-variant-2.bg-primary .button.button-ujarak:active {
	color: #50ba87;
	border-color: #ffffff;
}

.footer-modern.context-dark .form-input, .footer-modern.bg-gray-dark .form-input, .footer-modern.bg-primary .form-input {
	background: #565656;
}

.footer-creative.context-dark .form-input, .footer-creative.bg-gray-dark .form-input, .footer-creative.bg-primary .form-input {
	background: #54595e;
}

.context-dark .footer-contacts, .bg-gray-dark .footer-contacts, .bg-primary .footer-contacts {
	border-top-color: rgba(255, 255, 255, 0.2);
}

.context-dark .footer-contacts .icon, .bg-gray-dark .footer-contacts .icon, .bg-primary .footer-contacts .icon {
	color: #01b3a7;
}

.context-dark .footer-contacts p, .bg-gray-dark .footer-contacts p, .bg-primary .footer-contacts p {
	color: #ffffff;
}

.context-dark .footer-contacts a, .bg-gray-dark .footer-contacts a, .bg-primary .footer-contacts a, .context-dark .footer-contacts a:focus, .bg-gray-dark .footer-contacts a:focus, .bg-primary .footer-contacts a:focus, .context-dark .footer-contacts a:active, .bg-gray-dark .footer-contacts a:active, .bg-primary .footer-contacts a:active {
	color: #ffffff;
}

.context-dark .footer-contacts a:hover, .bg-gray-dark .footer-contacts a:hover, .bg-primary .footer-contacts a:hover {
	color: #01b3a7;
}

.context-dark .footer-link-list a, .bg-gray-dark .footer-link-list a, .bg-primary .footer-link-list a, .context-dark .footer-link-list a:focus, .bg-gray-dark .footer-link-list a:focus, .bg-primary .footer-link-list a:focus, .context-dark .footer-link-list a:active, .bg-gray-dark .footer-link-list a:active, .bg-primary .footer-link-list a:active {
	color: inherit;
}

.context-dark .footer-link-list a:hover, .bg-gray-dark .footer-link-list a:hover, .bg-primary .footer-link-list a:hover {
	color: #01b3a7;
}

.context-dark .footer-social-list-2 a, .bg-gray-dark .footer-social-list-2 a, .bg-primary .footer-social-list-2 a, .context-dark .footer-social-list-2 a:focus, .bg-gray-dark .footer-social-list-2 a:focus, .bg-primary .footer-social-list-2 a:focus, .context-dark .footer-social-list-2 a:active, .bg-gray-dark .footer-social-list-2 a:active, .bg-primary .footer-social-list-2 a:active {
	color: #ffffff;
}

.context-dark .footer-social-list-2 a:hover, .bg-gray-dark .footer-social-list-2 a:hover, .bg-primary .footer-social-list-2 a:hover {
	color: #01b3a7;
}

.footer-minimal.context-dark .footer-minimal-bottom-panel, .footer-minimal.bg-gray-dark .footer-minimal-bottom-panel, .footer-minimal.bg-primary .footer-minimal-bottom-panel,
.footer-minimal.context-dark .footer-corporate-bottom-panel,
.footer-minimal.bg-gray-dark .footer-corporate-bottom-panel,
.footer-minimal.bg-primary .footer-corporate-bottom-panel, .footer-corporate.context-dark .footer-minimal-bottom-panel, .footer-corporate.bg-gray-dark .footer-minimal-bottom-panel, .footer-corporate.bg-primary .footer-minimal-bottom-panel,
.footer-corporate.context-dark .footer-corporate-bottom-panel,
.footer-corporate.bg-gray-dark .footer-corporate-bottom-panel,
.footer-corporate.bg-primary .footer-corporate-bottom-panel {
	color: rgba(255, 255, 255, 0.3);
}

.footer-minimal.context-dark .footer-minimal-bottom-panel a, .footer-minimal.bg-gray-dark .footer-minimal-bottom-panel a, .footer-minimal.bg-primary .footer-minimal-bottom-panel a, .footer-minimal.context-dark .footer-minimal-bottom-panel a:focus, .footer-minimal.bg-gray-dark .footer-minimal-bottom-panel a:focus, .footer-minimal.bg-primary .footer-minimal-bottom-panel a:focus, .footer-minimal.context-dark .footer-minimal-bottom-panel a:active, .footer-minimal.bg-gray-dark .footer-minimal-bottom-panel a:active, .footer-minimal.bg-primary .footer-minimal-bottom-panel a:active,
.footer-minimal.context-dark .footer-corporate-bottom-panel a,
.footer-minimal.bg-gray-dark .footer-corporate-bottom-panel a,
.footer-minimal.bg-primary .footer-corporate-bottom-panel a,
.footer-minimal.context-dark .footer-corporate-bottom-panel a:focus,
.footer-minimal.bg-gray-dark .footer-corporate-bottom-panel a:focus,
.footer-minimal.bg-primary .footer-corporate-bottom-panel a:focus,
.footer-minimal.context-dark .footer-corporate-bottom-panel a:active,
.footer-minimal.bg-gray-dark .footer-corporate-bottom-panel a:active,
.footer-minimal.bg-primary .footer-corporate-bottom-panel a:active, .footer-corporate.context-dark .footer-minimal-bottom-panel a, .footer-corporate.bg-gray-dark .footer-minimal-bottom-panel a, .footer-corporate.bg-primary .footer-minimal-bottom-panel a, .footer-corporate.context-dark .footer-minimal-bottom-panel a:focus, .footer-corporate.bg-gray-dark .footer-minimal-bottom-panel a:focus, .footer-corporate.bg-primary .footer-minimal-bottom-panel a:focus, .footer-corporate.context-dark .footer-minimal-bottom-panel a:active, .footer-corporate.bg-gray-dark .footer-minimal-bottom-panel a:active, .footer-corporate.bg-primary .footer-minimal-bottom-panel a:active,
.footer-corporate.context-dark .footer-corporate-bottom-panel a,
.footer-corporate.bg-gray-dark .footer-corporate-bottom-panel a,
.footer-corporate.bg-primary .footer-corporate-bottom-panel a,
.footer-corporate.context-dark .footer-corporate-bottom-panel a:focus,
.footer-corporate.bg-gray-dark .footer-corporate-bottom-panel a:focus,
.footer-corporate.bg-primary .footer-corporate-bottom-panel a:focus,
.footer-corporate.context-dark .footer-corporate-bottom-panel a:active,
.footer-corporate.bg-gray-dark .footer-corporate-bottom-panel a:active,
.footer-corporate.bg-primary .footer-corporate-bottom-panel a:active {
	color: inherit;
}

.footer-minimal.context-dark .footer-minimal-bottom-panel a:hover, .footer-minimal.bg-gray-dark .footer-minimal-bottom-panel a:hover, .footer-minimal.bg-primary .footer-minimal-bottom-panel a:hover,
.footer-minimal.context-dark .footer-corporate-bottom-panel a:hover,
.footer-minimal.bg-gray-dark .footer-corporate-bottom-panel a:hover,
.footer-minimal.bg-primary .footer-corporate-bottom-panel a:hover, .footer-corporate.context-dark .footer-minimal-bottom-panel a:hover, .footer-corporate.bg-gray-dark .footer-minimal-bottom-panel a:hover, .footer-corporate.bg-primary .footer-minimal-bottom-panel a:hover,
.footer-corporate.context-dark .footer-corporate-bottom-panel a:hover,
.footer-corporate.bg-gray-dark .footer-corporate-bottom-panel a:hover,
.footer-corporate.bg-primary .footer-corporate-bottom-panel a:hover {
	color: #01b3a7;
}

.footer-minimal.context-dark .rights, .footer-minimal.bg-gray-dark .rights, .footer-minimal.bg-primary .rights, .footer-corporate.context-dark .rights, .footer-corporate.bg-gray-dark .rights, .footer-corporate.bg-primary .rights {
	color: inherit;
}

.footer-minimal.context-dark .button-gray-31.button-ujarak::before, .footer-minimal.bg-gray-dark .button-gray-31.button-ujarak::before, .footer-minimal.bg-primary .button-gray-31.button-ujarak::before, .footer-corporate.context-dark .button-gray-31.button-ujarak::before, .footer-corporate.bg-gray-dark .button-gray-31.button-ujarak::before, .footer-corporate.bg-primary .button-gray-31.button-ujarak::before {
	background: #01b3a7;
}

.footer-minimal.context-dark .button-gray-31.button-ujarak:hover, .footer-minimal.bg-gray-dark .button-gray-31.button-ujarak:hover, .footer-minimal.bg-primary .button-gray-31.button-ujarak:hover, .footer-minimal.context-dark .button-gray-31.button-ujarak:active, .footer-minimal.bg-gray-dark .button-gray-31.button-ujarak:active, .footer-minimal.bg-primary .button-gray-31.button-ujarak:active, .footer-corporate.context-dark .button-gray-31.button-ujarak:hover, .footer-corporate.bg-gray-dark .button-gray-31.button-ujarak:hover, .footer-corporate.bg-primary .button-gray-31.button-ujarak:hover, .footer-corporate.context-dark .button-gray-31.button-ujarak:active, .footer-corporate.bg-gray-dark .button-gray-31.button-ujarak:active, .footer-corporate.bg-primary .button-gray-31.button-ujarak:active {
	color: #ffffff;
	border-color: #01b3a7;
}

.footer-corporate-2.context-dark .footer-contacts .icon, .footer-corporate-2.bg-gray-dark .footer-contacts .icon, .footer-corporate-2.bg-primary .footer-contacts .icon {
	color: #01b3a7;
}

.footer-corporate-2.context-dark .button-gray-31.button-ujarak::before, .footer-corporate-2.bg-gray-dark .button-gray-31.button-ujarak::before, .footer-corporate-2.bg-primary .button-gray-31.button-ujarak::before {
	background: #50ba87;
}

.footer-corporate-2.context-dark .button-gray-31.button-ujarak:hover, .footer-corporate-2.bg-gray-dark .button-gray-31.button-ujarak:hover, .footer-corporate-2.bg-primary .button-gray-31.button-ujarak:hover, .footer-corporate-2.context-dark .button-gray-31.button-ujarak:active, .footer-corporate-2.bg-gray-dark .button-gray-31.button-ujarak:active, .footer-corporate-2.bg-primary .button-gray-31.button-ujarak:active {
	border-color: #50ba87;
}

.context-dark .post-minimal-2-time, .bg-gray-dark .post-minimal-2-time, .bg-primary .post-minimal-2-time {
	color: rgba(255, 255, 255, 0.25);
}

.context-dark .text-strike-1, .bg-gray-dark .text-strike-1, .bg-primary .text-strike-1 {
	color: rgba(255, 255, 255, 0.52);
}

.context-dark .text-strike-1::before, .bg-gray-dark .text-strike-1::before, .bg-primary .text-strike-1::before {
	background: rgba(252, 239, 87, 0.7);
}

.context-dark .video-modern-subtitle, .bg-gray-dark .video-modern-subtitle, .bg-primary .video-modern-subtitle {
	color: #01b3a7;
}

.context-dark .title-decoration-lines-left::before, .bg-gray-dark .title-decoration-lines-left::before, .bg-primary .title-decoration-lines-left::before {
	background: #50ba87;
}

.context-dark .text-gray-500, .bg-gray-dark .text-gray-500, .bg-primary .text-gray-500 {
	color: rgba(255, 255, 255, 0.3);
}

.context-dark .brand .brand-logo-dark, .bg-gray-dark .brand .brand-logo-dark, .bg-primary .brand .brand-logo-dark {
	display: none;
}

.context-dark .brand .brand-logo-light, .bg-gray-dark .brand .brand-logo-light, .bg-primary .brand .brand-logo-light {
	display: block;
}

/**
* Light Backgrounds
*/
.bg-default {
	background-color: #ffffff;
}

.bg-gray-100 {
	background-color: #f2f2f2;
}

.bg-gray-2 {
	background-color: #ebebeb;
}

.bg-gray-2 .owl-classic .owl-prev:hover::before,
.bg-gray-2 .owl-classic .owl-next:hover::before {
	background-color: #50ba87;
}

.bg-gray-2 .owl-classic .owl-prev:hover::after,
.bg-gray-2 .owl-classic .owl-next:hover::after {
	border-left-color: #50ba87;
	border-right-color: #50ba87;
}

.bg-gray-4 {
	background-color: #f7f7f7;
}

.bg-gray-4 .box-icon-modern {
	box-shadow: none;
}

.bg-gray-4 .box-icon-modern:hover {
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.17);
}

.bg-gray-36 {
	background-color: #545d64;
}

/**
* Dark Backgrounds
*/
.bg-gray-dark {
	background-color: #2c343b;
}

/** 
* Accent Backgrounds
*/
.bg-primary + .bg-primary {
	padding-top: 0;
}

/**
* Background Image
*/
[class*='bg-'] {
	background-size: cover;
	background-position: center center;
}

@media (min-width: 992px) {
	html:not(.tablet):not(.mobile) .bg-fixed {
		background-attachment: fixed;
	}
}

[class*='bg-overlay-'] {
	position: relative;
}

[class*='bg-overlay-']::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
}

[class*='bg-overlay-'] .section-single-inner,
[class*='bg-overlay-'] .container {
	position: relative;
}

[class*='bg-second-overlay-']::after {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	z-index: -1;
}

.bg-overlay-68::before {
	background: rgba(43, 49, 59, 0.68);
}

.bg-overlay-64::before {
	background: rgba(43, 49, 59, 0.64);
}

.bg-overlay-60::before {
	background: rgba(43, 49, 59, 0.025);
}

.bg-overlay-59::before {
	background: rgba(43, 49, 59, 0.59);
}

.bg-overlay-55::before {
	background: rgba(43, 49, 59, 0.55);
}

.bg-overlay-53::before {
	background: rgba(43, 49, 59, 0.53);
}

.bg-overlay-54::before {
	background: rgba(43, 49, 59, 0.53);
	z-index: 1;
}

.bg-overlay-49::before {
	background: rgba(43, 49, 59, 0.49);
}

.bg-overlay-48::before {
	background: rgba(43, 49, 59, 0.48);
}

.bg-overlay-46::before {
	background: rgba(43, 49, 59, 0.046);
}

.bg-overlay-43::before {
	background: rgba(43, 49, 59, 0.43);
}

.bg-overlay-3-41::before {
	background: rgba(1, 23, 42, 0.41);
}

.bg-overlay-41::before {
	background: rgba(43, 49, 59, 0.41);
}

.bg-overlay-40::before {
	background: rgba(43, 49, 59, 0.4);
}

.bg-overlay-39::before {
	background: rgba(43, 49, 59, 0.39);
}

.bg-overlay-37::before {
	background: rgba(43, 49, 59, 0.37);
}

.bg-overlay-33::before {
	background: rgba(43, 49, 59, 0.33);
}

.bg-overlay-26::before {
	background: rgba(43, 49, 59, 0.26);
}

.bg-overlay-24::before {
	background: rgba(43, 49, 59, 0.24);
}

.bg-second-overlay-4-22::after {
	background: rgba(24, 26, 35, 0.21);
}

.bg-overlay-2-21::before {
	background: rgba(24, 26, 35, 0.21);
}

.bg-overlay-2-14::before {
	background: rgba(24, 26, 35, 0.14);
}

.bg-overlay-4-60::before {
	background: rgba(24, 38, 50, 0.6);
}

/*
*
* Utilities custom
*/
.height-fill {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.height-fill > * {
	flex-grow: 1;
	flex-shrink: 0;
}

.object-inline {
	white-space: nowrap;
}

.object-inline > * {
	display: inline-block;
	min-width: 20px;
	vertical-align: top;
	white-space: normal;
}

.object-inline > * + * {
	margin-left: 5px;
}

.oh {
	position: relative;
	overflow: hidden;
}

@media (min-width: 992px) {
	.desktop .oh-desktop {
		overflow: hidden;
	}
}

.section-relative {
	position: relative;
}

.text-width-extra-small {
	max-width: 320px;
}

.text-width-small {
	max-width: 430px;
}

.text-width-medium {
	max-width: 600px;
}

.text-width-large {
	max-width: 650px;
}

.text-width-extra-large {
	max-width: 715px;
}

.text-center [class*='text-width-'] {
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 767.98px) {
	[class*='text-width-'] {
		margin-left: auto;
		margin-right: auto;
	}
}

p.rights {
	font-size: 14px;
	letter-spacing: .025em;
	font-weight: 500;
	color: #9b9b9b;
}

p.rights a {
	color: inherit;
}

p.rights a:hover {
	color: #01b3a7;
}

[style*='z-index: 1000;'] {
	z-index: 1101 !important;
}

.text-block > * {
	margin-left: 4px;
	margin-right: 4px;
}

.inset-top-10 {
	padding-top: 10px;
}

.inset-top-18 {
	padding-top: 18px;
}

@media (min-width: 992px) {
	.offset-top-lg-24 {
		margin-top: 24px;
	}
	.desktop .offset-negative-1 {
		margin-top: -35px;
	}
}

@media (min-width: 1200px) {
	.offset-right-xl-15 {
		margin-right: 15px;
	}
	.offset-top-xl-26 {
		margin-top: 26px;
	}
	.offset-left-xl-45 {
		margin-left: 45px;
	}
	.offset-left-xl-65 {
		margin-left: 65px;
	}
	.offset-left-xl-70 {
		margin-left: 70px;
	}
}

@media (min-width: 992px) {
	.box-width-lg-470 {
		max-width: 470px;
		margin-left: auto;
		margin-right: auto;
	}
}

.gutters-4 {
	margin-left: -2px;
	margin-right: -2px;
}

.gutters-4 [class*='col-'] {
	padding-left: 2px;
	padding-right: 2px;
}

.gutters-10 {
	margin-left: -5px;
	margin-right: -5px;
}

.gutters-10 [class*='col-'] {
	padding-left: 5px;
	padding-right: 5px;
}

.gutters-14 {
	margin-left: -7px;
	margin-right: -7px;
}

.gutters-14 [class*='col-'] {
	padding-left: 7px;
	padding-right: 7px;
}

.breadcrumbs-custom {
	position: relative;
	overflow: hidden;
}

.box-position {
	position: absolute;
	left: 0;
	top: 0;
	width: calc(100% + 150px);
	height: calc(100% + 150px);
	will-change: transform;
	animation: bgPos 100s linear infinite;
	transform: translate3d(-75px, -75px, 0) rotate(0.001deg);
	background-position: 50% 50%;
	background-size: cover;
	z-index: -1;
}

@keyframes bgPos {
	25% {
		transform: translate3d(-150px, -150px, 0) rotate(0.001deg);
	}
	75% {
		transform: translate3d(0, 0, 0) rotate(0.001deg);
	}
}

.order-negative-2 {
	order: -2;
}

/*
*
* Animate.css
*/
.animated {
	-webkit-animation-duration: .7s;
	animation-duration: .7s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	opacity: 1;
}

.animated.infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.animated.hinge {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
}

html:not(.lt-ie10) .not-animated {
	opacity: 0;
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 40px, 0);
		transform: translate3d(0, 40px, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, 40px, 0);
		transform: translate3d(0, 40px, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -40px, 0);
		transform: translate3d(0, -40px, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(0, -40px, 0);
		transform: translate3d(0, -40px, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(-40px, 0, 0);
		transform: translate3d(-40px, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(-40px, 0, 0);
		transform: translate3d(-40px, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(40px, 0, 0);
		transform: translate3d(40px, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(40px, 0, 0);
		transform: translate3d(40px, 0, 0);
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes slideInUp {
	0% {
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInUp {
	0% {
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.slideInUp {
	-webkit-animation-name: slideInUp;
	animation-name: slideInUp;
}

@-webkit-keyframes slideInDown {
	0% {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInDown {
	0% {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.slideInDown {
	-webkit-animation-name: slideInDown;
	animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
	0% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInLeft {
	0% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.slideInLeft {
	-webkit-animation-name: slideInLeft;
	animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
	0% {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInRight {
	0% {
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.slideInRight {
	-webkit-animation-name: slideInRight;
	animation-name: slideInRight;
}

@-webkit-keyframes slideOutDown {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	100% {
		visibility: hidden;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
}

@keyframes slideOutDown {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	100% {
		visibility: hidden;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
}

.slideOutDown {
	-webkit-animation-name: slideOutDown;
	animation-name: slideOutDown;
}

@keyframes pagInX {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes pagOutX {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(100%);
	}
}

/*
*
* Preloader
*/
.preloader {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	background: #ffffff;
	transition: .3s all ease;
}

.preloader.loaded {
	opacity: 0;
	visibility: hidden;
}

.preloader-body {
	text-align: center;
}

.preloader-body p {
	position: relative;
	right: -8px;
}

.cssload-container {
	width: 100%;
	height: 36px;
	text-align: center;
}

.cssload-speeding-wheel {
	width: 36px;
	height: 36px;
	margin: 0 auto;
	border: 3px solid #01b3a7;
	border-radius: 50%;
	border-left-color: transparent;
	border-bottom-color: transparent;
	animation: cssload-spin .88s infinite linear;
}

@-webkit-keyframes cssload-spin {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes cssload-spin {
	100% {
		transform: rotate(360deg);
	}
}

/*
*
* ToTop
*/
.ui-to-top {
	position: fixed;
	right: 15px;
	bottom: 15px;
	z-index: 100;
	width: 40px;
	height: 40px;
	font-size: 20px;
	line-height: 38px;
	color: #ffffff;
	background: #01b3a7;
	overflow: hidden;
	text-align: center;
	text-decoration: none;
	transition: .45s all ease-in-out;
	transform: translate3d(0, 100px, 0);
}

.ui-to-top:hover {
	color: #ffffff;
	background: #111111;
	text-decoration: none;
}

.ui-to-top:focus {
	color: #ffffff;
}

.ui-to-top.active {
	transform: translate3d(0, 0, 0);
}

html.mobile .ui-to-top,
html.tablet .ui-to-top {
	display: none !important;
}

@media (min-width: 768px) {
	.ui-to-top {
		width: 50px;
		height: 50px;
		line-height: 46px;
		right: 30px;
		bottom: 30px;
	}
}

@media (min-width: 1600px) {
	.ui-to-top {
		right: 12px;
	}
}

/*
*
* RD Navbar
*/
@keyframes rd-navbar-slide-down {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes rd-navbar-slide-up {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-100%);
	}
}

.rd-navbar-wrap, .rd-navbar,
.rd-menu,
.rd-navbar-nav,
.rd-navbar-panel, .rd-navbar-static .rd-menu, .rd-navbar-fixed .rd-navbar-nav-wrap, .rd-navbar-fixed .rd-navbar-submenu, .rd-navbar-project {
	transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
}

.rd-navbar--no-transition, .rd-navbar--no-transition * {
	transition: none !important;
}

.rd-navbar,
.rd-navbar.rd-navbar--is-clone {
	display: none;
}

.rd-navbar.rd-navbar-fixed + .rd-navbar.rd-navbar--is-clone,
.rd-navbar.rd-navbar-sidebar + .rd-navbar.rd-navbar--is-clone {
	display: none;
}

.rd-navbar {
	display: none;
	background: #ffffff;
}

.rd-navbar-toggle {
	display: inline-block;
	position: relative;
	width: 48px;
	height: 48px;
	line-height: 48px;
	cursor: pointer;
	color: #151515;
	background-color: transparent;
	border: none;
	display: none;
}

.rd-navbar-toggle span {
	position: relative;
	display: block;
	margin: auto;
	transition: .3s all ease;
}

.rd-navbar-toggle span:after, .rd-navbar-toggle span:before {
	content: "";
	position: absolute;
	left: 0;
	top: -10px;
	transition: .3s all ease;
}

.rd-navbar-toggle span:after {
	top: 10px;
}

.rd-navbar-toggle span:after, .rd-navbar-toggle span:before, .rd-navbar-toggle span {
	width: 24px;
	height: 3px;
	background-color: #151515;
	backface-visibility: hidden;
	border-radius: 0;
}

.rd-navbar-toggle span {
	transform: rotate(180deg);
}

.rd-navbar-toggle span:before, .rd-navbar-toggle span:after {
	transform-origin: 1.71429px center;
}

.rd-navbar-toggle.active span {
	transform: rotate(360deg);
}

.rd-navbar-toggle.active span:before, .rd-navbar-toggle.active span:after {
	top: 0;
	width: 15px;
}

.rd-navbar-toggle.active span:before {
	-webkit-transform: rotate3d(0, 0, 1, -40deg);
	transform: rotate3d(0, 0, 1, -40deg);
}

.rd-navbar-toggle.active span:after {
	-webkit-transform: rotate3d(0, 0, 1, 40deg);
	transform: rotate3d(0, 0, 1, 40deg);
}

.rd-navbar-collapse-toggle {
	display: inline-block;
	position: relative;
	width: 48px;
	height: 48px;
	line-height: 48px;
	cursor: pointer;
	color: #151515;
	display: none;
}

.rd-navbar-collapse-toggle span {
	top: 50%;
	margin-top: -3px;
}

.rd-navbar-collapse-toggle span, .rd-navbar-collapse-toggle span:before, .rd-navbar-collapse-toggle span:after {
	position: absolute;
	width: 6px;
	height: 6px;
	line-height: 6px;
	text-align: center;
	background: #151515;
	left: 50%;
	margin-left: -3px;
	border-radius: 50%;
	transition: .3s all ease;
}

.rd-navbar-collapse-toggle span:before, .rd-navbar-collapse-toggle span:after {
	content: '';
}

.rd-navbar-collapse-toggle span:before {
	bottom: 100%;
	margin-bottom: 3px;
}

.rd-navbar-collapse-toggle span:after {
	top: 100%;
	margin-top: 3px;
}

.rd-navbar-collapse-toggle.active span {
	transform: scale(0.7);
}

.rd-navbar-collapse-toggle.active span:before {
	transform: translateY(18px);
}

.rd-navbar-collapse-toggle.active span:after {
	transform: translateY(-18px);
}

.rd-navbar-brand a {
	display: block;
}

.rd-navbar-search {
	position: relative;
	display: inline-flex;
}

.rd-navbar-search .form-label {
	top: 25px;
	padding-left: 28px;
	padding-right: 50px;
	font-size: 16px;
	letter-spacing: 0;
	color: #01b3a7;
}

.rd-navbar-search .form-input {
	min-height: 47px;
	padding: 12px 50px 12px 28px;
	font-size: 16px;
	line-height: 23px;
	letter-spacing: 0;
	border: none;
	border-radius: 30px;
	background: #ffffff;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
}

.rd-navbar-search .rd-search-form-submit {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 6px;
	width: 50px;
	padding: 0;
	margin: 0;
	font-size: 24px;
	line-height: 1;
	border: none;
	cursor: pointer;
	background-color: transparent;
	color: #151515;
	transition: .33s;
}

.rd-navbar-search .rd-search-form-submit:hover {
	color: #01b3a7;
}

.rd-navbar-search-toggle {
	background: none;
	border: none;
	display: inline-block;
	padding: 0;
	outline: none;
	outline-offset: 0;
	cursor: pointer;
	-webkit-appearance: none;
}

.rd-navbar-search-toggle::-moz-focus-inner {
	border: none;
	padding: 0;
}

.rd-navbar-dropdown {
	display: none;
}

/*
* @subsection  RD Navbar Static
*/
.rd-navbar-static {
	display: block;
}

.rd-navbar-static .rd-nav-item {
	display: inline-block;
}

.rd-navbar-static .rd-nav-item.focus .rd-nav-link, .rd-navbar-static .rd-nav-item.opened .rd-nav-link {
	color: #01b3a7;
	background: transparent;
}

.rd-navbar-static .rd-nav-item.focus > .rd-navbar-submenu-toggle, .rd-navbar-static .rd-nav-item.opened > .rd-navbar-submenu-toggle {
	color: #01b3a7;
}

.rd-navbar-static .rd-nav-item.active .rd-nav-link {
	color: #01b3a7;
	background: transparent;
}

.rd-navbar-static .rd-nav-item.active > .rd-navbar-submenu-toggle {
	color: #01b3a7;
}

.rd-navbar-static .rd-nav-item.focus > .rd-navbar-submenu-toggle::before,
.rd-navbar-static .rd-nav-item.opened > .rd-navbar-submenu-toggle::before,
.rd-navbar-static .rd-nav-item .rd-nav-link:hover + .rd-navbar-submenu-toggle::before {
	transform: rotate(180deg);
}

.rd-navbar-static .rd-nav-item > .rd-navbar-submenu-toggle {
	display: none;
	margin-left: 4px;
	font-family: "Material Design Icons";
	font-size: 16px;
	cursor: pointer;
}

.rd-navbar-static .rd-nav-item > .rd-navbar-submenu-toggle::before {
	position: relative;
	display: inline-block;
	transition: .22s;
	content: '\f236';
}

.rd-navbar-static .rd-nav-item > .rd-navbar-submenu-toggle:hover {
	color: #01b3a7;
}

.rd-navbar-static .rd-nav-item > .rd-navbar-submenu {
	margin-top: 20px;
}

.rd-navbar-static .rd-nav-item + .rd-nav-item {
	margin-left: 42px;
}

.rd-navbar-static .rd-nav-link {
	position: relative;
	display: inline-block;
	font-size: 16px;
	line-height: 1.2;
	font-weight: 500;
	letter-spacing: 0;
	color: #151515;
	transition: .25s;
}

.rd-navbar-static .rd-nav-link:hover {
	color: #01b3a7;
}

.rd-navbar-static .rd-menu {
	z-index: 15;
	position: absolute;
	display: block;
	margin-top: 22px;
	visibility: hidden;
	opacity: 0;
	text-align: left;
	border: none;
	transform: translate3d(0, 30px, 0);
}

.rd-navbar-static .rd-navbar-dropdown {
	position: absolute;
	left: 0;
	padding: 22px 15px 20px 34px;
	width: 270px;
	background: #ffffff;
	z-index: 5;
}

.rd-navbar-static .rd-navbar-dropdown .rd-navbar-dropdown {
	top: -1px;
	left: 100%;
	z-index: 2;
	margin-top: -15px;
	margin-left: 15px;
	transform: translate3d(30px, 0, 0);
}

.rd-navbar-static .rd-navbar-dropdown .rd-navbar-dropdown.rd-navbar-open-left {
	left: auto;
	right: 100%;
	margin-left: 0;
	margin-right: 15px;
	transform: translate3d(-30px, 0, 0);
}

.rd-navbar-static .rd-dropdown-item + .rd-dropdown-item {
	margin-top: 8px;
}

.rd-navbar-static .rd-dropdown-item.focus .rd-dropdown-link, .rd-navbar-static .rd-dropdown-item.opened .rd-dropdown-link {
	color: #151515;
}

.rd-navbar-static .rd-dropdown-link {
	color: #151515;
}

.rd-navbar-static .rd-dropdown-link:hover {
	color: #151515;
}

.rd-navbar-static .rd-navbar-dropdown li > a,
.rd-navbar-static .rd-megamenu-list li > a {
	position: relative;
	left: -3px;
	display: flex;
	padding: 1px 14px 1px 0;
	text-align: left;
	transition: all .2s ease;
}

.rd-navbar-static .rd-navbar-dropdown li > a, .rd-navbar-static .rd-navbar-dropdown li > a:focus, .rd-navbar-static .rd-navbar-dropdown li > a:active,
.rd-navbar-static .rd-megamenu-list li > a,
.rd-navbar-static .rd-megamenu-list li > a:focus,
.rd-navbar-static .rd-megamenu-list li > a:active {
	color: #151515;
}

.rd-navbar-static .rd-navbar-dropdown li > a:hover,
.rd-navbar-static .rd-megamenu-list li > a:hover {
	color: #151515;
}

.rd-navbar-static .rd-navbar-dropdown li > a::before,
.rd-navbar-static .rd-megamenu-list li > a::before {
	display: inline-block;
	position: relative;
	content: '';
	top: 0;
	left: 0;
	width: 3px;
	height: 26px;
	font-size: 0;
	line-height: 0;
	background: #50ba87;
	transition: all .15s ease;
	visibility: hidden;
	opacity: 0;
}

.rd-navbar-static .rd-navbar-dropdown li.focus > a,
.rd-navbar-static .rd-navbar-dropdown li.opened > a,
.rd-navbar-static .rd-navbar-dropdown li > a:hover,
.rd-navbar-static .rd-megamenu-list li.focus > a,
.rd-navbar-static .rd-megamenu-list li.opened > a,
.rd-navbar-static .rd-megamenu-list li > a:hover {
	padding-left: 14px;
	padding-right: 0;
}

.rd-navbar-static .rd-navbar-dropdown li.focus > a::before,
.rd-navbar-static .rd-navbar-dropdown li.opened > a::before,
.rd-navbar-static .rd-navbar-dropdown li > a:hover::before,
.rd-navbar-static .rd-megamenu-list li.focus > a::before,
.rd-navbar-static .rd-megamenu-list li.opened > a::before,
.rd-navbar-static .rd-megamenu-list li > a:hover::before {
	transform: translateX(-14px) translate3d(0, 0, 0);
	visibility: visible;
	opacity: 1;
	transition-delay: .1s;
}

.rd-navbar-static .rd-navbar-dropdown > li > a,
.rd-navbar-static .rd-megamenu-list > li > a {
	font-size: 14px;
	line-height: 1.86;
	letter-spacing: .05em;
}

.rd-navbar-static .rd-megamenu-list {
	columns: 2;
	break-inside: avoid;
	-moz-column-gap: 30px;
	-webkit-column-gap: 30px;
	margin-bottom: -8px;
}

.rd-navbar-static .rd-megamenu-list li {
	display: inline-block;
	width: 100%;
	margin-bottom: 8px;
}

.rd-navbar-static .rd-navbar-megamenu {
	display: flex;
	left: 0;
	width: calc(100% + 1px);
	max-width: 1170px;
	padding: 40px 0;
	background: #ffffff;
}

@media (min-width: 1200px) {
	.rd-navbar-static .rd-navbar-megamenu {
		padding: 50px 0;
	}
}

.rd-navbar-static .rd-megamenu-item {
	flex-grow: 1;
	padding-left: 30px;
	padding-right: 30px;
}

.rd-navbar-static .rd-megamenu-item > div {
	max-width: 360px;
	margin-left: auto;
	margin-right: auto;
}

.rd-navbar-static .rd-megamenu-item + .rd-megamenu-item {
	position: relative;
}

.rd-navbar-static .rd-megamenu-item + .rd-megamenu-item::before {
	position: absolute;
	content: '';
	top: -12px;
	left: 0;
	bottom: -12px;
	width: 1px;
	background: #e1e1e1;
}

.rd-navbar-static .rd-megamenu-title {
	font-weight: 400;
	letter-spacing: .1em;
}

.rd-navbar-static .rd-megamenu-carousel > div {
	max-width: 180px;
}

@media (min-width: 1200px) {
	.rd-navbar-static .rd-megamenu-carousel > div {
		max-width: 300px;
	}
}

.rd-navbar-static .rd-megamenu-list-link {
	color: #000000;
	background: transparent;
}

.rd-navbar-static .rd-megamenu-list-link:hover {
	color: #01b3a7;
	background: transparent;
}

.rd-navbar-static * + .rd-megamenu-list {
	margin-top: 20px;
}

.rd-navbar-static .rd-navbar-submenu.focus > .rd-menu, .rd-navbar-static .rd-navbar-submenu.opened > .rd-menu {
	opacity: 1;
	visibility: visible;
	transform: translate3d(0, 0, 0);
}

.rd-navbar-static .rd-navbar-search.active .rd-search {
	opacity: 1;
	visibility: visible;
}

.rd-navbar-static .rd-search {
	position: absolute;
	top: 50%;
	right: calc(100% + 5px);
	width: 270px;
	margin-top: -23.5px;
	opacity: 0;
	visibility: hidden;
	transition: .3s;
	z-index: 2;
}

.rd-navbar-static * + .rd-navbar-search {
	margin-left: 8px;
}

.rd-navbar-static .rd-navbar-search-toggle {
	display: inline-flex;
	color: #151515;
}

.rd-navbar-static .rd-navbar-search-toggle span {
	display: inline-block;
	position: relative;
	width: 30px;
	height: 30px;
	font-size: 24px;
	line-height: 30px;
	text-align: center;
	cursor: pointer;
	background: none;
	border: none;
	outline: none;
	padding: 0;
}

.rd-navbar-static .rd-navbar-search-toggle span::before, .rd-navbar-static .rd-navbar-search-toggle span::after {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all .3s ease;
}

.rd-navbar-static .rd-navbar-search-toggle span::before {
	content: "";
	transform: rotate(0deg) scale(1);
	opacity: 1;
	visibility: visible;
	font-family: "fl-bigmug-line";
}

.rd-navbar-static .rd-navbar-search-toggle span::after {
	content: "";
	transform: rotate(-90deg) scale(0.4);
	opacity: 0;
	visibility: hidden;
	font-family: "fl-bigmug-line";
}

.rd-navbar-static .rd-navbar-search-toggle.active span::before {
	opacity: 0;
	visibility: hidden;
	transform: rotate(90deg) scale(0.4);
}

.rd-navbar-static .rd-navbar-search-toggle.active span::after {
	transform: rotate(0deg) scale(1);
	opacity: 1;
	visibility: visible;
}

.rd-navbar-static .rd-navbar-search-toggle:hover {
	color: #01b3a7;
}

.rd-navbar-static.rd-navbar--is-clone {
	display: block;
	transform: translate3d(0, -100%, 0);
}

.rd-navbar-static.rd-navbar--is-clone.rd-navbar--is-stuck {
	transform: translate3d(0, 0, 0);
}

.rd-navbar-static.rd-navbar--is-stuck, .rd-navbar-static.rd-navbar--is-clone {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 1030;
	background: #ffffff;
}

.rd-navbar-static .rd-navbar--has-dropdown {
	position: relative;
}

.rd-navbar-static .rd-navbar-basket-wrap {
	display: block;
}

.rd-navbar-static .rd-navbar-basket-mobile {
	display: none;
}

.rd-navbar-static .rd-navbar-share-list {
	justify-content: flex-start;
	position: absolute;
	top: 50%;
	right: 0;
	padding-top: 4px;
	padding-bottom: 4px;
	background: transparent;
	transform: translateY(-50%);
	pointer-events: none;
	transition: all .2s ease .1s;
}

.rd-navbar-static .rd-navbar-share-list-item {
	opacity: 0;
	visibility: hidden;
	transform: translateX(20px);
	will-change: transform;
	transition: all .3s ease;
}

.rd-navbar-static .rd-navbar-share-list.active {
	pointer-events: auto;
	background: #ffffff;
	transition-delay: 0s;
}

.rd-navbar-static .rd-navbar-share-list.active .rd-navbar-share-list-item {
	opacity: 1;
	transform: none;
	visibility: visible;
}

.rd-navbar-static .rd-navbar-share-list.active li:nth-child(2) {
	transition-delay: 100ms;
}

.rd-navbar-static .rd-navbar-share-list.active li:nth-child(3) {
	transition-delay: 150ms;
}

.rd-navbar-static .rd-navbar-share-list.active li:nth-child(4) {
	transition-delay: 200ms;
}

.rd-navbar-static .rd-navbar-share-list.active li:nth-child(5) {
	transition-delay: 250ms;
}

.rd-navbar-static .rd-navbar-share-list.active li:nth-child(6) {
	transition-delay: 300ms;
}

.rd-navbar-static .rd-navbar-share-list.active li:nth-child(7) {
	transition-delay: 350ms;
}

.rd-navbar-static .rd-navbar-share-list.active li:nth-child(8) {
	transition-delay: 400ms;
}

.rd-navbar-static .rd-navbar-share-list.active li:nth-child(9) {
	transition-delay: 450ms;
}

.rd-navbar-static .rd-navbar-share-list.active li:nth-child(10) {
	transition-delay: 500ms;
}

.rd-navbar-static * + .rd-navbar-share {
	margin-left: 12px;
}

.rd-navbar-basket {
	position: relative;
	display: inline-block;
	padding-right: 14px;
	font-size: 24px;
	line-height: 1;
	color: #151515;
	outline: none;
	border: none;
	box-shadow: none;
	background: none;
	cursor: pointer;
	transition: all .3s ease-in-out;
}

.rd-navbar-basket span {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 14px;
	font-family: "Maven Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	font-weight: 500;
	color: #50ba87;
}

.rd-navbar-basket:hover {
	color: #01b3a7;
}

.rd-navbar-basket-wrap {
	position: relative;
	display: none;
}

.cart-inline {
	position: absolute;
	text-align: left;
	top: calc(100% + 30px);
	right: -18px;
	min-width: 400px;
	margin-top: 30px;
	background: #ffffff;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.17));
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: all .3s ease-in-out;
	z-index: 999999;
}

.cart-inline::before {
	position: absolute;
	content: '';
	bottom: calc(100% - 10px);
	right: 40px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 0 34px 34px;
	border-color: transparent transparent #ffffff transparent;
}

.cart-inline.active {
	margin-top: 0;
	opacity: 1;
	visibility: visible;
	pointer-events: auto;
}

.cart-inline .stepper input[type="number"] {
	min-height: 40px;
	padding-top: 0;
	padding-bottom: 0;
}

.cart-inline-header {
	padding: 20px;
}

.cart-inline-body {
	padding: 20px;
	border-top: 1px solid #e1e1e1;
}

.cart-inline-footer {
	padding: 20px;
	border-top: 1px solid #e1e1e1;
}

.cart-inline-title {
	font-weight: 400;
}

.cart-inline-figure {
	display: block;
	background: #f4f4f4;
}

.cart-inline-figure img {
	width: 100%;
}

.cart-inline-name + * {
	margin-top: 10px;
}

* + .cart-inline-title {
	margin-top: 0;
}

.cart-inline-title + .cart-inline-title {
	margin-top: 10px;
}

.cart-inline-item + .cart-inline-item {
	margin-top: 15px;
}

.ie-10 .cart-inline,
.ie-11 .cart-inline,
.ie-edge .cart-inline {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
}

.ie-10 .cart-inline::before,
.ie-11 .cart-inline::before,
.ie-edge .cart-inline::before {
	bottom: 100%;
	border-width: 0 0 24px 24px;
	z-index: 1;
}

.ie-10 .cart-inline::after,
.ie-11 .cart-inline::after,
.ie-edge .cart-inline::after {
	position: absolute;
	content: '';
	bottom: calc(100% + 2px);
	right: 38px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 0 28px 28px;
	border-color: transparent transparent rgba(0, 0, 0, 0.04) transparent;
	z-index: 0;
}

.rd-navbar-share {
	position: relative;
	font-size: 24px;
	line-height: 1;
	cursor: pointer;
}

.rd-navbar-share-list {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	margin-left: -9px;
	margin-right: -9px;
}

.rd-navbar-share-list > * {
	padding-left: 9px;
	padding-right: 9px;
}

.rd-navbar-share-list-item a, .rd-navbar-share-list-item a:focus, .rd-navbar-share-list-item a:active {
	color: #151515;
}

.rd-navbar-share-list-item a:hover {
	color: #50ba87;
}

.rd-navbar-share-list-item .icon {
	font-size: 20px;
}

.rd-navbar-share:hover {
	color: #01b3a7;
}

.rd-navbar-project-hamburger {
	cursor: pointer;
	z-index: 1031;
}

.project-hamburger {
	display: flex;
	align-self: center;
	flex-direction: column;
	justify-content: space-between;
	height: 23px;
}

.project-hamburger > span {
	display: inline-block;
	align-self: flex-end;
	height: 3px;
	background: #151515;
}

.project-hamburger-arrow-top {
	width: 100%;
	transition: opacity .15s ease-in-out .3s;
}

.project-hamburger-arrow-center {
	width: 80%;
	transition: all .16s ease-in-out, opacity .15s ease-in-out .4s;
}

.project-hamburger-arrow-bottom {
	width: 60%;
	transition: all .32s ease-in-out, opacity .15s ease-in-out .5s;
}

.project-hamburger:hover > span {
	width: 100%;
}

.project-hamburger:hover .project-hamburger-arrow-top {
	animation: burger-hover .8s infinite ease-in-out alternate;
}

.project-hamburger:hover .project-hamburger-arrow-center {
	animation: burger-hover .8s infinite ease-in-out alternate forwards .16s;
}

.project-hamburger:hover .project-hamburger-arrow-bottom {
	animation: burger-hover .8s infinite ease-in-out alternate forwards .32s;
}

@keyframes burger-hover {
	0% {
		width: 100%;
	}
	50% {
		width: 50%;
		background: #50ba87;
	}
	100% {
		width: 100%;
	}
}

@keyframes burger-hover-primary {
	0% {
		width: 100%;
	}
	50% {
		width: 50%;
		background: #01b3a7;
	}
	100% {
		width: 100%;
	}
}

.project-close {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	transform: rotate(45deg);
	z-index: -1;
}

.project-close span {
	position: absolute;
	background: #151515;
	transition: all .15s ease-in-out;
}

.project-close span:nth-child(1) {
	height: 0;
	width: 2px;
	top: 10%;
	left: 50%;
	margin-left: -1px;
	transition-delay: 0s;
}

.project-close span:nth-child(2) {
	width: 0;
	height: 2px;
	left: 10%;
	top: 50%;
	margin-top: -1px;
	transition-delay: .15s;
}

.project-hamburger-2 {
	display: flex;
	overflow: hidden;
	align-self: center;
	flex-direction: column;
	justify-content: space-between;
	height: 23px;
}

.project-hamburger-2 > span {
	position: relative;
	display: inline-block;
	align-self: flex-end;
	height: 2px;
	width: 100%;
	background: #151515;
	transition: width .2s ease;
}

.project-hamburger-2 > span::after {
	position: absolute;
	display: inline-block;
	content: '';
	top: 0;
	left: 0;
	height: inherit;
	width: inherit;
	background: #50ba87;
	transform: translateX(-100%);
	animation: pagOutX .3s;
}

.project-hamburger-2:hover > span::after {
	animation: pagInX .3s forwards;
}

.project-hamburger-2:hover > span:nth-child(2)::after {
	animation-delay: .1s;
}

.project-hamburger-2:hover > span:nth-child(3)::after {
	animation-delay: .2s;
}

.project-hamburger-3 {
	display: flex;
	overflow: hidden;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	height: 16px;
}

.project-hamburger-3 > span {
	position: relative;
	display: inline-block;
	height: 2px;
	width: 100%;
	background: #ffffff;
	transition: width .2s ease .3s;
}

.rd-navbar-project-hamburger.active .project-hamburger > span {
	opacity: 0;
}

.rd-navbar-project-hamburger.active .project-hamburger .project-hamburger-arrow-top {
	transition-delay: 0s;
}

.rd-navbar-project-hamburger.active .project-hamburger .project-hamburger-arrow-center {
	transition-delay: .1s;
}

.rd-navbar-project-hamburger.active .project-hamburger .project-hamburger-arrow-bottom {
	transition-delay: .2s;
}

.rd-navbar-project-hamburger.active .project-hamburger-3 .project-hamburger-arrow {
	width: 0;
	transition-delay: 0s;
}

.rd-navbar-project-hamburger.active .project-close > span:nth-child(1) {
	height: 80%;
	transition-delay: .3s;
}

.rd-navbar-project-hamburger.active .project-close > span:nth-child(2) {
	width: 80%;
	transition-delay: .45s;
}

/*
*
* RD Navbar Fixed
*/
.rd-navbar-fixed {
	display: block;
}

.rd-navbar-fixed .rd-navbar-toggle {
	display: inline-block;
}

.rd-navbar-fixed .rd-navbar-brand {
	position: relative;
	margin-left: 6px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: left;
	font-size: 22px;
	line-height: 46px;
}

.rd-navbar-fixed .rd-navbar-brand img {
	max-width: 120px;
	max-height: 58px;
	width: auto;
	height: auto;
}

@media (min-width: 576px) {
	.rd-navbar-fixed .rd-navbar-brand img {
		max-width: 200px;
	}
}

.rd-navbar-fixed .rd-navbar-panel {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 1030;
	display: flex;
	align-items: center;
	height: 56px;
	padding: 4px;
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
	position: fixed;
	left: 0;
	top: -56px;
	bottom: -56px;
	z-index: 1029;
	width: 270px;
	padding: 140px 0 81px;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	font-size: 14px;
	line-height: 34px;
	transform: translateX(-110%);
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar {
	width: 4px;
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-thumb {
	background: rgba(80, 186, 135, 0.5);
	border: none;
	border-radius: 0;
	opacity: .2;
}

.rd-navbar-fixed .rd-navbar-nav-wrap::-webkit-scrollbar-track {
	background: #e1e1e1;
	border: none;
	border-radius: 0;
}

.rd-navbar-fixed .rd-navbar-nav-wrap.active {
	transform: translateX(0);
}

.rd-navbar-fixed .rd-navbar-nav {
	display: block;
	padding-top: 20px;
	margin: 30px 0 20px;
	height: auto;
	text-align: left;
	border-top: 1px solid #e1e1e1;
}

.rd-navbar-fixed .rd-nav-item {
	text-align: left;
}

.rd-navbar-fixed .rd-nav-item + .rd-nav-item {
	margin-top: 4px;
}

.rd-navbar-fixed .rd-nav-link {
	display: block;
	padding: 9px 56px 9px 18px;
}

.rd-navbar-fixed li.opened > .rd-navbar-dropdown {
	padding: 4px 0 0;
}

.rd-navbar-fixed li.opened > .rd-navbar-megamenu {
	padding-top: 15px;
	padding-bottom: 0;
}

.rd-navbar-fixed li.opened > .rd-menu {
	opacity: 1;
	height: auto;
}

.rd-navbar-fixed li.opened > .rd-navbar-submenu-toggle::after {
	transform: rotate(180deg);
}

.rd-navbar-fixed .rd-menu {
	display: none;
	transition: opacity 0.3s, height 0.4s ease;
	opacity: 0;
	height: 0;
	overflow: hidden;
}

.rd-navbar-fixed .rd-navbar-submenu {
	position: relative;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown .rd-navbar-submenu-toggle:after {
	height: 34px;
	line-height: 34px;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown > li > a {
	padding-left: 30px;
}

.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-dropdown li li > a,
.rd-navbar-fixed .rd-navbar-submenu .rd-navbar-megamenu ul li li > a {
	padding-left: 46px;
}

.rd-navbar-fixed .rd-navbar-submenu.opened > .rd-navbar-dropdown,
.rd-navbar-fixed .rd-navbar-submenu.opened > .rd-navbar-megamenu {
	display: block;
}

.rd-navbar-fixed .rd-megamenu-list > li > a,
.rd-navbar-fixed .rd-navbar-dropdown > li > a {
	display: block;
	padding: 9px 56px 9px 16px;
	font-size: 12px;
	line-height: 1.5;
}

.rd-navbar-fixed .rd-megamenu-list > li + li,
.rd-navbar-fixed .rd-navbar-dropdown > li + li {
	margin-top: 3px;
}

.rd-navbar-fixed .rd-megamenu-list > li > a {
	padding-left: 30px;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title {
	position: relative;
	display: block;
	padding: 0 18px 7px;
	font-size: 18px;
	line-height: 1.5;
	letter-spacing: .1em;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title::after {
	content: '';
	position: absolute;
	left: 20px;
	right: 20px;
	bottom: 0;
	border-bottom: 1px solid;
}

.rd-navbar-fixed .rd-navbar-megamenu * + .rd-megamenu-list {
	margin-top: 11px;
}

.rd-navbar-fixed .rd-navbar-megamenu * + .rd-megamenu-title {
	margin-top: 20px;
}

.rd-navbar-fixed .rd-navbar-megamenu > li + li {
	margin-top: 20px;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle {
	cursor: pointer;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle::after {
	content: '\f107';
	position: absolute;
	top: 0;
	right: 0;
	width: 56px;
	height: 52px;
	font: 400 16px "FontAwesome";
	line-height: 52px;
	text-align: center;
	transition: 0.4s all ease;
	z-index: 2;
	cursor: pointer;
}

.rd-navbar-fixed .rd-navbar-collapse-toggle {
	display: block;
	top: 4px;
	z-index: 1031;
}

.rd-navbar-fixed .rd-navbar-collapse {
	position: fixed;
	right: 0;
	top: 56px;
	z-index: 1029;
	transform: translate3d(0, 30px, 0);
	padding: 20px;
	width: auto;
	max-width: 270px;
	border-radius: 0;
	text-align: left;
	font-size: 14px;
	opacity: 0;
	visibility: hidden;
	transition: .3s;
}

.rd-navbar-fixed .rd-navbar-collapse.active {
	transform: translate3d(0, 0, 0);
	opacity: 1;
	visibility: visible;
}

.rd-navbar-fixed .rd-navbar-main-element {
	position: absolute;
	float: left;
}

.rd-navbar-fixed .rd-navbar-search {
	display: block;
	width: calc(100% - 30px);
	margin-left: auto;
	margin-right: auto;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-results-live {
	display: none;
}

.rd-navbar-fixed .rd-navbar-search .form-input {
	padding-right: 50px;
	padding-left: 20px;
	border-radius: 0;
}

.rd-navbar-fixed .rd-navbar-search .form-label {
	padding-left: 20px;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	width: 50px;
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
}

.rd-navbar-fixed .rd-navbar-search.active .rd-search {
	opacity: 1;
	visibility: visible;
	transform: none;
}

.rd-navbar-fixed .rd-navbar-share {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100% - 30px);
	margin-left: auto;
	margin-right: auto;
}

.rd-navbar-fixed .rd-navbar-share::before {
	display: none;
}

.rd-navbar-fixed * + .rd-navbar-share {
	margin-top: 20px;
}

.rd-navbar-fixed .rd-navbar-search-toggle {
	display: none;
}

.rd-navbar-fixed .rd-navbar-search-toggle span {
	display: inline-block;
	position: relative;
	width: 48px;
	height: 48px;
	font-size: 24px;
	line-height: 48px;
	text-align: center;
	cursor: pointer;
	background: none;
	border: none;
	outline: none;
	padding: 0;
}

.rd-navbar-fixed .rd-navbar-search-toggle span::before, .rd-navbar-fixed .rd-navbar-search-toggle span::after {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all .3s ease;
}

.rd-navbar-fixed .rd-navbar-search-toggle span::before {
	content: "";
	transform: rotate(0deg) scale(1);
	opacity: 1;
	visibility: visible;
	font-family: "fl-bigmug-line";
}

.rd-navbar-fixed .rd-navbar-search-toggle span::after {
	content: "";
	transform: rotate(-90deg) scale(0.4);
	opacity: 0;
	visibility: hidden;
	font-family: "fl-bigmug-line";
}

.rd-navbar-fixed .rd-navbar-search-toggle.active span::before {
	opacity: 0;
	visibility: hidden;
	transform: rotate(90deg) scale(0.4);
}

.rd-navbar-fixed .rd-navbar-search-toggle.active span::after {
	transform: rotate(0deg) scale(1);
	opacity: 1;
	visibility: visible;
}

.rd-navbar-fixed [class*='rd-navbar-fixed-element'] {
	position: fixed;
	top: 4px;
	z-index: 1032;
}

.rd-navbar-fixed .rd-navbar-fixed-element-1 {
	right: 0;
}

.rd-navbar-fixed .rd-navbar-fixed-element-2 {
	right: 52px;
}

.rd-navbar-fixed .rd-navbar-fixed-element-3 {
	right: 100px;
}

.rd-navbar-fixed.rd-navbar--is-clone {
	display: none;
}

.rd-navbar-fixed .rd-navbar-fixed--visible {
	display: block;
}

.rd-navbar-fixed .rd-navbar-fixed--hidden {
	display: none;
}

.rd-navbar-fixed .rd-megamenu-carousel {
	display: none;
}

.rd-navbar-fixed .rd-navbar-panel {
	color: #151515;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	background: #ffffff;
}

.rd-navbar-fixed .rd-navbar-nav-wrap {
	color: #ffffff;
	background: #ffffff;
	border: 1px solid #d7d7d7;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.rd-navbar-fixed .rd-nav-item:hover .rd-nav-link, .rd-navbar-fixed .rd-nav-item.focus .rd-nav-link, .rd-navbar-fixed .rd-nav-item.active .rd-nav-link, .rd-navbar-fixed .rd-nav-item.opened .rd-nav-link {
	color: #ffffff;
	background: #01b3a7;
}

.rd-navbar-fixed .rd-nav-item:hover > .rd-navbar-submenu-toggle, .rd-navbar-fixed .rd-nav-item.focus > .rd-navbar-submenu-toggle, .rd-navbar-fixed .rd-nav-item.active > .rd-navbar-submenu-toggle, .rd-navbar-fixed .rd-nav-item.opened > .rd-navbar-submenu-toggle {
	color: #ffffff;
}

.rd-navbar-fixed .rd-nav-link {
	color: #151515;
}

.rd-navbar-fixed .rd-megamenu-list > li > a,
.rd-navbar-fixed .rd-navbar-dropdown > li > a {
	color: #151515;
	font-size: 14px;
	line-height: 1.86;
	letter-spacing: .05em;
}

.rd-navbar-fixed .rd-megamenu-list > li:hover > a,
.rd-navbar-fixed .rd-megamenu-list > li.focus > a,
.rd-navbar-fixed .rd-megamenu-list > li.active > a,
.rd-navbar-fixed .rd-megamenu-list > li.opened > a,
.rd-navbar-fixed .rd-navbar-dropdown > li:hover > a,
.rd-navbar-fixed .rd-navbar-dropdown > li.focus > a,
.rd-navbar-fixed .rd-navbar-dropdown > li.active > a,
.rd-navbar-fixed .rd-navbar-dropdown > li.opened > a {
	color: #ffffff;
	background: #01b3a7;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title {
	color: #50ba87;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title::after {
	border-bottom: 1px solid #d7d7d7;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a, .rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:focus, .rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:active {
	color: #ffffff;
}

.rd-navbar-fixed .rd-navbar-megamenu .rd-megamenu-title a:hover {
	color: #01b3a7;
}

.rd-navbar-fixed .rd-navbar-submenu-toggle {
	color: #151515;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit {
	color: #151515;
}

.rd-navbar-fixed .rd-navbar-search .rd-search-form-submit:hover {
	color: #01b3a7;
}

.rd-navbar-fixed .rd-navbar-search-toggle {
	color: #151515;
}

.rd-navbar-fixed .rd-navbar-search-toggle:hover {
	color: #01b3a7;
}

.rd-navbar-fixed .rd-navbar-collapse {
	background-color: #ffffff;
	box-shadow: 0 0 22px -4px rgba(0, 0, 0, 0.17);
}

.rd-navbar-fixed .rd-navbar-collapse {
	color: #151515;
}

html.rd-navbar-fixed-linked .page {
	padding-top: 56px;
}

/*
*
* RD Navbar Sidebar
*/
.rd-navbar-sidebar {
	display: block;
	background: #ffffff;
}

.rd-navbar-sidebar .rd-navbar-main-outer {
	padding-left: 15px;
	padding-right: 15px;
}

.rd-navbar-sidebar .rd-navbar-main {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 22px 60px 22px 0;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.rd-navbar-sidebar .rd-navbar-main-element {
	display: flex;
	align-items: center;
}

.rd-navbar-sidebar .rd-navbar-main-element > * + * {
	margin-left: 20px;
}

.rd-navbar-sidebar .rd-navbar-toggle {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	display: block;
	z-index: 1102;
}

.rd-navbar-sidebar .rd-navbar-panel {
	min-width: 200px;
	text-align: center;
}

.rd-navbar-sidebar .rd-navbar-brand img {
	width: auto;
	height: auto;
	max-width: 200px;
	max-height: 200px;
}

.rd-navbar-sidebar .rd-navbar-nav-wrap {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 1101;
	width: 450px;
	padding: 94px 0 0 0;
	background: #ffffff;
	transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1);
	transform: translateX(100%);
	box-shadow: 0 0 13px 0 rgba(204, 204, 204, 0.16);
}

.rd-navbar-sidebar .rd-navbar-nav-wrap.active {
	transition-delay: .1s;
	transform: translateX(0);
}

.rd-navbar-sidebar .rd-navbar-nav {
	height: calc(100vh - 100px);
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	border-top: 1px solid #d7d7d7;
}

.rd-navbar-sidebar .rd-nav-item {
	position: relative;
	display: block;
	padding: 19px 30px 19px 40px;
}

.rd-navbar-sidebar .rd-nav-item.active .rd-nav-link {
	color: #01b3a7;
}

.rd-navbar-sidebar .rd-nav-item .rd-nav-link,
.rd-navbar-sidebar .rd-nav-item > .rd-navbar-submenu-toggle {
	font-size: 18px;
}

.rd-navbar-sidebar .rd-nav-item + .rd-nav-item {
	border-top: 1px solid #d7d7d7;
}

.rd-navbar-sidebar .rd-nav-item:last-child {
	border-bottom: 1px solid #d7d7d7;
	margin-bottom: 30px;
}

.rd-navbar-sidebar .rd-nav-link {
	max-width: calc(100% - 30px);
	word-break: break-all;
	font-size: 18px;
	line-height: 1.4;
	text-transform: none;
	color: #cccccc;
}

.rd-navbar-sidebar .rd-nav-link:hover {
	color: #01b3a7;
}

.rd-navbar-sidebar .rd-navbar-submenu .opened > .rd-navbar-submenu-toggle::after {
	transform: rotate(180deg);
}

.rd-navbar-sidebar .rd-navbar-submenu > .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu > .rd-navbar-megamenu {
	display: none;
	opacity: 0;
	visibility: hidden;
	will-change: opacity, visibility;
	transition: opacity .2s;
}

.rd-navbar-sidebar .rd-navbar-submenu.opened > .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu.opened > .rd-navbar-megamenu {
	display: block;
	opacity: 1;
	visibility: visible;
}

.rd-navbar-sidebar .rd-navbar-submenu > .rd-navbar-submenu > .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu > .rd-navbar-submenu > .rd-navbar-megamenu {
	transform: translateY(30px);
}

.rd-navbar-sidebar .rd-navbar-submenu > .rd-navbar-submenu.opened > .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-navbar-submenu > .rd-navbar-submenu.opened > .rd-navbar-megamenu {
	transform: translateY(0);
}

.rd-navbar-sidebar .rd-navbar-submenu > .rd-navbar-submenu .rd-navbar-submenu > .rd-navbar-dropdown {
	transform: translateX(-20px);
}

.rd-navbar-sidebar .rd-navbar-submenu > .rd-navbar-submenu .rd-navbar-submenu.opened > .rd-navbar-dropdown {
	transform: translateX(0);
}

.rd-navbar-sidebar .rd-nav-item > .rd-navbar-submenu-toggle,
.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown > .rd-navbar-submenu-toggle {
	position: absolute;
	top: 0;
	right: 0;
	padding-top: inherit;
	padding-right: inherit;
	padding-left: 10px;
	margin-bottom: inherit;
	display: inline-block;
	width: 30px;
	margin-left: 5px;
	text-align: center;
	cursor: pointer;
	color: #151515;
}

.rd-navbar-sidebar .rd-nav-item > .rd-navbar-submenu-toggle:hover,
.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown > .rd-navbar-submenu-toggle:hover {
	color: #01b3a7;
}

.rd-navbar-sidebar .rd-nav-item > .rd-navbar-submenu-toggle::after,
.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown > .rd-navbar-submenu-toggle::after {
	content: '\f107';
	position: relative;
	display: inline-block;
	font-family: "FontAwesome";
	font-size: inherit;
	line-height: inherit;
	text-align: center;
	vertical-align: middle;
	transition: 0.4s all ease;
	z-index: 2;
	will-change: transform;
}

.rd-navbar-sidebar .rd-nav-item .rd-navbar--has-dropdown > .rd-navbar-submenu-toggle {
	font-size: 14px;
	color: #cccccc;
	margin-left: 7px;
}

.rd-navbar-sidebar .rd-menu {
	margin-top: 31px;
}

.rd-navbar-sidebar .rd-navbar-dropdown,
.rd-navbar-sidebar .rd-megamenu-list {
	font-size: 14px;
}

.rd-navbar-sidebar .rd-navbar-dropdown li > a,
.rd-navbar-sidebar .rd-megamenu-list li > a {
	position: relative;
	left: -3px;
	display: flex;
	padding: 1px 14px 1px 0;
	text-align: left;
	transition: all .2s ease;
}

.rd-navbar-sidebar .rd-navbar-dropdown li > a, .rd-navbar-sidebar .rd-navbar-dropdown li > a:focus, .rd-navbar-sidebar .rd-navbar-dropdown li > a:active,
.rd-navbar-sidebar .rd-megamenu-list li > a,
.rd-navbar-sidebar .rd-megamenu-list li > a:focus,
.rd-navbar-sidebar .rd-megamenu-list li > a:active {
	color: #151515;
}

.rd-navbar-sidebar .rd-navbar-dropdown li > a:hover,
.rd-navbar-sidebar .rd-megamenu-list li > a:hover {
	color: #151515;
}

.rd-navbar-sidebar .rd-navbar-dropdown li > a::before,
.rd-navbar-sidebar .rd-megamenu-list li > a::before {
	display: inline-block;
	position: relative;
	content: '';
	top: 0;
	left: 0;
	width: 3px;
	height: 26px;
	font-size: 0;
	line-height: 0;
	background: #50ba87;
	transition: all .15s ease;
	visibility: hidden;
	opacity: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown li.focus > a,
.rd-navbar-sidebar .rd-navbar-dropdown li.opened > a,
.rd-navbar-sidebar .rd-navbar-dropdown li > a:hover,
.rd-navbar-sidebar .rd-megamenu-list li.focus > a,
.rd-navbar-sidebar .rd-megamenu-list li.opened > a,
.rd-navbar-sidebar .rd-megamenu-list li > a:hover {
	padding-left: 14px;
	padding-right: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown li.focus > a::before,
.rd-navbar-sidebar .rd-navbar-dropdown li.opened > a::before,
.rd-navbar-sidebar .rd-navbar-dropdown li > a:hover::before,
.rd-navbar-sidebar .rd-megamenu-list li.focus > a::before,
.rd-navbar-sidebar .rd-megamenu-list li.opened > a::before,
.rd-navbar-sidebar .rd-megamenu-list li > a:hover::before {
	transform: translateX(-14px) translate3d(0, 0, 0);
	visibility: visible;
	opacity: 1;
	transition-delay: .1s;
}

.rd-navbar-sidebar .rd-navbar-megamenu {
	max-width: 450px;
	margin-bottom: -30px;
}

.rd-navbar-sidebar .rd-navbar-megamenu > * {
	margin-bottom: 30px;
}

.rd-navbar-sidebar .rd-navbar-megamenu > li {
	display: inline-block;
	vertical-align: top;
	width: 45%;
}

.rd-navbar-sidebar .rd-megamenu-title {
	display: none;
}

.rd-navbar-sidebar .rd-megamenu-list {
	margin-top: 0;
}

.rd-navbar-sidebar .rd-megamenu-list > li + li {
	margin-top: 10px;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown > a {
	padding-right: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown > a::before {
	display: none;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown > a:hover {
	padding-left: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown.focus > a {
	padding-left: 0;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown.opened > .rd-navbar-submenu-toggle {
	color: #01b3a7;
}

.rd-navbar-sidebar .rd-navbar-dropdown .rd-navbar--has-dropdown.opened > .rd-navbar-submenu-toggle::after {
	top: 1px;
}

.rd-navbar-sidebar .rd-navbar-dropdown > li + li {
	margin-top: 10px;
}

.rd-navbar-sidebar .rd-nav-item > .rd-navbar-dropdown {
	margin-top: 25px;
}

.rd-navbar-sidebar .rd-nav-item > .rd-navbar-dropdown .rd-navbar-dropdown {
	margin-top: 10px;
	padding-left: 15px;
}

.rd-navbar-sidebar .rd-nav-item > .rd-navbar-dropdown .rd-navbar-dropdown > li > a {
	font-size: 12px;
}

@media (min-width: 1200px) {
	.rd-navbar-sidebar .rd-navbar-megamenu {
		margin-bottom: -30px;
	}
	.rd-navbar-sidebar .rd-navbar-megamenu > * {
		margin-bottom: 30px;
	}
	.rd-navbar-sidebar .rd-navbar-dropdown > li + li,
	.rd-navbar-sidebar .rd-megamenu-list > li + li {
		margin-top: 18px;
	}
}

@media (min-width: 1200px) {
	.rd-navbar-sidebar .rd-navbar-nav-wrap {
		width: auto;
		left: calc(50% + 600px - 80px);
	}
}

.rd-navbar-sidebar.rd-navbar--is-clone {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	transform: translateY(-101%);
}

.rd-navbar-sidebar.rd-navbar--is-clone.rd-navbar--is-stuck {
	transform: translateY(0);
}

.rd-navbar-sidebar.rd-navbar--is-stuck {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1000;
	box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);
}

.rd-navbar-sidebar.rd-navbar--is-stuck .rd-navbar-main {
	padding-top: 11px;
	padding-bottom: 11px;
}

/**
*
* RD Navbar Classic
* =====================================================
*/
.rd-navbar-classic.rd-navbar-static .rd-navbar-main-outer {
	position: relative;
	padding-left: 15px;
	padding-right: 15px;
}

@media (min-width: 1600px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-main-outer {
		padding-left: 75px;
		padding-right: 75px;
	}
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	max-width: 1770px;
	margin-left: auto;
	margin-right: auto;
	padding: 16px 55px 14px 0;
	transition: padding .35s ease-in-out;
}

@media (min-width: 1200px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-main {
		padding-top: 24px;
		padding-right: 68px;
		padding-bottom: 20px;
	}
	.rd-navbar-classic.rd-navbar-static .rd-navbar-main.active {
		padding-right: 150px;
	}
}

@media (min-width: 1400px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-main {
		padding-top: 30px;
		padding-bottom: 26px;
	}
	.rd-navbar-classic.rd-navbar-static .rd-navbar-main.active {
		padding-right: 290px;
	}
}

@media (min-width: 1600px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-main.active {
		padding-right: 330px;
	}
}

@media (min-width: 1600px) and (max-width: 1937px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-main {
		position: relative;
	}
	.rd-navbar-classic.rd-navbar-static .rd-navbar-main .rd-navbar-project-hamburger {
		right: 0;
	}
}

@media (min-width: 1937px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-main, .rd-navbar-classic.rd-navbar-static .rd-navbar-main.active {
		padding-right: 0;
	}
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-nav-wrap {
	display: flex;
	align-items: center;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-nav {
	order: -1;
	margin-right: 46px;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-brand img {
	width: auto;
	height: auto;
	max-width: 140px;
	max-height: 140px;
}

@media (min-width: 1400px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-brand img {
		max-width: 200px;
		max-height: 200px;
	}
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-main-element {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-submenu.focus .rd-navbar-megamenu, .rd-navbar-classic.rd-navbar-static .rd-navbar-submenu.opened .rd-navbar-megamenu {
	transform: translate3d(-50%, 0, 0);
}

.rd-navbar-classic.rd-navbar-static .rd-menu {
	margin-top: 22px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 1200px) {
	.rd-navbar-classic.rd-navbar-static .rd-menu {
		margin-top: 28px;
	}
}

@media (min-width: 1400px) {
	.rd-navbar-classic.rd-navbar-static .rd-menu {
		margin-top: 34px;
	}
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-megamenu {
	left: 50%;
	max-width: 1770px;
	transform: translate3d(-50%, 30px, 0);
}

@media (min-width: 1400px) {
	.rd-navbar-classic.rd-navbar-static .rd-megamenu-carousel > div {
		max-width: 350px;
	}
}

@media (min-width: 1600px) {
	.rd-navbar-classic.rd-navbar-static .rd-megamenu-carousel > div {
		max-width: 440px;
	}
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck, .rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-main, .rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-main {
	padding-top: 11px;
	padding-bottom: 11px;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-classic-project, .rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-navbar-classic-project {
	padding-top: 80px;
}

.rd-navbar-classic.rd-navbar-static.rd-navbar--is-stuck .rd-menu, .rd-navbar-classic.rd-navbar-static.rd-navbar--is-clone .rd-menu {
	margin-top: 19px;
}

.rd-navbar-classic.rd-navbar-static .rd-nav-item.focus .rd-nav-link, .rd-navbar-classic.rd-navbar-static .rd-nav-item.opened .rd-nav-link, .rd-navbar-classic.rd-navbar-static .rd-nav-item.active .rd-nav-link {
	color: #151515;
	background: transparent;
}

.rd-navbar-classic.rd-navbar-static .rd-nav-item.focus .rd-nav-link::before, .rd-navbar-classic.rd-navbar-static .rd-nav-item.opened .rd-nav-link::before, .rd-navbar-classic.rd-navbar-static .rd-nav-item.active .rd-nav-link::before {
	opacity: 1;
	transform: none;
	visibility: visible;
}

.rd-navbar-classic.rd-navbar-static .rd-nav-item.focus .rd-nav-link > .rd-navbar-submenu-toggle, .rd-navbar-classic.rd-navbar-static .rd-nav-item.opened .rd-nav-link > .rd-navbar-submenu-toggle, .rd-navbar-classic.rd-navbar-static .rd-nav-item.active .rd-nav-link > .rd-navbar-submenu-toggle {
	color: #151515;
}

.rd-navbar-classic.rd-navbar-static .rd-nav-link::before {
	position: absolute;
	content: '';
	bottom: -8px;
	left: 0;
	height: 3px;
	width: 100%;
	background: #50ba87;
	opacity: 0;
	visibility: hidden;
	transform: translateY(5px);
	transition: all .2s ease;
}

.rd-navbar-classic.rd-navbar-static .rd-nav-link:hover {
	color: #151515;
}

.rd-navbar-classic.rd-navbar-static .rd-nav-link:hover::before {
	opacity: 1;
	transform: none;
	visibility: visible;
}

.rd-navbar-classic.rd-navbar-static .rd-nav-item + .rd-nav-item {
	margin-left: 22px;
}

@media (min-width: 1200px) {
	.rd-navbar-classic.rd-navbar-static .rd-nav-item + .rd-nav-item {
		margin-left: 35px;
	}
}

@media (min-width: 1400px) {
	.rd-navbar-classic.rd-navbar-static .rd-nav-item + .rd-nav-item {
		margin-left: 42px;
	}
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-search.active .rd-search {
	width: 650px;
}

@media (min-width: 1200px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-search.active .rd-search {
		width: 750px;
	}
}

@media (min-width: 1400px) {
	.rd-navbar-classic.rd-navbar-static .rd-navbar-search.active .rd-search {
		width: 850px;
	}
}

.rd-navbar-classic.rd-navbar-static .rd-navbar-project-hamburger {
	position: absolute;
	right: 15px;
	width: 35px;
	height: 35px;
	padding: 6px 0;
}

.rd-navbar-classic.rd-navbar-fixed .rd-navbar-project-hamburger {
	position: fixed;
	right: 4px;
	width: 48px;
	height: 48px;
	top: 4px;
	padding: 13px 6px 12px;
}

.rd-navbar-classic-2.rd-navbar-static .rd-nav-link::before {
	background: #01b3a7;
}

.rd-navbar-classic-2.rd-navbar-static .rd-navbar-dropdown li > a::before,
.rd-navbar-classic-2.rd-navbar-static .rd-megamenu-list li > a::before {
	background: #01b3a7;
}

.rd-navbar-classic-2 .rd-navbar-basket span {
	color: #01b3a7;
}

.rd-navbar-classic-2 .rd-navbar-share-list-item a:hover {
	color: #01b3a7;
}

.rd-navbar-classic-2 .project-hamburger:hover > * {
	animation-name: burger-hover-primary;
}

.rd-navbar-project {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	background: #ffffff;
	transform: translateX(102%);
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.37);
	z-index: 1030;
}

.rd-navbar-project-content {
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}

.rd-navbar-project-content::-webkit-scrollbar {
	width: 4px;
}

.rd-navbar-project-content::-webkit-scrollbar-thumb {
	background: rgba(80, 186, 135, 0.5);
	border: none;
	border-radius: 0;
}

.rd-navbar-project-content::-webkit-scrollbar-track {
	background: #e1e1e1;
	border: none;
	border-radius: 0;
}

.rd-navbar-main.active .rd-navbar-project {
	transform: translateX(0);
}

.rd-navbar-classic-project {
	padding: 75px 15px 15px;
	width: 270px;
}

.rd-navbar-classic-project-content {
	direction: rtl;
	padding-left: 6px;
	margin-left: -6px;
	height: calc(100vh - 170px);
}

.rd-navbar-classic-project-content .row > div + div {
	margin-top: 15px;
}

.link-instafeed {
	display: inline-block;
	font-size: 14px;
	letter-spacing: .025em;
	color: #151515;
}

.link-instafeed a, .link-instafeed a:focus, .link-instafeed a:active {
	color: inherit;
}

.link-instafeed a:hover {
	color: #50ba87;
}

@media (min-width: 576px) {
	.rd-navbar-classic-project {
		width: 285px;
	}
}

@media (min-width: 992px) {
	.rd-navbar-classic-project-content {
		height: calc(100vh - 205px);
	}
}

@media (min-width: 1200px) {
	.rd-navbar-classic-project {
		padding-top: 100px;
		padding-bottom: 20px;
	}
}

@media (min-width: 1600px) {
	.rd-navbar-classic-project {
		width: 375px;
		padding-left: 20px;
		padding-right: 20px;
	}
	.rd-navbar-classic-project-content .row > div + div {
		margin-top: 20px;
	}
	.link-instafeed {
		font-size: 16px;
	}
}

* + .rd-navbar-classic-project-content {
	margin-top: 20px;
}

/**
*
* RD Navbar Modern
* =====================================================
*/
@media (min-width: 1200px) {
	.rd-navbar-modern-wrap {
		position: absolute;
		top: 20px;
		left: 0;
		right: 0;
		z-index: 1030;
	}
}

@media (min-width: 1600px) {
	.rd-navbar-modern-wrap {
		top: 40px;
	}
}

@media (min-width: 1800px) {
	.rd-navbar-modern-wrap {
		top: 70px;
	}
}

.rd-navbar-modern.rd-navbar-static {
	background: transparent;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-main-outer {
	position: relative;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-main {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1170px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 30px;
	background: #ffffff;
	transition: padding .35s ease-in-out;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-main.active .rd-navbar-nav-wrap {
	margin-right: 0;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-nav-wrap {
	display: flex;
	align-items: center;
	margin-right: 16px;
	transition: all .3s ease;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-nav {
	display: flex;
	flex-direction: row;
	margin-right: 10px;
	font-size: 0;
	line-height: 0;
	order: -1;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-brand img {
	width: auto;
	height: auto;
	max-width: 140px;
	max-height: 140px;
}

@media (min-width: 1400px) {
	.rd-navbar-modern.rd-navbar-static .rd-navbar-brand img {
		max-width: 200px;
		max-height: 170px;
	}
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-main-element {
	display: flex;
	align-items: center;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-main-element .rd-navbar-project-hamburger.active {
	width: 0;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-submenu.focus .rd-navbar-megamenu, .rd-navbar-modern.rd-navbar-static .rd-navbar-submenu.opened .rd-navbar-megamenu {
	transform: translate3d(-50%, 0, 0);
}

.rd-navbar-modern.rd-navbar-static .rd-menu {
	margin-top: 0;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-megamenu {
	left: 50%;
	transform: translate3d(-50%, 30px, 0);
}

.rd-navbar-modern.rd-navbar-static::before {
	position: absolute;
	content: '';
	top: 0;
	right: 10%;
	bottom: 0;
	left: 10%;
	visibility: hidden;
	background: transparent;
	transition: all .3s ease;
}

.rd-navbar-modern.rd-navbar-static.rd-navbar--is-stuck, .rd-navbar-modern.rd-navbar-static.rd-navbar--is-clone {
	background: transparent;
	box-shadow: none;
}

.rd-navbar-modern.rd-navbar-static.rd-navbar--is-stuck::before, .rd-navbar-modern.rd-navbar-static.rd-navbar--is-clone::before {
	right: 0;
	left: 0;
	visibility: visible;
	background: #ffffff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.rd-navbar-modern.rd-navbar-static.rd-navbar--is-stuck .rd-nav-link, .rd-navbar-modern.rd-navbar-static.rd-navbar--is-clone .rd-nav-link {
	padding-top: 30px;
	padding-bottom: 25px;
}

.rd-navbar-modern.rd-navbar-static .rd-nav-item.focus .rd-nav-link, .rd-navbar-modern.rd-navbar-static .rd-nav-item.opened .rd-nav-link {
	color: #50ba87;
}

.rd-navbar-modern.rd-navbar-static .rd-nav-item.focus .rd-nav-link > .rd-navbar-submenu-toggle, .rd-navbar-modern.rd-navbar-static .rd-nav-item.opened .rd-nav-link > .rd-navbar-submenu-toggle {
	color: #50ba87;
}

.rd-navbar-modern.rd-navbar-static .rd-nav-item.active .rd-nav-link {
	color: #ffffff;
}

.rd-navbar-modern.rd-navbar-static .rd-nav-item.active .rd-nav-link::before {
	height: 100%;
	opacity: 1;
}

.rd-navbar-modern.rd-navbar-static .rd-nav-link {
	padding: 30px 16px 25px;
	font-size: 18px;
	letter-spacing: .05em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	z-index: 1;
}

.rd-navbar-modern.rd-navbar-static .rd-nav-link::before {
	display: inline-block;
	position: absolute;
	content: '';
	top: 50%;
	left: 0;
	width: 100%;
	height: 0;
	opacity: 0;
	background: #50ba87;
	transform: translate3d(0, -50%, 0);
	transition: all .3s ease;
	z-index: -1;
}

.rd-navbar-modern.rd-navbar-static .rd-nav-link:hover {
	color: #50ba87;
}

@media (min-width: 1200px) {
	.rd-navbar-modern.rd-navbar-static .rd-nav-link {
		padding: 38px 28px 33px;
	}
}

.rd-navbar-modern.rd-navbar-static .rd-nav-item + .rd-nav-item {
	margin-left: 0;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-search.active .rd-search .form-wrap {
	opacity: 1;
	max-width: 80%;
	transition-delay: .3s;
}

@media (min-width: 1200px) {
	.rd-navbar-modern.rd-navbar-static .rd-navbar-search.active .rd-search .form-wrap {
		max-width: 1000px;
	}
}

.rd-navbar-modern.rd-navbar-static .rd-search {
	position: fixed;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: auto;
	margin-top: 0;
	background: rgba(0, 0, 0, 0.9);
	transition: all .3s ease-in-out;
	z-index: 1090;
}

.rd-navbar-modern.rd-navbar-static .rd-search .form-wrap {
	margin-top: 20%;
	margin-left: auto;
	margin-right: auto;
	max-width: 200px;
	opacity: 0;
	transition: all .3s ease;
}

@media (min-width: 1600px) {
	.rd-navbar-modern.rd-navbar-static .rd-search .form-wrap {
		margin-top: 15%;
	}
}

.rd-navbar-modern.rd-navbar-static .form-label {
	top: 30px;
}

.rd-navbar-modern.rd-navbar-static .form-label,
.rd-navbar-modern.rd-navbar-static .rd-navbar-search-form-input {
	color: #ffffff;
	font-size: 24px;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-search-form-input {
	border-radius: 0;
	background: transparent;
	border-bottom: 1px solid #ffffff;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-search-toggle {
	position: relative;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-search-toggle.active {
	color: #ffffff;
	z-index: 1091;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-search-toggle.active:hover {
	color: rgba(255, 255, 255, 0.5);
}

.rd-navbar-modern.rd-navbar-static .rd-search-results-live {
	min-height: 400px;
}

.rd-navbar-modern.rd-navbar-static .rd-search-results-live .search-quick-result {
	transform: translate3d(30px, 0, 0);
	visibility: hidden;
	opacity: 0;
}

.rd-navbar-modern.rd-navbar-static .rd-search-results-live .search-list > li {
	transform: translate3d(0, 40px, 0);
	visibility: hidden;
	opacity: 0;
}

.rd-navbar-modern.rd-navbar-static .rd-search-form-submit {
	color: #ffffff;
}

.rd-navbar-modern.rd-navbar-static .rd-search-form-submit:hover {
	color: rgba(255, 255, 255, 0.5);
}

.rd-navbar-modern.rd-navbar-static .search-list {
	display: flex;
	flex-wrap: wrap;
}

.rd-navbar-modern.rd-navbar-static .search-list > li {
	transition: all .5s ease;
}

.rd-navbar-modern.rd-navbar-static .search-list > li:not(.search-list-item-all) {
	flex: 0 0 50%;
	max-width: 50%;
	margin-top: 20px;
}

.rd-navbar-modern.rd-navbar-static .search-list .search-list-item-all {
	margin-top: 30px;
	padding: 0 15px;
}

.rd-navbar-modern.rd-navbar-static .search-list li + li {
	margin-top: 0;
}

.rd-navbar-modern.rd-navbar-static .search-list {
	margin-top: 10px;
}

.rd-navbar-modern.rd-navbar-static #search-results {
	margin: 0;
	color: #ffffff;
	background: transparent;
	opacity: 1;
	visibility: visible;
	transform: none;
}

.rd-navbar-modern.rd-navbar-static #search-results.active {
	transform: none;
}

.rd-navbar-modern.rd-navbar-static #search-results.active .search-quick-result {
	opacity: 1;
	visibility: visible;
	transform: none;
}

.rd-navbar-modern.rd-navbar-static #search-results.active .search-list > li {
	opacity: 1;
	visibility: visible;
	transform: none;
}

.rd-navbar-modern.rd-navbar-static #search-results.active .search-list li:nth-child(1) {
	transition-delay: 50ms;
}

.rd-navbar-modern.rd-navbar-static #search-results.active .search-list li:nth-child(2) {
	transition-delay: 100ms;
}

.rd-navbar-modern.rd-navbar-static #search-results.active .search-list li:nth-child(3) {
	transition-delay: 150ms;
}

.rd-navbar-modern.rd-navbar-static #search-results.active .search-list li:nth-child(4) {
	transition-delay: 200ms;
}

.rd-navbar-modern.rd-navbar-static #search-results.active .search-list li:nth-child(5) {
	transition-delay: 250ms;
}

.rd-navbar-modern.rd-navbar-static #search-results.active .search-list li:nth-child(6) {
	transition-delay: 300ms;
}

.rd-navbar-modern.rd-navbar-static #search-results.active .search-list li:nth-child(7) {
	transition-delay: 350ms;
}

.rd-navbar-modern.rd-navbar-static #search-results.active .search-list li:nth-child(8) {
	transition-delay: 400ms;
}

.rd-navbar-modern.rd-navbar-static #search-results.active .search-list li:nth-child(9) {
	transition-delay: 450ms;
}

.rd-navbar-modern.rd-navbar-static #search-results.active .search-list li:nth-child(10) {
	transition-delay: 500ms;
}

.rd-navbar-modern.rd-navbar-static .search-submit {
	display: inline-block;
	padding: 10px 30px;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.rd-navbar-modern.rd-navbar-static * + .rd-search-results-live {
	margin-top: 20px;
}

.rd-navbar-modern.rd-navbar-static .search-title {
	color: #ffffff;
}

.rd-navbar-modern.rd-navbar-static .search-quick-result {
	color: #ffffff;
	transition: all .5s ease;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-project-hamburger {
	position: relative;
	width: 28px;
	height: 35px;
	padding: 6px 0;
	transition: all .3s ease;
	z-index: 10;
}

.rd-navbar-modern.rd-navbar-static .project-hamburger {
	display: none;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-main-element .project-close {
	display: none;
}

.rd-navbar-modern.rd-navbar-static .rd-navbar-modern-project .rd-navbar-project-hamburger {
	width: 38px;
	height: 38px;
	flex-shrink: 0;
	margin-left: 20px;
}

.rd-navbar-modern.rd-navbar-fixed .rd-navbar-project-hamburger {
	position: fixed;
	right: 4px;
	width: 48px;
	height: 48px;
	top: 4px;
	padding: 13px 6px 12px;
}

.rd-navbar-modern.rd-navbar-fixed .project-hamburger-2 {
	display: none;
}

.rd-navbar-modern.rd-navbar-fixed .rd-navbar-modern-project {
	padding-top: 60px;
}

.rd-navbar-modern.rd-navbar-fixed .rd-navbar-modern-project .rd-navbar-project-hamburger {
	display: none;
}

.rd-navbar-modern.rd-navbar-fixed .rd-navbar-modern-project-content {
	height: calc(100vh - 134px);
}

.rd-navbar-modern.rd-navbar-fixed * + .rd-navbar-search {
	margin-top: 10px;
}

.rd-navbar-modern-project {
	text-align: left;
	padding: 60px 15px 15px;
	width: 270px;
}

.rd-navbar-modern-project * + .instafeed {
	margin-top: 22px;
}

.rd-navbar-modern-project * + .row {
	margin-top: 30px;
}

.rd-navbar-project-modern-header {
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;
}

.rd-navbar-project-modern-title {
	letter-spacing: .05em;
	margin-top: 5px;
}

.rd-navbar-modern-project-content {
	padding-right: 10px;
	margin-right: -10px;
	height: calc(100vh - 134px);
}

.link-instafeed-2 {
	display: inline-block;
	font-size: 18px;
	letter-spacing: .025em;
}

.link-instafeed-2 span {
	color: #01b3a7;
}

.link-instafeed-2 a, .link-instafeed-2 a:focus, .link-instafeed-2 a:active {
	color: #50ba87;
}

.link-instafeed-2 a:hover {
	color: #01b3a7;
}

* + .rd-navbar-modern-project-content {
	margin-top: 18px;
}

@media (min-width: 768px) {
	.rd-navbar-modern-project {
		width: 285px;
	}
}

@media (min-width: 992px) {
	.rd-navbar-modern-project {
		padding: 70px 30px 15px;
		width: 350px;
	}
	.rd-navbar-modern-project-content {
		height: calc(100vh - 144px);
	}
}

@media (min-width: 1200px) {
	.rd-navbar-modern-project {
		padding-top: 45px;
	}
	.rd-navbar-modern-project-content {
		height: calc(100vh - 119px);
	}
}

@media (min-width: 1600px) {
	.rd-navbar-modern-project {
		padding: 65px 40px 15px;
		width: 400px;
	}
	.rd-navbar-modern-project-content {
		height: calc(100vh - 139px);
	}
}

@media (min-width: 1800px) {
	.rd-navbar-modern-project {
		padding: 95px 64px 15px 70px;
		width: 525px;
	}
	.rd-navbar-modern-project-content {
		height: calc(100vh - 169px);
	}
}

.rd-navbar-modern-contacts {
	text-align: left;
	font-size: 0;
	line-height: 0;
	padding-bottom: 34px;
	border-bottom: 1px solid #d7d7d7;
}

.rd-navbar-modern-contacts .icon {
	vertical-align: middle;
	text-align: left;
	min-width: 21px;
	font-size: 24px;
	line-height: 24px;
	color: #50ba87;
}

.rd-navbar-modern-contacts .fa-envelope {
	font-size: 20px;
}

.rd-navbar-modern-contacts .link-phone {
	font-size: 18px;
	line-height: 1.34;
	letter-spacing: .025em;
}

.rd-navbar-modern-contacts a {
	display: inline-block;
	vertical-align: middle;
	font-size: 14px;
	line-height: 1.72;
	letter-spacing: .025em;
}

.rd-navbar-modern-contacts a, .rd-navbar-modern-contacts a:focus, .rd-navbar-modern-contacts a:active {
	color: #151515;
}

.rd-navbar-modern-contacts a:hover {
	color: #01b3a7;
}

.rd-navbar-modern-contacts li + li {
	margin-top: 22px;
}

* + .rd-navbar-modern-contacts {
	margin-top: 40px;
}

.rd-navbar-modern-list-social {
	font-size: 0;
	line-height: 0;
	margin-bottom: -10px;
	margin-left: -28px;
}

.rd-navbar-modern-list-social:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.rd-navbar-modern-list-social > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 10px;
	margin-left: 28px;
}

.rd-navbar-modern-list-social a {
	font-size: 24px;
	line-height: 1;
}

.rd-navbar-modern-list-social a, .rd-navbar-modern-list-social a:focus, .rd-navbar-modern-list-social a:active {
	color: #9b9b9b;
}

.rd-navbar-modern-list-social a:hover {
	color: #50ba87;
}

* + .rd-navbar-modern-list-social {
	margin-top: 36px;
}

/**
*
* RD Navbar Creative
* =====================================================
*/
.rd-navbar-creative.rd-navbar-static .rd-navbar-aside-outer,
.rd-navbar-creative.rd-navbar-static .rd-navbar-main-outer {
	padding-left: 15px;
	padding-right: 15px;
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-aside,
.rd-navbar-creative.rd-navbar-static .rd-navbar-main {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1170px;
	margin-left: auto;
	margin-right: auto;
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-aside-outer {
	background: #ebebeb;
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-aside {
	font-size: 12px;
	line-height: 2;
	font-weight: 500;
	letter-spacing: .025em;
	padding: 10px 0;
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-aside > * + * {
	margin-left: 20px;
}

@media (min-width: 1600px) {
	.rd-navbar-creative.rd-navbar-static .rd-navbar-aside {
		padding-top: 14px;
		padding-bottom: 14px;
	}
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-main-outer {
	position: relative;
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-main {
	padding: 15px 0;
	transition: padding .35s ease-in-out;
}

@media (min-width: 1200px) {
	.rd-navbar-creative.rd-navbar-static .rd-navbar-main {
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

@media (min-width: 1600px) {
	.rd-navbar-creative.rd-navbar-static .rd-navbar-main {
		padding-top: 38px;
		padding-bottom: 36px;
	}
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-nav-wrap {
	display: flex;
	align-items: center;
	margin-right: 26px;
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-nav {
	order: -1;
	margin-right: 25px;
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-brand img {
	width: auto;
	height: auto;
	max-width: 140px;
	max-height: 140px;
}

@media (min-width: 1200px) {
	.rd-navbar-creative.rd-navbar-static .rd-navbar-brand img {
		max-width: 200px;
		max-height: 170px;
	}
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-main-element {
	display: flex;
	align-items: center;
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-submenu.focus .rd-navbar-megamenu, .rd-navbar-creative.rd-navbar-static .rd-navbar-submenu.opened .rd-navbar-megamenu {
	transform: translate3d(-50%, 0, 0);
}

.rd-navbar-creative.rd-navbar-static .rd-menu {
	margin-top: 25px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

@media (min-width: 1200px) {
	.rd-navbar-creative.rd-navbar-static .rd-menu {
		margin-top: 30px;
	}
}

@media (min-width: 1600px) {
	.rd-navbar-creative.rd-navbar-static .rd-menu {
		margin-top: 46px;
	}
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-megamenu {
	left: 50%;
	transform: translate3d(-50%, 30px, 0);
}

.rd-navbar-creative.rd-navbar-static.rd-navbar--is-stuck, .rd-navbar-creative.rd-navbar-static.rd-navbar--is-clone {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.rd-navbar-creative.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-aside-outer, .rd-navbar-creative.rd-navbar-static.rd-navbar--is-clone .rd-navbar-aside-outer {
	display: none;
}

.rd-navbar-creative.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-main, .rd-navbar-creative.rd-navbar-static.rd-navbar--is-clone .rd-navbar-main {
	padding-top: 15px;
	padding-bottom: 15px;
}

.rd-navbar-creative.rd-navbar-static.rd-navbar--is-stuck .rd-menu, .rd-navbar-creative.rd-navbar-static.rd-navbar--is-clone .rd-menu {
	margin-top: 25px;
}

.rd-navbar-creative.rd-navbar-static .rd-nav-item.focus .rd-nav-link, .rd-navbar-creative.rd-navbar-static .rd-nav-item.opened .rd-nav-link {
	color: #151515;
	background: transparent;
}

.rd-navbar-creative.rd-navbar-static .rd-nav-item.focus .rd-nav-link::before, .rd-navbar-creative.rd-navbar-static .rd-nav-item.opened .rd-nav-link::before {
	opacity: 1;
	transform: scale3d(1, 1, 1);
	visibility: visible;
}

.rd-navbar-creative.rd-navbar-static .rd-nav-item.focus .rd-nav-link > .rd-navbar-submenu-toggle, .rd-navbar-creative.rd-navbar-static .rd-nav-item.opened .rd-nav-link > .rd-navbar-submenu-toggle {
	color: #151515;
}

.rd-navbar-creative.rd-navbar-static .rd-nav-item.active .rd-nav-link {
	color: #50ba87;
}

.rd-navbar-creative.rd-navbar-static .rd-nav-item.active .rd-nav-link::before {
	display: none;
}

.rd-navbar-creative.rd-navbar-static .rd-nav-link::before {
	position: absolute;
	content: '';
	bottom: -8px;
	left: 0;
	height: 3px;
	width: 100%;
	background: #50ba87;
	transform-origin: 0 50%;
	transform: scale3d(0, 3, 1);
	opacity: 0;
	visibility: hidden;
	transition: transform 0.25s;
	transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
}

.rd-navbar-creative.rd-navbar-static .rd-nav-link:hover {
	color: #151515;
}

.rd-navbar-creative.rd-navbar-static .rd-nav-link:hover::before {
	opacity: 1;
	transform: scale3d(1, 1, 1);
	visibility: visible;
}

.rd-navbar-creative.rd-navbar-static .rd-nav-item + .rd-nav-item {
	margin-left: 22px;
}

@media (min-width: 1200px) {
	.rd-navbar-creative.rd-navbar-static .rd-nav-item + .rd-nav-item {
		margin-left: 46px;
	}
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-search .form-input,
.rd-navbar-creative.rd-navbar-static .rd-navbar-search .form-label {
	color: #50ba87;
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-search.active .rd-search {
	width: 650px;
}

@media (min-width: 1200px) {
	.rd-navbar-creative.rd-navbar-static .rd-navbar-search.active .rd-search {
		width: 800px;
	}
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-project-hamburger {
	position: relative;
	width: 44px;
	height: 44px;
	padding: 14px 11px;
	border-radius: 50%;
	background-color: #50ba87;
	transition: all .2s ease-in-out;
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-project-hamburger:hover {
	background-color: #01b3a7;
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-project-hamburger.active .project-close > span:nth-child(1) {
	height: 50%;
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-project-hamburger.active .project-close > span:nth-child(2) {
	width: 50%;
}

.rd-navbar-creative.rd-navbar-static .project-hamburger {
	display: none;
}

.rd-navbar-creative.rd-navbar-static .project-close span {
	background: #ffffff;
}

.rd-navbar-creative.rd-navbar-static .project-close span:nth-child(1) {
	top: 25%;
}

.rd-navbar-creative.rd-navbar-static .project-close span:nth-child(2) {
	left: 25%;
}

@media (min-width: 992px) {
	.rd-navbar-creative.rd-navbar-static .owl-classic .owl-prev,
	.rd-navbar-creative.rd-navbar-static .owl-classic .owl-next {
		display: flex;
	}
	.rd-navbar-creative.rd-navbar-static .owl-classic .owl-dots {
		display: none;
	}
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-creative-contacts li {
	display: inline-block;
}

.rd-navbar-creative.rd-navbar-static .rd-navbar-creative-contacts li + li {
	margin-top: 0;
	margin-left: 8%;
}

.rd-navbar-creative.rd-navbar-fixed .rd-navbar-project-hamburger {
	position: fixed;
	right: 50px;
	width: 48px;
	height: 48px;
	top: 4px;
	padding: 13px 6px 12px;
}

.rd-navbar-creative.rd-navbar-fixed .rd-navbar-creative-project {
	position: fixed;
	padding: 20px 30px;
	top: 56px;
	z-index: 1030;
}

@media (min-width: 576px) {
	.rd-navbar-creative.rd-navbar-fixed .rd-navbar-creative-project {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

.rd-navbar-creative.rd-navbar-fixed .project-hamburger-3 {
	display: none;
}

.rd-navbar-creative.rd-navbar-fixed .owl-classic .owl-dots {
	font-size: 0;
	line-height: 0;
}

.rd-navbar-creative.rd-navbar-fixed * + .rd-navbar-search {
	margin-top: 10px;
}

.rd-navbar-creative.rd-navbar-fixed .rd-navbar-creative-contacts + * {
	margin-top: 20px;
}

.rd-navbar-creative-2.rd-navbar-static .rd-navbar-aside-outer {
	color: #ffffff;
	background: #303233;
}

.rd-navbar-creative-2.rd-navbar-static .rd-navbar-aside {
	font-weight: 400;
}

.rd-navbar-creative-2.rd-navbar-static .rd-nav-item.active .rd-nav-link {
	color: #01b3a7;
}

.rd-navbar-creative-2.rd-navbar-static .rd-nav-link::before {
	background: #01b3a7;
}

.rd-navbar-creative-2.rd-navbar-static .rd-navbar-dropdown li > a::before,
.rd-navbar-creative-2.rd-navbar-static .rd-megamenu-list li > a::before {
	background: #01b3a7;
}

.rd-navbar-creative-2.rd-navbar-static .rd-navbar-creative-contacts li {
	position: relative;
}

.rd-navbar-creative-2.rd-navbar-static .rd-navbar-creative-contacts li::before {
	position: absolute;
	display: none;
	content: '/';
	top: 0;
	left: 0;
	font-size: 12px;
	line-height: 2;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.3);
}

.rd-navbar-creative-2.rd-navbar-static .rd-navbar-creative-contacts li + li {
	margin-left: 22px;
	padding-left: 22px;
}

.rd-navbar-creative-2.rd-navbar-static .rd-navbar-creative-contacts li + li::before {
	display: inline-block;
}

.rd-navbar-creative-2.rd-navbar-static .rd-navbar-project-hamburger {
	background-color: #01b3a7;
	background-image: linear-gradient(to bottom, #01b3a7 0%, #01b3a7 50%, #50ba87 100%);
	background-repeat: no-repeat;
	background-size: auto 200%;
	transition-duration: .3s;
}

.rd-navbar-creative-2.rd-navbar-static .rd-navbar-project-hamburger:hover {
	background-color: #01b3a7;
	background-position: 50% 100%;
}

.rd-navbar-creative-2.rd-navbar-static .text-secondary {
	color: rgba(255, 255, 255, 0.3);
}

.rd-navbar-creative-2.rd-navbar-fixed .text-secondary {
	color: #151515;
}

.rd-navbar-creative-2 .rd-navbar-basket span {
	color: #01b3a7;
}

.rd-navbar-creative-2 .rd-navbar-creative-contacts {
	color: inherit;
}

.rd-navbar-creative-2 .rd-navbar-creative-contacts .icon {
	color: #01b3a7;
}

.rd-navbar-creative-2 .rd-navbar-creative-contacts p,
.rd-navbar-creative-2 .rd-navbar-creative-contacts a {
	font-weight: 400;
}

.rd-navbar-creative-2 .rd-navbar-creative-contacts a, .rd-navbar-creative-2 .rd-navbar-creative-contacts a:focus, .rd-navbar-creative-2 .rd-navbar-creative-contacts a:active {
	color: inherit;
}

.rd-navbar-creative-2 .rd-navbar-creative-contacts a:hover {
	color: #01b3a7;
}

.rd-navbar-creative-2 .rd-navbar-creative-list-social a, .rd-navbar-creative-2 .rd-navbar-creative-list-social a:focus, .rd-navbar-creative-2 .rd-navbar-creative-list-social a:active {
	color: inherit;
}

.rd-navbar-creative-2 .rd-navbar-creative-list-social a:hover {
	color: #01b3a7;
}

.rd-navbar-creative-project {
	position: absolute;
	padding: 30px 75px;
	top: 100%;
	width: 100%;
	left: 0;
	background: #ffffff;
	border-top: 1px solid #d7d7d7;
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease;
	transform: translate3d(0, 30px, 0);
	box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.05);
	z-index: 2;
}

.rd-navbar-creative-project.active {
	opacity: 1;
	visibility: visible;
	transform: none;
}

@media (max-height: 320px) {
	.rd-navbar-creative-project .thumbnail {
		max-width: 270px;
		margin-left: auto;
		margin-right: auto;
	}
}

.rd-navbar-creative-contacts {
	text-align: left;
	font-size: 0;
	line-height: 0;
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	color: #9b9b9b;
}

.rd-navbar-creative-contacts .icon {
	vertical-align: middle;
	text-align: center;
	font-size: 16px;
	line-height: 24px;
	color: #50ba87;
}

.rd-navbar-creative-contacts p,
.rd-navbar-creative-contacts a {
	font-size: 12px;
	line-height: 2;
	font-weight: 500;
	letter-spacing: .025em;
}

.rd-navbar-creative-contacts a {
	display: inline-block;
	vertical-align: middle;
}

.rd-navbar-creative-contacts a, .rd-navbar-creative-contacts a:focus, .rd-navbar-creative-contacts a:active {
	color: inherit;
}

.rd-navbar-creative-contacts a:hover {
	color: #151515;
}

.rd-navbar-creative-contacts li + li {
	margin-top: 10px;
}

.rd-navbar-creative-list-social {
	font-size: 0;
	line-height: 0;
}

.rd-navbar-creative-list-social a, .rd-navbar-creative-list-social a:focus, .rd-navbar-creative-list-social a:active {
	color: #9b9b9b;
}

.rd-navbar-creative-list-social a:hover {
	color: #50ba87;
}

.rd-navbar-creative-list-social .icon {
	line-height: 24px;
}

/**
*
* RD Navbar Corporate
* =====================================================
*/
.rd-navbar-corporate.rd-navbar-static .rd-navbar-aside-outer,
.rd-navbar-corporate.rd-navbar-static .rd-navbar-main-outer {
	padding-left: 15px;
	padding-right: 15px;
}

.rd-navbar-corporate.rd-navbar-static .rd-navbar-aside,
.rd-navbar-corporate.rd-navbar-static .rd-navbar-main {
	max-width: 1170px;
	margin-left: auto;
	margin-right: auto;
}

.rd-navbar-corporate.rd-navbar-static .rd-navbar-aside {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	line-height: 2;
	font-weight: 500;
	letter-spacing: .025em;
	padding: 10px 0;
}

.rd-navbar-corporate.rd-navbar-static .rd-navbar-aside > * + * {
	margin-left: 20px;
}

@media (min-width: 1200px) {
	.rd-navbar-corporate.rd-navbar-static .rd-navbar-aside {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

@media (min-width: 1600px) {
	.rd-navbar-corporate.rd-navbar-static .rd-navbar-aside {
		padding-top: 28px;
		padding-bottom: 28px;
	}
}

.rd-navbar-corporate.rd-navbar-static .rd-navbar-aside-right {
	display: flex;
	align-items: center;
}

.rd-navbar-corporate.rd-navbar-static .rd-navbar-main-outer {
	background: #162e44;
}

.rd-navbar-corporate.rd-navbar-static .rd-navbar-nav-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.rd-navbar-corporate.rd-navbar-static .rd-navbar-nav {
	order: -1;
	margin-right: 25px;
}

.rd-navbar-corporate.rd-navbar-static .rd-navbar-brand img {
	width: auto;
	height: auto;
	max-width: 200px;
	max-height: 170px;
}

@media (min-width: 1200px) {
	.rd-navbar-corporate.rd-navbar-static .rd-navbar-brand img {
		max-width: 225px;
		max-height: 190px;
	}
}

.rd-navbar-corporate.rd-navbar-static .rd-navbar-submenu.focus .rd-navbar-megamenu, .rd-navbar-corporate.rd-navbar-static .rd-navbar-submenu.opened .rd-navbar-megamenu {
	transform: translate3d(-50%, 0, 0);
}

.rd-navbar-corporate.rd-navbar-static .rd-menu {
	margin-top: 0;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.rd-navbar-corporate.rd-navbar-static .rd-navbar-megamenu {
	left: 50%;
	transform: translate3d(-50%, 30px, 0);
}

.rd-navbar-corporate.rd-navbar-static.rd-navbar--is-stuck, .rd-navbar-corporate.rd-navbar-static.rd-navbar--is-clone {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.rd-navbar-corporate.rd-navbar-static.rd-navbar--is-stuck .rd-navbar-aside-outer, .rd-navbar-corporate.rd-navbar-static.rd-navbar--is-clone .rd-navbar-aside-outer {
	display: none;
}

.rd-navbar-corporate.rd-navbar-static .rd-nav-item.focus .rd-nav-link, .rd-navbar-corporate.rd-navbar-static .rd-nav-item.opened .rd-nav-link {
	color: #ffffff;
}

.rd-navbar-corporate.rd-navbar-static .rd-nav-item.focus .rd-nav-link::before, .rd-navbar-corporate.rd-navbar-static .rd-nav-item.opened .rd-nav-link::before {
	opacity: 1;
	visibility: visible;
	transform: translate3d(0, 0, 0);
}

.rd-navbar-corporate.rd-navbar-static .rd-nav-item.focus .rd-nav-link > .rd-navbar-submenu-toggle, .rd-navbar-corporate.rd-navbar-static .rd-nav-item.opened .rd-nav-link > .rd-navbar-submenu-toggle {
	color: #151515;
}

.rd-navbar-corporate.rd-navbar-static .rd-nav-item.active .rd-nav-link {
	color: #ffffff;
}

.rd-navbar-corporate.rd-navbar-static .rd-nav-item.active .rd-nav-link::before {
	height: 100%;
	opacity: 1;
	visibility: visible;
	transform: translate3d(0, 0, 0);
	transition-delay: 0s, .2s;
}

.rd-navbar-corporate.rd-navbar-static .rd-nav-link {
	padding: 20px 15px;
	font-size: 16px;
	letter-spacing: .05em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #ffffff;
	z-index: 1;
}

.rd-navbar-corporate.rd-navbar-static .rd-nav-link::before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	height: 0;
	width: 100%;
	background: rgba(255, 255, 255, 0.11);
	border-top: 3px solid #01b3a7;
	transform: translate3d(-100%, 0, 0);
	transition: all 0.35s cubic-bezier(0.05, 0.29, 0.11, 1.54), height 0.3s ease;
	visibility: hidden;
	opacity: 0;
	z-index: -1;
}

.rd-navbar-corporate.rd-navbar-static .rd-nav-link:hover {
	color: #ffffff;
	background: rgba(255, 255, 255, 0.11);
	transition: all ease 0.35s;
}

.rd-navbar-corporate.rd-navbar-static .rd-nav-link:hover::before {
	opacity: 1;
	visibility: visible;
	transform: translate3d(0, 0, 0);
}

.rd-navbar-corporate.rd-navbar-static .rd-nav-item + .rd-nav-item {
	margin-left: 16px;
}

.rd-navbar-corporate.rd-navbar-static .rd-navbar-corporate-contacts li {
	display: inline-block;
}

.rd-navbar-corporate.rd-navbar-static .rd-navbar-corporate-contacts li + li {
	margin-top: 0;
	margin-left: 25px;
	padding-left: 25px;
	border-left: 1px solid #e1e1e1;
}

.rd-navbar-corporate.rd-navbar-static * + .button {
	margin-top: 0;
	margin-left: 65px;
}

.rd-navbar-corporate.rd-navbar-fixed .rd-navbar-corporate-list-social {
	color: #151515;
}

.rd-navbar-corporate.rd-navbar-fixed .rd-navbar-brand img {
	max-width: 200px;
}

.rd-navbar-corporate.rd-navbar-fixed .button {
	display: block;
	width: 100%;
}

.rd-navbar-corporate.rd-navbar-fixed * + .button {
	margin-top: 20px;
}

.rd-navbar-corporate-contacts {
	text-align: left;
	font-size: 0;
	line-height: 0;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	text-transform: uppercase;
	max-width: 100%;
	color: #151515;
	flex: 0 0 auto;
}

.rd-navbar-corporate-contacts .icon {
	vertical-align: middle;
	text-align: center;
	font-size: 24px;
	line-height: 1;
	color: #01b3a7;
}

.rd-navbar-corporate-contacts p,
.rd-navbar-corporate-contacts a {
	margin-top: 2px;
	font-size: 14px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: .075em;
}

.rd-navbar-corporate-contacts p > span {
	font-size: 16px;
}

.rd-navbar-corporate-contacts a {
	display: inline-block;
	vertical-align: middle;
}

.rd-navbar-corporate-contacts a, .rd-navbar-corporate-contacts a:focus, .rd-navbar-corporate-contacts a:active {
	color: #151515;
}

.rd-navbar-corporate-contacts a:hover {
	color: #50ba87;
}

.rd-navbar-corporate-contacts li + li {
	margin-top: 14px;
}

.rd-navbar-corporate-list-social {
	font-size: 0;
	line-height: 0;
	color: #ffffff;
}

.rd-navbar-corporate-list-social a, .rd-navbar-corporate-list-social a:focus, .rd-navbar-corporate-list-social a:active {
	color: inherit;
}

.rd-navbar-corporate-list-social a:hover {
	color: #50ba87;
}

.rd-navbar-corporate-list-social .icon {
	font-size: 18px;
}

/*
*
* RD Navbar Fullwidth
*/
.rd-navbar-fullwidth {
	display: block;
}

.rd-navbar-fullwidth .rd-navbar-nav > li + li {
	margin-left: 20px;
}

.rd-navbar-fullwidth.rd-navbar--is-stuck .rd-navbar-main, .rd-navbar-fullwidth.rd-navbar--is-clone .rd-navbar-main {
	padding: 10px 0;
}

/*
*
* Swiper
*/
.swiper-container {
	display: flex;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	height: auto;
	width: 100%;
	/* Fix of Webkit flickering */
	z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
	float: left;
}

.swiper-container-vertical > .swiper-wrapper {
	flex-direction: column;
}

.swiper-wrapper {
	position: relative;
	z-index: 1;
	display: flex;
	align-self: stretch;
	align-items: stretch;
	width: 100%;
	height: auto;
	min-height: inherit;
	transition-property: transform;
	box-sizing: content-box;
}

.swiper-container {
	flex-shrink: 0;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
	transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
	flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
	transition-timing-function: ease-out;
	margin: 0 auto;
}

/* a11y */
.swiper-container .swiper-notification {
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	opacity: 0;
	z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
	touch-action: pan-y;
}

.swiper-wp8-vertical {
	touch-action: pan-x;
}

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper {
	/* Windows 8 IE 10 fix */
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
	transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
	pointer-events: none;
}

.swiper-container-fade .swiper-slide .swiper-slide {
	pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
	pointer-events: auto;
}

.swiper-lazy-preloader {
	width: 42px;
	height: 42px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -21px;
	margin-top: -21px;
	z-index: 10;
	transform-origin: 50%;
	animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
	display: block;
	content: "";
	width: 100%;
	height: 100%;
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
	background-position: 50%;
	background-size: 100%;
	background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@keyframes swiper-preloader-spin {
	100% {
		transform: rotate(360deg);
	}
}

.swiper-slide > .vide__body,
.swiper-slide > .parallax_cnt {
	height: 100%;
}

.swiper-button-prev,
.swiper-button-next {
	text-align: center;
	position: absolute;
	display: none;
	top: 50%;
	width: 47px;
	height: 47px;
	line-height: 47px;
	background: transparent;
	transform: translateY(-50%);
	will-change: transform;
	transition: all .3s ease-in-out;
	cursor: pointer;
	z-index: 10;
}

.swiper-button-prev::before,
.swiper-button-next::before {
	display: inline-block;
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-color: transparent;
	transform: none;
	transition: inherit;
}

.swiper-button-prev::after,
.swiper-button-next::after {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #ffffff;
	transition: inherit;
	z-index: -1;
}

.swiper-button-prev:hover::before,
.swiper-button-next:hover::before {
	transform: scale(1.4);
}

.swiper-button-prev:hover::after,
.swiper-button-next:hover::after {
	border-radius: 50%;
	background: #50ba87;
}

@media (min-width: 768px) {
	.swiper-button-prev,
	.swiper-button-next {
		display: block;
	}
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
	opacity: 0.35;
	cursor: auto;
	pointer-events: none;
}

.swiper-button-prev {
	left: 15px;
}

.swiper-button-prev:before {
	margin-left: -4px;
	border-width: 5px 8px 5px 0;
	border-right-color: #50ba87;
}

.swiper-button-prev:hover::before {
	border-right-color: #ffffff;
}

.swiper-button-prev:hover::after {
	transform: rotate(-360deg);
}

@media (min-width: 1600px) {
	.swiper-button-prev {
		left: 75px;
	}
}

.swiper-button-next {
	right: 15px;
}

.swiper-button-next:before {
	margin-right: -4px;
	border-width: 5px 0 5px 8px;
	border-left-color: #50ba87;
}

.swiper-button-next:hover::before {
	border-left-color: #ffffff;
}

.swiper-button-next:hover::after {
	transform: rotate(360deg);
}

@media (min-width: 1600px) {
	.swiper-button-next {
		right: 75px;
	}
}

.swiper-slider.swiper-container-rtl .swiper-button-prev::before {
	content: "\f061";
}

.swiper-slider.swiper-container-rtl .swiper-button-next::before {
	content: "\f060";
}

.swiper-pagination {
	position: absolute;
	display: block;
	text-align: center;
	transform: translate3d(0, 0, 0);
	transition: .3s;
	z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
	opacity: 0;
}

.swiper-pagination.swiper-pagination-clickable .swiper-pagination-bullet {
	cursor: pointer;
}

.swiper-pagination-bullet {
	text-align: center;
	display: inline-block;
	position: relative;
	width: 14px;
	height: 14px;
	border: none;
	border-radius: 0;
	outline: none;
	transition: .2s;
	background: rgba(80, 186, 135, 0.6);
}

.swiper-pagination-bullet:hover, .swiper-pagination-bullet:focus {
	background: #50ba87;
}

.swiper-pagination-bullet-active {
	background: #01b3a7;
}

.swiper-pagination-style-2 .swiper-pagination {
	font-size: 0;
	line-height: 0;
}

.swiper-pagination-style-2 .swiper-pagination-bullet {
	background: rgba(155, 155, 155, 0.8);
}

.swiper-pagination-style-2 .swiper-pagination-bullet::before, .swiper-pagination-style-2 .swiper-pagination-bullet::after {
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.swiper-pagination-style-2 .swiper-pagination-bullet::before {
	background: rgba(80, 186, 135, 0.6);
	transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
}

.swiper-pagination-style-2 .swiper-pagination-bullet::after {
	opacity: 0;
	background: #01b3a7;
	transform: translateY(-200%);
	transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s;
}

.swiper-pagination-style-2 .swiper-pagination-bullet:hover, .swiper-pagination-style-2 .swiper-pagination-bullet:focus {
	background: transparent;
}

.swiper-pagination-style-2 .swiper-pagination-bullet:hover::before, .swiper-pagination-style-2 .swiper-pagination-bullet:focus::before {
	background: #50ba87;
}

.swiper-pagination-style-2 .swiper-pagination-bullet-active {
	background: transparent;
}

.swiper-pagination-style-2 .swiper-pagination-bullet-active::after {
	opacity: 1;
	visibility: visible;
	transform: translateY(0%);
	transition: transform 0.3s ease, opacity 0.3s ease;
}

.swiper-pagination-style-2 .swiper-pagination-bullet-active::before {
	opacity: 0;
	visibility: hidden;
	transform: translateY(200%);
}

.swiper-pagination-black .swiper-pagination-bullet {
	background: rgba(0, 0, 0, 0.6);
}

.swiper-pagination-black .swiper-pagination-bullet.swiper-pagination-bullet-active {
	background: black;
}

.swiper-container-vertical > .swiper-pagination {
	top: 50%;
	right: 10px;
	transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination .swiper-pagination-bullet {
	margin: 8px 0;
	display: block;
}

.swiper-container-vertical.swiper-pagination-style-2 .swiper-pagination-bullet::after {
	transform: translate3d(-200%, 0, 0);
}

.swiper-container-vertical.swiper-pagination-style-2 .swiper-pagination-bullet-active::before {
	transform: translate3d(200%, 0, 0);
}

.swiper-container-vertical.swiper-pagination-style-2 .swiper-pagination-bullet-active::after {
	transform: translate3d(0%, 0, 0);
}

.swiper-container-horizontal > .swiper-pagination {
	bottom: 30px;
	left: 0;
	width: 100%;
}

.swiper-container-horizontal > .swiper-pagination .swiper-pagination-bullet {
	margin: 0 10px;
}

.swiper-slide {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	white-space: nowrap;
	background-position: 50% 50%;
}

.swiper-slide-caption {
	width: 100%;
	white-space: normal;
}

@media (max-width: 767.98px) {
	.swiper-slide-caption {
		padding-bottom: 80px;
	}
}

.swiper-slider-classic {
	max-height: 38.54167vw;
	min-height: calc(100vh - 56px);
}

.swiper-slider-classic.swiper-container-horizontal {
	max-height: none;
}

@media (max-height: 420px) {
	.swiper-slider-classic {
		min-height: 260px;
	}
	.swiper-slider-classic p {
		display: none;
	}
}

.swiper-slider-classic .swiper-pagination-bullet {
	position: relative;
	overflow: hidden;
	display: inline-block;
	width: 29px;
	height: 19px;
	border-radius: 0;
	background: transparent;
}

.swiper-slider-classic .swiper-pagination-bullet::before, .swiper-slider-classic .swiper-pagination-bullet::after {
	position: absolute;
	display: inline-block;
	content: '';
	top: 8px;
	left: 0;
	height: 3px;
	width: 29px;
	transition: none;
	opacity: 1;
}

.swiper-slider-classic .swiper-pagination-bullet::before {
	background: #ffffff;
}

@media (min-width: 1600px) {
	.swiper-slider-classic .swiper-pagination-bullet::before {
		background: #252525;
	}
}

.swiper-slider-classic .swiper-pagination-bullet::after {
	transform: translateX(-100%);
	animation: pagOutX .3s;
	background: #50ba87;
}

.swiper-slider-classic .swiper-pagination-bullet:hover, .swiper-slider-classic .swiper-pagination-bullet.swiper-pagination-bullet-active {
	background: transparent;
}

.swiper-slider-classic .swiper-pagination-bullet:hover::after, .swiper-slider-classic .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
	animation: pagInX .3s forwards;
}

.swiper-slider-classic .swiper-pagination {
	position: static;
	transform: none;
}

.swiper-slider-classic .swiper-pagination .swiper-pagination-bullet {
	margin: 0 16px;
}

.swiper-slider-classic h1 {
	letter-spacing: .05em;
}

.swiper-slider-classic * + p {
	margin-top: 14px;
}

.swiper-slider-classic * + .button {
	margin-top: 25px;
}

.swiper-slider-classic .swiper-slide-1 {
	background-position-x: 40%;
}

@media (min-width: 768px) {
	.swiper-slider-classic {
		min-height: 460px;
	}
	.swiper-slider-classic.swiper-container-vertical .swiper-pagination-bullet {
		display: block;
	}
	.swiper-slider-classic.swiper-container-vertical .swiper-pagination .swiper-pagination-bullet {
		margin: 0;
	}
	.swiper-slider-classic.swiper-container-vertical .swiper-pagination__module {
		top: 50%;
		right: 20px;
		bottom: auto;
		left: auto;
		max-width: 29px;
		transform: translateY(-50%);
	}
	.swiper-slider-classic .swiper-slide-caption {
		padding-left: 70px;
		padding-right: 70px;
	}
	.swiper-slider-classic .swiper-slide-1 {
		background-position-x: 50%;
	}
	.swiper-slider-classic .swiper-slide-custom {
		padding-left: 0;
	}
	.swiper-slider-classic h1 {
		font-size: 60px;
	}
}

@media (min-width: 992px) {
	.swiper-slider-classic .swiper-slide-custom {
		padding-left: 70px;
	}
	.swiper-slider-classic h1 {
		font-size: 80px;
	}
}

@media (min-width: 1200px) {
	.swiper-slider-classic.swiper-container-horizontal {
		min-height: 38.54167vw;
	}
	.swiper-slider-classic h1 {
		font-size: 100px;
	}
}

@media (min-width: 1600px) {
	.swiper-slider-classic {
		max-width: 1920px;
		padding: 0 75px;
	}
}

.swiper-slider-classic-2 .swiper-pagination__fraction-index {
	color: #01b3a7;
}

.swiper-slider-classic-2 .swiper-pagination-bullet::after {
	background: #01b3a7;
}

.swiper-slider-classic-2 .context-dark .button.button-ujarak::before {
	background: #01b3a7;
}

.swiper-slider-classic-2 .context-dark .button.button-ujarak:hover, .swiper-slider-classic-2 .context-dark .button.button-ujarak:active {
	border-color: #01b3a7;
}

.swiper-slider-classic-2 .button {
	min-width: 175px;
}

.swiper-slider-classic-2 .swiper-title-2 {
	letter-spacing: .025em;
	font-weight: 400;
}

.swiper-slider-classic-2 h2 {
	letter-spacing: .075em;
}

.swiper-slider-classic-2 .swiper-slide-custom * + .button {
	margin-top: 20px;
}

@media (min-width: 768px) {
	.swiper-slider-classic-2 .swiper-slide-custom * + .button {
		margin-top: 30px;
	}
}

@media (min-width: 1200px) {
	.swiper-slider-classic-2 .swiper-title-2 {
		font-size: 110px;
	}
}

.swiper-pagination__module {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 0;
	line-height: 0;
	position: absolute;
	bottom: 25px;
	width: 100%;
	z-index: 1;
}

.swiper-pagination__fraction {
	display: none;
	font-size: 18px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: 0;
	font-family: "Maven Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	color: #9b9b9b;
	transform: rotate(-90deg);
}

.swiper-pagination__fraction-index {
	color: #50ba87;
}

.swiper-pagination__divider {
	display: none;
	margin: 30px 0 8px;
	width: 1px;
	height: 176px;
	background: #e1e1e1;
}

@media (min-width: 1600px) {
	.swiper-pagination__fraction {
		display: inline-block;
	}
	.swiper-pagination__divider {
		display: inline-block;
	}
}

.swiper-slider-modern {
	min-height: 44.27083vw;
}

.swiper-slider-modern .swiper-slide {
	background-position: 0 50%;
}

.swiper-slider-modern .swiper-slide-caption {
	padding-top: 60px;
}

.swiper-slider-modern .swiper-button-prev,
.swiper-slider-modern .swiper-button-next {
	transform: none;
	top: 51%;
}

.swiper-slider-modern .slider-modern-box {
	max-width: 240px;
}

.swiper-slider-modern .slider-modern-title {
	line-height: 1.115;
	font-weight: 300;
	letter-spacing: .025em;
}

.swiper-slider-modern .slider-modern-title span {
	display: inline-block;
}

.swiper-slider-modern .button {
	min-width: auto;
	padding-left: 40px;
	padding-right: 40px;
}

.swiper-slider-modern p {
	font-size: 16px;
	line-height: 1.625;
	font-weight: 500;
	letter-spacing: 0;
}

.swiper-slider-modern * + p {
	margin-top: 10px;
}

.swiper-slider-modern * + .button-wrap {
	margin-top: 30px;
}

@media (max-width: 575.98px) {
	.swiper-slider-modern .swiper-slide {
		background-position: 30% 50%;
	}
}

@media (min-width: 768px) {
	.swiper-slider-modern .swiper-slide-caption {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	.swiper-slider-modern .slider-modern-box {
		margin-left: 60px;
	}
	.swiper-slider-modern .swiper-pagination {
		display: none;
	}
}

@media (min-width: 992px) {
	.swiper-slider-modern .swiper-slide-caption {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	.swiper-slider-modern .slider-modern-box {
		margin-left: 80px;
		max-width: 320px;
	}
}

@media (min-width: 1200px) {
	.swiper-slider-modern .swiper-slide-caption {
		padding-top: 200px;
		padding-bottom: 96px;
	}
	.swiper-slider-modern .slider-modern-box {
		margin-left: 90px;
	}
	.swiper-slider-modern * + .button-wrap {
		margin-top: 40px;
	}
}

@media (min-width: 1600px) {
	.swiper-slider-modern .slider-modern-box {
		margin-left: 24px;
	}
}

.swiper-slider-creative {
	min-height: 34.42708vw;
}

.swiper-slider-creative .swiper-slider-text {
	font-size: 16px;
	line-height: 1.5;
	font-weight: 500;
	letter-spacing: 0;
}

.swiper-slider-creative .button {
	min-width: 175px;
}

.swiper-slider-creative h1 {
	letter-spacing: .025em;
}

.swiper-slider-creative hr {
	max-width: 78%;
	margin-left: 0;
	border-color: rgba(255, 255, 255, 0.48);
}

.swiper-slider-creative h4 {
	font-weight: 300;
	letter-spacing: .05em;
}

.swiper-slider-creative * + hr,
.swiper-slider-creative * + .swiper-slider-text {
	margin-top: 12px;
}

.swiper-slider-creative * + .swiper-creative-decor {
	margin-top: 16px;
}

.swiper-slider-creative h1 + h4 {
	margin-top: 2px;
}

.swiper-slider-creative * + .button-wrap {
	margin-top: 30px;
}

.swiper-slider-creative .swiper-slider-text + .button,
.swiper-slider-creative .swiper-slider-text + .button-wrap {
	margin-top: 30px;
}

.swiper-slider-creative .swiper-pagination {
	font-size: 0;
	line-height: 0;
	counter-reset: span;
	bottom: 20px;
}

.swiper-slider-creative .swiper-pagination-bullet {
	width: 35px;
	height: 23px;
	padding: 10px 0;
	background: transparent;
}

.swiper-slider-creative .swiper-pagination-bullet::after {
	display: inline-block;
	position: absolute;
	content: '';
	left: 50%;
	top: 50%;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: transparent;
	transform: scale(0, 0) translate3d(-50%, -50%, 0) rotate(0.1deg);
	will-change: transform;
	transition: inherit;
	transform-origin: 0 0;
}

.swiper-slider-creative .swiper-pagination-bullet span {
	display: inline-block;
	height: 3px;
	width: 100%;
	background: #ffffff;
	transition: all .3s ease;
}

.swiper-slider-creative .swiper-pagination-bullet:hover::after,
.swiper-slider-creative .swiper-pagination-bullet-active::after {
	background: #ffffff;
	transform: scale(1, 1) translate3d(-50%, -50%, 0) rotate(0.1deg);
	transition-delay: .1s;
}

.swiper-slider-creative .swiper-pagination-bullet:hover span,
.swiper-slider-creative .swiper-pagination-bullet-active span {
	width: 0;
}

@media (max-width: 575.98px) {
	.swiper-slider-creative .swiper-slide {
		background-position-x: 35%;
	}
	.swiper-slider-creative .swiper-slide::before {
		position: absolute;
		content: '';
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.15);
		z-index: -1;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.swiper-slider-creative h4 {
		font-size: 30px;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.swiper-slider-creative .swiper-slide-2 {
		background-position-x: 60%;
	}
	.swiper-slider-creative .swiper-slide-3 {
		background-position-x: 55%;
	}
}

@media (min-width: 768px) {
	.swiper-slider-creative .swiper-pagination {
		bottom: 30px;
	}
}

@media (min-width: 768px) and (max-width: 1199.98px) {
	.swiper-slider-creative .swiper-slide-2 {
		background-position-x: 70%;
	}
	.swiper-slider-creative .swiper-slide-3 {
		background-position-x: 60%;
	}
}

@media (min-width: 992px) {
	.swiper-slider-creative * + .button,
	.swiper-slider-creative * + .button-wrap {
		margin-top: 40px;
	}
}

@media (min-width: 1200px) {
	.swiper-slider-creative h1 {
		font-size: 110px;
	}
}

@media (min-width: 1600px) {
	.swiper-slider-creative .swiper-pagination {
		top: 50%;
		right: 134px;
		bottom: auto;
		left: auto;
		width: auto;
		transform: translate3d(0, -50%, 0);
	}
	.swiper-slider-creative .swiper-pagination .swiper-pagination-bullet {
		margin: 4px 0;
	}
	.swiper-slider-creative .swiper-pagination-bullet {
		display: block;
		width: 21px;
		height: 21px;
	}
	.swiper-slider-creative .swiper-pagination-bullet::before {
		display: inline-block;
		position: absolute;
		content: counter(span, decimal);
		counter-increment: span;
		top: 60%;
		left: -22px;
		font-size: 24px;
		line-height: 1;
		letter-spacing: .05em;
		font-weight: 500;
		font-family: "Montserrat", sans-serif, Arial, sans-serif;
		color: #ffffff;
		opacity: 0;
		visibility: hidden;
		transform: translate3d(-20px, -50%, 0);
		will-change: transform;
		transition: inherit;
	}
	.swiper-slider-creative .swiper-pagination-bullet::after {
		left: 1px;
		width: 5px;
		height: 5px;
		transform: scale(0, 0) translate3d(0, -50%, 0) rotate(0.1deg);
		transform-origin: 50% 0;
	}
	.swiper-slider-creative .swiper-pagination-bullet span {
		height: 1px;
	}
	.swiper-slider-creative .swiper-pagination-bullet:hover::after,
	.swiper-slider-creative .swiper-pagination-bullet-active::after {
		transform: scale(1, 1) translate3d(0, -50%, 0) rotate(0.1deg);
		transition-delay: 0s;
	}
	.swiper-slider-creative .swiper-pagination-bullet:hover span,
	.swiper-slider-creative .swiper-pagination-bullet-active span {
		width: 100%;
		transform: translate3d(24px, 0, 0);
	}
	.swiper-slider-creative .swiper-pagination-bullet-active::before {
		opacity: 1;
		visibility: visible;
		transform: translate3d(0, -50%, 0);
	}
	.swiper-slider-creative .swiper-pagination-bullet-active span {
		width: 340%;
	}
	.swiper-slider-creative .swiper-pagination-bullet-active:hover span {
		width: 340%;
	}
}

.swiper-slider-corporate {
	min-height: calc(100vh - 56px);
}

.swiper-slider-corporate.swiper-container-horizontal .swiper-pagination .swiper-pagination-bullet {
	margin: 0 12px;
}

.swiper-slider-corporate.swiper-container-vertical {
	max-height: 35.3125vw;
}

.swiper-slider-corporate.swiper-container-vertical .swiper-pagination-bullet {
	width: 12px;
	height: 12px;
}

@media (max-height: 370px) {
	.swiper-slider-corporate {
		min-height: 310px;
	}
}

.swiper-slider-corporate .swiper-pagination-bullet::before {
	background: rgba(255, 255, 255, 0.33);
}

.swiper-slider-corporate .swiper-pagination-bullet::after {
	background: #01b3a7;
}

.swiper-slider-corporate .swiper-pagination-bullet:hover::before {
	background: rgba(255, 255, 255, 0.7);
}

.swiper-slider-corporate .button {
	min-width: 160px;
	padding: 17px 18px 15px;
}

.swiper-slider-corporate h6 {
	letter-spacing: .050em;
	color: #ffffff;
	font-weight: 500;
	font-size: 16px;
}

.swiper-slider-corporate h2 {
	font-size: 0;
	line-height: 0;
	text-indent: 0;
}

.swiper-slider-corporate h2 span {
	font-size: 36px;
	line-height: 1.1;
}

@media (min-width: 768px) {
	.swiper-slider-corporate h2 span {
		font-size: 50px;
	}
}

@media (min-width: 992px) {
	.swiper-slider-corporate h2 span {
		font-size: 56px;
	}
}

@media (min-width: 1200px) {
	.swiper-slider-corporate h2 span {
		font-size: 64px;
		line-height: 1.1;
	}
}

.swiper-slider-corporate h6 + h2 {
	margin-top: 16px;
}

.swiper-slider-corporate * + .button-wrap {
	margin-top: 20px;
}

@media (min-width: 768px) {
	.swiper-slider-corporate {
		min-height: 370px;
	}
	.swiper-slider-corporate.swiper-container-vertical {
		min-height: 400px;
	}
	.swiper-slider-corporate h6 + h2 {
		margin-top: 10px;
	}
	.swiper-slider-corporate * + .button-wrap {
		margin-top: 30px;
	}
}

@media (min-width: 992px) {
	.swiper-slider-corporate.swiper-container-horizontal {
		min-height: 400px;
	}
	.swiper-slider-corporate.swiper-container-vertical .swiper-pagination {
		right: 6%;
	}
}

@media (min-width: 1200px) {
	.swiper-slider-corporate.swiper-container-horizontal {
		min-height: 35.3125vw;
	}
}

@media (min-width: 1600px) {
	.swiper-slider-corporate.swiper-container-vertical .swiper-pagination {
		top: 43%;
		right: 11.5%;
		transform: none;
	}
}

.swiper-slider-tara {
	min-height: 35.36458vw;
}

.swiper-slider-tara.swiper-container-horizontal > .swiper-pagination .swiper-pagination-bullet {
	margin: 0 6px;
}

.swiper-slider-tara .swiper-pagination {
	bottom: 0;
	left: 50%;
	transform: translate3d(-50%, 0, 0);
	width: auto;
	min-width: 155px;
	font-size: 0;
	line-height: 0;
	padding: 17px 20px 10px;
	background: #ffffff;
}

.swiper-slider-tara .swiper-pagination-bullet {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: transparent;
	border: 3px solid #d7d7d7;
	transition: all .1s ease-in-out;
}

.swiper-slider-tara .swiper-pagination-bullet::before, .swiper-slider-tara .swiper-pagination-bullet::after {
	position: absolute;
	content: '';
	opacity: 0;
	border-radius: inherit;
}

.swiper-slider-tara .swiper-pagination-bullet::before {
	top: 50%;
	left: 50%;
	width: 6px;
	height: 6px;
	background: #01b3a7;
	transform: translate3d(-50%, -400%, 0);
	transition: all .2s ease;
}

.swiper-slider-tara .swiper-pagination-bullet::after {
	top: -3px;
	right: -3px;
	bottom: -3px;
	left: -3px;
	border: 3px solid #50ba87;
	transform: scale(1.6);
	transition: all .3s ease;
}

.swiper-slider-tara .swiper-pagination-bullet-active::before, .swiper-slider-tara .swiper-pagination-bullet:hover::before {
	opacity: 1;
	transform: translate3d(-50%, -50%, 0);
}

.swiper-slider-tara .swiper-pagination-bullet-active {
	border-color: transparent;
}

.swiper-slider-tara .swiper-pagination-bullet-active::after {
	opacity: 1;
	transform: none;
}

.swiper-slider-tara .swiper-title {
	font-weight: 300;
}

.swiper-slider-tara .swiper-subtitle {
	font-weight: 400;
	color: #01b3a7;
}

.swiper-slider-tara .button {
	min-width: 176px;
}

.swiper-slider-tara .button.button-ujarak:not([class*='button-primary'])::before {
	background: #01b3a7;
}

.swiper-slider-tara .button.button-ujarak:not([class*='button-primary']):hover, .swiper-slider-tara .button.button-ujarak:not([class*='button-primary']):active {
	border-color: #01b3a7;
}

.swiper-slider-tara * + .swiper-title {
	margin-top: 16px;
}

.swiper-slider-tara * + .button,
.swiper-slider-tara * + .button-wrap {
	margin-top: 20px;
}

@media (max-width: 575.98px) {
	.swiper-slider-tara .swiper-slide::before {
		position: absolute;
		content: '';
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(255, 255, 255, 0.2);
		pointer-events: none;
	}
}

@media (min-width: 768px) {
	.swiper-slider-tara .swiper-pagination {
		right: 0;
		left: auto;
		transform: none;
		padding-bottom: 17px;
	}
}

@media (min-width: 992px) {
	.swiper-slider-tara .swiper-title {
		line-height: 1.2;
	}
	.swiper-slider-tara .swiper-subtitle {
		letter-spacing: .15em;
	}
	.swiper-slider-tara * + .swiper-title {
		margin-top: 22px;
	}
}

.google-map-markers {
	display: none;
}

.google-map-container {
	width: 100%;
}

.google-map {
	height: 300px;
	width: 100%;
}

@media (min-width: 1200px) {
	.google-map {
		height: 455px;
	}
}

/*
*
* Search Results
*/
.rd-search-results-live {
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	max-height: 600px;
	overflow-y: auto;
	margin: -3px 0 0;
	text-align: left;
	z-index: 998;
}

.rd-search-results-live #search-results {
	position: relative;
	padding: 16px 0 0;
	margin: 15px 5px 5px;
	color: #151515;
	background: #ffffff;
	opacity: 0;
	visibility: hidden;
	transform-origin: 50% 0;
	transform: scale(1, 0.9);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
	transition: .3s all ease;
}

.rd-search-results-live #search-results::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
	z-index: -1;
	border: none;
	border-top: 0;
}

.rd-search-results-live #search-results.active {
	opacity: 1;
	visibility: visible;
	transform: scale(1, 1);
}

.rd-search-results-live .search-quick-result {
	padding-left: 15px;
	padding-right: 15px;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	text-transform: uppercase;
	color: #151515;
}

.rd-search-results-live .search-list {
	margin-top: 20px;
}

.rd-search-results-live .search-list li:only-child {
	padding: 0 15px 15px;
}

.rd-search-results-live .search-link {
	color: inherit;
}

.rd-search-results-live .search-link:hover {
	color: #01b3a7;
}

.rd-search-results-live .search-error {
	font-size: 14px;
	line-height: 1.6;
}

.rd-search-results-live .search-title {
	position: relative;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	font-size: 16px;
	font-weight: 700;
	color: #151515;
}

.rd-search-results-live .search-title a:hover {
	color: #01b3a7;
}

.rd-search-results-live .search-list-item-all {
	margin-top: 18px;
	width: 100%;
}

.rd-search-results-live .search-submit {
	position: relative;
	overflow: hidden;
	z-index: 0;
	display: block;
	padding: 8px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.075em;
	text-transform: uppercase;
	text-align: center;
	color: #ffffff;
	background: #01b3a7;
}

.rd-search-results-live .search-submit:hover {
	color: #ffffff;
	background: #50ba87;
}

.rd-search-results-live .match {
	display: none;
}

.not-empty ~ .rd-search-results-live {
	visibility: visible;
	opacity: 1;
}

.rd-search-results-live p {
	font-size: 14px;
}

.rd-search-results-live p * {
	margin: 0;
}

.rd-search-results-live .search-list-item {
	padding: 0 15px;
}

.rd-search-results-live * + p {
	margin-top: 5px;
}

.rd-search-results-live .search-list-item + .search-list-item {
	margin-top: 17px;
}

.rd-search-results .search-list {
	counter-reset: li;
	text-align: left;
	padding-left: 0;
	font-size: 18px;
	list-style-type: none;
	overflow: hidden;
}

.rd-search-results .search-list li div {
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	white-space: nowrap;
}

.rd-search-results .search-list li:only-child::before {
	display: none;
}

.rd-search-results .search-list-item {
	position: relative;
	padding-left: 40px;
	font-size: 14px;
	color: #9b9b9b;
}

.rd-search-results .search-list-item::before {
	content: counter(li, decimal-leading-zero) ".";
	counter-increment: li;
	position: absolute;
	left: 0;
	top: 0;
	font-size: 16px;
	line-height: 1;
	font-weight: 600;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #000000;
}

.rd-search-results .search-list-item + .search-list-item {
	margin-top: 40px;
}

.rd-search-results .search-title {
	font-size: 16px;
	color: #000000;
}

.rd-search-results .search {
	color: #ffffff;
	padding: 0 .25em;
	background: #01b3a7;
}

.rd-search-results .match {
	padding: 5px;
	font-size: 12px;
	line-height: 1.7;
	letter-spacing: .1em;
	text-transform: uppercase;
	color: #000000;
}

.rd-search-results .match em {
	margin: 0;
	font-style: normal;
}

.rd-search-results p * {
	margin: 0;
}

.rd-search-results * + .match {
	margin-top: 10px;
}

@media (min-width: 768px) {
	.rd-search-results .search-title {
		font-size: 18px;
	}
	.rd-search-results .search-list-item::before {
		top: 0;
		font-size: 16px;
	}
	.rd-search-results * + p {
		margin-top: 12px;
	}
}

@media (min-width: 992px) {
	.rd-search-results .search-list-item {
		padding-left: 40px;
	}
}

* + .rd-search-results {
	margin-top: 40px;
}

@media (min-width: 768px) {
	* + .rd-search-results {
		margin-top: 55px;
	}
}

.rd-search-classic {
	position: relative;
}

.rd-search-classic .form-input {
	padding-right: 50px;
}

.rd-search-classic .form-input,
.rd-search-classic .form-label {
	letter-spacing: 0;
}

.rd-search-classic .rd-search-submit {
	background: none;
	border: none;
	display: inline-block;
	padding: 0;
	outline: none;
	outline-offset: 0;
	cursor: pointer;
	-webkit-appearance: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 50px;
	padding-top: 3px;
	padding-right: 2px;
	margin: 0;
	text-align: center;
	color: #cccccc;
	font-size: 0;
	line-height: 0;
}

.rd-search-classic .rd-search-submit::-moz-focus-inner {
	border: none;
	padding: 0;
}

.rd-search-classic .rd-search-submit:before {
	position: relative;
	top: -1px;
	content: '\e014';
	font: 400 20px 'fl-chapps';
	line-height: 1;
	transition: .33s all ease;
}

.rd-search-classic .rd-search-submit:hover {
	color: #01b3a7;
}

.rd-search.rd-search-inline {
	position: relative;
}

.rd-search.rd-search-inline .form-input {
	padding-right: 60px;
}

.rd-search.rd-search-inline .button-link {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 60px;
	right: 0;
	margin: 0;
	font-size: 21px;
	color: #cccccc;
	transition: .33s;
}

.rd-search.rd-search-inline .button-link::before {
	display: block;
	margin: auto;
}

.rd-search.rd-search-inline .button-link:hover {
	color: #01b3a7;
}

.rd-search.rd-search-inline.form-sm .form-input {
	padding-right: 40px;
}

.rd-search.rd-search-inline.form-sm .button-link {
	width: 40px;
	font-size: 18px;
}

.rd-search.rd-search-inline.form-lg .form-input {
	padding-right: 60px;
}

.rd-search.rd-search-inline.form-lg .button-link {
	width: 60px;
}

.form-search .form-label {
	letter-spacing: .05em;
}

.button-search {
	position: absolute;
	text-align: center;
	padding: 0 5px;
	right: 0;
	top: 0;
	height: 50px;
	width: 60px;
	font-size: 20px;
	line-height: 50px;
	border: none;
	outline: none;
	box-shadow: none;
	background: transparent;
	cursor: pointer;
	transition: all 250ms ease;
}

.button-search:hover {
	color: #01b3a7;
}

.form-product-search .form-input {
	min-height: 50px;
	padding-top: 12px;
	padding-right: 55px;
	padding-bottom: 12px;
	letter-spacing: .05em;
	background: #ffffff;
	border-color: #e1e1e1;
}

.form-product-search .form-label {
	top: 25px;
	padding-right: 55px;
}

.form-post-search .form-input {
	padding-top: 16px;
	padding-bottom: 16px;
	padding-right: 65px;
	background: #ffffff;
	border-width: 2px;
	border-color: #151515;
}

.form-post-search .form-label {
	padding-right: 65px;
}

.form-post-search .button-search {
	width: 70px;
	height: 60px;
	font-size: 24px;
}

.rd-navbar--is-stuck .rd-search-results-live #search-results,
.rd-navbar--is-clone .rd-search-results-live #search-results {
	margin-top: 10px;
}

/*
*
* Isotope
*/
@keyframes sprite-animation {
	from {
		background-position: 0 0;
	}
}

[data-isotope-layout] {
	z-index: 0;
	display: block;
	min-height: 160px;
	margin-left: -15px;
	margin-right: -15px;
	transition: .4s all ease;
}

[data-isotope-layout]:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 64px;
	height: 64px;
	margin-top: 15px;
	background-position: -1152px 0;
	animation: 0.7s sprite-animation steps(18) infinite;
	transition: .4s all ease;
	transform: translate3d(-50%, -50%, 0);
}

[data-isotope-layout] .row {
	margin-bottom: -30px;
}

[data-isotope-layout] .row > [class*="col-"] {
	margin-top: 30px;
}

[data-isotope-layout] .row-narrow {
	margin-bottom: -30px;
}

[data-isotope-layout] .row-narrow > [class*="col-"] {
	margin-top: 30px;
}

[data-isotope-layout] .row-condensed {
	margin-bottom: 0;
}

[data-isotope-layout] .row-condensed > [class*="col-"] {
	margin-top: 0;
}

[data-isotope-layout] [class*="col-"] {
	display: block;
	opacity: 0;
	will-change: transform;
	backface-visibility: hidden;
	transition: .1s opacity ease-in;
}

@media (max-width: 575.98px) {
	[data-isotope-layout] [class*="col-"] {
		max-width: 100%;
	}
}

[data-isotope-layout].isotope--loaded [class*="col-"] {
	opacity: 1;
}

[data-isotope-layout].isotope--loaded:after {
	opacity: 0;
	visibility: hidden;
}

@media (max-width: 767.98px) {
	[data-isotope-layout] .thumbnail {
		max-width: none;
	}
}

.isotope-filters {
	position: relative;
}

.isotope-filters > * {
	margin-top: 0;
	vertical-align: middle;
}

.isotope-filters .inline-list {
	position: relative;
	word-spacing: 0;
}

.isotope-filters .inline-list li {
	display: inline-block;
}

.isotope-filters .inline-list a {
	position: relative;
	transition: .3s;
	color: #cccccc;
}

.isotope-filters .inline-list .isotope-filters-trigger {
	display: none;
}

* + .isotope-filters {
	margin-top: 20px;
}

h3 + .isotope-filters {
	margin-top: 14px;
}

.isotope-filters-modern .inline-list {
	transform: translate3d(0, -10px, 0);
	margin-bottom: -10px;
	margin-left: -10px;
	margin-right: -10px;
}

.isotope-filters-modern .inline-list > * {
	margin-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
}

.isotope-filters-modern .inline-list > li {
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	font-size: 18px;
}

.isotope-filters-modern .inline-list a {
	position: relative;
	padding-bottom: 10px;
}

.isotope-filters-modern .inline-list a::after {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 0;
	height: 2px;
	transition: .4s;
	background: #01b3a7;
}

.isotope-filters-modern .inline-list a:hover {
	color: #01b3a7;
}

.isotope-filters-modern .inline-list a.active::after {
	width: 100%;
}

@media (min-width: 992px) {
	.isotope-filters-modern .inline-list {
		transform: translate3d(0, -10px, 0);
		margin-bottom: -10px;
		margin-left: -23px;
		margin-right: -23px;
	}
	.isotope-filters-modern .inline-list > * {
		margin-top: 10px;
		padding-left: 23px;
		padding-right: 23px;
	}
}

.isotope-modern-wrap [class*='col'] {
	margin-top: 30px;
}

@media (max-width: 767.98px) {
	.isotope-modern-wrap [class*='col'] {
		width: 100%;
		max-width: 100%;
	}
}

@media (min-width: 992px) {
	html:not(.tablet):not(.mobile) .isotope-modern-wrap [class*='col'] {
		margin-top: 0;
	}
}

.isotope-condensed {
	margin-left: 0;
	margin-right: 0;
}

.isotope-condensed .row > * {
	margin-top: -1px;
	margin-bottom: -1px;
}

* + .isotope {
	margin-top: 45px;
}

* + .isotope-condensed {
	margin-top: 30px;
}

@media (max-width: 991.98px) {
	.isotope-condensed {
		padding: 0 0 30px;
		margin-left: 0;
		margin-right: 0;
	}
	.isotope-condensed .row.row-condensed > [class*='col'] {
		padding-left: 10px;
		padding-right: 10px;
	}
	.isotope-condensed .row.row-condensed > [class*='col'] + [class*='col'] {
		margin-top: 25px;
	}
}

@media (min-width: 768px) {
	* + .isotope-condensed {
		margin-top: 50px;
	}
}

.isotope-filters .isotope-filters-toggle .icon {
	top: -2px;
	transition: transform .2s ease;
}

.isotope-filters .isotope-filters-toggle.button-icon-right .icon {
	margin-left: 8px;
	padding-left: 0;
}

.isotope-filters .isotope-filters-toggle.active .icon {
	transform: rotate(-180deg);
}

[class*='isotope-filters-list'] {
	position: absolute;
	top: calc(100% + 20px);
	left: 50%;
	max-width: 280px;
	width: 100%;
	padding: 20px;
	font-size: 18px;
	line-height: 1.2;
	font-weight: 500;
	letter-spacing: .1em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #9b9b9b;
	background: #ffffff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
	transform: translate3d(-50%, 0, 0);
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease-in-out;
	z-index: 1;
}

[class*='isotope-filters-list'].active {
	opacity: 1;
	visibility: visible;
}

[class*='isotope-filters-list'] a {
	display: block;
	padding: 9px 5px 5px;
}

[class*='isotope-filters-list'] a, [class*='isotope-filters-list'] a:focus, [class*='isotope-filters-list'] a:active {
	color: inherit;
}

[class*='isotope-filters-list'] a:hover {
	color: #ffffff;
}

[class*='isotope-filters-list'] a:hover {
	color: #50ba87;
}

[class*='isotope-filters-list'] a.active {
	color: #ffffff;
	background: #50ba87;
}

@media (min-width: 768px) {
	.isotope-filters {
		overflow: hidden;
	}
	[class*='isotope-filters-list'] {
		position: static;
		text-align: center;
		max-width: none;
		top: auto;
		left: auto;
		width: auto;
		padding: 0;
		box-shadow: none;
		background: transparent;
		transform: none;
		visibility: visible;
		opacity: 1;
	}
	.isotope-filters-list {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		margin-left: -21px;
		margin-right: -21px;
		margin-bottom: -5px;
	}
	.isotope-filters-list > * {
		margin-bottom: 5px;
	}
	.isotope-filters-list > li {
		position: relative;
		padding: 0 24px;
	}
	.isotope-filters-list > li + li::before {
		position: absolute;
		content: '';
		top: 0;
		left: -2px;
		height: 15px;
		width: 1px;
		background: #d7d7d7;
	}
	.isotope-filters-list a {
		display: inline;
		padding: 0;
	}
	.isotope-filters-list a, .isotope-filters-list a:focus, .isotope-filters-list a:active {
		color: inherit;
	}
	.isotope-filters-list a:hover {
		color: #50ba87;
	}
	.isotope-filters-list a.active {
		color: #50ba87;
		background: transparent;
	}
	.isotope-filters-list-2 a, .isotope-filters-list-2 a:focus, .isotope-filters-list-2 a:active {
		color: inherit;
	}
	.isotope-filters-list-2 a:hover {
		color: #01b3a7;
	}
	.isotope-filters-list-2 a.active {
		color: #01b3a7;
	}
	.isotope-filters .isotope-filters-toggle {
		display: none;
	}
	.isotope-filters-list-classic {
		text-align: right;
		font-size: 16px;
		letter-spacing: .075em;
		margin-bottom: -10px;
		margin-left: -8px;
	}
	.isotope-filters-list-classic:empty {
		margin-bottom: 0;
		margin-left: 0;
	}
	.isotope-filters-list-classic > * {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 10px;
		margin-left: 8px;
	}
	.isotope-filters-list-classic li a {
		position: relative;
		text-align: center;
		padding: 13px 14px 8px;
		color: #151515;
		border: 2px solid #151515;
	}
	.isotope-filters-list-classic li a::before, .isotope-filters-list-classic li a::after {
		position: absolute;
		content: '';
		top: -2px;
		right: -2px;
		bottom: -2px;
		left: -2px;
		border: 2px solid transparent;
		transition: color .2s ease-in-out, transform .3s ease;
		will-change: transform;
	}
	.isotope-filters-list-classic li a::before {
		transform: scaleX(0);
	}
	.isotope-filters-list-classic li a::after {
		transform: scaleY(0);
	}
	.isotope-filters-list-classic li a:hover, .isotope-filters-list-classic li a.active {
		color: #01b3a7;
		background: transparent;
		border-color: transparent;
	}
	.isotope-filters-list-classic li a:hover::before, .isotope-filters-list-classic li a:hover::after, .isotope-filters-list-classic li a.active::before, .isotope-filters-list-classic li a.active::after {
		transform: none;
	}
	.isotope-filters-list-classic li a:hover::before, .isotope-filters-list-classic li a.active::before {
		border-color: #01b3a7 transparent #01b3a7 transparent;
	}
	.isotope-filters-list-classic li a:hover::after, .isotope-filters-list-classic li a.active::after {
		border-color: transparent #01b3a7 transparent #01b3a7;
	}
}

@media (min-width: 992px) {
	.isotope-filters-list-classic li a {
		min-width: 158px;
	}
}

@media (min-width: 768px) {
	.row-isotope {
		margin-left: -0.85%;
		margin-right: -0.85%;
		margin-bottom: -1.7%;
	}
	.row-isotope > * {
		margin-bottom: 1.7%;
		padding-left: 0.85%;
		padding-right: 0.85%;
	}
}

@media (min-width: 768px) {
	.isotope-top-panel {
		display: flex;
		justify-content: space-between;
		margin-bottom: -20px;
		margin-left: -30px;
	}
	.isotope-top-panel:empty {
		margin-bottom: 0;
		margin-left: 0;
	}
	.isotope-top-panel > * {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 20px;
		margin-left: 30px;
	}
	.isotope-top-panel * + .isotope-filters {
		margin-top: 0;
	}
	.isotope-top-panel-title {
		flex-shrink: 0;
	}
}

.isotope-content {
	position: relative;
	padding-top: 50px;
}

.isotope-content::before, .isotope-content::after {
	position: absolute;
	content: '';
	top: 0;
	height: 1px;
	width: 50%;
	opacity: .21;
}

.isotope-content::before {
	left: 0;
	background: linear-gradient(to right, #d1d1d1 0%, #3b3b3b 100%);
}

.isotope-content::after {
	right: 0;
	background: linear-gradient(to right, #3b3b3b 0%, #d1d1d1 100%);
}

* + .isotope-content {
	margin-top: 30px;
}

.tabs-custom {
	text-align: left;
}

.tabs-custom .nav-tabs {
	font-size: 0;
	line-height: 0;
	word-spacing: 0;
	border: 0;
	justify-content: center;
}

.tabs-custom .nav-tabs:before, .tabs-custom .nav-tabs:after {
	display: none;
}

.tabs-custom .nav-item {
	float: none;
	margin: 0;
	border: 0;
	cursor: pointer;
	transition: .33s all ease;
}

.tabs-custom .nav-link {
	margin: 0;
	border-radius: 0;
	border: 0;
	color: #9b9b9b;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.tabs-custom .nav-link.active {
	cursor: default;
}

.tab-content > .tab-pane {
	display: block;
	visibility: hidden;
	height: 0;
	overflow: hidden;
}

.tab-content > .tab-pane.active {
	height: auto;
}

.ios .tab-height, .mac-os .tab-height {
	height: 88px;
}

.tab-pane .button-pipaluk {
	padding: 20px 33px 19px;
}

.tab-pane .button-black-outline {
	padding: 16px 33px 16px;
}

.tab-content > .active {
	visibility: visible;
	overflow: visible;
}

* + .tabs-line {
	margin-top: 24px;
}

* + .tabs-line-big {
	margin-top: 28px;
}

* + .tabs-line-2 {
	margin-top: 26px;
}

* + .tabs-horizontal.tabs-corporate {
	margin-top: 25px;
}

h1 + .tabs-line {
	margin-top: 22px;
}

.row + .tabs-corporate {
	margin-top: 66px;
}

.tabs-line .nav-link {
	padding: 10px 10px 6px;
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 400;
	line-height: 1;
	letter-spacing: .075em;
	text-align: center;
	vertical-align: middle;
}

.tabs-line .nav-link-big {
	font-size: 24px;
}

.tabs-line .nav-link-small {
	font-size: 14px;
	font-weight: 500;
	letter-spacing: .025em;
}

.tabs-line * + .tab-content {
	margin-top: 20px;
}

@media (max-width: 767.98px) {
	.tabs-line .nav-tabs {
		margin-bottom: -10px;
	}
	.tabs-line .nav-item {
		margin-bottom: 10px;
	}
	.tabs-line .nav-link {
		border-width: 1px 0 1px 0;
		border-style: solid;
		border-color: #e1e1e1;
	}
	.tabs-line .nav-link:hover,
	.tabs-line .nav-link.active {
		color: #01b3a7;
	}
	.tabs-line .nav-link.active {
		border-color: #01b3a7;
	}
}

@media (min-width: 768px) {
	.tabs-line .nav-link {
		font-size: 16px;
	}
	.tabs-line .nav-link-big {
		font-size: 36px;
	}
	.tabs-line .nav-link-small {
		font-size: 18px;
	}
}

.tabs-line-big .button {
	min-width: 180px;
}

.tabs-line-big h5 + p {
	margin-top: 8px;
}

.tabs-line-big * + .group-md {
	margin-top: 36px;
}

.tabs-line-style-2 .nav-link {
	letter-spacing: .1em;
}

.tabs-line-style-3 h5 + p {
	margin-top: 10px;
}

.tabs-line-style-3 * + .group-md {
	margin-top: 35px;
}

.tabs-corporate .nav-tabs {
	position: relative;
}

.tabs-corporate .nav-link {
	padding: 12px 0 8px;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 500;
	line-height: 1;
	letter-spacing: .1em;
	color: #9b9b9b;
	background: transparent;
	text-align: center;
	vertical-align: middle;
}

.tabs-corporate .nav-link:hover,
.tabs-corporate .nav-link.active {
	color: #151515;
}

.tabs-corporate .nav-link:hover {
	border-color: transparent;
}

.tabs-corporate .tab-content {
	padding: 20px 0 0;
}

@media (max-width: 767.98px) {
	.tabs-corporate .nav-tabs {
		margin-bottom: 0;
		margin-left: -20px;
	}
	.tabs-corporate .nav-tabs:empty {
		margin-bottom: 0;
		margin-left: 0;
	}
	.tabs-corporate .nav-tabs > * {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 20px;
	}
	.tabs-corporate .nav-link {
		border-width: 1px;
		border-style: solid;
		border-color: transparent;
	}
	.tabs-corporate .nav-link.active {
		border-color: transparent transparent #50ba87 transparent;
	}
}

@media (min-width: 768px) {
	.tabs-horizontal.tabs-corporate .nav-tabs {
		position: relative;
		width: 100%;
		border: 0;
		will-change: transform;
		justify-content: flex-start;
	}
	.tabs-horizontal.tabs-corporate .nav-item {
		will-change: transform;
	}
	.tabs-horizontal.tabs-corporate .nav-link {
		display: block;
		position: relative;
		z-index: 1;
		min-width: 188px;
		letter-spacing: .1em;
		padding: 26px 20px;
		border-width: 1px 1px 0 1px;
		border-style: solid;
		border-color: transparent;
	}
	.tabs-horizontal.tabs-corporate .nav-link.active,
	.tabs-horizontal.tabs-corporate .nav-link:hover {
		color: #151515;
	}
	.tabs-horizontal.tabs-corporate .nav-link.active {
		border-color: #e1e1e1;
	}
	.tabs-horizontal.tabs-corporate .tab-content {
		border: 1px solid #e1e1e1;
	}
	.tabs-horizontal.tabs-line .nav-tabs {
		justify-content: space-between;
		border-bottom: 3px solid #e1e1e1;
	}
	.tabs-horizontal.tabs-line .nav-item {
		display: inline-block;
	}
	.tabs-horizontal.tabs-line .nav-link {
		position: relative;
		padding: 0 0 10px 0;
		background-color: transparent;
		border: 0;
	}
	.tabs-horizontal.tabs-line .nav-link::after {
		content: '';
		position: absolute;
		bottom: -3px;
		left: 0;
		height: 3px;
		width: 0;
		background: #01b3a7;
		transition: .22s ease;
	}
	.tabs-horizontal.tabs-line .nav-link-big {
		padding-bottom: 5px;
	}
	.tabs-horizontal.tabs-line .nav-link-small {
		padding-bottom: 18px;
	}
	.tabs-horizontal.tabs-line .nav-link.active,
	.tabs-horizontal.tabs-line .nav-link:hover {
		color: #01b3a7;
	}
	.tabs-horizontal.tabs-line .nav-link.active::after {
		width: 100%;
	}
	.tabs-horizontal.tabs-line * + .tab-content {
		margin-top: 30px;
	}
	.tabs-horizontal.tabs-line-style-3 * + .tab-content {
		margin-top: 26px;
	}
	.tabs-horizontal.tabs-line-big * + .tab-content {
		margin-top: 26px;
	}
}

@media (min-width: 768px) {
	.tabs-horizontal.tabs-corporate .tab-content {
		padding: 45px 40px;
	}
}

@media (min-width: 768px) {
	.tabs-vertical {
		display: flex;
		align-items: flex-start;
	}
	.tabs-vertical .nav-tabs {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		flex-shrink: 0;
		max-width: 50%;
	}
	.tabs-vertical .nav-item {
		border: 0;
		width: 100%;
		text-align: left;
	}
	.tabs-vertical .tab-content {
		flex-grow: 1;
	}
	.tabs-vertical.tabs-line .nav-tabs {
		width: auto;
		min-width: 167px;
		border: 0;
	}
	.tabs-vertical.tabs-line .nav-item {
		margin: 0;
	}
	.tabs-vertical.tabs-line .nav-link {
		position: relative;
		padding: 21px 0 17px;
		border: 0;
		overflow: hidden;
		text-align: left;
	}
	.tabs-vertical.tabs-line .nav-link.active,
	.tabs-vertical.tabs-line .nav-link:hover {
		color: #01b3a7;
	}
	.tabs-vertical.tabs-line .nav-item + .nav-item {
		border-top: 1px solid #e1e1e1;
	}
	.tabs-vertical.tabs-line .tab-content {
		padding: 0 0 0 35px;
	}
	.tabs-vertical.tabs-line * + .tab-content {
		margin-top: 0;
	}
}

.tabs-custom-wrap {
	display: flex;
	align-items: center;
	min-height: 100%;
	padding: 30px 15px;
	background: #ffffff;
}

@media (min-width: 768px) {
	.tabs-custom-wrap {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (min-width: 1200px) {
	.tabs-custom-wrap {
		padding: 16px 35px 24px;
	}
}

.ie-10 .tabs-custom-wrap,
.ie-11 .tabs-custom-wrap,
.ie-edge .tabs-custom-wrap {
	height: 100%;
}

.card-group-custom {
	margin-bottom: 0;
}

.card-group-custom .card-header + .collapse > .card-body,
.card-group-custom .card-header + .collapse > .list-group {
	border-top: 0;
}

.card-group-custom .card + .card {
	margin-top: 0;
}

.card-group-custom.card-group-corporate .card + .card {
	margin-top: 15px;
}

.card-custom {
	display: block;
	margin: 0;
	background: inherit;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	text-align: left;
}

.card-custom a {
	display: block;
}

.card-custom .card-header {
	padding: 0;
	border-bottom: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.card-custom .card-body {
	padding: 0;
}

* + .card-group-custom {
	margin-top: 20px;
}

.card-corporate {
	text-align: left;
	border: 1px solid #e1e1e1;
}

.card-corporate .card-title {
	margin-bottom: 0;
}

.card-corporate .card-title a,
.card-corporate .card-body {
	background: #ffffff;
}

.card-corporate .card-title a {
	position: relative;
	z-index: 1;
	padding: 33px 72px 29px 30px;
	font-size: 20px;
	line-height: 1.2;
	font-weight: 400;
	letter-spacing: normal;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #151515;
	border-bottom: 1px solid #e1e1e1;
	transition: all .3s ease;
}

.card-corporate .card-title a:hover {
	color: #01b3a7;
}

.card-corporate .card-title a.collapsed {
	border-color: transparent;
}

.card-corporate .card-title a .card-arrow:after {
	opacity: 0;
	visibility: hidden;
}

.card-corporate .card-title a.collapsed {
	border-radius: 0;
	border-bottom-width: 0;
}

.card-corporate .card-title a.collapsed .card-arrow:after {
	opacity: 1;
	visibility: visible;
}

.card-corporate .card-arrow {
	display: inline-block;
	position: absolute;
	top: 50%;
	right: 10px;
	z-index: 2;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 4px solid #01b3a7;
	transform: translate3d(0, -50%, 0);
	transition: .33s all ease;
}

.card-corporate .card-arrow::before, .card-corporate .card-arrow::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 4;
	transform: translate(-50%, -50%);
	background: #151515;
}

.card-corporate .card-arrow::before {
	width: 15px;
	height: 3px;
}

.card-corporate .card-arrow::after {
	width: 3px;
	height: 15px;
}

.card-corporate .collapse {
	position: relative;
	z-index: 1;
	color: #151515;
	border-radius: 0;
}

.card-corporate .card-body {
	padding: 25px 30px 30px 30px;
	color: #9b9b9b;
}

@media (max-width: 575.98px) {
	.card-corporate .card-body {
		padding-right: 15px;
	}
}

@media (max-width: 767.98px) {
	.card-corporate .card-title a,
	.card-corporate .card-body {
		padding-left: 15px;
	}
	.card-corporate .card-body {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.card-corporate .card-title a {
		padding-top: 23px;
		padding-bottom: 17px;
	}
}

@media (min-width: 768px) {
	.card-corporate .card-arrow {
		right: 18px;
	}
	.card-corporate .card-title a {
		font-size: 20px;
		line-height: 1.2;
		padding-right: 60px;
	}
}

.card-corporate-2 .card-arrow {
	border-color: #50ba87;
}

.card-corporate-2 .card-arrow::before, .card-corporate-2 .card-arrow::after {
	background: #01b3a7;
}

/*
*
* Tooltop Custom
*/
.tooltip {
	font-size: 14px;
	line-height: 1.2;
	letter-spacing: .05em;
	z-index: 1010;
}

.tooltip.bs-tooltip-top .arrow::before {
	border-top-color: #151515;
}

.tooltip.bs-tooltip-right .arrow::before {
	border-right-color: #151515;
}

.tooltip.bs-tooltip-bottom .arrow::before {
	border-bottom-color: #151515;
}

.tooltip.bs-tooltip-left .arrow::before {
	border-left-color: #151515;
}

.tooltip-inner {
	padding: 4px 11px;
	background-color: #151515;
}

/*
*
* Counter
*/
.counter-modern {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: left;
	min-height: 220px;
	padding: 20px;
	z-index: 1;
}

.counter-modern::before {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 6px solid #ebebeb;
	transition: all .3s ease;
	z-index: -1;
}

.counter-modern-2 .counter-modern-decor {
	background: #50ba87;
}

.counter-modern-number {
	font-size: 60px;
	line-height: 0.6;
	letter-spacing: .025em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.counter-modern-number > * {
	display: inline-block;
}

.counter-modern-decor {
	width: 100%;
	height: 2px;
	margin: 16px 0 16px auto;
	background: #01b3a7;
	transition: all .3s ease;
}

.counter-modern-title {
	display: inline-block;
	font-weight: 300;
	letter-spacing: .05em;
}

@media (min-width: 992px) {
	.desktop .counter-modern::before {
		border-width: 22px;
	}
	.desktop .counter-modern-decor {
		width: 0;
		margin-top: 8px;
		margin-bottom: 8px;
	}
	.desktop .counter-modern:hover::before {
		border-width: 10px;
	}
	.desktop .counter-modern:hover .counter-modern-decor {
		width: 100%;
		margin-top: 24px;
		margin-bottom: 24px;
	}
}

@media (min-width: 768px) {
	.counter-modern {
		padding: 35px;
		min-height: 320px;
	}
	.counter-modern::before {
		border-width: 10px;
	}
	.counter-modern-number {
		font-size: 105px;
	}
	.counter-modern-decor {
		margin-top: 24px;
		margin-bottom: 24px;
	}
}

@media (min-width: 992px) {
	.counter-modern {
		min-height: 363px;
	}
}

.ie-edge .counter-modern,
.ie-10 .counter-modern,
.ie-11 .counter-modern {
	height: 320px;
}

@media (min-width: 992px) {
	.ie-edge .counter-modern,
	.ie-10 .counter-modern,
	.ie-11 .counter-modern {
		height: 363px;
	}
}

.counter-classic {
	text-align: center;
}

.counter-classic-number {
	font-size: 50px;
	line-height: 0.8;
	font-weight: 300;
	letter-spacing: .025em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	margin-bottom: 7px;
}

@media (min-width: 992px) {
	.counter-classic-number {
		font-size: 70px;
	}
}

.counter-classic-number > * {
	display: inline-block;
}

.counter-classic-number .symbol {
	font-size: 40px;
	letter-spacing: 0;
}

.counter-classic-title {
	font-weight: 400;
	letter-spacing: .05em;
	font-size: 16px;
	text-transform: uppercase;
}

@media (min-width: 992px) {
	.counter-classic-title {
		font-size: 21px;
	}
}

@media (min-width: 1200px) {
	.counter-classic-number {
		font-size: 110px;
	}
	.counter-classic-number .symbol {
		font-size: 70px;
	}
}

.counter-creative {
	text-align: center;
}

.counter-creative-2 .counter-creative-number {
	color: #50ba87;
}

.counter-creative-number {
	font-size: 70px;
	line-height: .8;
	font-weight: 300;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	text-transform: uppercase;
	letter-spacing: .025em;
}

.counter-creative-number > * {
	display: inline-block;
	vertical-align: top;
}

.counter-creative-icon {
	margin-left: 10px;
	font-size: 42px;
	line-height: 1;
	color: #01b3a7;
}

.counter-creative-title {
	font-weight: 400;
	letter-spacing: .075em;
}

* + .counter-creative-title {
	margin-top: 8px;
}

.counter-abby {
	position: relative;
	padding: 40px 20px;
	min-height: 100%;
	border: 9px solid transparent;
	transition: all .3s ease;
	z-index: 1;
}

.counter-abby::before, .counter-abby::after {
	position: absolute;
	content: '';
	border: 1px solid #151515;
	transition: inherit;
	will-change: transform;
	z-index: -1;
}

.counter-abby::before {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.counter-abby::after {
	top: -9px;
	right: -9px;
	bottom: -9px;
	left: -9px;
}

.counter-abby:hover {
	border-color: #151515;
}

.counter-abby:hover::before {
	transform: scale(0.95);
}

.counter-abby:hover::after {
	transform: scale(1.05);
}

@media (min-width: 992px) {
	.counter-abby {
		padding-top: 60px;
		padding-bottom: 60px;
	}
}

.context-dark .counter-abby::before, .bg-gray-dark .counter-abby::before, .bg-primary .counter-abby::before, .context-dark .counter-abby::after, .bg-gray-dark .counter-abby::after, .bg-primary .counter-abby::after {
	border-color: rgba(255, 255, 255, 0.27);
}

.context-dark .counter-abby:hover, .bg-gray-dark .counter-abby:hover, .bg-primary .counter-abby:hover {
	border-color: rgba(255, 255, 255, 0.27);
}

.counter-amy {
	text-align: center;
	padding: 30px 10px;
}

.counter-amy-number {
	font-size: 110px;
	line-height: 0.8;
	font-weight: 300;
	letter-spacing: .1em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.counter-amy-number > * {
	display: inline-block;
}

.counter-amy-number .symbol {
	font-size: 70px;
	letter-spacing: 0;
	margin-left: -8px;
}

.counter-amy-title {
	font-weight: 400;
	letter-spacing: .1em;
	color: #9b9b9b;
}

* + .counter-amy-title {
	margin-top: 10px;
}

.border-classic > div {
	position: relative;
}

.border-classic > div::before {
	position: absolute;
	display: none;
	content: '';
	left: 50%;
	top: -25px;
	transform: translateX(-50%);
	width: 80%;
	height: 1px;
	background: #d7d7d7;
}

@media (max-width: 575.98px) {
	.border-classic > div + div::before {
		display: inline-block;
	}
}

@media (min-width: 576px) {
	.border-classic > div {
		position: relative;
	}
	.border-classic > div::before {
		top: 50%;
		left: 0;
		width: 1px;
		height: 68px;
		margin-top: -34px;
		transform: none;
	}
	.border-classic > div:nth-child(odd) + div::before {
		display: inline-block;
	}
}

@media (min-width: 992px) {
	.border-classic > div + div::before {
		display: inline-block;
	}
}

@media (min-width: 576px) {
	.border-classic-big > div::before {
		height: 86px;
		margin-top: -43px;
	}
}

.context-dark .border-classic > div::before, .bg-gray-dark .border-classic > div::before, .bg-primary .border-classic > div::before {
	background: rgba(244, 244, 244, 0.55);
}

.border-modern {
	border-width: 1px 0 0 1px;
	border-style: solid;
	border-color: #e1e1e1;
}

.border-modern > div {
	border-width: 0 1px 1px 0;
	border-style: solid;
	border-color: #e1e1e1;
}

.border-creative {
	overflow: hidden;
}

.border-creative > div::before, .border-creative > div::after {
	position: absolute;
	content: '';
	background: #d1d1d1;
}

.border-creative > div::before {
	height: 1px;
	left: 26px;
	right: 26px;
	bottom: -1px;
}

.border-creative > div::after {
	width: 1px;
	top: 26px;
	right: -1px;
	bottom: 26px;
}

* + .border-creative {
	margin-top: 0;
}

.border-2-column > div {
	padding: 0;
	border-top: 1px solid #e1e1e1;
}

.border-2-column > div:nth-child(1) {
	border-top: none;
}

.border-2-column > div:nth-child(1) .counter-amy {
	padding-top: 0;
}

.border-2-column > div:nth-last-child(1) .counter-amy {
	padding-bottom: 0;
}

@media (min-width: 576px) {
	.border-2-column > div:nth-child(2) {
		border-top: none;
	}
	.border-2-column > div:nth-child(2) .counter-amy {
		padding-top: 0;
	}
	.border-2-column > div:nth-last-child(2) .counter-amy {
		padding-bottom: 0;
	}
	.border-2-column > div:nth-child(even) {
		border-left: 1px solid #e1e1e1;
	}
	.border-2-column > div:nth-child(even) .counter-amy {
		padding-right: 0;
	}
	.border-2-column > div:nth-child(odd) .counter-amy {
		padding-left: 0;
	}
}

.context-dark .border-2-column > div, .bg-gray-dark .border-2-column > div, .bg-primary .border-2-column > div {
	border-color: rgba(255, 255, 255, 0.1);
}

.box-ordered {
	counter-reset: div;
}

.box-ordered .box-ordered-item::before {
	content: counter(div, decimal-leading-zero);
	counter-increment: div;
}

.box-ordered-2 {
	counter-reset: div;
}

.box-ordered-2 .box-ordered-item::before {
	content: counter(div, decimal);
	counter-increment: div;
}

/*
*
* Progress Bars
*/
.progress-linear {
	position: relative;
	text-align: left;
	font-size: 18px;
	line-height: 1;
	letter-spacing: .075em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #9b9b9b;
}

.progress-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.progress-bar-linear-wrap {
	height: 6px;
	border-radius: 3px;
	background: #dddddd;
}

.progress-bar-linear {
	position: relative;
	width: 0;
	height: inherit;
	background: #01b3a7;
	transition: .5s all ease-in-out;
}

.progress-value::after {
	content: "%";
}

.progress-value {
	position: absolute;
	bottom: 100%;
	right: -4px;
	margin-bottom: 12px;
	transform: translate3d(50%, 0, 0);
	will-change: transform;
}

.progress-marker {
	display: inline-block;
	position: absolute;
	top: 50%;
	right: 0;
	width: 22px;
	height: 22px;
	background: #01b3a7;
	border: 7px solid #ffffff;
	border-radius: 50%;
	transform: translate(50%, -50%);
	box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.1);
}

.progress-secondary .progress-bar-linear {
	background: #50ba87;
}

.progress-secondary .progress-marker {
	background: #50ba87;
}

.progress-orange .progress-bar-linear {
	background: #e7a855;
}

.progress-orange .progress-marker {
	background: #e7a855;
}

* + .progress-bar-linear-wrap {
	margin-top: 12px;
}

* + .progress-linear {
	margin-top: 35px;
}

.progress-linear + .progress-linear {
	margin-top: 20px;
}

@media (min-width: 992px) {
	.progress-linear + .progress-linear {
		margin-top: 30px;
	}
}

/*
*
* Circle Progress Bars
*/
.progress-bar-circle {
	display: inline-block;
	position: relative;
	text-align: center;
	line-height: 1.2;
	letter-spacing: .05em;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #151515;
	z-index: 1;
}

.progress-bar-circle::before {
	position: absolute;
	content: '';
	top: 2px;
	right: 2px;
	bottom: 2px;
	left: 2px;
	border: 8px solid #e1e1e1;
	border-radius: 50%;
	z-index: -1;
}

.progress-bar-circle canvas {
	vertical-align: middle;
}

.progress-bar-circle span {
	position: absolute;
	top: 53%;
	left: 50%;
	font-size: 48px;
	line-height: 1;
	transform: translate(-50%, -50%);
}

.progress-bar-circle span::after {
	font-size: 36px;
	line-height: 1;
	content: "%";
}

.progress-bar-circle-title {
	font-size: 14px;
	letter-spacing: normal;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	color: #151515;
}

* + .progress-bar-circle-title {
	margin-top: 6px;
}

/*
*
* Time Circles
*/
.time_circles {
	position: relative;
	width: 100%;
}

.time_circles > div {
	position: absolute;
	top: 0 !important;
	bottom: 0;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: -10px;
	text-align: center;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

@media (min-width: 1200px) {
	#DateCountdown {
		width: 100%;
	}
}

.time_circles > div > h4 {
	position: absolute;
	left: 0;
	top: calc(100% + 10px);
	right: 0;
	transform: translateY(-100%);
	margin: 0;
	padding: 0;
	text-align: center;
	font-family: inherit;
	font-size: 16px !important;
	line-height: 1.2 !important;
	color: rgba(255, 255, 255, 0.3);
	text-transform: uppercase;
}

.time_circles > div > h4 + * {
	margin-top: 0;
}

@media (min-width: 768px) {
	.time_circles > div > h4 {
		font-size: 18px !important;
	}
}

@media (min-width: 992px) {
	.time_circles > div > h4 {
		top: calc(100% + 6px);
	}
}

.time_circles > div > span {
	display: block;
	text-align: center;
	margin-top: 12px;
	font-size: 24px !important;
	font-weight: 400;
	line-height: 1 !important;
	color: #111111;
}

@media (min-width: 768px) {
	.time_circles > div > span {
		font-size: 34px !important;
	}
}

@media (min-width: 992px) {
	.time_circles > div > span {
		font-size: 48px !important;
	}
}

.context-dark .time_circles > div > span, .bg-gray-dark .time_circles > div > span, .bg-primary .time_circles > div > span {
	color: #ffffff;
}

.countdown-wrap {
	max-width: 730px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 20px;
}

* + .countdown-wrap {
	margin-top: 35px;
}

* + .DateCountdown {
	margin-top: 20px;
}

.DateCountdown + * {
	margin-top: 40px;
}

.countdown-wrap + .button {
	margin-top: 40px;
}

/*
*
* jScrollPane
*/
.jspContainer {
	overflow: hidden;
	position: relative;
}

.jspPane {
	position: absolute;
	right: 4px;
}

.jspVerticalBar {
	position: absolute;
	top: 0;
	right: 0;
	width: 4px;
	height: 100%;
	background: #e1e1e1;
	pointer-events: auto;
}

.jspHorizontalBar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 16px;
	background: #e1e1e1;
	pointer-events: auto;
}

.jspCap {
	display: none;
}

.jspHorizontalBar .jspCap {
	float: left;
}

.jspTrack {
	background: #e1e1e1;
	position: relative;
}

.jspDrag {
	background: rgba(1, 179, 167, 0.5);
	width: 8px;
	position: relative;
	top: 0;
	left: 0;
	cursor: pointer;
}

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag {
	float: left;
	height: 100%;
}

/*
* Arrow
*/
.jspArrow {
	background: #50506d;
	text-indent: -20000px;
	display: block;
	cursor: pointer;
	padding: 0;
	margin: 0;
}

.jspArrow.jspDisabled {
	cursor: default;
	background: #80808d;
}

.jspVerticalBar .jspArrow {
	height: 16px;
}

.jspHorizontalBar .jspArrow {
	width: 16px;
	float: left;
	height: 100%;
}

.jspVerticalBar .jspArrow:focus {
	outline: none;
}

.jspCorner {
	background: #eeeef4;
	float: left;
	height: 100%;
}

/*
*
* Owl Carousel
*/
.owl-carousel .animated {
	animation-duration: 1000ms;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	animation-name: fadeOut;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
	transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
	cursor: pointer;
	user-select: none;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
	display: none;
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-item .owl-item {
	width: 100%;
}

.owl-carousel .owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	font: 400 40px/80px 'FontAwesome';
	cursor: pointer;
	z-index: 1;
	transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:before {
	content: '\f144';
}

.owl-carousel .owl-video-play-icon:hover {
	transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
}

/*
 * Owl Navigation
 */
.owl-nav.disabled {
	display: none !important;
}

.owl-prev,
.owl-next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font: 400 46px/50px 'FontAwesome';
	color: #cccccc;
	cursor: pointer;
}

.owl-prev:hover,
.owl-next:hover {
	color: #01b3a7;
}

.owl-prev {
	left: 0;
}

.owl-prev::before {
	content: '\f104';
}

.owl-next {
	right: 0;
}

.owl-next::before {
	content: '\f105';
}

/*
 * Owl Pagination
 */
.owl-dots {
	position: absolute;
	text-align: center;
	font-size: 0;
	line-height: 0;
	bottom: 30px;
	width: 100%;
}

.owl-dots.disabled {
	display: none !important;
}

.owl-dot {
	text-align: center;
	display: inline-block;
	position: relative;
	width: 12px;
	height: 12px;
	border: none;
	border-radius: 0;
	margin-left: 6px;
	margin-right: 6px;
	outline: none;
	cursor: pointer;
	transition: .2s;
}

@media (min-width: 992px) {
	.owl-dot {
		margin-left: 4px;
		margin-right: 4px;
	}
}

.owl-dot::before, .owl-dot::after {
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.owl-dot::before {
	background: #9b9b9b;
	transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
}

.owl-dot::after {
	opacity: 0;
	background: #01b3a7;
	transform: translateY(-200%);
	transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s;
}

.owl-dot:only-child {
	display: none;
}

.owl-dot:hover::before, .owl-dot:focus::before {
	background: #151515;
}

.owl-dot.active::after {
	opacity: 1;
	visibility: visible;
	transform: translateY(0%);
	transition: transform 0.3s ease, opacity 0.3s ease;
}

.owl-dot.active::before {
	opacity: 0;
	visibility: hidden;
	transform: translateY(200%);
}

.owl-dots-secondary .owl-dot::after {
	background: #50ba87;
}

.owl-dots-white .owl-dot::before {
	background: rgba(255, 255, 255, 0.8);
}

.owl-dots-white .owl-dot:hover::before, .owl-dots-white .owl-dot:focus::before {
	background: #ffffff;
}

.post .owl-dots {
	bottom: 40px;
}

.owl-carousel.owl-carousel-light-dots .owl-dot {
	border-color: #ffffff;
}

.owl-carousel.owl-carousel-light-dots .owl-dot:hover, .owl-carousel.owl-carousel-light-dots .owl-dot:focus {
	background-color: #ffffff;
}

.owl-carousel.owl-carousel-light-dots .owl-dot.active {
	background-color: #ffffff;
}

.owl-classic .owl-dots {
	position: relative;
	bottom: 0;
	margin-top: 25px;
}

.owl-classic .owl-prev,
.owl-classic .owl-next {
	display: none;
	align-items: center;
	justify-content: space-between;
	height: 40px;
	width: 54px;
	font-size: 0;
	line-height: 40px;
	padding-left: 5px;
	padding-right: 5px;
	transition: all .25s ease;
}

.owl-classic .owl-prev::before, .owl-classic .owl-prev::after,
.owl-classic .owl-next::before,
.owl-classic .owl-next::after {
	display: inline-block;
	vertical-align: middle;
	content: '';
	transition: all .3s ease-in-out;
}

.owl-classic .owl-prev::before,
.owl-classic .owl-next::before {
	height: 2px;
	width: 32px;
	background-color: rgba(21, 21, 21, 0.21);
}

.owl-classic .owl-prev::after,
.owl-classic .owl-next::after {
	width: 0;
	height: 0;
	border-style: solid;
}

.owl-classic .owl-prev:hover,
.owl-classic .owl-next:hover {
	padding-left: 0;
	padding-right: 0;
}

.owl-classic .owl-prev:hover::before,
.owl-classic .owl-next:hover::before {
	background-color: #01b3a7;
}

.owl-classic .owl-prev:hover::after,
.owl-classic .owl-next:hover::after {
	border-left-color: #01b3a7;
	border-right-color: #01b3a7;
}

.owl-classic .owl-prev {
	flex-direction: row-reverse;
	left: -62px;
}

.owl-classic .owl-prev::after {
	border-width: 5px 8px 5px 0;
	border-color: transparent rgba(21, 21, 21, 0.21) transparent transparent;
}

.owl-classic .owl-next {
	right: -62px;
}

.owl-classic .owl-next::after {
	border-width: 5px 0 5px 8px;
	border-color: transparent transparent transparent rgba(21, 21, 21, 0.21);
}

@media (min-width: 1600px) {
	.owl-classic .owl-prev,
	.owl-classic .owl-next {
		display: flex;
	}
	.owl-classic .owl-dots {
		display: none;
	}
}

.owl-modern {
	margin-left: -15px;
	margin-right: -15px;
	width: auto;
}

.owl-modern .owl-stage-outer {
	padding-top: 19px;
	padding-bottom: 15px;
}

.owl-modern .owl-stage-outer::before, .owl-modern .owl-stage-outer::after {
	position: absolute;
	content: '';
}

.owl-modern .owl-stage-outer::before {
	top: 0;
	right: 55px;
	left: 55px;
	height: 18px;
	background: #e8e8e8;
	z-index: -1;
}

.owl-modern .owl-stage-outer::after {
	top: 9px;
	right: 30px;
	left: 30px;
	height: 9px;
	background: #f4f4f4;
	z-index: -1;
}

.owl-modern .owl-dots {
	position: relative;
	bottom: 0;
	margin-top: 17px;
}

.owl-timeline::before {
	position: absolute;
	content: '';
	left: 0;
	bottom: 100px;
	height: 0px;
	width: 100%;
	background: #dddddd;
}

.owl-timeline .owl-prev,
.owl-timeline .owl-next {
	top: 35%;
	transform: none;
}

@media (min-width: 1600px) {
	.owl-timeline::before {
		bottom: 51px;
	}
}

.owl-navbar .owl-dots {
	position: static;
	margin-top: 25px;
}

.owl-navbar .owl-dot:before {
	background: #e7e7e7;
}

.owl-navbar .owl-dot:hover::before, .owl-navbar .owl-dot:focus::before {
	background: #9b9b9b;
}

.owl-products .owl-dots {
	position: relative;
	bottom: 0;
	margin-top: 26px;
}

* + .owl-products {
	margin-top: 40px;
}

.owl-clients .owl-dots {
	position: relative;
	bottom: 0;
	margin-top: 30px;
}

.owl-posts .owl-stage-outer {
	padding-bottom: 10px;
}

.owl-posts .owl-dots {
	position: relative;
	bottom: 0;
	margin-top: 22px;
}

.owl-posts .owl-dot::before {
	background: #d7d7d7;
}

.owl-posts .owl-dot::after {
	background: #50ba87;
}

.owl-posts .owl-dot:hover::before, .owl-posts .owl-dot:focus::before {
	background: #01b3a7;
}

.owl-posts-2 .owl-dot::after {
	background: #01b3a7;
}

.owl-posts-2 .owl-dot:hover::before, .owl-posts-2 .owl-dot:focus::before {
	background: #50ba87;
}

.owl-services .owl-stage-outer {
	padding: 10px;
	margin: -10px;
}

.owl-services .owl-prev {
	left: -10px;
}

.owl-services .owl-next {
	right: -10px;
}

@media (min-width: 576px) {
	.owl-services {
		padding: 0 40px;
	}
	.owl-services .owl-prev,
	.owl-services .owl-next {
		display: flex;
	}
	.owl-services .owl-dots {
		display: none;
	}
}

@media (min-width: 768px) {
	.owl-services .owl-prev {
		left: -25px;
	}
	.owl-services .owl-next {
		right: -25px;
	}
}

@media (min-width: 992px) {
	.owl-services {
		padding: 0;
	}
}

.owl-services-2 .owl-stage-outer {
	padding-top: 30px;
	margin-top: -30px;
}

.owl-team {
	position: static;
}

.owl-team .owl-next,
.owl-team .owl-prev {
	position: absolute;
	display: none;
	top: 0;
	bottom: 0;
	font-size: 0;
	line-height: 0;
	background: rgba(247, 247, 247, 0.7);
	transform: none;
	transition: all .3s ease-in-out;
}

.owl-team .owl-next::before,
.owl-team .owl-prev::before {
	display: none;
}

.owl-team .owl-next:hover,
.owl-team .owl-prev:hover {
	background: rgba(247, 247, 247, 0.4);
}

.owl-team .owl-next {
	right: 0;
	left: calc(50% + 615px);
}

.owl-team .owl-prev {
	left: 0;
	right: calc(50% + 615px);
}

.owl-team .owl-dots {
	position: static;
	margin-top: 35px;
}

@media (min-width: 1200px) {
	.owl-team .owl-stage-outer {
		overflow: visible;
	}
	.owl-team .owl-item:not(.active) .team-creative {
		pointer-events: none;
	}
	.owl-team .owl-next,
	.owl-team .owl-prev {
		display: block;
	}
}

@media (min-width: 1280px) {
	.owl-team .owl-dots {
		display: none;
	}
}

@media (min-width: 1280px) and (max-width: 1399px) {
	.container-custom-2 {
		max-width: 1100px;
	}
	.owl-team {
		max-width: 1070px;
	}
	.owl-team .owl-next {
		left: calc(50% + 564px);
	}
	.owl-team .owl-prev {
		right: calc(50% + 564px);
	}
}

.owl-team-2 .owl-stage-outer {
	padding: 10px;
	margin: -10px;
}

.owl-team-2 .owl-dots {
	position: relative;
	bottom: 0;
	margin-top: 30px;
}

.owl-quote .owl-prev,
.owl-quote .owl-next {
	width: 74px;
}

.owl-quote .owl-prev::before,
.owl-quote .owl-next::before {
	width: 52px;
}

.owl-quote .owl-prev {
	left: -100px;
}

.owl-quote .owl-next {
	right: -100px;
}

.owl-quote .owl-dots {
	margin-top: 30px;
}

@media (min-width: 1400px) {
	.owl-quote .owl-prev,
	.owl-quote .owl-next {
		display: flex;
	}
	.owl-quote .owl-dots {
		display: none;
	}
}

@media (min-width: 1600px) {
	.owl-quote .owl-prev,
	.owl-quote .owl-next {
		width: 94px;
	}
	.owl-quote .owl-prev::before,
	.owl-quote .owl-next::before {
		width: 72px;
	}
	.owl-quote .owl-prev {
		left: -185px;
	}
	.owl-quote .owl-next {
		right: -185px;
	}
}

@media (min-width: 992px) {
	.desktop .owl-quote .owl-stage-outer {
		padding: 35px 0;
	}
	.desktop .owl-quote .owl-dots {
		margin-top: 0;
	}
	.desktop [class*='section-'].section-lg-bottom-10 {
		padding-bottom: 10px;
	}
	.desktop * + .owl-quote {
		margin-top: 0;
	}
}

.owl-quote-2 .owl-stage-outer {
	padding: 10px 10px 25px;
	margin: -10px -10px -25px;
}

.owl-custom-nav {
	font-size: 0;
	line-height: 0;
	letter-spacing: 0;
	margin-bottom: -6px;
	margin-left: -6px;
}

.owl-custom-nav:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.owl-custom-nav > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 6px;
	margin-left: 6px;
}

.owl-custom-nav .owl-prev,
.owl-custom-nav .owl-next {
	position: static;
	display: inline-block;
	top: auto;
	left: auto;
	right: auto;
	width: 33px;
	height: 33px;
	font-size: 22px;
	line-height: 25px;
	color: #01b3a7;
	border: 4px solid #50ba87;
	border-radius: 50%;
	transform: none;
	transition: all .25s ease-in-out;
}

.owl-custom-nav .owl-prev:hover,
.owl-custom-nav .owl-next:hover {
	border-color: #01b3a7;
}

.lg-sub-html, .lg-toolbar {
	background-color: rgba(0, 0, 0, 0.45);
}

@font-face {
	font-family: lg;
	src: url(./fonts/lg.eot?n1z373);
	src: url(./fonts/lg.eot?#iefixn1z373) format("embedded-opentype"), url(./fonts/lg.woff?n1z373) format("woff"), url(./fonts/lg.ttf?n1z373) format("truetype"), url(./fonts/lg.svg?n1z373#lg) format("svg");
	font-weight: 400;
	font-style: normal;
}

.lg-icon {
	font-family: lg;
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
	background-color: rgba(0, 0, 0, 0.45);
	border-radius: 2px;
	color: #999;
	cursor: pointer;
	display: block;
	font-size: 22px;
	margin-top: -10px;
	padding: 8px 10px 9px;
	position: absolute;
	top: 50%;
	z-index: 1080;
	border: none;
	outline: 0;
}

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
	pointer-events: none;
	opacity: .5;
}

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
	color: #fff;
}

.lg-actions .lg-next {
	right: 20px;
}

.lg-actions .lg-next:before {
	content: "\e095";
}

.lg-actions .lg-prev {
	left: 20px;
}

.lg-actions .lg-prev:after {
	content: "\e094";
}

@-webkit-keyframes lg-right-end {
	0%, 100% {
		left: 0;
	}
	50% {
		left: -30px;
	}
}

@-moz-keyframes lg-right-end {
	0%, 100% {
		left: 0;
	}
	50% {
		left: -30px;
	}
}

@-ms-keyframes lg-right-end {
	0%, 100% {
		left: 0;
	}
	50% {
		left: -30px;
	}
}

@keyframes lg-right-end {
	0%, 100% {
		left: 0;
	}
	50% {
		left: -30px;
	}
}

@-webkit-keyframes lg-left-end {
	0%, 100% {
		left: 0;
	}
	50% {
		left: 30px;
	}
}

@-moz-keyframes lg-left-end {
	0%, 100% {
		left: 0;
	}
	50% {
		left: 30px;
	}
}

@-ms-keyframes lg-left-end {
	0%, 100% {
		left: 0;
	}
	50% {
		left: 30px;
	}
}

@keyframes lg-left-end {
	0%, 100% {
		left: 0;
	}
	50% {
		left: 30px;
	}
}

.lg-outer.lg-right-end .lg-object {
	-webkit-animation: lg-right-end .3s;
	-o-animation: lg-right-end .3s;
	animation: lg-right-end .3s;
	position: relative;
}

.lg-outer.lg-left-end .lg-object {
	-webkit-animation: lg-left-end .3s;
	-o-animation: lg-left-end .3s;
	animation: lg-left-end .3s;
	position: relative;
}

.lg-toolbar {
	z-index: 1082;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.lg-toolbar .lg-icon {
	color: #999;
	cursor: pointer;
	float: right;
	font-size: 24px;
	height: 47px;
	line-height: 27px;
	padding: 10px 0;
	text-align: center;
	width: 50px;
	text-decoration: none !important;
	outline: 0;
	-webkit-transition: color .2s linear;
	-o-transition: color .2s linear;
	transition: color .2s linear;
}

.lg-toolbar .lg-icon:hover {
	color: #fff;
}

.lg-toolbar .lg-close:after {
	content: "\e070";
}

.lg-toolbar .lg-download:after {
	content: "\e0f2";
}

.lg-sub-html {
	bottom: 0;
	color: #eee;
	font-size: 16px;
	left: 0;
	padding: 10px 40px;
	position: fixed;
	right: 0;
	text-align: center;
	z-index: 1080;
}

.lg-sub-html h4 {
	margin: 0;
	font-size: 13px;
	font-weight: 700;
}

.lg-sub-html p {
	font-size: 12px;
	margin: 5px 0 0;
}

#lg-counter {
	color: #999;
	display: inline-block;
	font-size: 16px;
	padding-left: 20px;
	padding-top: 12px;
	vertical-align: middle;
}

.lg-next, .lg-prev, .lg-toolbar {
	opacity: 1;
	-webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
	-moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
	-o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
	transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
}

.lg-hide-items .lg-prev {
	opacity: 0;
	-webkit-transform: translate3d(-10px, 0, 0);
	transform: translate3d(-10px, 0, 0);
}

.lg-hide-items .lg-next {
	opacity: 0;
	-webkit-transform: translate3d(10px, 0, 0);
	transform: translate3d(10px, 0, 0);
}

.lg-hide-items .lg-toolbar {
	opacity: 0;
	-webkit-transform: translate3d(0, -10px, 0);
	transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
	-webkit-transform: scale3d(0.5, 0.5, 0.5);
	transform: scale3d(0.5, 0.5, 0.5);
	opacity: 0;
	-webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
	-moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
	-o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
	transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
	opacity: 1;
}

.lg-outer .lg-thumb-outer {
	background-color: #0d0a0a;
	bottom: 0;
	position: absolute;
	width: 100%;
	z-index: 1080;
	max-height: 350px;
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
	-webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
	-moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
	-o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
	transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
	cursor: -webkit-grab;
	cursor: -moz-grab;
	cursor: -o-grab;
	cursor: -ms-grab;
	cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
	cursor: move;
	cursor: -webkit-grabbing;
	cursor: -moz-grabbing;
	cursor: -o-grabbing;
	cursor: -ms-grabbing;
	cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
	-webkit-transition-duration: 0s !important;
	transition-duration: 0s !important;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.lg-outer .lg-thumb {
	padding: 10px 0;
	height: 100%;
	margin-bottom: -5px;
	margin-left: auto;
	margin-right: auto;
}

.lg-outer .lg-thumb-item {
	cursor: pointer;
	float: left;
	overflow: hidden;
	height: 100%;
	border: 2px solid rgba(255, 255, 255, 0);
	border-radius: 0;
	margin-bottom: 5px;
}

@media (min-width: 1025px) {
	.lg-outer .lg-thumb-item {
		-webkit-transition: border-color .25s ease;
		-o-transition: border-color .25s ease;
		transition: border-color .25s ease;
	}
}

.lg-outer .lg-thumb-item:hover {
	border-color: #ffffff;
}

.lg-outer .lg-thumb-item.active {
	border-color: #01b3a7;
}

.lg-outer .lg-thumb-item img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.lg-outer.lg-has-thumb .lg-item {
	padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
	padding-bottom: 0;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
	-webkit-transition: bottom .25s ease;
	-o-transition: bottom .25s ease;
	transition: bottom .25s ease;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
	bottom: 100px;
}

.lg-outer .lg-toogle-thumb {
	background-color: #0d0a0a;
	border-radius: 2px 2px 0 0;
	color: #999;
	cursor: pointer;
	font-size: 24px;
	height: 39px;
	line-height: 27px;
	padding: 5px 0;
	position: absolute;
	right: 20px;
	text-align: center;
	top: -39px;
	width: 50px;
}

.lg-outer .lg-toogle-thumb:hover, .lg-outer.lg-dropdown-active #lg-share {
	color: #fff;
}

.lg-outer .lg-toogle-thumb:after {
	content: "\e1ff";
}

.lg-outer .lg-video-cont {
	display: inline-block;
	vertical-align: middle;
	max-width: 1140px;
	max-height: 100%;
	width: 100%;
	padding: 0 5px;
}

.lg-outer .lg-video {
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	overflow: hidden;
	position: relative;
}

.lg-outer .lg-video .lg-object {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
}

.lg-outer .lg-video .lg-video-play {
	width: 84px;
	height: 59px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -42px;
	margin-top: -30px;
	z-index: 1080;
	cursor: pointer;
}

.lg-outer .lg-has-iframe .lg-video {
	-webkit-overflow-scrolling: touch;
	overflow: auto;
}

.lg-outer .lg-has-vimeo .lg-video-play {
	background: url(./images/vimeo-play.png) no-repeat;
}

.lg-outer .lg-has-vimeo:hover .lg-video-play {
	background: url(./images/vimeo-play.png) 0 -58px no-repeat;
}

.lg-outer .lg-has-html5 .lg-video-play {
	background: url(./images/video-play.png) no-repeat;
	height: 64px;
	margin-left: -32px;
	margin-top: -32px;
	width: 64px;
	opacity: .8;
}

.lg-outer .lg-has-html5:hover .lg-video-play {
	opacity: 1;
}

.lg-outer .lg-has-youtube .lg-video-play {
	background: url(./images/youtube-play.png) no-repeat;
}

.lg-outer .lg-has-youtube:hover .lg-video-play {
	background: url(./images/youtube-play.png) 0 -60px no-repeat;
}

.lg-outer .lg-video-object {
	width: 100% !important;
	height: 100% !important;
	position: absolute;
	top: 0;
	left: 0;
}

.lg-outer .lg-has-video .lg-video-object {
	visibility: hidden;
}

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
	display: none;
}

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
	visibility: visible;
}

.lg-progress-bar {
	background-color: #333;
	height: 5px;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1083;
	opacity: 0;
	-webkit-transition: opacity 80ms ease 0s;
	-moz-transition: opacity 80ms ease 0s;
	-o-transition: opacity 80ms ease 0s;
	transition: opacity 80ms ease 0s;
}

.lg-progress-bar .lg-progress {
	background-color: #01b3a7;
	height: 5px;
	width: 0;
}

.lg-progress-bar.lg-start .lg-progress {
	width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
	opacity: 1;
}

.lg-autoplay-button:after {
	content: "\e01d";
}

.lg-show-autoplay .lg-autoplay-button:after {
	content: "\e01a";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
	-webkit-transition-duration: 0s;
	transition-duration: 0s;
}

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
	-moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
	-o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
	transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
	-webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
	-moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
	-o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
	transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
	-moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
	-o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
	transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
	-webkit-transform-origin: 0 0;
	-moz-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
}

#lg-zoom-in:after {
	content: "\e311";
}

#lg-actual-size {
	font-size: 20px;
}

#lg-actual-size:after {
	content: "\e033";
}

#lg-zoom-out {
	opacity: .5;
	pointer-events: none;
}

#lg-zoom-out:after {
	content: "\e312";
}

.lg-zoomed #lg-zoom-out {
	opacity: 1;
	pointer-events: auto;
}

.lg-outer .lg-pager-outer {
	bottom: 60px;
	left: 0;
	position: absolute;
	right: 0;
	text-align: center;
	z-index: 1080;
	height: 10px;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
	overflow: visible;
}

.lg-outer .lg-pager-cont {
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	position: relative;
	vertical-align: top;
	margin: 0 5px;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
	box-shadow: 0 0 0 2px #fff inset;
}

.lg-outer .lg-pager-thumb-cont {
	background-color: #fff;
	color: #fff;
	bottom: 100%;
	height: 83px;
	left: 0;
	margin-bottom: 20px;
	margin-left: -60px;
	opacity: 0;
	padding: 5px;
	position: absolute;
	width: 120px;
	border-radius: 3px;
	-webkit-transition: opacity .15s ease 0s, -webkit-transform .15s ease 0s;
	-moz-transition: opacity .15s ease 0s, -moz-transform .15s ease 0s;
	-o-transition: opacity .15s ease 0s, -o-transform .15s ease 0s;
	transition: opacity .15s ease 0s, transform .15s ease 0s;
	-webkit-transform: translate3d(0, 5px, 0);
	transform: translate3d(0, 5px, 0);
}

.lg-outer .lg-pager-thumb-cont img {
	width: 100%;
	height: 100%;
}

.lg-outer .lg-pager {
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 50%;
	box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
	display: block;
	height: 12px;
	-webkit-transition: box-shadow .3s ease 0s;
	-o-transition: box-shadow .3s ease 0s;
	transition: box-shadow .3s ease 0s;
	width: 12px;
}

.lg-outer .lg-pager:focus, .lg-outer .lg-pager:hover {
	box-shadow: 0 0 0 8px #fff inset;
}

.lg-outer .lg-caret {
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px dashed;
	bottom: -10px;
	display: inline-block;
	height: 0;
	left: 50%;
	margin-left: -5px;
	position: absolute;
	vertical-align: middle;
	width: 0;
}

.lg-fullscreen:after {
	content: "\e20c";
}

.lg-fullscreen-on .lg-fullscreen:after {
	content: "\e20d";
}

.lg-outer #lg-dropdown-overlay {
	background-color: rgba(0, 0, 0, 0.25);
	bottom: 0;
	cursor: default;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1081;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: visibility 0s linear .18s, opacity .18s linear 0s;
	-o-transition: visibility 0s linear .18s, opacity .18s linear 0s;
	transition: visibility 0s linear .18s, opacity .18s linear 0s;
}

.lg-outer.lg-dropdown-active #lg-dropdown-overlay, .lg-outer.lg-dropdown-active .lg-dropdown {
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
	-moz-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	opacity: 1;
	visibility: visible;
}

.lg-outer .lg-dropdown {
	background-color: #fff;
	border-radius: 2px;
	font-size: 14px;
	list-style-type: none;
	margin: 0;
	padding: 10px 0;
	position: absolute;
	right: 0;
	text-align: left;
	top: 50px;
	opacity: 0;
	visibility: hidden;
	-moz-transform: translate3d(0, 5px, 0);
	-o-transform: translate3d(0, 5px, 0);
	-ms-transform: translate3d(0, 5px, 0);
	-webkit-transform: translate3d(0, 5px, 0);
	transform: translate3d(0, 5px, 0);
	-webkit-transition: -webkit-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
	-moz-transition: -moz-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
	-o-transition: -o-transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
	transition: transform .18s linear 0s, visibility 0s linear .5s, opacity .18s linear 0s;
}

.lg-outer .lg-dropdown:after {
	content: "";
	display: block;
	height: 0;
	width: 0;
	position: absolute;
	border: 8px solid transparent;
	border-bottom-color: #fff;
	right: 16px;
	top: -16px;
}

.lg-outer .lg-dropdown > li:last-child {
	margin-bottom: 0;
}

.lg-outer .lg-dropdown > li:hover .lg-icon, .lg-outer .lg-dropdown > li:hover a {
	color: #333;
}

.lg-outer .lg-dropdown a {
	color: #333;
	display: block;
	white-space: pre;
	padding: 4px 12px;
	font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 12px;
}

.lg-outer .lg-dropdown a:hover {
	background-color: rgba(0, 0, 0, 0.07);
}

.lg-outer .lg-dropdown .lg-dropdown-text {
	display: inline-block;
	line-height: 1;
	margin-top: -3px;
	vertical-align: middle;
}

.lg-outer .lg-dropdown .lg-icon {
	color: #333;
	display: inline-block;
	float: none;
	font-size: 20px;
	height: auto;
	line-height: 1;
	margin-right: 8px;
	padding: 0;
	vertical-align: middle;
	width: auto;
}

.lg-outer, .lg-outer .lg, .lg-outer .lg-inner {
	width: 100%;
	height: 100%;
}

.lg-outer #lg-share {
	position: relative;
}

.lg-outer #lg-share:after {
	content: "\e80d";
}

.lg-outer #lg-share-facebook .lg-icon {
	color: #3b5998;
}

.lg-outer #lg-share-facebook .lg-icon:after {
	content: "\e901";
}

.lg-outer #lg-share-twitter .lg-icon {
	color: #00aced;
}

.lg-outer #lg-share-twitter .lg-icon:after {
	content: "\e904";
}

.lg-outer #lg-share-googleplus .lg-icon {
	color: #dd4b39;
}

.lg-outer #lg-share-googleplus .lg-icon:after {
	content: "\e902";
}

.lg-outer #lg-share-pinterest .lg-icon {
	color: #cb2027;
}

.lg-outer #lg-share-pinterest .lg-icon:after {
	content: "\e903";
}

.lg-group:after {
	content: "";
	display: table;
	clear: both;
}

.lg-outer {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1090;
	text-align: left;
	opacity: 0;
	-webkit-transition: opacity .15s ease 0s;
	-o-transition: opacity .15s ease 0s;
	transition: opacity .15s ease 0s;
}

.lg-outer * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.lg-outer.lg-visible {
	opacity: 1;
}

.lg-outer.lg-css3 .lg-item.lg-current, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-prev-slide {
	-webkit-transition-duration: inherit !important;
	transition-duration: inherit !important;
	-webkit-transition-timing-function: inherit !important;
	transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-current, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide {
	-webkit-transition-duration: 0s !important;
	transition-duration: 0s !important;
	opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
	cursor: -webkit-grab;
	cursor: -moz-grab;
	cursor: -o-grab;
	cursor: -ms-grab;
	cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
	cursor: move;
	cursor: -webkit-grabbing;
	cursor: -moz-grabbing;
	cursor: -o-grabbing;
	cursor: -ms-grabbing;
	cursor: grabbing;
}

.lg-outer .lg {
	position: relative;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
	max-height: 100%;
}

.lg-outer .lg-inner {
	position: absolute;
	left: 0;
	top: 0;
	white-space: nowrap;
}

.lg-outer .lg-item {
	background: url(./images/loading.gif) center center no-repeat;
	display: none !important;
}

.lg-outer.lg-css .lg-current, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide, .lg-outer.lg-css3 .lg-prev-slide {
	display: inline-block !important;
}

.lg-outer .lg-img-wrap, .lg-outer .lg-item {
	display: inline-block;
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
}

.lg-outer .lg-img-wrap:before, .lg-outer .lg-item:before {
	content: "";
	display: inline-block;
	height: 50%;
	width: 1px;
	margin-right: -1px;
}

.lg-outer .lg-img-wrap {
	position: absolute;
	padding: 0 5px;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.lg-outer .lg-item.lg-complete {
	background-image: none;
}

.lg-outer .lg-item.lg-current {
	z-index: 1060;
}

.lg-outer .lg-image {
	display: inline-block;
	vertical-align: middle;
	max-width: 100%;
	max-height: 100%;
	width: auto !important;
	height: auto !important;
}

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
	opacity: 0;
	-webkit-transition: opacity .15s ease 0s;
	-o-transition: opacity .15s ease 0s;
	transition: opacity .15s ease 0s;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
	opacity: 1;
}

.lg-outer .lg-empty-html, .lg-outer.lg-hide-download #lg-download {
	display: none;
}

.lg-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1090;
	background-color: rgba(0, 0, 0, 0.8);
	opacity: 0;
	-webkit-transition: opacity .15s ease 0s;
	-o-transition: opacity .15s ease 0s;
	transition: opacity .15s ease 0s;
}

.lg-backdrop.in {
	opacity: 1;
}

.lg-css3.lg-no-trans .lg-current, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-prev-slide {
	-webkit-transition: none 0s ease 0s !important;
	-moz-transition: none 0s ease 0s !important;
	-o-transition: none 0s ease 0s !important;
	transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item, .lg-css3.lg-use-left .lg-item {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
	opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
	opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-current, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-prev-slide {
	-webkit-transition: opacity .1s ease 0s;
	-moz-transition: opacity .1s ease 0s;
	-o-transition: opacity .1s ease 0s;
	transition: opacity .1s ease 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
	opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
	-webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
	-moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
	-o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
	transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-left .lg-item {
	opacity: 0;
	position: absolute;
	left: 0;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
	left: -100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
	left: 100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
	left: 0;
	opacity: 1;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
	-webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
	-moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
	-o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
	transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.tablet #lg-zoom-in,
.tablet #lg-zoom-out,
.mobile #lg-zoom-in,
.mobile #lg-zoom-out {
	display: none;
}

.tablet .lg-icon,
.mobile .lg-icon {
	font-size: 22px;
	width: 45px;
}

@media (max-width: 767.98px) {
	.lg-fullscreen,
	#lg-zoom-in,
	#lg-zoom-out {
		display: none;
	}
	.lg-icon {
		font-size: 22px;
		width: 45px;
	}
}

/*
*
* Material Parallax
*/
.parallax-container {
	position: relative;
	overflow: hidden;
}

.material-parallax {
	position: absolute;
	top: 0;
	left: -1px;
	right: -1px;
	bottom: 0;
}

.ipad .material-parallax, .iphone .material-parallax {
	background-attachment: scroll !important;
}

.material-parallax img {
	display: none;
	position: absolute;
	left: 50%;
	bottom: 0;
	min-width: 101%;
	max-width: none;
	min-height: 101%;
	transform: translate3d(-50%, 0, 0);
}

.parallax-content {
	position: relative;
	z-index: 1;
}

/*
*
* Select 2
*/
/*
Version: 3.5.4 Timestamp: Sun Aug 30 13:30:32 EDT 2015
*/
.select2-container {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	min-height: 50px;
	min-width: 250px;
	margin: 0;
	padding: 0;
	letter-spacing: .05em;
	background: #ffffff;
	border: 1px solid #e1e1e1;
}

.select2-container-active {
	border: 1px solid #01b3a7;
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
	/*
    Force border-box so that % widths fit the parent
    container without overlap because of margin/padding.
    More Info : http://www.quirksmode.org/css/box.html
  */
	-webkit-box-sizing: border-box;
	/* webkit */
	-moz-box-sizing: border-box;
	/* firefox */
	box-sizing: border-box;
	/* css3 */
}

.select2-container .select2-choice {
	display: block;
	position: relative;
	color: #151515;
	background-color: #ffffff;
	padding: 12px 40px 12px 26px;
	border-radius: 0;
	text-decoration: none;
}

.select2-container .select2-choice > .select2-chosen {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.select2-dropdown-open .select2-choice {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select2-arrow {
	display: inline-block;
	position: absolute;
	right: 14px;
	top: 50%;
	transform: translateY(-50%);
	line-height: 1;
}

.select2-arrow b:before {
	display: inline-block;
	font-weight: 400;
	content: "";
	font-family: "Material Design Icons";
	font-size: 20px;
	transform: rotate(0deg);
}

.select2-dropdown-open .select2-arrow b:before {
	transform: rotate(180deg);
}

.select2-container .select2-choice abbr {
	display: none;
}

.select2-drop-mask {
	border: 0;
	margin: 0;
	padding: 0;
	position: fixed;
	left: 0;
	top: 0;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	z-index: 99;
	/* styles required for IE to work */
	background-color: #ffffff;
	filter: alpha(opacity=0);
}

.select2-drop {
	width: 100%;
	margin-top: -1px;
	position: absolute;
	z-index: 100;
	top: 100%;
	background: #ffffff;
	color: #151515;
	border: 1px solid #f7f7f7;
	border-top: 0;
	border-radius: 0 0 0 0;
}

.select2-drop-active {
	border: 1px solid #01b3a7;
	border-top: 0;
}

.select2-drop.select2-drop-above {
	margin-top: 1px;
	border-top: 1px solid #f7f7f7;
	border-bottom: 0;
}

.select2-drop.select2-drop-above.select2-drop-active {
	border: 1px solid #01b3a7;
}

.select2-search {
	display: inline-block;
	width: 100%;
	padding: 4px 4px 0;
	position: relative;
	z-index: 10000;
	white-space: nowrap;
}

.select2-search:before {
	position: absolute;
	right: 22px;
	top: 50%;
	transform: translateY(calc(-50% + 4px));
	display: inline-block;
	content: '\f43b';
	font-family: "Material Design Icons";
	font-size: 20px;
	line-height: 1;
}

.select2-search input {
	width: 100%;
	padding: 5px 35px 5px 5px;
	margin: 0;
	outline: 0;
	font-size: 1em;
	background-color: #ffffff;
	color: #151515;
	border: 1px solid #f7f7f7;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
	border: 1px solid #f7f7f7;
	border-top-color: transparent;
}

.select2-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

/* results */
.select2-results {
	padding: 4px 4px;
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.select2-result {
	border-radius: 0;
	transition: .1s;
}

.select2-results li.select2-result-with-children > .select2-result-label {
	font-weight: bold;
}

.select2-results .select2-result-label {
	padding: 3px 7px 4px;
	margin: 0;
	cursor: pointer;
	min-height: 1em;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.select2-results .select2-highlighted {
	color: #ffffff;
	background: #01b3a7;
}

.select2-results li em {
	background: #ffffff;
	font-style: normal;
}

.select2-results .select2-highlighted em {
	background: transparent;
}

.select2-results .select2-highlighted ul {
	background: #ffffff;
	color: #000000;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
	color: #ffffff;
	background: #01b3a7;
	display: list-item;
	cursor: default;
}

.select2-results .select2-disabled {
	background: #b7b7b7;
	display: list-item;
	cursor: default;
}

.select2-results .select2-selected {
	display: none;
}

.select2-results .select2-ajax-error {
	background: rgba(255, 50, 50, 0.2);
}

.select2-more-results {
	background: #f4f4f4;
	display: list-item;
}

/* disabled styles */
.select2-container.select2-container-disabled .select2-choice {
	background-color: #f4f4f4;
	background-image: none;
	border: 1px solid #ddd;
	cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
	background-color: #f4f4f4;
	background-image: none;
	border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
	display: none;
}

/* multiselect */
.select2-container-multi {
	display: flex;
	align-items: stretch;
	height: auto;
	min-height: 60px;
}

.select2-container-multi .select2-choices {
	display: flex;
	flex-flow: row;
	align-items: center;
	width: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	line-height: 0;
	cursor: text;
	overflow: hidden;
}

.select2-locked {
	padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
	min-height: 26px;
}

.select2-container-multi .select2-choices .select2-search-field {
	margin: 0;
	padding: 0;
	white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
	width: 100% !important;
	outline: 0;
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
	padding: 4px 5px 4px 20px;
	margin: 3px 0 3px 5px;
	position: relative;
	line-height: 13px;
	cursor: default;
	border: 1px solid #dedede;
	background-clip: padding-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: #dedede;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
	cursor: default;
}

.select2-search-choice-close {
	display: block;
	width: 12px;
	height: 13px;
	position: absolute;
	right: 3px;
	top: 3px;
	color: #2c343b;
}

.select2-search-choice-close:before {
	display: inline-block;
	content: '\f24c';
	font-family: "Material Design Icons";
	font-size: 12px;
	line-height: 1;
}

.select2-container-multi .select2-search-choice-close {
	left: 3px;
}

/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
	background-color: #f4f4f4;
	background-image: none;
	border: 1px solid #ddd;
	cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
	padding: 3px 5px 3px 5px;
	border: 1px solid #ddd;
	background-image: none;
	background-color: #f4f4f4;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
	display: none;
	background: none;
}

/* end multiselect */
.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
	text-decoration: underline;
}

.select2-offscreen, .select2-offscreen:focus {
	clip: rect(0 0 0 0) !important;
	width: 1px !important;
	height: 1px !important;
	border: 0 !important;
	margin: 0 !important;
	padding: 0 !important;
	overflow: hidden !important;
	position: absolute !important;
	outline: 0 !important;
	left: 0px !important;
	top: 0px !important;
}

.select2-display-none {
	display: none;
}

.select2-measure-scrollbar {
	position: absolute;
	top: -10000px;
	left: -10000px;
	width: 100px;
	height: 100px;
	overflow: scroll;
}

.rd-range {
	height: 18px;
	margin-top: 16px;
	display: flex;
	-webkit-align-items: center;
}

.rd-range__wrap {
	position: relative;
	width: 100%;
	height: 6px;
	border-radius: 3px;
	background-color: #f4f4f4;
	transition: background 100ms cubic-bezier(0.23, 1, 0.32, 1);
}

.rd-range__pointer {
	position: absolute;
	cursor: pointer;
	top: 3px;
	left: 6%;
	z-index: 1;
	width: 19px;
	height: 19px;
	border-radius: 50%;
	transform: translate(-50%, -50%);
	transform-origin: 0 15%;
	transition: transform 0.2s cubic-bezier(0.35, 0, 0.25, 1);
	overflow: visible;
	background-color: #50ba87;
	border: 6px solid #ffffff;
	background-clip: padding-box;
	box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
	.rd-range__pointer.active {
		transform: scale3d(1.1, 1.1, 1.1) translate(-50%, -50%);
	}
}

.rd-range__line {
	background-color: #01b3a7;
	position: absolute;
	top: 0;
	left: 0;
	height: 6px;
	transition: none;
}

.rd-range .rd-range__pointer-tooltip {
	display: none !important;
}

.rd-range-wrap {
	display: flex;
	align-items: center;
}

.rd-range-form-wrap span,
.rd-range-input,
.rd-range-title {
	font-size: 18px;
	line-height: 1;
	letter-spacing: .1em;
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
}

.rd-range-title {
	margin-right: 4px;
}

.rd-range-form-wrap {
	position: relative;
	padding-left: 6px;
	color: #151515;
}

.rd-range-form-wrap span {
	position: absolute;
	top: 4px;
	left: 0;
}

.rd-range-divider {
	height: 2px;
	width: 10px;
	margin-left: 2px;
	margin-right: 5px;
	margin-bottom: 2px;
	border-top: 2px solid #151515;
}

.rd-range-input {
	text-align: center;
	outline: none;
	border: none;
	padding: 0;
	max-width: 36px;
	background-color: transparent;
	border-radius: 0;
}

.rd-range + * {
	margin-top: 22px;
}

.slick-slider {
	position: relative;
	display: block;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
}

.slick-track:before, .slick-track:after {
	content: "";
	display: table;
}

.slick-track:after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	text-align: center;
	padding-left: 0;
	padding-right: 0;
	display: none;
}

[dir="rtl"] .slick-slide {
	float: right;
}

.slick-slide img {
	display: inline-block;
}

.slick-slide:focus {
	outline: none;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;
	height: auto;
}

.slick-arrow.slick-hidden {
	display: none;
}

.slick-loading .slick-list {
	background: #fff url("././images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: 40px;
	width: 40px;
	line-height: 40px;
	font-size: 24px;
	cursor: pointer;
	background: #01b3a7;
	color: #ffffff;
	top: 50%;
	margin-top: -10px \9;
	/*lte IE 8*/
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	z-index: 9;
}

.slick-prev:hover,
.slick-next:hover {
	outline: none;
	color: #01b3a7;
	background-color: #ffffff;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
	background-color: #d7d7d7;
}

.slick-prev:before, .slick-next:before {
	font-family: "Material Design Icons";
	opacity: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-prev {
	left: -15px;
}

[dir="rtl"] .slick-prev {
	left: auto;
	right: 0;
}

.slick-prev:before {
	content: "";
}

[dir="rtl"] .slick-prev:before {
	content: "";
}

.slick-next {
	right: -15px;
}

[dir="rtl"] .slick-next {
	left: 0;
	right: auto;
}

.slick-next:before {
	content: "";
}

[dir="rtl"] .slick-next:before {
	content: "";
}

/* Dots */
.slick-dots {
	position: absolute;
	bottom: -45px;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	width: 100%;
}

.slick-dots li {
	position: relative;
	display: inline-block;
	height: 20px;
	width: 20px;
	margin: 0 5px;
	padding: 0;
	cursor: pointer;
}

.slick-dots li button {
	border: 0;
	background: transparent;
	display: block;
	height: 20px;
	width: 20px;
	outline: none;
	line-height: 0;
	font-size: 0;
	color: transparent;
	padding: 5px;
	cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
	outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
	opacity: 0.7;
}

.slick-dots li button:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "•";
	width: 20px;
	height: 20px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 6px;
	line-height: 20px;
	text-align: center;
	color: black;
	opacity: 0.7;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
	color: black;
	opacity: 1;
}

.carousel-parent {
	margin-bottom: 7px;
}

.child-carousel .slick-list {
	margin-left: -6px;
	margin-right: -6px;
}

.child-carousel .slick-slide {
	cursor: pointer;
	margin: 0 6px;
}

.child-carousel .slick-slide img {
	width: 100%;
}

.child-carousel .slick-product-figure {
	margin-top: 5px;
	margin-bottom: 5px;
}

.child-carousel .slick-product-figure:hover {
	background: rgba(244, 244, 244, 0.5);
}

.child-carousel .slick-product-figure:hover img {
	transform: scale(1.1);
}

.child-carousel .slick-current .slick-product-figure {
	background: transparent;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.17);
}

@media (min-width: 768px) {
	.child-carousel .slick-slide {
		max-width: 100%;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.slick-product {
		display: flex;
		margin-left: -6px;
		margin-right: -6px;
	}
	.slick-product .carousel-parent,
	.slick-product .child-carousel {
		padding-left: 6px;
		padding-right: 6px;
	}
	.slick-product .carousel-parent {
		width: 74%;
		margin-bottom: 0;
	}
	.slick-product .child-carousel {
		width: 26%;
		display: flex;
		align-content: stretch;
	}
	.slick-product .child-carousel .slick-list {
		display: flex;
		margin-left: 0;
		margin-right: 0;
	}
	.slick-product .child-carousel .slick-slide {
		width: calc(100% - 10px) !important;
		margin-left: 5px;
		margin-right: 5px;
	}
	.slick-product .slick-arrow {
		right: auto;
		left: 50%;
		transform: translate(-50%, 0);
	}
	.slick-product .slick-prev {
		top: -10px;
	}
	.slick-product .slick-prev::before {
		content: '\f239';
	}
	.slick-product .slick-next {
		top: auto;
		bottom: -10px;
	}
	.slick-product .slick-next::before {
		content: '\f236';
	}
}

@media (min-width: 992px) {
	.slick-product {
		margin-right: 25px;
	}
}

@media (min-width: 1200px) {
	.slick-product {
		margin-right: 38px;
	}
}

.slick-custom-nav .slick-prev,
.slick-custom-nav .slick-next {
	background-color: transparent;
	font-size: 60px;
}

.slick-custom-nav .slick-prev {
	left: -30px;
}

.slick-custom-nav .slick-next {
	right: -20px;
}

@media (min-width: 1600px) {
	.slick-custom-nav .slick-prev {
		left: -50px;
	}
	.slick-custom-nav .slick-next {
		right: -40px;
	}
}

.slick-product-figure {
	position: relative;
	overflow: hidden;
	background: #f4f4f4;
	transition: all .3s ease;
}

.slick-product-figure img {
	width: 100%;
	transform: none;
	transition: inherit;
}

.stepper {
	position: relative;
	display: block;
}

.stepper input[type="number"] {
	width: 100%;
	padding: 10px 42px;
	font-weight: 500;
	font-family: "Montserrat", sans-serif, Arial, sans-serif;
	text-align: center;
	-moz-appearance: textfield;
}

.stepper input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.stepper-arrow {
	position: absolute;
	text-align: center;
	top: 50%;
	margin-top: -19px;
	width: 38px;
	height: 38px;
	font-size: 14px;
	line-height: 38px;
	font-weight: 400;
	font-family: "FontAwesome";
	cursor: pointer;
	color: #151515;
	border-radius: 0;
	transition: .3s all ease;
}

.stepper-arrow:hover {
	color: #01b3a7;
}

.stepper-arrow.up {
	right: 0;
}

.stepper-arrow.up:before {
	content: '\f067';
}

.stepper-arrow.down {
	left: 0;
}

.stepper-arrow.down:before {
	content: '\f068';
}

.stepper.disabled .stepper-arrow {
	pointer-events: none;
	opacity: .5;
}

.product-stepper .stepper {
	padding-right: 32px;
}

.product-stepper input[type="number"] {
	padding: 10px 5px;
	font-size: 24px;
	max-width: 66px;
}

.product-stepper .stepper-arrow {
	margin-top: 0;
	top: auto;
	right: 0;
	width: 28px;
	height: 28px;
	line-height: 28px;
	background: #f7f7f7;
}

.product-stepper .stepper-arrow.up {
	top: 0;
}

.product-stepper .stepper-arrow.down {
	left: auto;
	bottom: 0;
}

.table-cart-stepper {
	max-width: 114px;
	margin-left: auto;
	margin-right: auto;
}

.table-cart-stepper input[type="number"] {
	font-size: 18px;
	line-height: 1;
	letter-spacing: .1em;
	padding-bottom: 6px;
	background: #ffffff;
	border-color: #ffffff;
}

.table-cart-stepper .stepper-arrow {
	line-height: 36px;
	border-radius: 4px;
	border: 1px solid #e1e1e1;
}

/*
* @subsection   Magnific Popup
*/
.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1042;
	overflow: hidden;
	position: fixed;
	background: #0b0b0b;
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.mfp-wrap {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1043;
	position: fixed;
	outline: none !important;
	-webkit-backface-visibility: hidden;
}

.mfp-container {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 8px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.mfp-container:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
	display: none;
}

.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto;
}

.mfp-ajax-cur {
	cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder {
	cursor: -moz-zoom-out;
	cursor: -webkit-zoom-out;
	cursor: zoom-out;
}

.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
	cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.mfp-close:hover {
	cursor: pointer;
	color: #ECECEC;
}

.mfp-loading.mfp-figure {
	display: none;
}

.mfp-hide {
	display: none !important;
}

.mfp-preloader {
	color: #CCC;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -0.8em;
	left: 8px;
	right: 8px;
	z-index: 1044;
}

.mfp-preloader a {
	color: #CCC;
}

.mfp-preloader a:hover {
	color: #FFF;
}

.mfp-s-ready .mfp-preloader {
	display: none;
}

.mfp-s-error .mfp-content {
	display: none;
}

button.mfp-close, button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
	display: block;
	outline: none;
	padding: 0;
	z-index: 1046;
	-webkit-box-shadow: none;
	box-shadow: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.mfp-close {
	width: 44px;
	height: 44px;
	line-height: 44px;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	filter: alpha(opacity=65);
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover, .mfp-close:focus {
	opacity: 1;
	filter: alpha(opacity=100);
}

.mfp-close:active {
	top: 1px;
}

.mfp-close-btn-in .mfp-close {
	color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	color: #FFF;
	right: -6px;
	text-align: right;
	padding-right: 6px;
}

.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 0;
	display: none;
}

.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	filter: alpha(opacity=65);
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 90px;
	height: 110px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
	margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
	opacity: 1;
	filter: alpha(opacity=100);
}

.mfp-arrow:before, .mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
	content: '';
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
	margin-top: 35px;
	margin-left: 35px;
	border: medium inset transparent;
}

.mfp-arrow:after,
.mfp-arrow .mfp-a {
	border-top-width: 13px;
	border-bottom-width: 13px;
	top: 8px;
}

.mfp-arrow:before,
.mfp-arrow .mfp-b {
	border-top-width: 21px;
	border-bottom-width: 21px;
	opacity: 0.7;
}

.mfp-arrow-left {
	left: 0;
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
	border-right: 17px solid #FFF;
	margin-left: 31px;
}

.mfp-arrow-left:before,
.mfp-arrow-left .mfp-b {
	margin-left: 25px;
	border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
	right: 0;
}

.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
	border-left: 17px solid #FFF;
	margin-left: 39px;
}

.mfp-arrow-right:before,
.mfp-arrow-right .mfp-b {
	border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
	top: -40px;
}

.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #000;
}

/* Main image in popup */
img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 40px 0 40px;
	margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
	line-height: 0;
}

.mfp-figure:after {
	content: '';
	position: absolute;
	left: 0;
	top: 40px;
	bottom: 40px;
	display: block;
	right: 0;
	width: auto;
	height: auto;
	z-index: -1;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: #444;
}

.mfp-figure small {
	color: #BDBDBD;
	display: block;
	font-size: 12px;
	line-height: 14px;
}

.mfp-figure figure {
	margin: 0;
}

.mfp-bottom-bar {
	margin-top: -36px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto;
}

.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #F3F3F3;
	word-wrap: break-word;
	padding-right: 36px;
}

.mfp-image-holder .mfp-content {
	max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
	/**
       * Remove all paddings around the image on small screen
       */
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0;
	}
	.mfp-img-mobile img.mfp-img {
		padding: 0;
	}
	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0;
	}
	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px;
	}
	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0;
	}
	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px;
	}
	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0;
	}
}

@media all and (max-width: 900px) {
	.mfp-arrow {
		-webkit-transform: scale(0.75);
		transform: scale(0.75);
	}
	.mfp-arrow-left {
		-webkit-transform-origin: 0;
		transform-origin: 0;
	}
	.mfp-arrow-right {
		-webkit-transform-origin: 100%;
		transform-origin: 100%;
	}
	.mfp-container {
		padding-left: 6px;
		padding-right: 6px;
	}
}

.mfp-ie7 .mfp-img {
	padding: 0;
}

.mfp-ie7 .mfp-bottom-bar {
	width: 600px;
	left: 50%;
	margin-left: -300px;
	margin-top: 5px;
	padding-bottom: 5px;
}

.mfp-ie7 .mfp-container {
	padding: 0;
}

.mfp-ie7 .mfp-content {
	padding-top: 44px;
}

.mfp-ie7 .mfp-close {
	top: 0;
	right: 0;
	padding-top: 0;
}

.twentytwenty-horizontal .twentytwenty-handle:before, .twentytwenty-horizontal .twentytwenty-handle:after, .twentytwenty-vertical .twentytwenty-handle:before, .twentytwenty-vertical .twentytwenty-handle:after {
	content: " ";
	display: block;
	background: #ffffff;
	position: absolute;
	z-index: 30;
	box-shadow: 0 0 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-horizontal .twentytwenty-handle:before, .twentytwenty-horizontal .twentytwenty-handle:after {
	width: 2px;
	height: 9999px;
	left: 50%;
	margin-left: -1px;
}

.twentytwenty-vertical .twentytwenty-handle:before, .twentytwenty-vertical .twentytwenty-handle:after {
	width: 9999px;
	height: 2px;
	top: 50%;
	margin-top: -1px;
}

.twentytwenty-before-label, .twentytwenty-after-label, .twentytwenty-overlay {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}

.twentytwenty-before-label, .twentytwenty-after-label, .twentytwenty-overlay {
	transition-duration: 0.5s;
}

.twentytwenty-before-label, .twentytwenty-after-label {
	transition-property: opacity;
}

.twentytwenty-before-label:before, .twentytwenty-after-label:before {
	color: #ffffff;
	font-size: 13px;
	letter-spacing: 0.1em;
}

.twentytwenty-before-label:before, .twentytwenty-after-label:before {
	position: absolute;
	background: rgba(255, 255, 255, 0.2);
	line-height: 38px;
	padding: 0 20px;
	border-radius: 2px;
}

.twentytwenty-horizontal .twentytwenty-before-label:before, .twentytwenty-horizontal .twentytwenty-after-label:before {
	top: 50%;
	margin-top: -19px;
}

.twentytwenty-vertical .twentytwenty-before-label:before, .twentytwenty-vertical .twentytwenty-after-label:before {
	left: 50%;
	margin-left: -45px;
	text-align: center;
	width: 90px;
}

.twentytwenty-left-arrow, .twentytwenty-right-arrow, .twentytwenty-up-arrow, .twentytwenty-down-arrow {
	position: absolute;
	color: #01b3a7;
	font-family: "FontAwesome";
	font-size: 24px;
	line-height: 47px;
}

.twentytwenty-left-arrow::before, .twentytwenty-right-arrow::before, .twentytwenty-up-arrow::before, .twentytwenty-down-arrow::before {
	display: inline-block;
}

.twentytwenty-container {
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	width: auto;
	z-index: 0;
	overflow: hidden;
	position: relative;
	-moz-user-select: none;
}

.twentytwenty-container img {
	max-width: 100%;
	position: absolute;
	top: 0;
	display: block;
}

.twentytwenty-container.active .twentytwenty-overlay, .twentytwenty-container.active :hover.twentytwenty-overlay {
	background: rgba(0, 0, 0, 0);
}

.twentytwenty-container.active .twentytwenty-overlay .twentytwenty-before-label,
.twentytwenty-container.active .twentytwenty-overlay .twentytwenty-after-label, .twentytwenty-container.active :hover.twentytwenty-overlay .twentytwenty-before-label,
.twentytwenty-container.active :hover.twentytwenty-overlay .twentytwenty-after-label {
	opacity: 0;
}

.twentytwenty-container * {
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}

.twentytwenty-before-label {
	opacity: 0;
}

.twentytwenty-before-label:before {
	content: "Before";
}

.twentytwenty-after-label {
	opacity: 0;
}

.twentytwenty-after-label:before {
	content: "After";
}

.twentytwenty-horizontal .twentytwenty-before-label:before {
	right: 10px;
}

.twentytwenty-horizontal .twentytwenty-after-label:before {
	left: 10px;
}

.twentytwenty-vertical .twentytwenty-before-label:before {
	top: 10px;
}

.twentytwenty-vertical .twentytwenty-after-label:before {
	bottom: 10px;
}

.twentytwenty-overlay {
	transition-property: background;
	background: rgba(0, 0, 0, 0);
	z-index: 25;
}

.twentytwenty-overlay:hover {
	background: rgba(0, 0, 0, 0.5);
}

.twentytwenty-overlay:hover .twentytwenty-after-label {
	opacity: 1;
}

.twentytwenty-overlay:hover .twentytwenty-before-label {
	opacity: 1;
}

.twentytwenty-before {
	z-index: 20;
}

.twentytwenty-after {
	z-index: 10;
}

.twentytwenty-handle {
	height: 47px;
	width: 47px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -25.5px;
	margin-top: -25.5px;
	border: 2px solid #ffffff;
	background: #ffffff;
	border-radius: 1000px;
	box-shadow: 0 0 12px rgba(51, 51, 51, 0.5);
	z-index: 40;
	cursor: pointer;
}

.twentytwenty-horizontal .twentytwenty-handle:before {
	bottom: 50%;
	margin-bottom: 22px;
	box-shadow: 0 3px 0 #ffffff, 0 0 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-horizontal .twentytwenty-handle:after {
	top: 50%;
	margin-top: 22px;
	box-shadow: 0 -3px 0 #ffffff, 0 0 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-vertical .twentytwenty-handle:before {
	left: 50%;
	margin-left: 22px;
	box-shadow: 3px 0 0 #ffffff, 0 0 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-vertical .twentytwenty-handle:after {
	right: 50%;
	margin-right: 22px;
	box-shadow: -3px 0 0 #ffffff, 0 0 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-left-arrow {
	left: 50%;
	margin-left: -13px;
}

.twentytwenty-left-arrow::before {
	content: '\f104';
}

.twentytwenty-right-arrow {
	right: 50%;
	margin-right: -13px;
}

.twentytwenty-right-arrow::before {
	content: '\f105';
}

.twentytwenty-up-arrow {
	top: 50%;
	margin-top: -13px;
}

.twentytwenty-up-arrow::before {
	content: '\f106';
}

.twentytwenty-down-arrow {
	bottom: 50%;
	margin-bottom: -13px;
}

.twentytwenty-down-arrow::before {
	content: '\f107';
}

/*
*
* Video BG
* --------------------------------------------------
*/
.bg-vide {
	position: relative;
	z-index: 0;
}

.bg-vide > * {
	position: relative;
	z-index: 2;
}

.bg-vide > div:not(.container) {
	pointer-events: none;
}

.bg-vide::before {
	z-index: 1;
}

.video-background {
	position: relative;
	overflow: hidden;
	background-color: #151515;
}

.video-background-poster {
	display: none;
}

.video-foreground,
.video-background iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-foreground {
	height: 300%;
	top: -100%;
}

.video-foreground iframe {
	left: -5px;
	width: calc(100% + 5px);
}

.video-background-inner {
	position: relative;
	z-index: 1;
}

[data-x-mode="true"] .video-foreground,
.builder .video-foreground,
.ios .video-foreground,
.ipad .video-foreground,
.tablet .video-foreground,
.devices .video-foreground,
.mobile .video-foreground {
	display: none;
}

[data-x-mode="true"] .video-background-poster,
.builder .video-background-poster,
.ios .video-background-poster,
.ipad .video-background-poster,
.tablet .video-background-poster,
.devices .video-background-poster,
.mobile .video-background-poster {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

[data-x-mode="true"] .video-background-poster img,
.builder .video-background-poster img,
.ios .video-background-poster img,
.ipad .video-background-poster img,
.tablet .video-background-poster img,
.devices .video-background-poster img,
.mobile .video-background-poster img {
	object-fit: cover;
	object-position: center;
	width: 100%;
	height: 100%;
}

.banner {
	display: block;
	position: relative;
	z-index: 2;
}

.banner img {
	width: auto;
}

@media (max-width: 1199.98px) {
	.banner-top {
		display: none;
	}
}
