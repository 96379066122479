.tabServices {
    display: flex;
    flex-direction: row;
    max-width: 100%; /* ajusta el ancho máximo según tus necesidades */
    gap: 20px;
    
}

/* Media query para pantallas de al menos 600px de ancho */
@media (max-width: 600px) {
    .tabServices {
        display: flex;
        flex-direction: column;
        
        
    }
}


/* Media query para pantallas de al menos 768px de ancho */
@media (max-width: 768px) {
    .tabServices {
        display: flex;
        flex-direction: column;
        
        
    }
}

/* Media query para pantallas de al menos 834px de ancho */
@media (max-width: 834px) {
    .tabServices {
        display: flex;
        flex-direction: column;
        
        
    }
}

.columna {
    
    flex: 25%; /* para que cada columna ocupe el 25% del contenedor */
    padding: 20px;
    box-sizing: border-box;
    
}
/* Añade estilos adicionales según tus necesidades */
.columna {
    background-color: #ffffff;
    border: 2px solid #dddddd;
    text-align: center;
    margin: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* sombra suave */
    
}

.botonera-columna{
    height: 20%;
    width: 100%;
}

.tete-columna{
    height: 80%;
    width: 100%;
}

