.cont-serv {
    display: flex;
    flex-direction: column;
    margin: 0 auto; /* Centra el contenedor horizontalmente */
    gap: 50px;
  }
  .section-tete{
    width: 100vw;
    height: 10vh;
    margin: 0%;
    position: relative;
  }
  

  .section-corp{
    width: 100vw;
    height: 45vh;
    margin: 0%;
    position: relative;
  }

  .section-foot{
    width: 100vw;
    height: 45vh;
    margin: 0%;
    position: relative;
  }