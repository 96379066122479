.input {
    max-width: 100%;
    min-width: 100%;
    background-color: #f5f5f5;
    color: #242424;
    padding: .15rem .5rem;
    min-height: 40px;
    border-radius: 0px;
    outline: none;
    border: none;
    line-height: 1.15;
    box-shadow: 0px 10px 20px -18px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  input:focus {
    border-bottom: 2px solid #5b5fc7;
    border-radius: 4px 4px 2px 2px;
  }
  
  input:hover {
    outline: 1px solid lightgrey;
  }


  .textarea {
    max-width: 100%; /* O ajusta el ancho según tus necesidades */
    min-width: 100%;
    padding: 10px;
    background-color: #f5f5f5;
    color: #242424;
    padding: .15rem .5rem;
    min-height: 150px;
    border-radius: 0px;
    outline: none;
    border: none;
    line-height: 1.15;
    box-shadow: 0px 10px 20px -18px;
    margin-top: 10px;
    margin-bottom: 10px;
    resize: vertical; /* Permite que el usuario redimensione verticalmente */
}

/* Estilos adicionales para cuando el textarea está enfocado */
.textarea:focus {
    border-color: #007bff; /* Cambia el color del borde cuando está enfocado */
    outline: none; /* Elimina el contorno predeterminado */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Agrega una sombra suave cuando está enfocado */
}

/* Estilos para el textarea cuando está deshabilitado */
.textarea:disabled {
    background-color: #f0f0f0; /* Cambia el color de fondo cuando está deshabilitado */
    color: #999; /* Cambia el color del texto cuando está deshabilitado */
    cursor: not-allowed; /* Cambia el cursor cuando está deshabilitado */
}
  