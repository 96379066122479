/* Estilo base para el contenedor de cada imagen con el elemento superpuesto */
  .slick-slider {
    position: relative;
    height: 20vh;
  }

  .cont-img:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(rgba(255,255,255,0),rgba(0,0,0,0.8));
  }

  .cont-img-index:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(rgba(255,255,255,0),rgba(0,0,0,0.8));
  }

  .cont-img { 
    width: 100%;
    display: block;
    height: 100vh;
  }

  .cont-img-index {
    width: 100%;
    display: block;
    height: auto;
    margin: 0;
  }

  /* Estilo para las imágenes dentro del slider */
  .slick-slider img {
    width: 100%; /* Ajusta el ancho según sea necesario */
    height: 100%; /* Ajusta la altura según sea necesario */
    display: block;
    margin: 0 auto; /* Centra horizontalmente la imagen */
    object-fit:cover;
    
  }

  
  
  /* Estilo para el elemento superpuesto */
  .elemento-sobre-imagen {
    position: absolute;
    top: 50%; /* Centra verticalmente */
    left: 50%; /* Centra horizontalmente */
    transform: translate(-50%, -50%); /* Centra el elemento correctamente */
    background-color: rgba(255, 255, 255, 0.7); /* Fondo semi-transparente (puedes ajustar el color y la opacidad) */
    padding: 10px; /* Ajusta el relleno según sea necesario */
    border-radius: 5px; /* Ajusta el radio de la esquina según sea necesario */
    text-align: center; /* Ajusta la alineación del texto según sea necesario */
  }

  /* Media query para pantallas de tamaño medio */
@media screen and (max-width: 768px) {
  .cont-img-index {
    
    height: 50vh;
    
  }
}

@media screen and (max-width: 1440px) {
  .cont-img-index {
    
    height: 45vh;
    
  }
}

@media screen and (min-width: 1728px) {
  .cont-img-index {
    
    height: 35vh;
    
  }
}

@media screen and (min-width: 2048px) {
  .cont-img-index {
    
    height: 30vh;
    
  }
}


/* Media query para pantallas pequeñas (móviles, por ejemplo) */
@media screen and (max-width: 480px) {
  .cont-img-index {
  
    display: none;
    
  }

  .col-lg-6{
    margin-top: -50px;
    
  }
}

  
  /* Ajusta otros estilos según sea necesario */