/* Estilo para el contenedor de los botones */
.button-container {
  position: fixed;
  top: 20px; /* Ajusta esta propiedad para cambiar la distancia desde el borde superior */
  right: 20px; /* Ajusta esta propiedad para cambiar la distancia desde el borde derecho */
  z-index: 9999; /* Asegura que los botones estén por encima de otros elementos */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Estilo para cada botón */
.button-container button {
  display: block;
  margin-bottom: 10px; /* Ajusta esta propiedad para cambiar la separación entre los botones */
  padding: 10px 20px;
  background-color: #007bff; /* Color de fondo */
  color: #fff; /* Color del texto */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
}

/* Estilo para el hover del botón */
.button-container button:hover {
  background-color: #0056b3; /* Cambia el color de fondo al pasar el mouse */
}

/* Estilo para las imágenes */
.img-btn-play {
  width: 30px; /* Ajusta el tamaño de la imagen */
  height: 30px; /* Ajusta el tamaño de la imagen */
  border-radius: 50%; /* Hace que la imagen sea circular */
  transition: transform 0.2s ease; /* Agrega una transición suave al cambiar el tamaño */
  background-color: #89B91C;
}

.img-btn-mute {
  width: 30px; /* Ajusta el tamaño de la imagen */
  height: 30px; /* Ajusta el tamaño de la imagen */
  border-radius: 50%; /* Hace que la imagen sea circular */
  transition: transform 0.2s ease; /* Agrega una transición suave al cambiar el tamaño */
  background-color: #89B91C;
  padding: 5px;
}

/* Estilo para el hover de las imágenes */
.img-btn-mute:hover {
  transform: scale(1.2); /* Hace que la imagen se agrande ligeramente al pasar el mouse */
  cursor: pointer;
}
/* Estilo para el hover de las imágenes */
.img-btn-play:hover {
  transform: scale(1.2); /* Hace que la imagen se agrande ligeramente al pasar el mouse */
  cursor: pointer;
}