.fond-services{
    text-align: center;
    width: 100vw;
    height: auto;
    background-color: #cdd4d4;
    opacity: 0.8;
    
}

.fondo-contactus{

}

.org-contact{
    position: relative;
    width: 100%;
    height: 100vh; /* Altura de la ventana completa */
    overflow: hidden; /* Asegura que el contenido adicional no se muestre */
    display: flex;
    justify-content: center; /* Centrando horizontalmente */
    align-items: center; /* Centrando verticalmente */
}

.org-contact img.fondo-contactus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Asegura que la imagen cubra todo el área */
    z-index: -1; /* Coloca la imagen detrás del contenido */
}
