  .banner{
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin-top: 150px;
    opacity: 0.5;
  }

  @media screen and (max-width: 2048px) {
    .banner{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        margin-top: 150px;
        opacity: 0.5;
      }
}