* {
  margin: 0%;
  padding: 0%;
  box-sizing:border-box;
 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.slider-site-index{
  position: relative;
  margin: auto;
  z-index: 0;
  
}

.card-advantage {
  position: relative;
  width: 150px;
  height: 150px;
  background: linear-gradient(
    to bottom,
    rgb(253, 197, 107) 12%, rgb(255, 187, 78) 24%,
    rgba(0, 0, 0, .3) 25%, rgba(0, 0, 0, .1) 26% 26%,
    #eee 28% 49%,
    rgba(0, 0, 0, .3) 50%,
    #eee 51% 74%,
    rgba(0, 0, 0, .3) 75%,
    #eee 76%
  );
  border: none;
  border-radius: 35px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  overflow: hidden;
}

.dots {
  position: absolute;
  content: '';
  inset: 0 -3px;
  color: rgba(0, 0, 0, .3);
  font-size: 50px;
  user-select: none;
  -webkit-user-select: none;
}

.video-index-contactus{
  z-index: 1;
    width: 70vw;
    height: auto;
    position: absolute;
    margin-top: 5%;
    opacity: 1;
    margin-left: 15vw;
    border-radius: 30px;
    margin-bottom: 0%;
    object-fit: cover;
    border-radius: 20px;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    transform: none !important;
    padding: 20px;
    opacity: 1;  
    display: flex;
    flex-direction: row;
}


.h3-card-service{
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 1.3em;
  margin-left: 0%;
  margin-right: 0%;
}

.p-card-service{
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1em;
  margin-left: 0%;
  margin-right: 0%;
}

.GridContainer{
  gap: 20px;
  margin-top: 100px;
  width: auto;
}

.ServiceCard{
  border: 0px solid #ddd;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 25%;
  height: 400px;
  background-color: whitesmoke;
}

.btn-mensaje-service{
  width: 70%;
  height: 40px;
  padding: 5px;
  border-radius: 10px;
  font-family: 'EngraversGothic BT';
  font-size: 1.1em;
  margin-left: 0%;
  background-color:#89B91C;
  color: white; 
  z-index: 1;
  margin-top: 0px;
}
.btn-mensaje-service:hover{
  cursor: pointer;
}

.btn-mensaje-service:hover{
  background-color: #e96656;
  box-shadow: 0px 15px 20px rgba(233,102,86,0.4);
  font-size: 1.2em;
  color: #fff;
  transform: translateY(-4px);
}
.btn-mensaje-service:active{
  transform: translateY(-1px);
}

.banner{
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin-top: 100px;
  opacity: 0.5;
  background-image: url("");
}

.icon-service{
  width: 80px;
  height: 80px;
}

.clients-line{
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 100%;
  margin-left: 0%;
  margin-bottom: 20px;
  margin-top: 20px;
  opacity: 0.5;
  justify-content: center;
}

.titulo-seccion-clients{
  font-size: 1.5em;
  font-family: 'EngraversGothic BT';
  font-weight: bold;
  color: #89B91C;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
  opacity: 0.5;
}

.logo-client{
  width: auto;
  height: 60px;
}

.flag-element{
  width: 40px;
  height: 25px;
  margin-right: 10px;
  margin-bottom: 0px;
}

.header-wrapper {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  padding: 1rem 0;
  color: #ffffff;
}

.header-wrapper h1 {
  font-size: 2rem;
}

.image-gallery-wrapper {
  margin-top: 5rem;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid rgb(146, 129, 242);
  box-shadow: #2b15453d 1px 10px 10px 5px;
}

.txt-boton{
  width: 70%;
  text-decoration: none;
  font-size: 1em;
  margin-left: 15%;
}

.inputField {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgb(173, 173, 173);
  border-radius: 30px;
  margin: 10px 0;
  color: black;
  font-size: .8em;
  font-weight: 500;
  box-sizing: border-box;
  padding-left: 30px;
}

.inputField:focus {
  outline: none;
  border-bottom: 2px solid #0072b1;
}

.inputField::placeholder {
  color: rgb(80, 80, 80);
  font-size: 1em;
  font-weight: 500;
}

.signupContainer {
  position: relative;
  width: 50%;
  margin-left: 25%;
  border: 2px solid #0072b1;
  background-color:#0072b1;
  height: 40px;
  color: white;
  font-size: .8em;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 30px;
  margin-top: 20px;
  cursor: pointer;
  overflow: hidden;
}

.signupContainer::after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.253);
  height: 100%;
  width: 150px;
  top: 0;
  left: -200px;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 100px;
  filter: blur(10px);
  transition-duration: .5s;
}

.signupContainer:hover::after {
  transform: translateX(600px);
  transition-duration: .5s;
}

.card-title {
  color: #262626;
  font-size: 1.5em;
  line-height: normal;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.small-desc {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
  color: #452c2c;
}

.small-desc {
  font-size: 1em;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 2em;
  height: 2em;
  overflow: hidden;
  top: 0;
  right: 0;
  background: linear-gradient(135deg, #6293c8, #384c6c);
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card {
  display: block;
  position: relative;
  max-width: 100%;
  max-height: 100%;
  background-color: #f2f8f9;
  border-radius: 10px;
  padding: 2em 1.2em;
  margin: 0px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(to bottom, #c3e6ec, #a7d1d9);
  font-family: Arial, Helvetica, sans-serif;
}

.card:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: linear-gradient(135deg, #364a60, #384c6c);
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.35s ease-out;
}

.card:hover:before {
  transform: scale(100);
}

.card:hover .small-desc {
  transition: all 0.5s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card:hover .card-title {
  transition: all 0.3s ease-out;
  color: #ffffff;
}
.container{
  width: 100%;
}
html {
  height:100%;
  overflow: scroll;
}
html::-webkit-scrollbar {
  -webkit-appearance: none;
  display:none
}


body {
  margin:0;
}

.bg {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #6c3 50%, #09f 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
  opacity: 0.33;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:4s;
  opacity: 0.33;
}

.bg3 {
  animation-duration:5s;
  opacity: 0.33;
}

.content{
  box-sizing:border-box;
  left:0%;
  text-align:center;
  top:0%;
}

.content::-webkit-scrollbar {
  -webkit-appearance: none;
  display:none
}

h1 {
  font-family:monospace;
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

.container-logo{
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 40%;
  margin-top: 4%;
  width: 20%;
  height: 50vh;
  margin-bottom: 10%;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  padding: 20px;
}

.input-text{
  width: 100%;
  height: 80px;
  border-radius: 20px;
  font-size: xx-large;
  text-align: center;
  margin-top: 1%;
  font-weight: bold;
  background-color: white;
  color:black;
  font-family: 'EngraversGothic BT';
}

.input-text-log{
  width: 80%;
  height: 50px;
  border-radius: 10px;
  font-size: 1.2em;
  text-align: center;
  margin-top: 1%;
  margin-left: 10%;
  font-weight: bold;
  background-color: white;
  color:black;
  font-family: 'EngraversGothic';
}

.contenedor-completo{
  width: 100vw;
  height: auto;
}

.btn-login{
  width: 80%;
  height: 60px;
  font-size: 2em;
  border-radius: 20px;
  font-family: 'EngraversGothic BT';
  cursor: pointer;
  border: none;
  font-weight: bold;
  position: relative;
  margin-top: 5%;
  margin-left: 10%;
  background-color: #006C85;
  color: white;
}

h1{
  color: white;
  font-size: 4em;
  margin-left: 1%;
  text-align: center;
}
.info-error{
  width: 80%;
  height: 40px;
  font-size: 30px;
  color: red;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: normal;
  border-color: red;
  padding: auto;
  opacity: .3;
  text-align:center;
}
.imagenLogo {
  width: 30vw;
  height: 30vh;
  margin-top: 1%;
  opacity: .5;
}
.caja-logo{
  margin-left: 2%;
  margin-top: 1%;
  height: 40vh;
}
.btn-salir{
  width: 50px;
  height: 50px;
  margin-left: 1vw;
  background-color: #006C85;
}
.btn-salir:hover{
  cursor: pointer;
  transform: translateY(-7px);
}
.btn-salir:active {
  transform: translateY(-1px);
}
.tete{
  width: 100vw;
  height: 10vh;
  display: flex;
  align-items: center;
  background-color: #006C85;
  padding: 10px;
}
.btn-modal{
  
    width: 120%;
    height: 60px;
    font-size: 1.5em;
    border-radius: 10px;
    font-family: 'EngraversGothic BT';
    cursor: pointer;
    border: none;
    font-weight: bold;
    position: relative;
    margin-top: 5%;
    background-color: #006C85;
    color: white; 
}

.modal-login{
  background-color:white;
  width: 600px;
  height: 300px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  border-radius: 12px;
  padding: 5px;
}

.modal-message{
  background-color:white;
  width: 600px;
  height: 300px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  border-radius: 12px;
  padding: 5px;
}
.info-btn-modal{
  margin: 10px;
}

.modal-background{
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -35%;
  margin-top: -4%;
}

.btn-menu{
  width: 50px;
  height: 50px;
  margin-left: 80vw;
  background-color: #006C85;
}
.btn-menu:hover{
  cursor: pointer;
  transform: translateY(-7px);
}
.btn-menu:active {
  transform: translateY(-1px);
}
.modal-user{
  background-color: #e8e8e8;
  width: auto;
  height: auto;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  flex-direction: column;
  justify-content: center;
  display:grid;
  border-radius: 12px;
  padding: 30px;
  margin-left: 1500px;
  margin-top: -800px;
}
.linea-modal{
  display: flex;
  flex-direction: row;
  
}
.botonera-modal-user{
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
}
.btn-modal-user{
  width: 45%;
  height: 65px;
  padding: 10px;
  font-family: 'EngraversGothic BT';
  font-size: 1.5em;
  margin: 30px;
  background-color: #006C85;
  color: white; 
}
.btn-modal-user:hover{
  cursor: pointer;
}
.dropdown {
  position: relative;
  font-family: 'EngraversGothic BT';
  font-size: 1.5em;
}

.menu {
  position: absolute;

  list-style-type: none;
  margin: 5px 0;
  padding: 0;

  border: 1px solid grey;
  width: 150px;
}

.menu > li {
  margin: 0;

  background-color: white;
}

.imgSlide{
  width: 100%;
  height: 650px;
  margin-bottom: 20px;
  opacity: 1;
  border-radius : 20px;
}

.menu > li:hover {
  background-color: lightgray;
}

.menu > li > button {
  width: 100%;
  height: 100%;
  text-align: left;
  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

.btn-volver{
  width: 15%;
  height: 30px;
  font-size: 1.5em;
  border-radius: 20px;
  font-family: 'EngraversGothic BT';
  cursor: pointer;
  border: none;
  font-weight: bold;
  position: relative;
  margin-top: 5%;
  margin-left: 10%;
  background-color: #89B91C;
  color: white;
  text-align: center;
  
}

.btn-volver:hover{
  cursor: pointer;
  transform: translateY(-7px);
}

.elemento-tete-page{
  width: 130px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'EngraversGothic BT';
  font-size: 1.5em;
  margin-top: 10px;
  margin-left: 80px;
  color: white;
  padding: 1em;
  position: relative;
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.2s;
  border-radius: 5px;
  opacity: 0.8;
  letter-spacing: 1px;
  
}

.elemento-tete-page:hover{
  opacity: 1;
  cursor: pointer;
  --bg: #89B91C;
  background: var(--bg);
  box-shadow: #89B91C 0px 7px 2px, #000 0px 8px 5px;
}

.elemento-tete-page:active{
  top: 4px;
  box-shadow: #89B91C 0px 3px 2px,#000 0px 3px 5px;
}

.img-principal{
  width: 100%;
  height: auto;
  position: relative;
  z-index: 0;
}

.img-alternativa{
  width: 100vw;
  height: auto;
  position: relative;
  z-index: 0;
}

.img-pie{
  width: 100%;
  height: auto;
  position: relative;
  margin-top: -9%;
 
  display: flex-end;
}
.img-pie-servicios{
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 55%;
  display: flex-end;
}

.img-pie-pro{
  width: 100%;
  height: auto;
  position: relative;
  margin-top: -25%;
 
  display: flex-end;
}

.caja-imagen{
  width: 100vw;
  height: 70vh;
}

.linea-division{
  color: #89B91C;
  display: flex;
  flex-direction: row;
  font-family: 'EngraversGothic';
  font-size: 2em;
  align-items: center;
  width: 80vw;
  margin-left: 10%;
  margin-right: 10%;
}
.linea-division-servicios{
  color: #89B91C;
  display: flex;
  flex-direction: row;
  font-family: 'EngraversGothic';
  font-size: 2em;
  align-items: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  height: 60px;
  z-index: 0;
}
.linea-division-pqr{
  color: #89B91C;
  display: flex;
  flex-direction: row;
  font-family: 'EngraversGothic';
  font-size: 2em;
  align-items: center;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.linea-horizontal-index{
  width: 30vw;
  color: #89B91C;
  size: 1em;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 100px;
}
.linea-horizontal-galery{
  width: 30vw;
  color: #89B91C;
  size: 1em;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 10px;
}

.caja-contenido-services{
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  font-family: 'EngraversGothic';
  z-index: 0;
  position: relative;
}

.caja-contenido-productos{
  width: 100%;
  margin-left: 0%;
  margin-right: 0%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: auto;
  font-family: 'EngraversGothic';
}

.caja-contenido-pqr{
  width: 100%;
  display: flex;
  width: auto;
  flex-direction: row;
  margin-top: 30px;
  height: 40%;
  font-family: 'EngraversGothic';
  z-index: 0;
  background-color: transparent;
}

.caja-contenido-galeria{
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  height: auto;
}

.caja-contenido{
  width: 50vw;
  margin-left: 15%;
  width: auto;
  display: flex;
  flex-direction: row;
  margin-right: 15%;
  margin-top: 80px;
  font-family: 'EngraversGothic';
  border-radius: 20px;
  opacity: 0.8;
}

.img-caja-cont-mision{
  width: 600px;
  height: 400px;
  margin-right: 2%;
  margin-top: 4%;
  margin-bottom: 2%;
  border-radius: 10px;
}

.img-caja-cont-vision{
  width: 600px;
  height: 400px;
  margin-left: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 10px;
}

.img-caja-cont{
  width: 600px;
  height: 400px;
  
}

.p-caja-cont{
  font-size: 1.3em;
  font-family: 'EngraversGothic BT';
  text-align: justify;
  margin: 50px; 
  z-index: 0;
}
.p-caja-cont-completo{
  font-size: 1.3em;
  font-family: 'EngraversGothic BT';
  text-align: justify;
  margin: 20px; 
  z-index: 0;
  width: 100%;
}

.p-caja-cont-pqr{
  font-size: 1.3em;
  font-family: 'EngraversGothic BT';
  text-align: justify;
  padding: 50px;
  margin: 0px;
  width: 70%;
  z-index: 0;
}

.contacto{
  display: flex;
  flex-direction: row;
  font-size: 1.5em;
  font-family: 'EngraversGothic BT';
  text-align: justify;
  margin-right: 15%;
  margin-top: 20px;
  width: 70vw;
  margin-left: 15%;
}

.formulario-contacto{
  width: 40%;
  height: auto;
  margin-right: 2vw;
  display: flex;
  flex-direction: column;
}

.form-pqr{
  width: 100%;
  max-height: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.txtMensaje{
  width: 80%;
  height: 50%;
  border-radius: 10px;
  margin: 15px;
  margin-bottom: 10px;
  font-size: 1.1em;
  font-family: 'EngraversGothic';
  padding: 10px;
  border: none;
  z-index: 1;
}

.txtMensaje-pqr{
  width: 80%;
  height: 50%;
  border-radius: 5px;
  margin: 15px;
  margin-bottom: 10px;
  font-size: 1.2em;
  font-family: 'EngraversGothic';
  padding: 10px;
  border:0cap;
  z-index: 1;
}

.input-drop-index{
  width: 80%;
  height: 40px;
  border-radius: 10px;
  margin: 10px;
  margin-bottom: 10px;
  font-size: 1.1em;
  font-family: 'EngraversGothic';
  padding: 10px;
  border: none;
}
.input-drop-pqr{
  width: 80%;
  height: 40px;
  border-radius: 10px;
  margin: 10px;
  margin-bottom: 10px;
  font-size: 1.1em;
  font-family: 'EngraversGothic';
  padding: 10px;
  border:0cap;
  border-radius: 5px;
}

.btn-mensaje-index{
  width: 80%;
  height: 65px;
  padding: 10px;
  border-radius: 20px;
  font-family: 'EngraversGothic BT';
  font-size: 1.5em;
  margin-left: 2%;
  background-color:#89B91C;
  color: white; 
  z-index: 1;
}

.btn-mensaje-pqr{
  width: 100%;
  height: 65px;
  padding: 10px;
  border-radius: 20px;
  font-family: 'EngraversGothic BT';
  font-size: 1.5em;
  margin-left: 0%;
  background-color:#89B91C;
  color: white; 
  z-index: 1;
}

.btn-mensaje-index:hover{
  cursor: pointer;
}

.btn-mensaje-pqr:hover{
  cursor: pointer;
}

.titulo-seccion{
  font-family: 'EngraversGothic BT';
  width: 500px;
  text-align: center;
  margin-top: 80px;
}
.titulo-seccion-galery{
  font-family: 'EngraversGothic BT';
  width: 500px;
  text-align: center;
  margin-top: 20px;
}
.galery{
  width: 100vw;
  height: auto;
}

.logo-red-insta{
  z-index: 1;
  width: 80px;
  height: 80px;
  position: absolute;
  margin-top: 10%;
  margin-left: -10%;
}

.logo-red-face{
  z-index: 1;
  width: 80px;
  height: 80px;
  position: absolute;
  margin-top: 10%;
  margin-left: -15%;
}

.logo-red-tik{
  z-index: 1;
  width: 80px;
  height: 80px;
  position: absolute;
  margin-top: 10%;
  margin-left: -20%;
}
.logo-red-tik:hover{
  cursor: pointer;
}
.logo-red-face:hover{
  cursor: pointer;
}
.logo-red-insta:hover{
  cursor: pointer;
}
.copy{
  color: black;
  font-size: 1em;
  width: 300px;
  height: 30px;
  margin-top: 100px;
  text-align: left;
}

.contenedor-servicios{
  z-index: 0;
  position: relative;
  border-radius: 20px;
  opacity: 0.9;
  height: auto;
  align-items: center;
}

.contenedor-pqr{
  z-index: 0;
  margin-top: 1%;
  position: absolute;
 
  border-radius: 20px;
  opacity: 0.8;
  width: 80vw;
  height: auto;
  margin-left: 10vw;
  margin-right: 10vw;
  align-items: center;
  padding: 1%;
}

.contenedor-galery{
  margin-top: 2%;
  position: relative;
  width: 40vw;
  height: auto;
  margin-left: 30vw;
  margin-right: 30vw;
  align-items: center;
  z-index: 0;
}

.galeria{
  width: 100%;
  height: auto;
  z-index: 0;
  position: relative;
  margin-top: 0%;
  margin-bottom: 0%;
  margin-left: 0%;
  margin-right: 0%;
}
.pie{
  width: 100%;
  height: auto;
  margin-top: 0%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color:#006C85;
  margin-bottom: 0%;
}

.pie-galery{
  width: 100%;
  height: 20vh;
  z-index: 0;
  margin-top: 70vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color:#006C85;
  position: absolute;
}

.pie-pqr{
  width: 100%;
  height: auto;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color:#006C85;
  margin-bottom: 0%;
}

.pie-products{
  width: 100%;
  height: auto;
  margin-top: 1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color:#006C85;
  margin-bottom: 0%;
}

.pie-servicios{
  width: 100vw;
  height: auto;
  margin-top: 0vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color:#006C85;
  position: relative;
  z-index: 0;
}

.input-drop-pqr{
  width: 80%;
  height: 38px;
  border-radius: 0px;
  margin: 15px;
  margin-bottom: 15px;
  font-size: 1.4em;
  font-family: 'EngraversGothic';
  padding: 5px;
  border:0cap;
  border-radius: 5px;
}

.card-social {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 20px;
}

/* for all social containers*/
.socialContainer {
  width: 52px;
  height: 52px;
  background-color: rgb(44, 44, 44);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition-duration: .3s;
}
/* instagram*/
.containerOne:hover {
  background-color: #d62976;
  transition-duration: .3s;
}
/* twitter*/
.containerTwo:hover {
  background-color: #00acee;
  transition-duration: .3s;
}
/* linkdin*/
.containerThree:hover {
  background-color: #0072b1;
  transition-duration: .3s;
}
/* Whatsapp*/
.containerFour:hover {
  background-color: #128C7E;
  transition-duration: .3s;
}

.socialContainer:active {
  transform: scale(0.9);
  transition-duration: .3s;
}

.socialSvg {
  width: 17px;
}

.socialSvg path {
  fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
  animation: slide-in-top 0.3s both;
}

.linea-dato{
  display: flex;
  flex-direction: row;
  text-align: center;
}

.h4-dato{
  margin: 15px;
  color: white;
}

.icono-dato{
  height: 30px;
  width: 30px;
  margin: 15px;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


.copy-redes{
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 40vw;
}
.imgs-redes{
  display: flex;
  flex-direction: row;
}

.img-services{
  width: 50%;
  height: 30%;
  margin-left: 25%;
  margin-right: 25%;
  margin-bottom: 15%;
}
.img-products{
  width: 50%;
  height: 30%;
  margin-left: 25%;
  margin-right: 25%;
  margin-bottom: 10%;
}

.logo-red-insta-ser{
  z-index: 1;
  width: 80px;
  height: 80px;
  position: absolute;
  margin-top: 75%;
  margin-left: -28%;
}

.logo-red-face-ser{
  z-index: 1;
  width: 80px;
  height: 80px;
  position: absolute;
  margin-top: 75%;
  margin-left: -32%;
}

.logo-red-tik-ser{
  z-index: 1;
  width: 80px;
  height: 80px;
  position: absolute;
  margin-top: 75%;
  margin-left: -36%;
}
.logo-red-tik-ser:hover{
  cursor: pointer;
}
.logo-red-face-ser:hover{
  cursor: pointer;
}
.logo-red-insta-ser:hover{
  cursor: pointer;
}

.logo-red-insta-pro{
  
  width: 80px;
  height: 80px;
  position: relative;
  
}

.logo-red-face-pro{
  width: 80px;
  height: 80px;
  position: relative;
}

.logo-red-tik-pro{
  
  width: 80px;
  height: 80px;
  position: relative;
  
}
.logo-red-tik-pro:hover{
  cursor: pointer;
}
.logo-red-face-pro:hover{
  cursor: pointer;
}
.logo-red-insta-pro:hover{
  cursor: pointer;
}

.horario{
  font-family: 'EngraversGothic BT';
  margin-left: 5%;
  padding: 10px;
  width: 10%;
  font-size: 1.2em;
  margin-right: 1%;
  color: white;
}

.datos{
  font-family: 'EngraversGothic BT';
  margin-left: 0%;
  padding: 10px;
  width: 20vw;
  font-size: 1.2em;
  color: white;
}

.mapa{
  width: 100%;
  height: 90%;
  border-radius: 10px;
}

.esp-mapa{
  width: 60%;
  height: auto;
}

.video-index{
  z-index: 1;
  width: 100vw;
  height: 64%;
  position: absolute;
  margin-top: -32%;
  opacity: 1;
  margin-left: 0%;
  border-radius: 30px;
  margin-bottom: 0%;
  object-fit: cover;
  border-radius: 20px;
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
  transform: none !important;
  padding: 20px;
  opacity: 1;  
}

.anuncio{  
  width: 40%;
  margin-right: 5%;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.anuncio-principal{
  font-size: 2.5em;
  font-family: Averta;
  width: 100%;
  font-weight: bold;
  margin-top: 0%;
  color: #000;
}

.anuncio-principal-quality{
  font-size: 2.5em;
  font-family: Averta;
  width: 100%;
  font-weight: bold;
  margin-top: 10%;
  color: #000;
}

.btn-anuncio{
    width: 80%;
    height: 70px;
    margin-left: 0%;
    margin-top: 10%;
    padding: 1.2em 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 1.3em;
    color: #000;
    background-color: #89B91C;
    border: none;
    border-radius: 15px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}

.btn-anuncio:hover{
  background-color: #e96656;
  box-shadow: 0px 15px 20px rgba(233,102,86,0.4);
  font-size: 1.3em;
  color: #fff;
  transform: translateY(-7px);
}
.btn-anuncio:active{
  transform: translateY(-1px);
}

.cuerpo-anuncio{
  font-family: Averta;
  color: #000;
  font-size: 1.4em;
  margin-top: 5%;
  text-align: justify;
}

/* Css 501 px a 1024 pixeles --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (min-width: 501px) and (max-width: 1024px) and (orientation:portrait){
  .tete{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding: 0px;
    gap: 0px;
  }

  .video-index-contactus{
    z-index: 1;
      width: 100vw;
      height: auto;
      position: absolute;
      margin-top: 5%;
      opacity: 1;
      margin-left: 0vw;
      border-radius: 30px;
      margin-bottom: 0%;
      object-fit: cover;
      border-radius: 20px;
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      transform: none !important;
      padding: 20px;
      opacity: 1;  
      display: flex;
      flex-direction: column;
  }

  .anuncio{  
    width: 80%;
    margin-right: 0%;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #000;
    margin-left: 10%;
  }
  
  .anuncio-principal{
    font-size: 2em;
    font-family: Averta;
    width: 80%;
    font-weight: bold;
    color: #000;
  }
  
  .btn-anuncio{
      width: 50%;
      height: 70px;
      margin-left: 0%;
      margin-top: 10%;
      padding: 1em 2em;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      font-size: 1.3em;
      color: #000;
      background-color: #89B91C;
      border: none;
      border-radius: 15px;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease 0s;
      cursor: pointer;
      outline: none;
      text-decoration: none;
      
  }

  .imagenLogodash{
    width: 20%;
    height: 60px;
    opacity: .9;
    margin-left: 0%;
    background-color: #006C85;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-right: 1%;
    margin-left: 1%;
  } 

  .GridContainer{
    display: flex;
    flex-direction: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 100px;
  }

  .ServiceCard{
    border: 1px solid #ddd;
    padding: 0px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 40%;
    height: 500px;
    background-color: whitesmoke;
  }

  .clients-line{
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 20%;
    margin-left: 0%;
    margin-bottom: 20px;
    margin-top: 20px;
    opacity: 0.5;
    justify-content: center;
  }

  .modal-login{
    background-color: white;
    width: 100%;
    height: auto;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    border-radius: 12px;
    padding: 5px;
  }
  
  .modal-message{
    background-color:white;
    width: 100vw;
    height: auto;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    border-radius: 12px;
    padding: 5px;
  }
  .info-btn-modal{
    margin: 10px;
  }
  
  .modal-background{
    width: 100vw;
    height: auto;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
    margin-top: 0%;
  }

  .caja-contenido-productos{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    height: auto;
    font-family: 'EngraversGothic';
  }

  .linea-division-servicios{
    color: #89B91C;
    display: flex;
    flex-direction: row;
    font-family: 'EngraversGothic';
    font-size: 1em;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: 60px;
    z-index: 0;
  }

  .p-caja-cont{
    font-size: 1em;
    font-family: 'EngraversGothic BT';
    text-align: justify;
    margin: 50px; 
    z-index: 0;
  }
  .p-caja-cont-completo{
    font-size: 1em;
    font-family: 'EngraversGothic BT';
    text-align: justify;
    margin: 20px; 
    z-index: 0;
    width: 95%;
  }
  

  .linea-division{
    color: #89B91C;
    display: flex;
    flex-direction: row;
    font-family: 'EngraversGothic';
    font-size: 1em;
    align-items: center;
    width: 80vw;
    margin-left: 10%;
    margin-right: 10%;
  }

  .elemento-tete-page{
    font-size: 0.7em;
    margin-left: 0%;
  }
  
  .body{
    width: 100vw;
    height: auto;
  }

  .caja-imagen{
    display: none;
  }

  .video-index{
    margin-top: 5%;
    width: 100vw;
    height: auto;
    margin-left: 0vw;
    display: flex;
    flex-direction: column;
  }

  .titulo-seccion{
    font-size: 1em;
  }

  .caja-contenido{
    width: 100vw;
    margin-left: 0vw;
    display: flex;
    flex-direction: column;
    
  }

  .img-caja-cont-mision{
    width: 80%;
    height: auto;
    margin-left: 10%;
  }

  .img-caja-cont-vision{
    width: 80%;
    height: auto;
    margin-left: 10%;
  }

  .contacto{
    display: flex;
    flex-direction: column;
    width: 70vw;
    height: auto;
    margin-left: 15vw;
  }

  .formulario-contacto{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .input-drop-index{
    width: 100%;
    font-size: 0.7em;
    border-style:ridge;
  }
  .txtMensaje{
    width: 100%;
    font-size: 0.7em;
    border-style:ridge;
    margin-left: 2%;
  }

  .btn-mensaje-index{
    width: 50%;
    margin-bottom: 20px;
    font-size: 1em;
    margin-left: 25%;
  }
  .esp-mapa{
    width: 70vw;
    height: 500px;
    margin-left: 0%;
    margin-top: 10%;
  }

  .pie{
    width: 100vw;
  }

  .datos{
    width: 50%;
  }

  .copy-redes{
    width: 20%;
    margin-left: 20%;
  }
  .copy{
    display: none;
  }
  
  .imagenLogo{
    width: 700px;
  }

  .btn-volver{
    width: 150px;
  }

  .pie-products{
    margin-top: 5.1%;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:#006C85;
    margin-bottom: 0%;
  }
  .contenedor-servicios{
    width: 100vw;
    margin-left: 0vw;
    margin-right: 0vw;
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .caja-contenido-services{
    width: 100%;
    margin: 0%;
    height: auto;
    position: relative;
    z-index: 0;
    margin-top: 50px;
  }

  .video-servicios{
    width: 100%;
    height: auto;
  }

  .video-servicios{
    z-index: 0;
    width: 100%;
    height: auto;
    margin-left: 0%;
    margin-bottom: 0%;
    object-fit: cover;
    border-radius: 2px;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    transform: none !important;
    background-color: white;
    padding: 10px;
    opacity: 1;
  }

  .pie-servicios{
    margin-top: 0%;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:#006C85;
    margin-bottom: 0%;
  }
  .galery{
    width: 100vw;
    height: auto;
    margin-left: 0vw;
  }

  .contenedor-galery{
    width: 100%;
    margin-left: 0%;
  }

  .imgSlide{
    width: 100%;
  }

  .pie-galery{
    margin-top: auto;
    height: auto;
  }

  .caja-contenido-pqr{
    display: flex;
    flex-direction: column;
    z-index: 0;
  }

  .p-caja-cont-pqr{
    width: 100%;
    z-index: 0;
    font-size: 1em;
  }

  .form-pqr{
    width: 100%;
    margin-left: 0%;
    align-items: center;
  }

  .pie-pqr{
    margin-top: auto;
    height: auto;
  }

  .contenedor-pqr{
    z-index: 0;
    margin-top: 0%;
    position: relative;
   
    border-radius: 20px;
    opacity: 0.8;
    width: 100vw;
    height: auto;
    margin-left: 0vw;
    margin-right: 0vw;
    align-items: center;
    padding: 0px;
  }

  .input-drop-pqr{
    width: 80%;
    height: 40px;
    border-radius: 10px;
    margin: 10px;
    margin-bottom: 10px;
    font-size: 1em;
    font-family: 'EngraversGothic';
    padding: 10px;
    border-radius: 5px;
    border-style: ridge;
  }

  .txtMensaje-pqr{
    width: 80%;
    height: 50%;
    border-radius: 5px;
    margin: 15px;
    margin-bottom: 10px;
    font-size: 1em;
    font-family: 'EngraversGothic';
    padding: 10px;
    border:0cap;
    z-index: 0;
  }

}

/* Css 320 - 500 pixeles --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (min-width: 320px) and (max-width: 500px) and (orientation:portrait){
  .tete{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding: 0px;
    width: 100vw;
  }
  .video-index-contactus{
    z-index: 1;
      width: 100vw;
      height: auto;
      position: absolute;
      margin-top: 5%;
      opacity: 1;
      margin-left: 0vw;
      border-radius: 30px;
      margin-bottom: 0%;
      object-fit: cover;
      border-radius: 20px;
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      transform: none !important;
      padding: 20px;
      opacity: 1;  
      display: flex;
      flex-direction: column;
  }

  .GridContainer{
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 100px;
  }

  .ServiceCard{
    border: 1px solid #ddd;
    padding: 0px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 60%;
    margin-left: 20%;
    height: 400px;
    background-color: whitesmoke;
  }

  .clients-line{
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 40%;
    margin-left: 0%;
    margin-bottom: 20px;
    margin-top: 20px;
    opacity: 0.5;
    justify-content: center;
  }

  .linea-horizontal-index{
    width: 20vw;
    color: #89B91C;
    size: 1em;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10pxpx;
  }

  .player-video{
    width:100%;
    height:250px;
  }

  .flag-element{
    width: 20px;
    height: 15px;
    margin-right: 10px;
    margin-bottom: 0px;
  }
  
  .card-social {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;
    gap: 5px;
    
  }

  .anuncio{  
    width: 100%;
    margin-right: 0%;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #000;
  }
  
  .anuncio-principal{
    font-size: 1.5em;
    font-family: Averta;
    width: 100%;
    font-weight: bold;
    color: #000;
  }

  .anuncio-principal-quality{
    font-size: 1.5em;
    font-family: Averta;
    width: 100%;
    font-weight: bold;
    margin-top: 10%;
    color: #000;
  }
  
  .btn-anuncio{
      width: 60%;
      height: 50px;
      margin-left: 0%;
      margin-top: 3%;
      padding: 1em 0em;
      text-transform: uppercase;
      letter-spacing: 1.0px;
      font-size: 0.8em;
      color: #000;
      background-color: #89B91C;
      border: none;
      border-radius: 15px;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease 0s;
      cursor: pointer;
      outline: none;
      text-decoration: none;
      
  }

  .txt-boton{
    width: 80%;
    font-size: 1em;
    margin-left: 10%;
    text-align: center;
  }
  
  .btn-anuncio:hover{
    background-color: #e96656;
    box-shadow: 0px 15px 20px rgba(233,102,86,0.4);
    font-size: 1.0em;
    color: #fff;
    transform: translateY(-7px);
  }
  .btn-anuncio:active{
    transform: translateY(-1px);
  }
  
  .cuerpo-anuncio{
    font-family: Averta;
    color: #000;
    font-size: 1em;
    margin-top: 5%;
  }

  .video-index{
    margin-top: 2%;
    width: 100%;
    height: 700px;
    margin-left: 0vw;
    margin-right: 0vw;
    display: flex;
    flex-direction: column;
  }

  .imagenLogodash{
    width: 25%;
    height: 60px;
    opacity: .9;
    margin-left: 0%;
    background-color: #006C85;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-right: 1%;
  } 
  .modal-login{
    background-color: white;
    width: 100%;
    height: auto;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    border-radius: 12px;
    padding: 0px;
  }
  
  .modal-message{
    background-color:white;
    width: 100vw;
    height: auto;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    border-radius: 12px;
    padding: 5px;
  }
  .info-btn-modal{
    margin: 10px;
  }
  
  .modal-background{
    width: 80vw;
    height: auto;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
    margin-top: 10%;
  }

  .info-error{
    width: 90%;
    height: 30px;
    font-size: 1em;
    color: red;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-style: normal;
    border-color: red;
    padding: auto;
    opacity: .3;
    text-align:center;
  }

  .btn-modal{
  
    width: 100%;
    height: 40px;
    font-size: 1.2em;
    border-radius: 10px;
    font-family: 'EngraversGothic BT';
    cursor: pointer;
    border: none;
    padding: 10px;
    font-weight: bold;
    position: relative;
    margin-top: 5%;
    background-color: #006C85;
    color: white; 
  }

  .btn-menu{
    width: 50px;
    height: 50px;
    margin-left: 0vw;
    background-color: #006C85;
  }

  .elemento-tete-page{
    font-size: 1em;
    margin-left: 0%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .body{
    width: 100vw;
    height: auto;
  }

  .caja-imagen{
    display: none;
  }

  .video-index{
    margin-top: 5%;
    width: 100vw;
    height: auto;
    margin-left: 0vw;
  }

  .titulo-seccion{
    font-size: 1em;
    width: 100%;
  }

  .caja-contenido{
    width: 100vw;
    margin-left: 0vw;
    display: flex;
    flex-direction: column;
    font-size: 1em;
  }

  .img-caja-cont-mision{
    width: 80%;
    height: auto;
    margin-left: 10%;
  }

  .img-caja-cont-vision{
    width: 80%;
    height: auto;
    margin-left: 10%;
  }

  .contacto{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    margin-left: 0vw;
    margin-right: 0vw;
  }

  .formulario-contacto{
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .input-drop-index{
    width: 100%;
    font-size: 0.7em;
    margin: 0%;
    margin-bottom: 10px;
  }
  .txtMensaje{
    width: 100%;
    font-size: 0.7em;
    margin: 0%;
    margin-bottom: 10px;
  }

  .btn-mensaje-index{
    width: 100%;
    margin: 0%;
    margin-bottom: 20px;
    font-size: 1em;
  }
  .esp-mapa{
    width: 80vw;
    height: 500px;
    margin-left: 10%;
    margin-top: 10%;
  }

  .pie{
    width: 100vw;
    display: flex;
    flex-direction: column;
  }

  .horario{
    width: 50%;
  }

  .datos{
    width: 100%;
    margin-left: 0%;
  }

  .copy-redes{
    width: 100%;
    margin-left: 0%;
    margin-bottom: 30px;
  }

  .copy{
    color: black;
    font-size: 1em;
    width: 200px;
    height: 30px;
    margin-top: 5px;
  }
  
  .imagenLogo{
    width: 700px;
  }

  .btn-volver{
    width: 100px;
    font-size: 1em;
    margin-left: 5%;
    padding: 5px;
  }

  .contenedor-servicios{
    width: 100vw;
    margin-left: 0vw;
    margin-right: 0vw;
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .caja-contenido-services{
    width: 100%;
    margin: 0%;
    height: auto;
    position: relative;
    z-index: 0;
    margin-top: 10px;
  }
  .card-servicio {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0px;
    overflow: hidden;
    border-radius: 10px;
    height: 650px;
    margin-left: 0%;
    margin-bottom: 10px;
    flex-direction: column;
    margin-top: 0%;
    gap: 20px;
  }
  
  .content-servicio {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #e8e8e8;
    width: 100%;
    margin-top: 0%;
  }
  
  .content-servicio .heading {
    font-weight: 1400;
    font-size: 40px;
    text-align: left;
    justify-content: center;
  }
  
  .content-servicio .para {
    line-height: 1;
    font-weight: 70;
    font-size: 23px;
    text-align: justify;
  }
  
  .content-servicio .btn {
    color: #e8e8e8;
    text-decoration: none;
    padding: 10px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .card-servicio::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    z-index: -1;
  }
  
  .card-servicio:hover::before {
    height: 100%;
    cursor: pointer;
  }
  
  .card-servicio:hover {
    box-shadow: none;
    background: blur(256px);
  }

  .video-servicios{
    width: 100%;
    height: auto;
    margin-top: 20px;
  }

  .video-servicios{
    width: 100%;
    margin: 0%;
    height: auto;
  }

  .pie-servicios{
    margin-top: 0%;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:#006C85;
    margin-bottom: 0%;
  }
  .galery{
    width: 100vw;
    height: auto;
    margin-left: 0vw;
  }

  .contenedor-galery{
    width: 100%;
    margin-left: 0%;
    height: auto;
  }

  .imgSlide{
    width: 100%;
    height: auto;
  }

  .pie-galery{
    margin-top: auto;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .caja-contenido-pqr{
    display: flex;
    flex-direction: column;
    z-index: 0;
    width: 100%;
    padding: 1px;
  }

  .p-caja-cont-pqr{
    width: 100%;
    z-index: 0;
    font-size: 1em;
    padding: 0px;
  }

  .p-caja-cont{
    font-size: 1em;
    font-family: 'EngraversGothic BT';
    text-align: justify;
    margin: 10px; 
    z-index: 0;
    margin-top: 10px;
  }
  .p-caja-cont-completo{
    font-size: 1em;
    font-family: 'EngraversGothic BT';
    text-align: justify;
    margin: 10px; 
    z-index: 0;
    width: 95%;
  }
  

  .form-pqr{
    width: 100%;
    margin-left: 0%;
  }

  .pie-pqr{
    margin-top: auto;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .pie{
    margin-top: auto;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .contenedor-pqr{
    z-index: 0;
    margin-top: 0%;
    position: relative;
  
    border-radius: 20px;
    opacity: 0.8;
    width: 90vw;
    height: auto;
    margin-left: 5vw;
    margin-right: 5vw;
    align-items: center;
    padding: 5px;
  }


  .linea-division{
    color: #89B91C;
    display: flex;
    flex-direction: row;
    font-family: 'EngraversGothic';
    font-size: 1em;
    align-items: center;
    width: 80vw;
    margin-left: 6%;
    margin-right: 10%;
  }

  @media screen and (min-width: 430px) {

    .linea-division{
      color: #89B91C;
      display: flex;
      flex-direction: row;
      font-family: 'EngraversGothic';
      font-size: 1em;
      align-items: center;
      width: 80vw;
      margin-left: 9%;
      margin-right: 10%;
    }
    
}

  .caja-logo{
    display: none;
  }

  .container-logo{
    align-content: center;
    display: flex;
    flex-direction: column;
    margin-left: 10vw;
    margin-top: 4vh;
    width: 80vw;
    height: auto;
  }

  .input-text-log{
    width: 90%;
    height: 50px;
    border-radius: 10px;
    font-size: 0.7em;
    text-align: center;
    margin-top: 1%;
    margin-left: 5%;
    font-weight: bold;
    background-color: white;
    color:black;
    font-family: 'EngraversGothic';
  }

  .btn-login{
    width: 90%;
    height: 60px;
    font-size: 1em;
    border-radius: 20px;
    font-family: 'EngraversGothic BT';
    cursor: pointer;
    border: none;
    font-weight: bold;
    position: relative;
    margin-top: 5%;
    margin-left: 5%;
    background-color: #006C85;
    color: white;
  }

  .linea-division-servicios{
    color: #89B91C;
    display: flex;
    flex-direction: row;
    font-family: 'EngraversGothic';
    font-size: 1em;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: 60px;
    z-index: 0;
    padding: 10px;
    margin-bottom: 20px;
  }

  .caja-contenido-productos{
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
    display: flex;
    width: auto;
    flex-direction: column;
    margin-top: 10px;
    height: auto;
    font-family: 'EngraversGothic';
  }

  .caja-contenido-galeria{
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    height: auto;
  }


  .input-drop-pqr{
    width: 90%;
    height: 38px;
    border-radius: 0px;
    margin-bottom: 15px;
    font-size: 0.7em;
    font-family: 'EngraversGothic';
    padding: 5px;
    border:0cap;
    border-radius: 5px;
  }

  .txtMensaje-pqr{
    width: 90%;
    height: 50%;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 0.7em;
    font-family: 'EngraversGothic';
    border:0cap;
    z-index: 1;
    
  }
  .btn-mensaje-pqr{
    width: 100%;
    height: 65px;
    margin-left: 0%;
  }
}

/* Css 1025 px a 1700 pixeles landscape--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (min-width: 1025px) and (max-width: 1700px) and (orientation:landscape){
  .tete{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding: 0px;
    width: 100vw;
  }
  .imagenLogodash{
    width: 8%;
    height: 60px;
    opacity: .9;
    margin-left: 0%;
    background-color: #006C85;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-right: 1%;
  } 
  .GridContainer{
    display: flex;
    flex-direction: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 100px;
  }

  .btn-anuncio{
    width: 80%;
    height: 50px;
    margin-left: 0%;
    margin-top: 3%;
    padding: 1em 1.3em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9em;
    color: #000;
    background-color: #89B91C;
    border: none;
    border-radius: 15px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.txt-boton{
  width: 70%;
  font-size: 1em;
  margin-left: 15%;
  text-align: center;
}

.btn-anuncio:hover{
  background-color: #e96656;
  box-shadow: 0px 15px 20px rgba(233,102,86,0.4);
  font-size: 1em;
  color: #fff;
  transform: translateY(-5px);
}

  .card {
    display: block;
    position: relative;
    max-width: 80%;
    max-height: 100%;
    background-color: #f2f8f9;
    border-radius: 10px;
    padding: 2em 1.2em;
    margin: 0px;
    margin-left: 10%;
    text-decoration: none;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient(to bottom, #c3e6ec, #a7d1d9);
    font-family: Arial, Helvetica, sans-serif;
  }

  .modal-login{
    background-color: white;
    width: 100%;
    height: auto;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    border-radius: 12px;
    padding: 0px;
    margin-left: -150px;
    margin-top: 450px;
  }
  
  .modal-message{
    background-color:white;
    width: 100vw;
    height: auto;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    border-radius: 12px;
    padding: 5px;
  }
  .info-btn-modal{
    margin: 10px;
  }
  
  .modal-background{
    width: 30vw;
    height: auto;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
    margin-top: 0%;
  }

  .info-error{
    width: 90%;
    height: 30px;
    font-size: 1em;
    color: red;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-style: normal;
    border-color: red;
    padding: auto;
    opacity: .3;
    text-align:center;
  }

  .btn-modal{
  
    width: 100%;
    height: 40px;
    font-size: 1.2em;
    border-radius: 10px;
    font-family: 'EngraversGothic BT';
    cursor: pointer;
    border: none;
    padding: 10px;
    font-weight: bold;
    position: relative;
    margin-top: 5%;
    background-color: #006C85;
    color: white; 
  }

  .btn-menu{
    width: 50px;
    height: 50px;
    margin-left: 60vw;
    background-color: #006C85;
  }

  .card-servicio {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding: 20px;
    overflow: hidden;
    border-radius: 10px;
    height: 450px;
    margin-left: 10%;
    margin-bottom: 20px;
  }
  
  .content-servicio {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    color: #e8e8e8;
    width: 40%;
  }
  
  .content-servicio .heading {
    font-weight: 1400px;
    font-size: 50px;
    text-align: center;
  }
  
  .content-servicio .para {
    line-height: 1;
    font-weight: 50px;
    font-size: 25px;
    text-align:justify;
    letter-spacing: 0px;
    
  }
  
  .content-servicio .btn {
    color: #e8e8e8;
    text-decoration: none;
    padding: 10px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .card-servicio::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    z-index: -1;
  }
  
  .card-servicio:hover::before {
    height: 100%;
    cursor: pointer;
  }
  
  .card-servicio:hover {
    box-shadow: none;
    background: blur(256px);
  }

  .caja-contenido-productos{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    height: auto;
    font-family: 'EngraversGothic';
  }

  .linea-division-servicios{
    color: #89B91C;
    display: flex;
    flex-direction: row;
    font-family: 'EngraversGothic';
    font-size: 1em;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: 60px;
    z-index: 0;
  }

  .p-caja-cont{
    font-size: 1em;
    font-family: 'EngraversGothic BT';
    text-align: justify;
    margin: 50px; 
    z-index: 0;
  }
  .p-caja-cont-completo{
    font-size: 1em;
    font-family: 'EngraversGothic BT';
    text-align: justify;
    margin: 50px; 
    z-index: 0;
    width: 90%;
  }
  

  .linea-division{
    color: #89B91C;
    display: flex;
    flex-direction: row;
    font-family: 'EngraversGothic';
    font-size: 1em;
    align-items: center;
    width: 80vw;
    margin-left: 10%;
    margin-right: 10%;
  }

  .elemento-tete-page{
    font-size: 0.7em;
    margin: 15px;
  }
  
  .body{
    width: 100vw;
    height: auto;
  }

  .caja-imagen{
    display: none;
  }

  .video-index{
    margin-top: 2%;
    width: 80vw;
    height: auto;
    margin-left: 10vw;
    margin-right: 0vw;
    display: flex;
    flex-direction: row;
  }

  .titulo-seccion{
    font-size: 1em;
  }

  .caja-contenido{
    width: 80vw;
    margin-left: 10vw;
    display: flex;
    flex-direction: column;
    
  }

  .img-caja-cont-mision{
    width: 80%;
    height: auto;
    margin-left: 10%;
  }

  .img-caja-cont-vision{
    width: 80%;
    height: auto;
    margin-left: 10%;
  }

  .contacto{
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: auto;
    margin-left: 0vw;
    font-size: 1.5em;
    font-family: 'EngraversGothic BT';
    text-align: justify;
    margin-top: 20px;
  }

  .formulario-contacto{
    width: 30%;
    margin-left: 10%;
    margin-right: 5%;
    
    padding: 5px;
    border-radius: 0px;
    margin-bottom: 30px;
    height: 450px;
  }

  .formulario-contacto-contactus{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    
    padding: 5px;
    border-radius: 0px;
    margin-bottom: 30px;
    height: auto;
    
  }

  .input-drop-index{
    width: 95%;
    font-size: 0.7em;
    border-style:ridge;
  }
  .txtMensaje{
    width: 95%;
    font-size: 0.7em;
    border-style:ridge;
    margin-left: 2%;
  }

  .btn-mensaje-index{
    width: 95%;
    height: 40px;
    margin-left: 2%;
    margin-top: 0%;
    padding: 1em 1.5em;
    font-size: 0.5em;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #000;
    background-color: #89B91C;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.btn-mensaje-index:hover{
  background-color: #e96656;
  box-shadow: 0px 15px 20px rgba(233,102,86,0.4);
  font-size: 0.6em;
  color: #fff;
  transform: translateY(-4px);
}
.btn-mensaje-index:active{
  transform: translateY(-1px);
}

  .esp-mapa{
    width: 45%;
    height: 500px;
    margin-left: 0%;
    margin-top: 0%;
  }

  .pie{
    width: 100vw;
  }

  .horario{
    width: 20%;
  }

  .datos{
    width: 40%;
  }

  .copy-redes{
    width: 20%;
    margin-left: 20%;
  }
  
  .imagenLogo{
    width: 400px;
  }

  .btn-volver{
    width: 50px;
    margin-left: 0px;
  }

  .pie-products{
    margin-top: 5.1%;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:#006C85;
    margin-bottom: 0%;
  }
  .contenedor-servicios{
    width: 100vw;
    margin-left: 0vw;
    margin-right: 0vw;
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    height: auto;
  }

  .caja-contenido-services{
    width: 100%;
    margin: 0%;
    height: auto;
    position: relative;
    z-index: 0;
    margin-top: 50px;
  }

  .video-servicios{
    z-index: 0;
    width: 58%;
    height: 400px;
    margin-left: 2%;
    margin-bottom: 0%;
    object-fit: cover;
    border-radius: 2px;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    transform: none !important;
    padding: 10px;
    opacity: 1;
  }

  .pie-servicios{
    margin-top: 0%;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:#006C85;
    margin-bottom: 0%;
  }
  .galery{
    width: 100vw;
    height: auto;
    margin-left: 0vw;
  }

  .contenedor-galery{
    width: 100%;
    margin-left: 0%;
  }

  .imgSlide{
    width: 70%;
    height: auto;
  }

  .pie-galery{
    margin-top: 90px;
    height: auto;
  }

  .caja-contenido-pqr{
    display: flex;
    flex-direction: row;
    z-index: 0;
    width: 90%;
    margin-left: 0%;
    background-color: transparent;
  }

  .p-caja-cont-pqr{
    width: 80%;
    z-index: 0;
    font-size: 1em;
    margin-left: 10%;
  }

  .form-pqr{
    width: 70%;
    margin-left: 0%;
    max-height: 100%;
    align-items: center;
    
    border-radius: 0px;
    padding: 5px;
    margin-bottom: 20px;
  }

  .pie-pqr{
    margin-top: auto;
    height: auto;
  }

  .contenedor-pqr{
    z-index: 0;
    margin-top: 0%;
    position: relative;
    border-radius: 20px;
    opacity: 0.8;
    width: 100vw;
    height: auto;
    margin-left: 0vw;
    margin-right: 0vw;
    align-items: center;
    padding: 0px;
  }

  .input-drop-pqr{
    width: 95%;
    height: 38px;
    border-radius: 10px;
    margin-bottom: 5px;
    font-size: 1em;
    font-family: 'EngraversGothic';
    padding: 5px;
    border-style: ridge;
  }

  .txtMensaje-pqr{
    width: 95%;
    height: 200px;
    border-radius: 5px;
    margin: 10px;
    margin-bottom: 10px;
    font-size: 1em;
    font-family: 'EngraversGothic';
    padding: 10px;
    border:0cap;
    z-index: 0;
  }

  .btn-mensaje-pqr{
    width: 80%;
    height: 40px;
    margin-left: 0%;
    margin-top: 0%;
    padding: 1em 1.5em;
    font-size: 0.8em;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #000;
    background-color: #89B91C;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.btn-mensaje-pqr:hover{
  background-color: #e96656;
  box-shadow: 0px 15px 20px rgba(233,102,86,0.4);
  font-size: 0.9em;
  color: #fff;
  transform: translateY(-4px);
}
.btn-mensaje-pqr:active{
  transform: translateY(-1px);
}

}


/* Css 501 px a 1024 pixeles landscape--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (min-width: 501px) and (max-width: 1024px) and (orientation:landscape){
  .tete{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    padding: 0px;
  }

  .caja-contenido-productos{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    height: auto;
    font-family: 'EngraversGothic';
  }

  .GridContainer{
    display: flex;
    flex-direction: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 100px;
  }

  .linea-division-servicios{
    color: #89B91C;
    display: flex;
    flex-direction: row;
    font-family: 'EngraversGothic';
    font-size: 1em;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: 60px;
    z-index: 0;
  }

  .p-caja-cont{
    font-size: 1em;
    font-family: 'EngraversGothic BT';
    text-align: justify;
    margin: 50px; 
    z-index: 0;
  }
  .p-caja-cont-completo{
    font-size: 1em;
    font-family: 'EngraversGothic BT';
    text-align: justify;
    margin: 50px; 
    z-index: 0;
    width: 90%;
  }
  

  .linea-division{
    color: #89B91C;
    display: flex;
    flex-direction: row;
    font-family: 'EngraversGothic';
    font-size: 1em;
    align-items: center;
    width: 80vw;
    margin-left: 10%;
    margin-right: 10%;
  }

  .elemento-tete-page{
    font-size: 0.7em;
  }
  
  .body{
    width: 100vw;
    height: auto;
  }

  .caja-imagen{
    display: none;
  }

  .video-index{
    margin-top: 5%;
    width: 100vw;
    height: auto;
    margin-left: 0vw;
  }

  .titulo-seccion{
    font-size: 1em;
  }

  .caja-contenido{
    width: 100vw;
    margin-left: 0vw;
    display: flex;
    flex-direction: column;
    
  }

  .img-caja-cont-mision{
    width: 80%;
    height: auto;
    margin-left: 10%;
  }

  .img-caja-cont-vision{
    width: 80%;
    height: auto;
    margin-left: 10%;
  }

  .contacto{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    margin-left: 0vw;
  }

  .formulario-contacto{
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .input-drop-index{
    width: 100%;
    font-size: 0.7em;
    border-style:ridge;
  }
  .txtMensaje{
    width: 100%;
    font-size: 0.7em;
    border-style:ridge;
    margin-left: 1%;
  }

  .btn-mensaje-index{
    width: 100%;
    margin-bottom: 20px;
    font-size: 1em;
    margin-left: 1%;
  }
  .esp-mapa{
    width: 80vw;
    height: 500px;
    margin-left: 10%;
    margin-top: 10%;
  }

  .pie{
    width: 100vw;
  }

  .horario{
    width: 20%;
  }

  .datos{
    width: 20%;
  }

  .copy-redes{
    width: 20%;
    margin-left: 20%;
  }
  
  .imagenLogo{
    width: 700px;
  }

  .btn-volver{
    width: 150px;
  }

  .pie-products{
    margin-top: 5.1%;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:#006C85;
    margin-bottom: 0%;
  }
  .contenedor-servicios{
    width: 100vw;
    margin-left: 0vw;
    margin-right: 0vw;
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .caja-contenido-services{
    width: 100%;
    margin: 0%;
    height: auto;
    position: relative;
    z-index: 0;
    margin-top: 50px;
  }

  .video-servicios{
    width: 100%;
    height: auto;
  }

  .video-servicios{
    z-index: 0;
    width: 100%;
    height: auto;
    margin-left: 0%;
    margin-bottom: 0%;
    object-fit: cover;
    border-radius: 2px;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    transform: none !important;
    background-color: white;
    padding: 10px;
    opacity: 1;
  }

  .pie-servicios{
    margin-top: 0%;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:#006C85;
    margin-bottom: 0%;
  }
  .galery{
    width: 100vw;
    height: auto;
    margin-left: 0vw;
  }

  .contenedor-galery{
    width: 100%;
    margin-left: 0%;
  }

  .imgSlide{
    width: 100%;
  }

  .pie-galery{
    margin-top: auto;
    height: auto;
  }

  .caja-contenido-pqr{
    display: flex;
    flex-direction: column;
    z-index: 0;
  }

  .p-caja-cont-pqr{
    width: 100%;
    z-index: 0;
    font-size: 1em;
  }

  .form-pqr{
    width: 100%;
    margin-left: 0%;
    align-items: center;
  }

  .pie-pqr{
    margin-top: auto;
    height: auto;
  }

  .contenedor-pqr{
    z-index: 0;
    margin-top: 0%;
    position: relative;
    border-radius: 20px;
    opacity: 0.8;
    width: 100vw;
    height: auto;
    margin-left: 0vw;
    margin-right: 0vw;
    align-items: center;
    padding: 0px;
  }

  .input-drop-pqr{
    width: 80%;
    height: 40px;
    border-radius: 10px;
    margin: 10px;
    margin-bottom: 10px;
    font-size: 1em;
    font-family: 'EngraversGothic';
    padding: 10px;
    border-radius: 5px;
    border-style: ridge;
  }

  .txtMensaje-pqr{
    width: 80%;
    height: 50%;
    border-radius: 5px;
    margin: 15px;
    margin-bottom: 10px;
    font-size: 1em;
    font-family: 'EngraversGothic';
    padding: 10px;
    border:0cap;
    z-index: 0;
  }

  .btn-mensaje-pqr{
    width: 80%;
    height: 65px;
    padding: 10px;
    border-radius: 20px;
    font-family: 'EngraversGothic BT';
    font-size: 1.5em;
    margin-left: 0%;
    background-color:#89B91C;
    color: white; 
    z-index: 1;
  }

}

/* Css 1701 px a 1920 pixeles landscape--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (min-width: 1701px) and (max-width: 1920px) and (orientation:landscape){
  .tete{
    display: flex;
    flex-direction: row;
    height: auto;
    padding: 0px;
    font-size: 1.5em;
  }

  .player-video{
    width: 50%;
    height: 500px;
  }

  .imagenLogodash{
    width: 8%;
    height: 60px;
    opacity: .9;
    margin-left: 2%;
    background-color: #006C85;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-right: 1%;
  } 

  .btn-anuncio{
    width: 80%;
    height: 60px;
    margin-left: 0%;
    margin-top: 3%;
    padding: 1em 1.3em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.1em;
    color: #000;
    background-color: #89B91C;
    border: none;
    border-radius: 15px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.btn-anuncio:hover{
  background-color: #e96656;
  box-shadow: 0px 15px 20px rgba(233,102,86,0.4);
  font-size: 1.1em;
  color: #fff;
  transform: translateY(-7px);
}



/* Card service 1920---------*/
  .card-servicio {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    overflow: hidden;
    border-radius: 10px;
    height: 450px;
  }
  
  .content-servicio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    color: #e8e8e8;
    width: 40%;
    
  }
  
  .content-servicio .heading {
    font-weight: 1500;
    font-size: 50px;
    text-align: left;
    justify-content: center;

  }
  
  .content-servicio .para {
    line-height: 1;
    font-weight: 80px;
    font-size: 35px;
    text-align: justify;
    
  }
  
  .content-servicio .btn {
    color: #e8e8e8;
    text-decoration: none;
    padding: 10px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .card-servicio::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    z-index: -1;
  }
  
  .card-servicio:hover::before {
    height: 100%;
    cursor: pointer;
  }
  
  .card-servicio:hover {
    box-shadow: none;
    background: blur(256px);
  }

  .foto-perfil{
    width: 30%;
    height: auto;
    border-radius: 15px;
  }

  .indicador{
    width: 40%;
    height: auto;
    border-radius: 15px;
  }

  .botonera-cambios{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    height: 10%;
  }

  .btn-editar{
    width: 30%;
    height: 30px;
    background-color: #006C85;
    font-size: 1.5em;
    font-family: 'EngraversGothic BT';
    color: white;
    margin-right: 20px;
    border-radius: 10px;
  }

  .btn-eliminar{
    width: 30%;
    height: 30px;
    background-color: #006C85;
    font-size: 1.5em;
    font-family: 'EngraversGothic BT';
    color: white;
    border-radius: 10px;
  }

  .btn-eliminar:hover{
    cursor: pointer;
    transform: translateY(-2px);
  }
  .btn-eliminar:active {
    transform: translateY(-1px);
  }

  .btn-editar:hover{
    cursor: pointer;
    transform: translateY(-2px);
  }
  .btn-editar:active {
    transform: translateY(-1px);
  }

  .seccion-foto-perfil{
    height: 20%;
  }

  .seccion-tareas{
    height: 20%;
    width: 100%;
  }

  .seccion-anotaciones{
    width: 100%;
    height: 20%;
  }
  .seccion-indicadores{
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .seccion-botonera{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .modal-user{
    background-color: #e8e8e8;
    width: auto;
    height: auto;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-direction: column;
    justify-content: center;
    display:grid;
    border-radius: 12px;
    padding: 30px;
    margin-left: 210%;
    margin-top: auto;
    position: absolute;
  }

  .btn-menu{
    width: 50px;
    height: 50px;
    margin-left: 80vw;
    background-color: #006C85;
  }

  .btn-salir{
    width: 50px;
    height: 50px;
    margin-left: 20px;
    background-color: #006C85;
    margin-right: 20px;
  }

  .modal-login{
    background-color: white;
    width: 100%;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    border-radius: 12px;
    padding: 5px;
    margin-left: -225px;
    margin-top: 150px;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
  }
  
  .modal-message{
    background-color:white;
    width: 100%;
    height: auto;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    border-radius: 12px;
    padding: 5px;
  }
  .info-btn-modal{
    margin: 10px;
  }
  
  .modal-background{
    width: 30vw;
    height: auto;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
    margin-top: 15%;
  }

  .titulo-seccion-galery{
    font-family: 'EngraversGothic BT';
    width: 500px;
    text-align: center;
    margin-top: 20px;
    font-size: 1.5em;
  }

  .caja-contenido-productos{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    height: auto;
    font-family: 'EngraversGothic';
  }

  .linea-division-servicios{
    color: #89B91C;
    display: flex;
    flex-direction: row;
    font-family: 'EngraversGothic';
    font-size: 1em;
    align-items: center;
    width: 25%;
    margin-left: 10%;
    margin-right: 10%;
    height: 60px;
    z-index: 0;
    margin-bottom: 50px;
  }

  .p-caja-cont{
    font-size: 1em;
    font-family: 'EngraversGothic BT';
    text-align: justify;
    margin: 20px; 
    z-index: 0;
    width: 55%;
  }

  .p-caja-cont-completo{
    font-size: 1.2em;
    font-family: 'EngraversGothic BT';
    text-align: justify;
    margin: 20px; 
    z-index: 0;
    width: 100%;
  }

  

  .elemento-tete-page{
    font-size: 0.7em;
  }
  
  .body{
    width: 50vw;
    height: auto;
  }

  .video-index{
    margin-top: 5%;
    width: 70vw;
    height: auto;
    margin-left: 15vw;
    display: flex;
    flex-direction: row;
  }

  .titulo-seccion{
    font-size: 1.2em;
  }

  .caja-contenido{
    width: 50vw;
    margin-left:25vw;
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    padding: 0%;
    opacity: 0.9;    
  }

  .img-caja-cont-mision{
    width: 45%;
    height: 50%;
    margin-top: 6%;
    opacity: 1;
  }

  .img-caja-cont-vision{
    width: 45%;
    height: 50%;
    margin-left: 0%;
    opacity: 1;
  }

  .contacto{
    display: flex;
    flex-direction: row;
    width: 60vw;
    height: auto;
    margin-left: 20vw;
  }

  .formulario-contacto-contactus{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    
    padding: 5px;
    border-radius: 0px;
    margin-bottom: 30px;
    height: auto;
   
  }

  .formulario-contacto{
    width: 40%;
    margin-left: 2.5%;
    margin-right: 5%;
    
    padding: 5px;
    border-radius: 0px;
    margin-bottom: 30px;
    height: 450px;
  }

  .esp-mapa{
    width: 50%;
    height: 500px;
    margin-left: 0%;
    margin-top: 0%;
  }

  .btn-mensaje-index{
    width: 80%;
    height: 40px;
    margin-left: 10%;
    margin-top: 0%;
    padding: 1em 1.5em;
    font-size: 0.5em;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #000;
    background-color: #89B91C;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.btn-mensaje-index:hover{
  background-color: #e96656;
  box-shadow: 0px 15px 20px rgba(233,102,86,0.4);
  font-size: 0.6em;
  color: #fff;
  transform: translateY(-4px);
}
.btn-mensaje-index:active{
  transform: translateY(-1px);
}

  .input-drop-index{
    width: 95%;
    font-size: 0.7em;
    border-style:ridge;
  }
  .txtMensaje{
    width: 95%;
    font-size: 0.7em;
    border-style:ridge;
    margin-left: 2%;
  }

  .pie{
    width: 100vw;
    height: 30vh;
  }

  .horario{
    width: 20%;
  }

  .datos{
    width: 30%;
    margin-left: 10%;
  }

  .copy-redes{
    width: 20%;
    margin-left: 30%;
  }
  
  .imagenLogo{
    width: 700px;
  }

  .btn-volver{
    width: 50px;
    margin: 0px;
    margin-top: 10px;
  }

  .contenedor-servicios{
    width: 70vw;
    margin-left: 15vw;
    margin-right: 15vw;
    margin-top: 10vh;
    margin-bottom: 10vh;
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    height: 70%;
    padding: 0%;
  }

  .caja-contenido-services{
    width: 100%;
    margin: 0%;
    height: auto;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: justify;
  }

  .linea-division{
    color: #89B91C;
    display: flex;
    flex-direction: row;
    font-family: 'EngraversGothic';
    font-size: 1em;
    align-items: center;
    width: 100%;
    margin-left:0%;
    margin-right: 0%;
  }

  .video-servicios{
    z-index: 0;
    width: 58%;
    height: 400px;
    margin-left: 2%;
    margin-bottom: 0%;
    object-fit: cover;
    border-radius: 2px;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    transform: none !important;
    padding: 10px;
    opacity: 1;
  }
  .galery{
    width: 100vw;
    height: auto;
    margin-left: 0vw;
  }

  .contenedor-galery{
    width: 60%;
    margin-left: 20%;
    margin-bottom: 4%;
  }

  .imgSlide{
    width: 85%;
    height: 90%;
  }

  .pie-galery{
    margin-top: auto;
    height: auto;
  }

  .caja-contenido-pqr{
    display: flex;
    flex-direction: row;
    z-index: 0;
  }

  .p-caja-cont-pqr{
    width: 100%;
    z-index: 0;
    font-size: 1.3em;
  }

  .form-pqr{
    width: 70%;
    margin-left: 0%;
    height: auto;
    align-items: center;
    
    border-radius: 0px;
    padding: 15px;
    margin-bottom: 20px;
  }

  .pie-pqr{
    margin-top: auto;
    height: auto;
  }

  .contenedor-pqr{
    z-index: 0;
    margin-top: 0%;
    position: relative;
    border-radius: 20px;
    opacity: 0.8;
    width: 70vw;
    height: auto;
    margin-left: 15vw;
    margin-right: 15vw;
    align-items: center;
    padding: 0px;
  }

  .input-drop-pqr{
    width: 95%;
    height: 40px;
    border-radius: 10px;
    margin: 10px;
    margin-bottom: 10px;
    font-size: 1em;
    font-family: 'EngraversGothic';
    padding: 10px;
    border-radius: 5px;
    border-style: ridge;
  }

  .txtMensaje-pqr{
    width: 95%;
    height: 50%;
    border-radius: 5px;
    margin: 15px;
    margin-bottom: 10px;
    font-size: 1em;
    font-family: 'EngraversGothic';
    padding: 10px;
    border:0cap;
    z-index: 0;
  }

  .btn-mensaje-pqr{
    width: 95%;
    height: 50px;
    margin-left: 0%;
    margin-top: 10px;
    padding: 1em 2em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 1.3em;
    color: #000;
    background-color: #89B91C;
    border: none;
    border-radius: 15px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}

.btn-mensaje-pqr:hover{
  background-color: #e96656;
  box-shadow: 0px 15px 20px rgba(233,102,86,0.4);
  font-size: 1.3em;
  color: #fff;
  transform: translateY(-7px);
}
.btn-mensaje-pqr:active{
  transform: translateY(-1px);
}

}

/* Css 1921 px a 3840 pixeles landscape  IMAC--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (min-width: 1921px) and (max-width: 3840px) and (orientation:landscape){
  .tete{
    display: flex;
    flex-direction: row;
    height: auto;
    padding: 0px;
    font-size: 1.5em;
  }

  .imagenLogodash{
    width: 8%;
    height: 60px;
    opacity: .9;
    margin-left: 0%;
    background-color: #006C85;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-right: 1%;
    margin-left: 1%;
  } 


  .btn-menu{
    width: 50px;
    height: 50px;
    margin-left: 85vw;
    background-color: #006C85;
  }

  .btn-salir{
    width: 50px;
    height: 50px;
    margin-left: 20px;
    background-color: #006C85;
    margin-right: 20px;
  }

  .modal-login{
    background-color: white;
    width: 100%;
    height: auto;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    border-radius: 12px;
    padding: 5px;
    margin-left: 0vw;
  }
  
  .modal-message{
    background-color:white;
    width: 100%;
    height: auto;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    border-radius: 12px;
    padding: 5px;
  }
  .info-btn-modal{
    margin: 10px;
  }
  
  .modal-background{
    width: 30vw;
    height: auto;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
    margin-top: 15%;
  }

  .titulo-seccion-galery{
    font-family: 'EngraversGothic BT';
    width: 500px;
    text-align: center;
    margin-top: 20px;
    font-size: 1.5em;
  }

  .caja-contenido-productos{
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    height: auto;
    font-family: 'EngraversGothic';
  }

  .linea-division-servicios{
    color: #89B91C;
    display: flex;
    flex-direction: row;
    font-family: 'EngraversGothic';
    font-size: 1em;
    align-items: center;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    height: 60px;
    z-index: 0;
    margin-bottom: 50px;
  }

  .p-caja-cont{
    font-size: 1.3em;
    font-family: 'EngraversGothic BT';
    text-align: justify;
    margin: 20px; 
    z-index: 0;
    width: 60%;
  }

  .p-caja-cont-completo{
    font-size: 1.3em;
    font-family: 'EngraversGothic BT';
    text-align: justify;
    margin: 20px; 
    z-index: 0;
    width: 100%;
  }

  .linea-division{
    color: #89B91C;
    display: flex;
    flex-direction: row;
    font-family: 'EngraversGothic';
    font-size: 1em;
    align-items: center;
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }

  .elemento-tete-page{
    font-size: 0.7em;
  }
  
  .body{
    width: 50vw;
    height: auto;
  }

  .titulo-seccion{
    font-size: 1.5em;
  }

  .caja-contenido{
    width: 70%;
    margin-left:15%;
    display: flex;
    flex-direction: row;
    
  }

  .img-caja-cont-mision{
    width: 40%;
    height: auto;
    margin: 0%;
  }

  .img-caja-cont-vision{
    width: 40%;
    height: auto;
    margin-left: 0%;
  }

  .contacto{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    margin-left: 0%;
  }

  .formulario-contacto{
    width: 30%;
    margin-left: 5%;
    margin-right: 5%;
   
    padding: 20px;
    border-radius: 0px;
    margin-bottom: 30px;
    height: 550px;
    align-items: center;
    justify-content: center;
  }

  .input-drop-index{
    width: 95%;
    font-size: 1.2em;
    border-style:ridge;
    height: 50px;
  }
  .txtMensaje{
    width: 95%;
    font-size: 1.2em;
    border-style:ridge;
    margin-left: 2%;
  }
  .esp-mapa{
    width: 55%;
    height: 610px;
    margin-left: 0%;
    margin-top: 0%;
  }

  .pie{
    width: 100%;
    height: auto;
    margin-top: 0%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:#006C85;
    margin-bottom: 0%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .horario{
    width: 20%;
  }

  .datos{
    width: 40%;
  }

  .copy-redes{
    width: 20%;
    margin-left: 30%;
  }
  
  .imagenLogo{
    width: 700px;
  }

  .btn-volver{
    width: 150px;
  }

  .pie-products{
    margin-top: 5.1%;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:#006C85;
    margin-bottom: 0%;
  }
  .contenedor-servicios{
    width: 70vw;
    margin-left: 15vw;
    margin-right: 0vw;
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    height: auto;
  }

  .caja-contenido-services{
    width: 100%;
    margin: 0%;
    height: auto;
    position: relative;
    z-index: 0;
    margin-top: 50px;
  }

  .video-servicios{
    z-index: 0;
    width: 50%;
    height: auto;
    margin-left: 0%;
    margin-bottom: 0%;
    margin-top: 0%;
    object-fit: cover;
    border-radius: 2px;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    transform: none !important;
    padding: 0px;
    opacity: 1;
  }

  .pie-servicios{
    margin-top: 0%;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color:#006C85;
    margin-bottom: 0%;
  }
  .galery{
    width: 100vw;
    height: auto;
    margin-left: 0vw;
  }

  .contenedor-galery{
    width: 60%;
    margin-left: 20%; 
    height: auto;
  }

  .imgSlide{
    width: auto;
    margin-left: 15%;
    margin-bottom: 6.3%;
  }

  .pie-galery{
    margin-top: auto;
    height: auto;
  }

  .caja-contenido-pqr{
    display: flex;
    flex-direction: row;
    z-index: 0;
  }

  .p-caja-cont-pqr{
    width: 100%;
    z-index: 0;
    font-size: 1.3em;
  }

  .form-pqr{
    width: 100%;
    margin-left: 0%;
    align-items: center;
  }

  .pie-pqr{
    margin-top: auto;
    height: auto;
  }

  .contenedor-pqr{
    z-index: 0;
    margin-top: 0%;
    position: relative;
    border-radius: 20px;
    opacity: 0.8;
    width: 60vw;
    height: auto;
    margin-left: 20vw;
    margin-right: 20vw;
    align-items: center;
    padding: 0px;
    margin-bottom: 8.8%;
  }

  .input-drop-pqr{
    width: 80%;
    height: 40px;
    border-radius: 10px;
    margin: 10px;
    margin-bottom: 10px;
    font-size: 1em;
    font-family: 'EngraversGothic';
    padding: 10px;
    border-radius: 5px;
    border-style: ridge;
  }

  .txtMensaje-pqr{
    width: 80%;
    height: 50%;
    border-radius: 5px;
    margin: 15px;
    margin-bottom: 10px;
    font-size: 1em;
    font-family: 'EngraversGothic';
    padding: 10px;
    border:0cap;
    z-index: 0;
  }

  .btn-mensaje-pqr{
    width: 80%;
    height: 65px;
    padding: 10px;
    border-radius: 20px;
    font-family: 'EngraversGothic BT';
    font-size: 1.5em;
    margin-left: 0%;
    background-color:#89B91C;
    color: white; 
    z-index: 1;
  }
  .content{
    width: 70vw;
    margin-left: 15vw;
  }

  .video-index{
    z-index: 1;
    width: 100%;
    height: auto;
    position: absolute;
    margin-top: 5%;
    opacity: 1;
    margin-left: 0%;
    border-radius: 30px;
    margin-bottom: 0%;
    object-fit: cover;
    border-radius: 20px;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    transform: none !important;
    padding: 20px;
    opacity: 1;  
    display: flex;
    flex-direction: row;
  }

  

  .linea-horizontal-index{
    width: 40%;
    color: #89B91C;
    size: 1em;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10pxpx;
  }

  .card-servicio {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    overflow: hidden;
    border-radius: 10px;
    height: 450px;
    margin-bottom: 200px;
    margin-top: 150px;
  }

  .content-servicio {
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: #e8e8e8;
    width: 50%;
    margin-top: 10%;
    height: 500px;
  }
  
  .content-servicio .heading {
    font-weight: 1400px;
    font-size: 40px;
    text-align: left;
    justify-content: center;
  }
  
  .content-servicio .para {
    line-height: 12;
    font-weight: 70px;
    font-size: 50px;
    text-align: justify;
  }
  
  .content-servicio .btn {
    color: #e8e8e8;
    text-decoration: none;
    padding: 10px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }

  .anuncio-principal{
    font-size: 2.2em;
    font-family: Averta;
    width: 100%;
    font-weight: bold;
    margin-top: 0%;
    color: #000;
    margin-bottom: 1%;
  }

  .cuerpo-anuncio{
    font-family: Averta;
    color: #000;
    font-size: 1.2em;
    margin-top: 0%;
    text-align: justify;
    padding-right: 30px;
  }

  .btn-anuncio{
    width: 80%;
    height: 80px;
    margin-left: 0%;
    margin-top: 3%;
    padding: 1em 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 1.5em;
    color: #000;
    background-color: #89B91C;
    border: none;
    border-radius: 15px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    text-decoration: none;

}

.btn-anuncio:hover{
  background-color: #e96656;
  box-shadow: 0px 15px 20px rgba(233,102,86,0.4);
  font-size: 1.5em;
  color: #fff;
  transform: translateY(-7px);
}
.btn-anuncio:active{
  transform: translateY(-1px);
}

.anuncio-principal-quality{
  font-size: 2.8em;
  font-family: Averta;
  width: 100%;
  font-weight: bold;
  margin-top: 0%;
  color: #000;
}

.formulario-contacto-contactus{
  width: 100%;
  margin-left: 0%;
  padding: 30px;
  border-radius: 0px;
  margin-bottom: 30px;
  height: auto;
  
  
}



}


